<template>
  <AppModal :active="active" :header="true" :progress="100" title="" :overlayClose="true" @close="modalCloseDocumentCreateComplete()">
    <div class="ModalDocumentCreateComplete_container">
      <img src="@/assets/images/splash-upload-complete.png" alt="" />
      <div class="ModalDocumentCreateComplete_title">{{ $t("modals.DocumentCreateComplete.title." + document?.type) }}</div>
      <div class="ModalDocumentCreateComplete_text">{{ $t("modals.DocumentCreateComplete.text." + document?.type) }}</div>
      <div class="buttons">
        <AppButton size="medium" :iconLeft="false" iconType="document" @click="this.open()">{{ $t("modals.DocumentCreateComplete.buttons.open." + document?.type) }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentCreateComplete } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalDocumentCreateComplete",
  components: {
    AppButton,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: () => { return { type: "folder" }; }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentCreateComplete,
      toast
    };
  },
  methods: {
    open() {
      modalCloseDocumentCreateComplete();

      if (this.document?.type === "folder") {
        this.$router.push("/documents/" + this.document?.id);
      } else {
        this.$router.push("/document/" + this.document?.id);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentCreateComplete_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentCreateComplete_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalDocumentCreateComplete_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-left: auto;
  }
}
</style>
