<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 13.999L13 14C14.0538 14 14.9181 14.8155 14.9945 15.8507L15 16V17.5C14.999 21 11.284 22 8.5 22C5.77787 22 2.1647 21.044 2.00545 17.7296L2 17.5V15.999C2 14.9452 2.8164 14.0809 3.85081 14.0045L4 13.999ZM15.22 14H20C21.0538 14 21.9181 14.8164 21.9945 15.8508L22 16V17C21.999 20.062 19.142 21 17 21C16.32 21 15.569 20.904 14.86 20.678C15.196 20.292 15.467 19.851 15.662 19.351C16.205 19.476 16.715 19.5 17 19.5L17.2665 19.494C18.2518 19.4509 20.3529 19.1306 20.4927 17.205L20.5 17V16C20.5 15.7547 20.3222 15.5504 20.0896 15.5081L20 15.5H15.949C15.865 14.9986 15.6554 14.5432 15.3545 14.1598L15.22 14H20H15.22ZM4 15.499L3.89934 15.509C3.77496 15.5343 3.69 15.6018 3.646 15.645C3.6028 15.689 3.53528 15.7733 3.51 15.898L3.5 15.999V17.5C3.5 18.509 3.95 19.222 4.917 19.742C5.74315 20.1869 6.91951 20.4563 8.18258 20.4951L8.5 20.5L8.8174 20.4951C10.0803 20.4563 11.2559 20.1869 12.083 19.742C12.9886 19.2545 13.4416 18.5974 13.4947 17.6849L13.5 17.499V16C13.5 15.7547 13.3222 15.5504 13.0896 15.5081L13 15.5L4 15.499ZM8.5 3C10.985 3 13 5.015 13 7.5C13 9.985 10.985 12 8.5 12C6.015 12 4 9.985 4 7.5C4 5.015 6.015 3 8.5 3ZM17.5 5C19.433 5 21 6.567 21 8.5C21 10.433 19.433 12 17.5 12C15.567 12 14 10.433 14 8.5C14 6.567 15.567 5 17.5 5ZM8.5 4.5C6.846 4.5 5.5 5.846 5.5 7.5C5.5 9.154 6.846 10.5 8.5 10.5C10.154 10.5 11.5 9.154 11.5 7.5C11.5 5.846 10.154 4.5 8.5 4.5ZM17.5 6.5C16.397 6.5 15.5 7.397 15.5 8.5C15.5 9.603 16.397 10.5 17.5 10.5C18.603 10.5 19.5 9.603 19.5 8.5C19.5 7.397 18.603 6.5 17.5 6.5Z" />
  </svg>
</template>

<script>
export default {
  name: "IconPeople",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>