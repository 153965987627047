<template>
  <AppHeader />
  <div class="AppProfile_container">
    <div class="AppProfile_left">
      <ProfileNavigation />
    </div>
    <div class="AppProfile_right">
      <div class="AppProfile_header">
        <AppAvatar size="large" :name="Account.fullName" />
        <div class="AppProfile_info-container">
          <div class="AppProfile_name">{{ Account.fullName }}</div>
          <div class="AppProfile_email">{{ Account.email }}</div>
        </div>
      </div>
      <div class="AppProfile_content">
        <ProfileLanguage v-if="page === 'language-and-region' || page === '' || page === undefined" />
        <ProfileOrganizations v-if="page === 'organizations'" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import ProfileNavigation from "@/components/ProfileNavigation";
import AppAvatar from "@/components/AppAvatar";
import Account from "@/core/account";
import ProfileLanguage from "@/components/ProfileLanguage";
import ProfileOrganizations from "@/components/ProfileOrganizations";

export default {
  name: "AppProfile",
  components: {
    ProfileOrganizations,
    ProfileLanguage,
    AppAvatar,
    ProfileNavigation,
    AppHeader
  },
  setup() {
    return {
      Account
    };
  },
  computed: {
    page() {
      return this.$route.params.page;
    }
  }
}
</script>

<style lang="scss" scoped>
.AppProfile_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppProfile_left {
    display: flex;
    flex-direction: column;
  }

  .AppProfile_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    .AppProfile_header {
      border-radius: 0.75rem;
      border: 0.03125rem solid #c3c8cc;
      padding: 1.0rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.0rem;

      .AppProfile_info-container {
        display: flex;
        flex-direction: column;

        .AppProfile_name {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.25rem;
          color: #2d2d2d;
        }

        .AppProfile_email {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.0rem;
          color: #626d78;
        }
      }
    }

    .AppProfile_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 2.0rem;
    }
  }
}
</style>
