<template>
  <div class="container">
    <div class="avatar">
      <AppAvatar :name="signee.fullName" />
    </div>
    <div class="info">
      <div class="name">{{ signee.fullName === " " ? signee.email : signee.fullName }}</div>
      <div class="email">{{ signee.fullName === " " ? '' : signee.email }}</div>
    </div>
    <div v-if="Account.permissions?.documents?.edit?.documents && document.state < Document.State.Signing" class="button" @click="remove()">
      <AppIcon type="minus" />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppAvatar from "@/components/AppAvatar";
import SigningService from "@/services/signing";
import { checkErrors } from "@/utilities/errors";
import events from "@/events";
import { useToast } from "vue-toastification";
import Account from "@/core/account";
import Document from "@/core/document";

export default {
  name: "DocumentNavigationSignee",
  components: {
    AppAvatar,
    AppIcon
  },
  props: {
    document: {
      type: Object,
      default: null
    },
    signee: {
      type: Object,
      default: null
    }
  },
  setup() {
    const toast = useToast();

    return {
      Account,
      Document,
      events,
      toast
    };
  },
  methods: {
    remove() {
      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Signing.deleteSignee(this.signee.id)
          .then(() => {
            this.toast.success(this.$t("components.DocumentNavigationSignee.toasts.remove.success"));

            this.events.$emit("signee:remove", { signee: this.signee });
          })
          .catch((error) => {
            this.toast.error(this.$t("components.DocumentNavigationSignee.toasts.remove.failed"));

            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  align-items: center;

  .avatar {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 0.75rem;
  }

  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding-top: 0.25rem;

    .name {
      font-weight: 600;
      color: #2d2d2d;
      font-size: 0.875rem;
      line-height: 1.0rem;
    }

    .email {
      font-weight: 400;
      color: #626d78;
      font-size: 0.75rem;
      line-height: 1.0rem;
    }
  }

  .button {
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
  }
}
</style>
