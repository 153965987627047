<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V16.8242C20.0032 17.7185 19.6836 18.5833 19.1021 19.2627C17.5327 21.0962 15.1455 22.0011 12.0001 22.0011C8.85426 22.0011 6.46825 21.0959 4.90194 19.2617C4.32218 18.5828 4.00366 17.7193 4.00366 16.8265V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM17.7543 15.4999H6.25254C5.83895 15.4999 5.50366 15.8352 5.50366 16.2488V16.8265C5.50366 17.3622 5.69477 17.8802 6.04263 18.2876C7.29594 19.7553 9.26182 20.5011 12.0001 20.5011C14.7384 20.5011 16.706 19.7553 17.9625 18.2873C18.3114 17.8797 18.5032 17.3608 18.5032 16.8242V16.2488C18.5032 15.8352 18.1679 15.4999 17.7543 15.4999ZM12.0001 2.00464C14.7615 2.00464 17.0001 4.24321 17.0001 7.00464C17.0001 9.76606 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76606 7.00012 7.00464C7.00012 4.24321 9.2387 2.00464 12.0001 2.00464ZM12.0001 3.50464C10.0671 3.50464 8.50012 5.07164 8.50012 7.00464C8.50012 8.93764 10.0671 10.5046 12.0001 10.5046C13.9331 10.5046 15.5001 8.93764 15.5001 7.00464C15.5001 5.07164 13.9331 3.50464 12.0001 3.50464Z" />
  </svg>
</template>

<script>
export default {
  name: "IconProfile",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>