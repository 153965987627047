<template>
  <div class="SettingsBilling_container">
    <div class="SettingsBilling_header">{{ $t("components.SettingsBilling.header") }}</div>
    <template v-if="loaded">
      <div class="SettingsBilling_sub-header">{{ $t("components.SettingsBilling.balance.header") }}</div>
      <div class="SettingsBilling_content">
        <div class="text">{{ $t("components.SettingsBilling.balance.text") }} <AppPrice :amount="currentBalance" /></div>
        <template v-if="billing.paymentMethod">
          <div class="text">&nbsp;</div>
          <div class="text">{{ $t("components.SettingsBilling.balance.more") }}</div>
          <AppButton @click="modalOpenBalanceAdd()" size="small" style="margin-top: 0.5rem">{{ $t("components.SettingsBilling.buttons.addBalance") }}</AppButton>
        </template>
      </div>

      <div class="SettingsBilling_sub-header">{{ $t("components.SettingsBilling.paymentMethod.header") }}</div>
      <div class="SettingsBilling_content">
        <div v-if="!billing.paymentMethod" class="no-method">
          <div class="text">{{ $t("components.SettingsBilling.paymentMethod.notFound") }}</div>
          <AppButton @click="modalOpenPaymentMethodAdd()" size="small" iconType="credit-card" type="outline" primaryColor="#4198f0">{{ $t("components.SettingsBilling.buttons.addPaymentMethod") }}</AppButton>
        </div>
        <div v-if="billing.paymentMethod" class="method">{{ $t("components.SettingsBilling.paymentMethod.active") }}</div>
      </div>

      <div class="SettingsBilling_sub-header">{{ $t("components.SettingsBilling.history.header") }}</div>
      <div class="SettingsBilling_content">
        <div v-if="billing.transactions.length > 0" class="transactions">
          <div class="transaction" style="border-bottom: 0.03125rem solid #626d78; height: 2.5rem">
            <div class="icon" style="font-weight: 600; color: #2d2d2d"></div>
            <div class="name" style="font-weight: 600; color: #2d2d2d">{{ $t("components.SettingsBilling.transaction.title.name") }}</div>
            <div class="amount" style="font-weight: 600; color: #2d2d2d">{{ $t("components.SettingsBilling.transaction.title.amount") }}</div>
            <div class="date" style="font-weight: 600; color: #2d2d2d">{{ $t("components.SettingsBilling.transaction.title.date") }}</div>
          </div>
          <div class="transaction" v-for="transaction in billing.transactions" :key="transaction.id">
            <div class="icon">
              <AppIcon v-if="transaction.amount > 0" type="arrow-up" color="#3ac178" />
              <AppIcon v-else type="arrow-down" color="#f14a4a" />
            </div>
            <div class="name">{{ $t("components.SettingsBilling.transactions." + transaction.name) }}</div>
            <div class="amount"><AppPrice :amount="transaction.amount" /></div>
            <div class="date">{{ formatDate(transaction.created) }}</div>
          </div>
        </div>
        <div v-if="billing.transactions.length === 0" class="no-transactions">{{ $t("components.SettingsBilling.history.noTransactions") }}</div>
      </div>
    </template>
    <div v-else class="SettingsBilling_content" style="display: flex; flex-direction: column; flex: 1"><AppLoading  /></div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import AppButton from "@/components/AppButton";
import { formatDate } from "@/utilities";
import AppPrice from "@/components/AppPrice";
import { modalOpenBalanceAdd, modalOpenPaymentMethodAdd } from "@/modals";
import PaymentsService from "@/services/payments";
import { checkErrors } from "@/utilities/errors";
import PlanService from "@/services/plan";
import Transaction from "@/core/transaction";
import AppLoading from "@/components/AppLoading";
import AppIcon from "@/components/AppIcon";
import Account from "@/core/account";
import events from "@/events";

export default {
  name: "SettingsBilling",
  components: {
    AppIcon,
    AppPrice,
    AppButton,
    AppLoading
  },
  setup() {
    const toast = useToast();

    return {
      modalOpenPaymentMethodAdd,
      modalOpenBalanceAdd,
      toast,
      formatDate,
      Account,
      events
    };
  },
  data() {
    return {
      neededToLoad: 2,
      loadCount: 0,
      billing: {
        paymentMethod: false,
        transactions: []
      },
      currentBalance: 0
    };
  },
  mounted() {
    this.currentBalance = Account.selectedOrganization?.plan?.balance;

    this.events.$on("balance:update", (data) => {
      this.currentBalance += data.value;
      this.billing.transactions.unshift({ id: "0x0", name: "balance", amount: data.value, created: (new Date()).toISOString() });
    });

    this.getPaymentMethod();
    this.getTransactions();
  },
  methods: {
    getPaymentMethod() {
      const Payments = new PaymentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Payments.canCharge()
          .then((data) => {
            if (data.status === "OK") {
              this.billing.paymentMethod = true;
            } else if (data.status === "PAYMENT_METHOD_REQUIRED") {
              this.billing.paymentMethod = false;
            }

            this.loadCount++;
          })
          .catch((error) => {
            checkErrors(error);

            this.loadCount++;
          });
    },
    getTransactions() {
      const Plan = new PlanService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Plan.getTransactions()
          .then((data) => {
            let transactions = [];

            for (let i = 0; i < data?.transactions.length; i++) {
              transactions.push(new Transaction(data?.transactions[i]));
            }

            this.billing.transactions = transactions;

            this.loadCount++;
          })
          .catch((error) => {
            checkErrors(error);

            this.loadCount++;
          });
    }
  },
  computed: {
    loaded() {
      return this.loadCount >= this.neededToLoad;
    }
  }
}
</script>

<style lang="scss" scoped>
.SettingsBilling_container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .SettingsBilling_header {
    display: flex;
    flex-direction: row;
    height: 2.875rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
  }

  .SettingsBilling_sub-header {
    display: flex;
    flex-direction: row;
    height: 2.875rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #163b61;
    margin-top: 1.5rem;
  }

  .SettingsBilling_content {
    .method {
      color: #626d78;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .text {
      color: #626d78;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .no-method {
      color: #626d78;
      font-size: 0.875rem;
      font-weight: 400;

      .text {
        margin-bottom: 0.75rem;
      }
    }

    .transactions {
      display: flex;
      flex-direction: column;
      max-width: 78.5rem;

      .transaction {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 3.5rem;
        border-bottom: 0.03125rem solid #c3c8cc;

        .icon {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 2.0rem;
        }

        .name {
          flex: 3;
          color: #2d2d2d;
          font-weight: 500;
          font-size: 0.875rem;
        }

        .amount {
          flex: 1;
          color: #626d78;
          font-weight: 400;
          font-size: 0.875rem;
        }

        .date {
          flex: 1;
          color: #626d78;
          font-weight: 400;
          font-size: 0.875rem;
        }
      }
    }

    .no-transactions {
      color: #626d78;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}
</style>
