<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.1499 5.9999C4.1499 5.53046 4.53046 5.1499 4.9999 5.1499H18.9999C19.4693 5.1499 19.8499 5.53046 19.8499 5.9999C19.8499 6.46934 19.4693 6.8499 18.9999 6.8499H4.9999C4.53046 6.8499 4.1499 6.46934 4.1499 5.9999ZM4.1499 11.9999C4.1499 11.5305 4.53046 11.1499 4.9999 11.1499H18.9999C19.4693 11.1499 19.8499 11.5305 19.8499 11.9999C19.8499 12.4693 19.4693 12.8499 18.9999 12.8499H4.9999C4.53046 12.8499 4.1499 12.4693 4.1499 11.9999ZM4.1499 17.9999C4.1499 17.5305 4.53046 17.1499 4.9999 17.1499H18.9999C19.4693 17.1499 19.8499 17.5305 19.8499 17.9999C19.8499 18.4693 19.4693 18.8499 18.9999 18.8499H4.9999C4.53046 18.8499 4.1499 18.4693 4.1499 17.9999Z" />
  </svg>
</template>

<script>
export default {
  name: "IconBurger",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>