export default class Document {
    static get State() {
        return {
            Draft: 1,
            Published: 10,
            Signing: 20,
            Signed: 30,
            Archived: 40,
            Deleted: 100,
            DeletedPermanently: 101,
            Hidden: 200
        };
    }

    constructor(document) {
        this._document = document;
        console.log(this._document);
    }

    get id() {
        return this._document?.id;
    }

    get created() {
        return this._document?.created;
    }

    get updated() {
        return this._document?.updated;
    }

    get name() {
        return this._document?.name;
    }

    set name(name) {
        this._document.name = name;
    }

    get type() {
        return this._document?.type;
    }

    get driveId() {
        return this._document?.driveId;
    }

    get children() {
        if (this._document?.children.length > 0) {
            return this._document?.children;
        }

        return [];
    }

    addChild(child) {
        this._document.children.push(child);
    }

    get extension() {
        return this._document?.extension;
    }

    get state() {
        return this._document?.state;
    }

    get platform() {
        return this._document?.platform;
    }

    get owner() {
        return this._document?.owner;
    }

    get roles() {
        return this._roles;
    }

    get driveUrl() {
        return this._document?.driveUrl;
    }

    setRoles(data) {
        let roles = [];

        for (let role of data) {
            roles.push(role);
        }

        this._roles = roles;
    }

    get isTemplate() {
        return this.type === "template";
    }

    get isDocument() {
        return this.type === "contract" || this.type === "contract-noedit";
    }

    get isEditable() {
        let typeCheck = (this.isDocument || this.isTemplate) && this.type !== "contract-noedit";

        return typeCheck && this.state < Document.State.Signing;
    }

    get marketplace() {
        return this._document?.marketplace;
    }
}
