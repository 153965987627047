<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.45 2.15C14.992 4.05652 17.5866 5 20.25 5C20.6642 5 21 5.33579 21 5.75V11C21 16.0012 18.0424 19.6757 12.2749 21.9478C12.0982 22.0174 11.9018 22.0174 11.7251 21.9478C5.95756 19.6757 3 16.0012 3 11V5.75C3 5.33579 3.33579 5 3.75 5C6.41341 5 9.00797 4.05652 11.55 2.15C11.8167 1.95 12.1833 1.95 12.45 2.15ZM12 3.67782C9.58084 5.38829 7.07735 6.32585 4.5 6.47793V11C4.5 15.2556 6.95337 18.3789 12 20.4419C17.0466 18.3789 19.5 15.2556 19.5 11V6.47793C16.9227 6.32585 14.4192 5.38829 12 3.67782ZM9.28091 8.22158L12.004 10.945L14.7287 8.22158C14.9946 7.95565 15.4108 7.93147 15.704 8.14906L15.788 8.22158C16.054 8.48751 16.0781 8.90365 15.8606 9.1969L15.788 9.28091L13.064 12.004L15.788 14.7287C16.0806 15.0212 16.0806 15.4955 15.788 15.788C15.4955 16.0806 15.0212 16.0806 14.7287 15.788L12.004 13.064L9.28091 15.788C9.01498 16.054 8.59884 16.0781 8.3056 15.8606L8.22158 15.788C7.95565 15.5221 7.93147 15.106 8.14906 14.8127L8.22158 14.7287L10.945 12.004L8.22158 9.28091C7.92906 8.98839 7.92906 8.51411 8.22158 8.22158C8.51411 7.92906 8.98839 7.92906 9.28091 8.22158Z" />
  </svg>
</template>

<script>
export default {
  name: "IconBlocked",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>