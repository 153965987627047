<template>
  <div ref="AppInputSelect" class="AppInputSelect_container">
    <div @click="open = !open" class="AppInputSelect_select" :class="open ? ' AppInputSelect_open' : ''">
      <div class="AppInputSelect_selected-option">{{ selectedOption.name }}</div>
      <div class="AppInputSelect_icon">
        <AppIcon v-if="open" type="chevron-up" color="#626d78" />
        <AppIcon v-else type="chevron-down" color="#626d78" />
      </div>
    </div>
    <div v-show="open" class="AppInputSelect_dropdown">
      <template v-for="option in options" :key="option.value">
        <div @click="select(option)" class="AppInputSelect_option">{{ option.name }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "AppInputSelect",
  components: {
    AppIcon
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: String
    }
  },
  watch: {
    selected: function(value) {
      this.options.forEach((option) => {
        if (option.value === value) {
          this.selectedOption = option;
        }
      });
    }
  },
  data() {
    const closeListener = (e) => {
      if (!this.$refs.AppInputSelect.contains(e.target)) {
        this.open = false;
      }
    };

    return {
      closeListener,
      open: false,
      selectedOption: { name: this.placeholder, value: null }
    };
  },
  methods: {
    select(option) {
      this.open = false;
      this.selectedOption = option;
      this.$emit("update:modelValue", option.value);
    }
  },
  mounted() {
    document.addEventListener("click", this.closeListener);

    this.options.forEach((option) => {
      if (option.value === this.selected) {
        this.selectedOption = option;
      }
    });
  },
  unmounted() {
    document.removeEventListener("click", this.closeListener);
  },
  emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
.AppInputSelect_container {
  position: relative;
  flex: 1;

  .AppInputSelect_select {
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    border: 0.03125rem solid #d9d9d9;
    height: 2.3125rem;
    background-color: white;
    align-items: center;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    cursor: pointer;

    .AppInputSelect_selected-option {
      flex: 1;
      color: #626d78;
      line-height: 1.0rem;
      font-size: 0.875rem;
      font-weight: 400;
      padding-top: 0.125rem;
    }

    .AppInputSelect_icon {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .AppInputSelect_dropdown {
    z-index: 10;
    display: flex;
    flex-direction: column;
    border: 0.03125rem solid #d9d9d9;
    border-radius: 0 0 0.5rem 0.5rem;
    border-top: none;
    background-color: white;
    position: absolute;
    width: calc(100% - (0.03125rem * 2));

    .AppInputSelect_option {
      padding: 0.5rem 1.0rem;
      line-height: 1.0rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #2d2d2d;
      cursor: pointer;
    }

    .AppInputSelect_option:hover {
      background-color: #f0f0f0;
    }
  }

  .AppInputSelect_open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
