<template>
  <div @click="$emit('click')" class="NavigationOrganizationItem_item">
    <div class="NavigationOrganizationItem_avatar" :class="description === '' ? 'NavigationOrganizationItem_plus' : ''">
      <AppAvatar v-if="description !== ''" :name="name" />
      <AppIcon v-else type="plus-circle" color="#4198F0" />
    </div>
    <div class="NavigationOrganizationItem_text">
      <div class="NavigationOrganizationItem_name">{{ name }}</div>
      <div class="NavigationOrganizationItem_description">{{ description }}</div>
    </div>
    <div v-if="icon" class="NavigationOrganizationItem_icon">
      <AppIcon type="checkmark" color="#4198f0" />
    </div>
  </div>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppIcon from "@/components/AppIcon";

export default {
  name: "NavigationOrganizationItem",
  components: {
    AppIcon,
    AppAvatar
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"]
}
</script>

<style lang="scss" scoped>
.NavigationOrganizationItem_item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 0.5rem;

  .NavigationOrganizationItem_plus {
    height: 2.5rem;
    width: 2.5rem;
  }

  .NavigationOrganizationItem_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .NavigationOrganizationItem_name {
      color: #2c2c2c;
      font-size: 0.875rem;
      line-height: 1.0rem;
      font-weight: 600;
    }

    .NavigationOrganizationItem_description {
      color: #626d78;
      font-size: 0.75rem;
      line-height: 1.0rem;
      font-weight: 400;
    }
  }

  .NavigationOrganizationItem_icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 0.5rem;
  }
}
</style>
