<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 2C14.6297 2 14.9435 2.28215 14.9932 2.64823L15 2.75V5H20.25C20.6642 5 21 5.33579 21 5.75V21.25C21 21.6642 20.6642 22 20.25 22H17.25C16.8358 22 16.5 21.6642 16.5 21.25V18.5H14.5V21.25C14.5 21.6642 14.1642 22 13.75 22H10.75C10.3358 22 10 21.6642 10 21.25V5.75C10 5.33579 10.3358 5 10.75 5H13.5V3.5H4.5V21.25C4.5 21.6642 4.16421 22 3.75 22C3.3703 22 3.05651 21.7178 3.00685 21.3518L3 21.25V2.75C3 2.3703 3.28215 2.05651 3.64823 2.00685L3.75 2H14.25ZM19.5 6.5H11.5V20.5H13V17.75C13 17.3358 13.3358 17 13.75 17H17.25C17.6642 17 18 17.3358 18 17.75V20.5H19.5V6.5ZM8 17V19H6V17H8ZM15 14V16H13V14H15ZM18 14V16H16V14H18ZM8 14V16H6V14H8ZM15 11V13H13V11H15ZM18 11V13H16V11H18ZM8 11V13H6V11H8ZM8 8V10H6V8H8ZM15 8V10H13V8H15ZM18 8V10H16V8H18ZM8 5V7H6V5H8Z" />
  </svg>
</template>

<script>
export default {
  name: "IconBuilding",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>