<template>
  <div class="list">
    <ListHeader :fields="fields" resource="role" />
    <RoleListItem @action="action" v-for="role in roles" :key="role.id" :role="role" :fields="fields" />
    <div class="empty" v-if="roles != null && roles.length === 0">
      <img class="splash" src="@/assets/images/splash-document-list-empty.png" alt="Empty" />
      <div class="header">{{ $t('components.RoleList.empty.header') }}</div>
      <div class="text">{{ $t('components.RoleList.empty.text') }}</div>
    </div>
  </div>
</template>

<script>
import ListHeader from "@/components/ListHeader";
import Organization from "@/core/organization";
import RoleListItem from "@/components/RoleListItem";
import Account from "@/core/account";

export default {
  name: "RoleList",
  components: {
    ListHeader,
    RoleListItem
  },
  props: {
    organization: {
      type: Organization,
      required: true
    },
    roles: {
      required: true,
      default: null
    }
  },
  methods: {
    action({ role, action }) {
      switch (action) {
        case "edit": this.$emit("edit", role); break;
        case "delete": this.$emit("delete", role); break;
        default: console.log(action, role); break;
      }
    }
  },
  data() {
    return {
      fields: [
        { name: "name", type: "text", weight: "3", header: true },
        { name: "default", type: "boolean", weight: "1", header: true },
        Account.permissions?.organization?.edit?.roles ? { name: "actions", type: "actions", weight: "1", header: true,
          data: [
            { name: "more", list: true },
            { name: "edit", list: false },
            { name: "delete", list: false }
          ]
        } : null
      ].filter(element => element != null)
    }
  },
  emits: ["edit", "delete"]
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  flex: 1;

  .empty {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 38.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.0rem;

    .splash {
      width: 30.0rem;
      height: 17.5rem;
      margin-bottom: 2.5rem;
    }

    .header {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.0rem;
      text-align: center;
      color: #2d2d2d;
      margin-bottom: 0.5rem;
    }

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #626d78;
    }
  }
}
</style>
