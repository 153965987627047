<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.34968 6.30373L9.27706 6.21961C9.01076 5.95338 8.5941 5.92921 8.30051 6.1471L8.2164 6.21972L3.21978 11.2174C2.95369 11.4835 2.92939 11.8999 3.14695 12.1935L3.21947 12.2776L8.21609 17.2811C8.50878 17.5742 8.98365 17.5745 9.27675 17.2818C9.5432 17.0157 9.56769 16.5991 9.35003 16.3053L9.27747 16.2211L5.56658 12.504L20.2466 12.5049C20.6263 12.5049 20.9401 12.2228 20.9897 11.8567L20.9966 11.7549C20.9966 11.3752 20.7144 11.0615 20.3484 11.0118L20.2466 11.0049L5.55358 11.004L9.27717 7.28028C9.54341 7.01398 9.56757 6.59732 9.34968 6.30373L9.27706 6.21961L9.34968 6.30373Z" />
  </svg>

</template>

<script>
export default {
  name: "IconArrowLeft",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>