import ConnectorService from "@/services/connector";

export default class SigningService extends ConnectorService {
    constructor(url, id) {
        super(url + "signing/", id);
    }

    async addSignee(documentId, givenName, familyName, email, level) {
        return await this.postp("signee/" + documentId, {
            givenName: givenName,
            familyName: familyName,
            email: email,
            level: level
        });
    }

    async getSignees(documentId) {
        return await this.getp("signees/" + documentId);
    }

    async send(documentId) {
        return await this.getp("send/" + documentId);
    }

    async cancel(documentId) {
        return await this.getp("cancel/" + documentId);
    }

    async deleteSignee(signeeId) {
        return await this.getp("delete/signee/" + signeeId);
    }

    async status(type, id) {
        return await this.get("status/" + type + "/" + id);
    }

    async start(id) {
        return await this.get("start/" + id);
    }

    async download(id) {
        return await this.getp("download/" + id);
    }
}
