<template>
  <router-view />
  <AppModals />
  <AppSounds />
  <AppGuides v-if="Account.id" />
</template>

<script>
import { names } from "@/utilities/i18n";
import { accountRoles, changeLanguage } from "@/utilities";
import AppModals from "@/components/AppModals";
import Account from "@/core/account";
import AppSounds from "@/components/AppSounds";
import AppGuides from "@/components/AppGuides";

export default {
  name: 'App',
  components: {
    AppGuides,
    AppSounds,
    AppModals
  },
  setup() {
    return {
      Account
    };
  },
  beforeMount() {
    const lang = this.$route.query.lang;

    let language = Account.settings.language === "" ? (this.$route.query.lang ? this.$route.query.lang : "en") : Account.settings.language;

    if (lang) {
      if (lang in names && lang !== language) {
        changeLanguage(this, lang, true);
      }
    } else {
      if (window.localStorage.getItem("language")) {
        changeLanguage(this, window.localStorage.getItem("language"), true);
      }
    }

    accountRoles();
  }
}
</script>

<style lang="scss">
@import url(./assets/css/root.scss);

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
