<template>
  <div class="item">
    <template v-for="field in fields" :key="field.name">
      <div class="column" :style="'flex: ' + field.weight">
        <span class="text" v-if="field.type === 'boolean'">{{ role[field.name] ? $t('components.RoleListItem.default.yes') : $t('components.RoleListItem.default.no') }}</span>
        <span class="text" v-if="field.type === 'text'">{{ role[field.name] }}</span>
        <span class="actions" v-if="field.type === 'actions'">
          <template v-for="action in field.data" :key="action.name">
            <span @click="$emit('action', { role: role, action: action.name })" class="action" v-if="!action.list && (action.name !== 'delete' || !role.default)">
              <AppHint position="left" :text="$t('components.RoleListItem.actions.' + action.name + '.label')">
                <AppIcon color="#2d2d2d" :type="$t('components.RoleListItem.actions.' + action.name + '.icon')" />
              </AppHint>
            </span>
          </template>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import Role from "@/core/role";
import AppHint from "@/components/AppHint";
import AppIcon from "@/components/AppIcon";

export default {
  name: "RoleListItem",
  components: {
    AppIcon,
    AppHint
  },
  props: {
    role: {
      type: Role
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  emits: ["action"]
}
</script>

<style lang="scss" scoped>
.item {
  height: calc(4.5rem - (0.75rem * 2));
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem;
  border-bottom: solid 0.03125rem #c3c8cc;

  .column {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0rem;
    color: #2d2d2d;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0rem;
      color: #2d2d2d;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 1.25rem;
      cursor: pointer;

      .action {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}
</style>
