<template>
  <div v-if="!loading" class="signing-start">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views.AppSigningStart.header." + state) }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views.AppSigningStart.text." + state) }}</span>
      </div>
      <div v-if="!locked && !signed && !notFound" class="buttons">
        <AppButton @click="start" :icon="false" size="extra-large">{{ $t("views.AppSigningStart.buttons.start") }}</AppButton>
      </div>
    </div>
    <div class="right">
      <img class="splash" src="@/assets/images/splash-signing.png" alt="Signing" />
    </div>
  </div>
  <div v-if="loading" class="signing-start" style="flex: 1"><AppLoading /></div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppLoading from "@/components/AppLoading";
import { checkErrors } from "@/utilities/errors";
import SigningService from "@/services/signing";
import Sounds from "@/core/sounds";

export default {
  name: "AppSigningStart",
  components: {
    AppLoading,
    AppButton
  },
  data() {
    return {
      locked: false,
      signed: false,
      notFound: false,
      loading: true
    };
  },
  mounted() {
    this.getStatus();
  },
  computed: {
    state() {
      if (this.locked) {
        return "locked";
      } else if (this.signed) {
        return "signed";
      } else if (this.notFound) {
        return "notFound";
      }

      return "normal";
    }
  },
  methods: {
    getStatus() {
      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Signing.status("signee", this.$route.params.signee)
          .then((data) => {
            if ((this.locked === true && data?.locked === false) || (this.signed === true && data?.signed === false)) {
              Sounds.play("notification");
            }

            this.locked = data?.locked;
            this.signed = data?.signed;

            if (this.locked) {
              setTimeout(() => {
                this.getStatus();
              }, 15000);
            }

            if (data.status === "NOT_FOUND") {
              this.notFound = true;
            }

            this.loading = false;
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    start() {
      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      this.loading = true;

      Signing.start(this.$route.params.signee)
          .then((data) => {
            let language = window.navigator.userLanguage || window.navigator.language;
            if (language.includes("-")) {
              language = language.split("-")[0];
            }

            window.location.href = data?.signingUrl + "&lang=" + language;
          })
          .catch((error) => {
            checkErrors(error);

            this.loading = false;
            this.toast.error(this.$t("views.AppSigningStart.toasts.start.failed"));
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.signing-start {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 87.0rem;

  .left {
    width: 28.5rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      width: fit-content;
      margin-top: 4.0rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
