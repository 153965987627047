<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7501 3C19.4831 3 20.8993 4.35645 20.995 6.06558L21.0001 6.25V17.75C21.0001 19.483 19.6436 20.8992 17.9345 20.9949L17.7501 21H6.2501C4.51707 21 3.10086 19.6435 3.00525 17.9344L3.0001 17.75L2.99981 9.37208C3.23448 9.45505 3.48702 9.5002 3.7501 9.5002C4.01315 9.5002 4.26566 9.45506 4.50031 9.37211L4.5001 17.75C4.5001 18.6682 5.20721 19.4212 6.10657 19.4942L6.2501 19.5H17.7501C18.6683 19.5 19.4213 18.7929 19.4943 17.8935L19.5001 17.75V6.25C19.5001 5.33183 18.793 4.57881 17.8936 4.5058L17.7501 4.5H6.2501C5.64992 4.5 5.12031 4.80213 4.80507 5.2626C4.49114 5.095 4.13172 5 3.7501 5C3.56887 5 3.39265 5.02142 3.22383 5.06188C3.67711 3.90899 4.77007 3.07762 6.06568 3.00514L6.2501 3H17.7501ZM13.2461 13.0031C13.6603 13.0031 13.9961 13.3388 13.9961 13.7531C13.9961 14.1327 13.714 14.4465 13.3479 14.4962L13.2461 14.5031H8.74786C8.33365 14.5031 7.99786 14.1673 7.99786 13.7531C7.99786 13.3734 8.28002 13.0596 8.64609 13.0099L8.74786 13.0031H13.2461ZM15.2523 9.49623C15.6666 9.49623 16.0023 9.83201 16.0023 10.2462C16.0023 10.6259 15.7202 10.9397 15.3541 10.9894L15.2523 10.9962H8.74786C8.33365 10.9962 7.99786 10.6604 7.99786 10.2462C7.99786 9.86653 8.28002 9.55274 8.64609 9.50307L8.74786 9.49623H15.2523ZM3.7501 6C4.44051 6 5.0002 6.55969 5.0002 7.2501C5.0002 7.94051 4.44051 8.5002 3.7501 8.5002C3.05969 8.5002 2.5 7.94051 2.5 7.2501C2.5 6.55969 3.05969 6 3.7501 6Z" />
  </svg>
</template>

<script>
export default {
  name: "IconChannel",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>