<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.71967 8.96967C5.01256 8.67678 5.48744 8.67678 5.78033 8.96967L12 15.1893L18.2197 8.96967C18.5126 8.67678 18.9874 8.67678 19.2803 8.96967C19.5732 9.26256 19.5732 9.73744 19.2803 10.0303L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.71967 10.0303C4.42678 9.73744 4.42678 9.26256 4.71967 8.96967Z" />
  </svg>
</template>

<script>
export default {
  name: "IconChevronDown",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>