<template>
  <div class="AppInputRadio_radio" @click="!disabled && updateModel()" :class="disabled ? 'AppInputRadio_disabled' : ''">
    <IconRadioButton :active="modelValue[name] ?? false" :color="disabled ? '#999999' : '#163B61'" />
  </div>
</template>

<script>
import IconRadioButton from "@/assets/icons/IconRadioButton";
export default {
  name: "AppInputRadio",
  components: {IconRadioButton},
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateModel() {
      if (this.modelValue[this.name]) {
        return;
      }

      let newValue = this.modelValue;
      Object.keys(this.modelValue).forEach((key) => {
        newValue[key] = false;
      });
      newValue[this.name] = true;

      this.$emit("update:modelValue", newValue);
    }
  },
  emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
.AppInputRadio_radio {
  height: 1.0rem;
  width: 1.0rem;
  cursor: pointer;
}
.AppInputRadio_disabled {
  cursor: not-allowed;
}
</style>
