<template>
  <div class="event">
    <div class="actor">
      <div class="icon"><AppAvatar :name="event.actorName" size="small" /></div>
      <div class="name">{{ event.actorName }}</div>
    </div>
    <div class="description">
      <span v-if="event.eventType === 'DocumentCreatedEvent'">{{ $t("components.DocumentAuditEvent.events.DocumentCreatedEvent", { name: event.actorName }) }}</span>
      <!--<Tooltip :tooltipText="$t('components.DocumentAuditEvent.tooltip.rename', event)" :position="'right'">-->
        <span v-if="event.eventType === 'DocumentRenamedEvent'">{{ $t("components.DocumentAuditEvent.events.DocumentRenamedEvent", { name: event.actorName }) }}</span>
      <!--</Tooltip>-->
      <span v-if="event.eventType === 'DocumentMovedEvent'">{{ $t("components.DocumentAuditEvent.events.DocumentMovedEvent", { name: event.actorName }) }}</span>
      <span v-if="event.eventType === 'AddSigneeEvent'">{{ $t("components.DocumentAuditEvent.events.AddSigneeEvent", { name: event.actorName }) }}</span>
      <span v-if="event.eventType === 'RemoveSigneeEvent'">{{ $t("components.DocumentAuditEvent.events.RemoveSigneeEvent", { name: event.actorName }) }}</span>
      <span v-if="event.eventType === 'SigneeAddedEvent'">{{ $t("components.DocumentAuditEvent.events.SigneeAddedEvent", { name: event.actorName, signeeName: (JSON.parse(event.signeeData)).givenName + ' ' + (JSON.parse(event.signeeData)).familyName }) }}</span>
      <span v-if="event.eventType === 'SigneeRemovedEvent'">{{ $t("components.DocumentAuditEvent.events.SigneeRemovedEvent", { name: event.actorName, signeeName: (JSON.parse(event.signeeData)).givenName + ' ' + (JSON.parse(event.signeeData)).familyName }) }}</span>
      <span v-if="event.eventType === 'SigneeSignedEvent'">{{ $t("components.DocumentAuditEvent.events.SigneeSignedEvent", { name: (JSON.parse(event.signeeData)).givenName + ' ' + (JSON.parse(event.signeeData)).familyName }) }}</span>
      <span v-if="event.eventType === 'SigneeFailedEvent' && !event?.timeout">{{ $t("components.DocumentAuditEvent.events.SigneeFailedEvent", { name: (JSON.parse(event.signeeData)).givenName + ' ' + (JSON.parse(event.signeeData)).familyName }) }}</span>
      <span v-if="event.eventType === 'SigneeFailedEvent' && event?.timeout">{{ $t("components.DocumentAuditEvent.events.SigneeTimeoutEvent", { name: (JSON.parse(event.signeeData)).givenName + ' ' + (JSON.parse(event.signeeData)).familyName }) }}</span>
      <span v-if="event.eventType === 'SigningSentEvent'">{{ $t("components.DocumentAuditEvent.events.SigningSentEvent", { name: event.actorName }) }}</span>
      <span v-if="event.eventType === 'SigningCompleteEvent'">{{ $t("components.DocumentAuditEvent.events.SigningCompleteEvent", { name: event.actorName }) }}</span>
      <span v-if="event.eventType === 'SigningCanceledEvent'">{{ $t("components.DocumentAuditEvent.events.SigningCanceledEvent", { name: event.actorName }) }}</span>
    </div>
    <div class="timestamp">
      <AppHint :text="formatDate(event.timestamp, 'LLLL')">{{ relativeDate }}</AppHint>
    </div>
  </div>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppHint from "@/components/AppHint";
import { formatDate } from "@/utilities";

export default {
  name: "DocumentAuditEvent",
  components: {
    AppAvatar,
    AppHint
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      formatDate,
      interval: null
    };
  },
  data() {
    return {
      relativeDate: ""
    };
  },
  created() {
    this.relativeDate = formatDate(this.event.timestamp, "relative");
  },
  mounted() {
    this.interval = setInterval(() => {
      this.relativeDate = formatDate(this.event.timestamp, "relative");
    }, 15 * 1000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss" scoped>
.event {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.25rem;
  border-bottom: 0.0625rem solid #c3c8cc;

  .actor {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    .icon {
      margin-right: 0.5rem;
    }

    .name {
      font-weight: 500;
      font-size: 0.875rem;
      color: #2d2d2d;
    }
  }

  .description {
    font-size: 0.875rem;
    color: #626d78;
    font-weight: 400;
  }

  .timestamp {
    font-size: 0.75rem;
    color: #c3c8cc;
    font-weight: 400;
  }
}
</style>
