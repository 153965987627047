<template>
  <div class="DocumentHeader_header">
    <div class="DocumentHeader_left">
      <div class="DocumentHeader_burger" @click="$emit('update:modelValue', !modelValue)">
        <AppIcon type="burger" color="white" />
      </div>
      <div class="DocumentHeader_logo" @click="$router.push('/')">
        <img src="@/assets/images/logo-icon.svg" alt="Sowise" style="cursor: pointer" />
      </div>
      <HeaderDocument :loaders="loaders" @edit="$emit('edit')" @refresh="$emit('refresh')" @rename="$emit('rename', $event)" @delete="$emit('delete')" @download="$emit('download')" :document="document" :download="download" />
    </div>
    <div class="DocumentHeader_right">
      <HeaderUserAvatar />
    </div>
  </div>
</template>

<script>
import HeaderUserAvatar from "@/components/HeaderUserAvatar";
import HeaderDocument from "@/components/HeaderDocument";
import AppIcon from "@/components/AppIcon";

export default {
  name: "DocumentHeader",
  components: {
    HeaderDocument,
    HeaderUserAvatar,
    AppIcon
  },
  props: {
    document: {
      type: Object,
    },
    download: {
      type: Object
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    loaders: {
      type: Object
    }
  },
  emits: ["update:modelValue", "edit", "refresh", "rename", "delete", "download"]
}
</script>

<style lang="scss" scoped>
.DocumentHeader_header {
  background-color: #163b61;
  display: flex;
  flex-direction: row;
  align-items: center;

  .DocumentHeader_left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: left;

    .DocumentHeader_burger {
      height: 1.5rem;
      width: 1.5rem;
      margin-left: 1.0rem;
      cursor: pointer;
    }

    .DocumentHeader_logo {
      height: 2.5rem;
      margin-left: 1.0rem;
    }
  }

  .DocumentHeader_right {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
