<template>
  <div v-if="!loading" class="signing-start">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views.AppSigningComplete.header." + (validSession ? 'signedIn' : 'signedOut')) }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views.AppSigningComplete.text." + (validSession ? 'signedIn' : 'signedOut')) }}</span>
      </div>
      <div class="buttons">
        <AppButton stretch @click="this.continue()" :icon="false" size="extra-large">{{ $t("views.AppSigningComplete.buttons.continue." + (validSession ? 'signedIn' : 'signedOut')) }}</AppButton>
        <AppButton :href="src" target="_blank" :download="name" stretch iconType="download" type="outline" primaryColor="#163b61" size="extra-large">{{ $t("views.AppSigningComplete.buttons.download") }}</AppButton>
      </div>
    </div>
    <div class="right">
      <img class="splash" src="@/assets/images/splash-signing.png" alt="Signing" />
    </div>
  </div>
  <div v-if="loading" class="signing-start" style="flex: 1"><AppLoading /></div>
</template>

<script>
import AppButton from "@/components/AppButton";
import Session from "@/core/session";
import AppLoading from "@/components/AppLoading";
import { base64ToBlob } from "@/utilities";
import { checkErrors } from "@/utilities/errors";
import SigningService from "@/services/signing";

export default {
  name: "AppSigningComplete",
  components: {
    AppButton,
    AppLoading
  },
  data() {
    return {
      src: "",
      name: "",
      loading: true
    };
  },
  mounted() {
    const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

    Signing.download(this.$route.params.signee)
        .then((data) => {
          const blob = base64ToBlob(data?.source.slice("data:application/pdf;base64,".length), 'application/pdf');
          this.src = URL.createObjectURL(blob);
          this.name = data?.name + ".pdf";

          this.loading = false;
        })
        .catch((error) => {
          checkErrors(error);

          this.loading = false;

          this.$router.push("/page-not-found");
        });
  },
  computed: {
    validSession() {
      const sessionId = Session.id;

      return sessionId !== false && sessionId !== null;
    }
  },
  methods: {
    continue() {
      const sessionId = Session.id;

      if (sessionId !== false && sessionId !== null) {
        this.$router.push("/");
      } else {
        this.$router.push("/signup");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.signing-start {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 87.0rem;

  .left {
    width: 28.5rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      width: fit-content;
      margin-top: 4.0rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
