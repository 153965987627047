<template>
  <svg viewBox="40 36 35 27" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="color" style="fill: none; stroke-width: 2px;" d="M 61.731 61.922 C 71.202 61.922 77.121 51.67 72.386 43.468 C 70.117 39.539 65.87 37.177 61.335 37.324"/>
    <path :stroke="color" style="fill: none; stroke-width: 2px;" d="M 61.958 56.965 C 67.631 56.965 71.176 50.825 68.34 45.911 C 66.981 43.559 64.437 42.144 61.721 42.232"/>
    <path :stroke="color" style="fill: none; stroke-width: 2px;" d="M 58.067 37.318 L 58.3 37.318 L 58.3 61.922 L 58.067 61.922 L 49.382 53.788 L 41.801 53.714 L 41.886 49.201 L 41.941 44.627 L 49.816 44.623 L 58.067 37.318 Z"/>
  </svg>
</template>

<script>
export default {
  name: "IconSpeaker",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
