export default class RoleEvents {
    static edit(instance, field) {
        instance.events.$on("role:edit", () => {
            if (field instanceof Function) {
                field(instance);
            }
        });
    }

    static delete(instance, field) {
        instance.events.$on("role:delete", () => {
            if (field instanceof Function) {
                field(instance);
            }
        });
    }
}
