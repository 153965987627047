<template>
  <div class="item">
    <template v-for="field in fields" :key="field.name">
      <div @click="(field.type !== 'actions') && $emit('click')" class="column" :style="'flex: ' + field.weight">
        <span class="user-container" v-if="field.type === 'avatar'">
          <span class="avatar"><AppAvatar :name="user.fullName" size="small" /></span>
          <span class="info-container">
            <span class="name">{{ user.fullName }}</span>
            <span v-if="Account.permissions?.users?.view?.details" class="email">{{ user.email }}</span>
          </span>
        </span>
        <span class="roles" v-if="Account.permissions?.users?.view?.roles && field.type === 'roles'">
          <template v-for="role in user.roles" :key="role.id">
            <span class="role">
              <ResourceLabel :closable="false">{{ role.name }}</ResourceLabel>
            </span>
          </template>
        </span>
        <span class="actions" v-if="field.type === 'actions'">
          <template v-for="action in field.data" :key="action.name">
            <span @click="$emit('action', { user: user, action: action.name })" class="action" v-if="!action.list">
              <AppHint position="left" :text="$t('components.UserListItemUser.actions.' + action.name + '.label')">
                <AppIcon color="#2d2d2d" :type="$t('components.UserListItemUser.actions.' + action.name + '.icon')" />
              </AppHint>
            </span>
          </template>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppHint from "@/components/AppHint";
import User from "@/core/user";
import AppAvatar from "@/components/AppAvatar";
import ResourceLabel from "@/components/ResourceLabel";
import Account from "@/core/account";

export default {
  name: "UserListItemUser",
  components: {
    ResourceLabel,
    AppAvatar,
    AppIcon,
    AppHint
  },
  props: {
    user: {
      type: User,
      required: true
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup() {
    return {
      Account
    };
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: solid 0.03125rem #c3c8cc;
  align-items: center;

  .column {
    .user-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.0rem;

      .avatar {
        height: 2.0rem;
        width: 2.0rem;
        margin-right: 1.0rem;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        height: 2.0rem;
        justify-content: center;

        .name {
          font-size: 0.875rem;
          font-weight: 600;
          color: #2d2d2d;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }

        .email {
          font-size: 0.75rem;
          font-weight: 400;
          color: #626d78;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }
      }
    }

    .roles {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 1.25rem;

      .action {
        height: 1.25rem;
        width: 1.25rem;
        cursor: pointer;
      }
    }
  }
}
</style>
