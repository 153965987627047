<template>
  <div @click="select()" class="container" @drop.prevent="drop">
    <div class="text">
      <div class="top">{{ $t('components.AppUploadZone.text.top') }}</div>
      <div class="bottom">{{ $t('components.AppUploadZone.text.bottom') }}</div>
    </div>
    <AppButton class="button" type="outline" :icon="false" size="small" primaryColor="#4198f0">{{ $t("components.AppUploadZone.buttons.select") }}</AppButton>
    <input id="file-input" type="file" style="display: none" @change="selected" />
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
  name: "AppUploadZone",
  components: {
    AppButton
  },
  setup() {
    const events = ["dragenter", "dragover", "dragleave", "drop"];

    return {
      events
    };
  },
  methods: {
    select() {
      document.getElementById('file-input').click();
    },
    file(file) {
      this.$emit('file-selected', file);
    },
    drop(event) {
      let files = event.dataTransfer.files;

      if (files.length > 0) {
        this.file(files[0]);
      }
    },
    selected(event) {
      let files = event.target.files;

      if (files.length > 0) {
        this.file(files[0]);
      }
    },
    preventDefaults(event) {
      event.preventDefault();
    }
  },
  mounted() {
    this.events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults);
    });
  },
  unmounted() {
    this.events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults);
    });
  },
  emits: ["file-selected"]
}
</script>

<style lang="scss" scoped>
.container {
  border-radius: 0.5rem;
  border: 0.0625rem dashed #c3c8cc;
  padding: 1.0rem;
  cursor: pointer;

  .text {
    .top {
      text-align: center;
      line-height: 1.0rem;
      font-size: 0.875rem;
      font-weight: 500;
      color: #2d2d2d;
      margin-bottom: 0.25rem;
    }

    .bottom {
      text-align: center;
      margin-bottom: 0.5rem;
      line-height: 1.0rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: #626d78;
    }
  }

  .button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
