<template>
  <div class="SettingsNavigation_navigation">
    <AppButton @click="back()" type="outline" iconType="arrow-left" primaryColor="#626d78" secondaryColor="#c3c8cc" stretch>{{ $t("components.SettingsNavigation.buttons.goBack") }}</AppButton>
    <NavigationItemGroup v-if="loaded" :title="$t('components.SettingsNavigation.organizationSettings.title')">
      <NavigationItem v-if="Account.permissions?.organization?.view?.details" @click="$router.push('/settings/information')" icon="clipboard" type="grey">{{ $t("components.SettingsNavigation.organizationSettings.items.information") }}</NavigationItem>
      <NavigationItem v-if="Account.permissions?.organization?.view?.users && !organization.isPersonal" @click="$router.push('/settings/users')" icon="team" type="grey">{{ $t("components.SettingsNavigation.organizationSettings.items.users") }}</NavigationItem>
      <NavigationItem v-if="Account.permissions?.organization?.view?.roles && !organization.isPersonal" @click="$router.push('/settings/permissions')" icon="blocked" type="grey">{{ $t("components.SettingsNavigation.organizationSettings.items.permissions") }}</NavigationItem>
      <NavigationItem v-if="Account.permissions?.isOwner" @click="$router.push('/settings/billing')" icon="credit-card" type="grey">{{ $t("components.SettingsNavigation.organizationSettings.items.billing") }}</NavigationItem>
      <NavigationItem @click="$router.push('/settings/pricing')" icon="price" type="grey">{{ $t("components.SettingsNavigation.organizationSettings.items.pricing") }}</NavigationItem>
    </NavigationItemGroup>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import NavigationItemGroup from "@/components/NavigationItemGroup";
import NavigationItem from "@/components/NavigationItem";
import { base64Decode } from "@/utilities";
import Organization from "@/core/organization";
import Account from "@/core/account";

export default {
  name: "SettingsNavigation",
  components: {
    NavigationItem,
    NavigationItemGroup,
    AppButton
  },
  props: {
    loaded: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Organization,
      required: true
    }
  },
  setup() {
    return {
      Account
    };
  },
  methods: {
    back() {
      if (this.$route.query?.back) {
        this.$router.push(base64Decode(this.$route.query?.back));

        return;
      }

      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
.SettingsNavigation_navigation {
  display: flex;
  flex-direction: column;
  width: calc(19.25rem - 2.0rem);
  padding: 1.5rem 1.0rem;
  background-color: #f7f7f7;
  flex: 1;
  gap: 0.75rem;
}
</style>
