<template>
  <div class="header">
    <template v-for="field in fields" :key="field">
      <template v-if="!omit.includes(field.name)">
        <div class="item" :style="'flex: ' + field.weight">
          {{ field.header ? $t('components.ListHeader.' + resource + '.' + field.name) : '' }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListHeader",
  props: {
    resource: {
      type: String,
      default: "document",
      validator: (value) => {
        return ["document", "user", "role"].includes(value);
      }
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    omit: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: calc(2.5rem - (0.75rem * 2));
  border-bottom: solid 0.03125rem #626d78;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem;

  .item {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.0rem;
    color: #2d2d2d;
  }
}
</style>
