<template>
  <div class="signup">
    <!--<AppStepNavigation :steps="steps" :currentStep="currentStep" />-->
    <AppButton @click="goBack()" iconType="arrow-left" size="small" type="link-like" primary-color="#626d78">{{ $t("views.SignUpConfirmPlan.buttons.goBack") }}</AppButton>
    <div class="content">
      <div class="left">
        <div>
          <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
        </div>
        <div class="header">{{ $t("views.SignUpConfirmPlan.header") }}</div>
        <div class="content">
          <span>{{ $t("views.SignUpConfirmPlan.text") }}</span>
        </div>
        <div class="buttons">
          <AppButton @click="next()" :icon="false" size="large">{{ $t("views.SignUpConfirmPlan.buttons.continue") }}</AppButton>
        </div>
      </div>
      <div class="right">

      </div>
    </div>
    <AppButton @click="signOut()" style="margin-top: 5.0rem" type="outline" primaryColor="#f14a4a" iconType="sign-out" size="medium">{{ $t("components.HeaderUserAvatarDropdown.items.signOut") }}</AppButton>
  </div>
</template>

<script>
//import AppStepNavigation from "@/components/AppStepNavigation";
import AppButton from "@/components/AppButton";
import AuthenticationService from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";
import { signOut } from "@/utilities";

export default {
  name: "SignUpConfirmPlan",
  components: {
    AppButton,
    //AppStepNavigation
  },
  setup() {
    return {
      signOut
    };
  },
  data() {
    return {
      currentStep: 2,
      steps: [
        {
          name: this.$t("views.SignUpInviteUsers.steps.organizationName")
        },
        {
          name: this.$t("views.SignUpInviteUsers.steps.inviteUsers")
        },
        {
          name: this.$t("views.SignUpInviteUsers.steps.finishSetup")
        }
      ]
    };
  },
  methods: {
    goBack() {
      this.$store.dispatch("setup/previousStep"); // this
      this.$router.push(this.$store.getters["setup/route"]);
    },
    next() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      let data = this.$store.getters["setup/data"];
      let organizations = [];
      if (data[2]?.organizationName) {
        organizations.push(data[2]?.organizationName);
      }

      let lang = window.localStorage.getItem("language") ?? "en";

      Authentication.setup(organizations, lang)
          .then((data) => {
            if (data.status === "OK") {
              if (data?.account) {
                this.$store.dispatch("account/set", data.account);
              }

              this.$store.dispatch("setup/nextStep"); // this
              this.$router.push(this.$store.getters["setup/route"]);
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.5rem;
  width: 78.5rem;
  gap: 2.5rem;
}
.logo {
  height: 4.5rem;
  margin-bottom: 2.5rem;
}
.content {
  display: flex;
  flex-direction: row;

  .left {
    width: 28.5rem;
    justify-content: normal;

    .header {
      line-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
    }

    .content {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      white-space: pre-line;
    }

    .buttons {
      margin-top: 1.5rem;
    }
  }
}
</style>
