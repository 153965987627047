<template>
  <AppLoading v-show="loading" />
  <template v-if="!loading">
    <AppHeader />
    <div class="AppDashboard_container">
      <div class="AppDashboard_left">
        <AppNavigation />
      </div>
      <div class="AppDashboard_right">
        <div class="AppDashboard_content">
          <DashboardStateCards :stats="stats" />
          <div class="AppDashboard_document-list-title">{{ $t('views.AppDashboard.titles.recentDocuments') }}</div>
          <DocumentList :documents="documents" :omit="['actions']" />
        </div>
        <div class="AppDashboard_sidebar"></div>
      </div>
    </div>
  </template>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppNavigation from "@/components/AppNavigation";
import DashboardStateCards from "@/components/DashboardStateCards";
import DocumentList from "@/components/DocumentList";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";
import events from "@/events";
import DocumentEvents from "@/events/document";
import AuthenticationService from "@/services/authentication";
import Account from "@/core/account";
import { modalOpenOrganizationInvites } from "@/modals";
import { accountRoles, changeLanguage } from "@/utilities";
import router from "@/router";
import AppLoading from "@/components/AppLoading";

export default {
  name: "AppDashboard",
  components: {
    AppLoading,
    DocumentList,
    DashboardStateCards,
    AppNavigation,
    AppHeader
  },
  setup() {
    return {
      events
    };
  },
  mounted() {
    if (this.$store.getters["setup/template"] !== null) {
      this.loading = true;
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      let templateId = this.$store.getters["setup/template"];
      this.$store.dispatch("setup/setTemplate", "");

      Documents.useTemplate(templateId)
          .then((data) => {
            if (data.status === "OK") {
              this.loading = false;
              this.$router.push({ name: "Document", params: { document: data.document.id } });
            }
          })
          .catch((error) => {
            checkErrors(error);
            this.loading = false;

            router.push(this.$store.getters["setup/route"]);
          });
    }

    changeLanguage(this, Account.settings.language, true);

    accountRoles();

    this.getDocuments();
    this.getStats();
    this.getOrganizationInvites();
    this.getIntegrationRequests();

    DocumentEvents.add(this, function(instance, document) {
      instance.stats[instance.stateName(document.state)]++;
    });
    DocumentEvents.add(this, (instance, document) => {
      if (instance["documents"] === null) {
        instance["documents"] = [];
      }

      if (document.type !== "folder") {
        instance["documents"].unshift(document);
      }
    });
    DocumentEvents.delete(this, function(instance, document) {
      instance.stats[instance.stateName(document.state)]--;
    });
    DocumentEvents.delete(this, "documents");
    DocumentEvents.rename(this, "documents");
  },
  data() {
    return {
      test: {},
      stats: { draft: 0, published: 0, signing: 0, signed: 0, archived: 0 },
      documents: null,
      loading: false
    }
  },
  methods: {
    getStats() {
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.stats()
          .then((data) => {
            this.stats = {
              draft: data?.draft,
              published: 0,
              signing: data?.pending,
              signed: data?.complete,
              archived: data?.archived
            };
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    getDocuments() {
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.recent(8)
          .then((data) => {
            if (data.status === "OK") {
              this.documents = data?.documents;
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    stateName(state) {
      switch (state) {
        case 1:
          return "draft";
        case 10:
          return "published";
        case 20:
          return "signing";
        case 30:
          return "signed";
        case 40:
          return "archived";
        default:
          return "draft";
      }
    },
    getOrganizationInvites() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.getInvites("account", Account.id)
          .then((data) => {
            let organizationInvites = data?.companyInvites;
            if (organizationInvites.length > 0) {
              modalOpenOrganizationInvites(organizationInvites);
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    getIntegrationRequests() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.getApiRequests()
          .then((data) => {
            if (data.apiKeyRequest) {
              this.$store.dispatch("session/setIntegrationRequest", data.apiKeyRequest);
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.AppDashboard_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppDashboard_left {
    display: flex;
    flex-direction: column;
  }

  .AppDashboard_right {
    flex: 1;
    display: flex;
    flex-direction: row;

    .AppDashboard_content {
      flex: 1;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .AppDashboard_document-list-title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.0rem;
        color: #163b61;
        margin-top: 1.0rem;
      }
    }

    .AppDashboard_sidebar {
    }
  }
}
</style>
