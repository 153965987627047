import ConnectorService from "@/services/connector";

export default class AuditorService extends ConnectorService {
    constructor(url, id) {
        super(url + "auditor/", id);
    }

    async getEvents(type, id, full = false) {
        return await this.getp("events/" + type + "/" + id + "/" + (full ? "full" : "minimal"));
    }
}

export const ResourceType = Object.freeze({
    AccountResource: "AccountResource",
    DocumentResource: "DocumentResource"
});
