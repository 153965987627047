<template>
  <div class="list">
    <ListHeader :fields="fields" :omit="omit" />
    <DocumentListItem @action="action" @click="open(document)" v-for="document in documents" :key="document.id" :document="document" :fields="fields" :omit="omit" />
    <div class="empty" v-if="documents != null && documents.length === 0">
      <img class="splash" src="@/assets/images/splash-document-list-empty.png" alt="Empty" />
      <div class="header">{{ $t('components.DocumentList.empty.header') }}</div>
      <div class="text">{{ $t('components.DocumentList.empty.text') }}</div>
    </div>
    <div v-if="documents == null" class="empty">
      <AppLoading />
    </div>
  </div>
</template>

<script>
import DocumentListItem from "@/components/DocumentListItem";
import ListHeader from "@/components/ListHeader";
import { base64Encode } from "@/utilities";
import {
  modalOpenDocumentDelete,
  modalOpenDocumentMove,
  modalOpenDocumentRename,
  modalOpenDocumentRoles
} from "@/modals";
import AppLoading from "@/components/AppLoading";
import PrivilegesService from "@/services/privileges";
import { checkErrors } from "@/utilities/errors";
import Account from "@/core/account";
import Document from "@/core/document";
import events from "@/events";

export default {
  name: "DocumentList",
  components: {
    AppLoading,
    ListHeader,
    DocumentListItem
  },
  props: {
    documents: {
      required: true,
      default: null
    },
    omit: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open(document) {
      if (document.type === "folder") {
        const link = "/documents/" + document.id + "?back=" + base64Encode(this.$route.path);
        this.$router.push(link);
      } else {
        const link = "/document/" + document.id;
        this.$router.push(link);
      }
    },
    action({ document, action }) {
      switch (action) {
        case "delete": modalOpenDocumentDelete(document); break;
        case "rename": modalOpenDocumentRename(document); break;
        case "move": modalOpenDocumentMove(document); break;
        case "permissions": this.openPermissions(document); break;
        default: console.log(action, document); break;
      }
    },
    openPermissions(document) {
      this.getRoles(new Document(document));
    },
    getRoles(document) {
      const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Privileges.roles()
          .then((organizationRoleData) => {
            this.organization.setRoles(organizationRoleData?.roles);

            Privileges.documentRoles(document.id)
                .then((data) => {
                  document.setRoles(data?.roles);

                  modalOpenDocumentRoles(this.organization, document);
                })
                .catch((error) => {
                  checkErrors(error);
                });
          })
          .catch((error) => {
            checkErrors(error);
          });
    }
  },
  data() {
    return {
      organization: Account.selectedOrganization,
      fields: [
        { name: "type", type: "icon", weight: "0.25", header: true },
        { name: "name", type: "text", weight: "1.5", header: true },
        { name: "state", type: "state", weight: "0.5", header: true },
        { name: "created", type: "date", weight: "1", header: true },
        { name: "updated", type: "date", weight: "1", header: true },
        { name: "actions", type: "actions", weight: "1", header: false,
          data: [
            { name: "more", list: true },
            Account.permissions?.documents?.edit?.documents ? { name: "rename", list: false } : null,
            Account.permissions?.documents?.edit?.documents ? { name: "move", list: false } : null,
            Account.permissions?.documents?.delete?.documents ? { name: "delete", list: false }: null,
            Account.permissions?.documents?.view?.roles ? { name: "permissions", list: false } : null
          ].filter(element => element != null)
        }
      ]
    }
  },
  mounted() {
    events.$on("account:update", () => {
      this.fields = [
        { name: "type", type: "icon", weight: "0.25", header: true },
        { name: "name", type: "text", weight: "1.5", header: true },
        { name: "state", type: "state", weight: "0.5", header: true },
        { name: "created", type: "date", weight: "1", header: true },
        { name: "updated", type: "date", weight: "1", header: true },
        { name: "actions", type: "actions", weight: "1", header: false,
          data: [
            { name: "more", list: true },
            Account.permissions?.documents?.edit?.documents ? { name: "rename", list: false } : null,
            Account.permissions?.documents?.edit?.documents ? { name: "move", list: false } : null,
            Account.permissions?.documents?.delete?.documents ? { name: "delete", list: false }: null,
            Account.permissions?.documents?.view?.roles ? { name: "permissions", list: false } : null
          ].filter(element => element != null)
        }
      ];
    });
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  flex: 1;

  .empty {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 38.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.0rem;

    .splash {
      width: 30.0rem;
      height: 17.5rem;
      margin-bottom: 2.5rem;
    }

    .header {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.0rem;
      text-align: center;
      color: #2d2d2d;
      margin-bottom: 0.5rem;
    }

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #626d78;
    }
  }
}
</style>
