<template>
  <AppModal :active="active" :header="true" :progress="33" :title="$t('modals.DocumentCreateName.title.' + type)" @close="close()">
    <div class="ModalDocumentCreateName_container">
      <div class="ModalDocumentCreateName_text">{{ $t("modals.DocumentCreateName.text." + type) }}</div>
      <div class="ModalDocumentCreateName_input">
        <AppInputText @keyup.enter="this.continue()" ref="nameInput" v-model="name" size="small" stretch :placeholder="$t('modals.DocumentCreateName.inputs.name.placeholder.' + type)" />
      </div>
      <div class="ModalDocumentCreateName_button">
        <AppButton size="medium" :iconLeft="false" iconType="wand" @click="this.continue()">{{ $t("modals.DocumentCreateName.buttons.create") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import {
  modalCloseDocumentCreateName,
  modalOpenDocumentCreateProgress
} from "@/modals/index";
import { useToast } from "vue-toastification";
import AppInputText from "@/components/AppInputText";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalDocumentCreateName",
  components: {
    AppInputText,
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "folder"
    },
    template: {
      type: Object,
      default: null
    }
  },
  watch: {
    active: function (value) {
      if (value) {
        setTimeout(() => {
          this.$refs.nameInput.$refs.textInput.focus();
        }, 500);
      }
    }
  },
  data() {
    return {
      name: ""
    };
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentCreateName,
      toast
    };
  },
  methods: {
    continue() {
      if (this.name?.length <= 0) {
        this.toast.error(this.$t("modals.DocumentCreateName.toasts.nameEmpty." + this.type));

        return;
      }

      modalCloseDocumentCreateName();

      modalOpenDocumentCreateProgress(this.type, this.name, this.template);
      this.name = "";
    },
    close() {
      this.name = "";

      modalCloseDocumentCreateName();
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentCreateName_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentCreateName_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .ModalDocumentCreateName_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;

    .ModalDocumentCreateName_icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }

    .ModalDocumentCreateName_name {
      padding-top: 0.25rem;
      line-height: 1.0rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #2d2d2d;
    }
  }

  .ModalDocumentCreateName_button {
    margin-top: 1.5rem;
    margin-left: auto;
  }
}
</style>
