export default class Permission {
    static OrganizationView = 1;
    static OrganizationViewDetails = 2;
    static OrganizationViewBalance = 3;
    static OrganizationViewUsers = 4;
    static OrganizationViewRoles = 5;
    static OrganizationUserInvite = 6;
    static OrganizationUserRemove = 16;
    static OrganizationEdit = 7;
    static OrganizationEditDetails = 8;
    static OrganizationEditRoles = 9;

    static UserView = 10;
    static UserViewDetails = 11;
    static UserViewRoles = 12;
    static UserEdit = 13;
    static UserEditDetails = 14;
    static UserEditRoles = 15;

    static DocumentView = 17;
    static DocumentViewDetails = 18;
    static DocumentViewRoles = 19;
    static DocumentCreate = 20;
    static DocumentEdit = 21;
    static DocumentEditDetails = 22;
    static DocumentEditRoles = 23;
    static DocumentDelete = 24;
    static DocumentDeleteDetails = 25;
    static DocumentDeleteRoles = 26;

    constructor(data) {
        this._id = data?.id;
        this._key = data?.permissionKey;
        this._value = data?.permissionValue;
    }

    get id() {
        return this._id;
    }

    get key() {
        return this._key;
    }

    get value() {
        return this._value;
    }
}
