<template>
  <div class="signup">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views.SignUpCreateOrganization.header") }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views.SignUpCreateOrganization.text") }}</span>
      </div>
      <div class="buttons">
        <AppButton @click="next" :icon="false" size="extra-large">{{ $t("views.SignUpCreateOrganization.buttons.create") }}</AppButton>
        <AppButton @click="skip" :icon="false" size="extra-large" type="empty" primary-color="#626d78">{{ $t("views.SignUpCreateOrganization.buttons.skip") }}</AppButton>
      </div>
      <AppButton @click="signOut()" style="margin-top: 5.0rem" type="outline" primaryColor="#f14a4a" iconType="sign-out" size="medium">{{ $t("components.HeaderUserAvatarDropdown.items.signOut") }}</AppButton>
    </div>
    <div class="right">
      <img class="splash" src="@/assets/images/splash-create-organization.png" alt="Create Organization" />
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import { signOut } from "@/utilities";

export default {
  name: "SignUpCreateOrganization",
  components: {
    AppButton
  },
  setup() {
    return {
      signOut
    };
  },
  methods: {
    next() {
      this.$store.dispatch("setup/nextStep"); // this
      this.$router.push(this.$store.getters["setup/route"]);
    },
    skip() {
      this.$store.dispatch("setup/nextStep"); // this
      this.$store.dispatch("setup/skipStep"); // organization name
      this.$store.dispatch("setup/skipStep"); // invite users
      this.$store.dispatch("setup/skipStep"); // choose plan
      //this.$store.dispatch("setup/nextStep"); // confirm plan
      this.$router.push(this.$store.getters["setup/route"]);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 87.0rem;

  .left {
    width: 28.5rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      width: fit-content;
      margin-top: 4.0rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
