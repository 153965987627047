<template>
  <div class="AppLoading_loader">
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 30" xml:space="preserve">
      <rect x="0" y="10" width="4" height="10" :fill="color" opacity="0.2">
        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
      </rect>
      <rect x="8" y="10" width="4" height="10" :fill="color"  opacity="0.2">
        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
      </rect>
      <rect x="16" y="10" width="4" height="10" :fill="color"  opacity="0.2">
        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
      </rect>
    </svg>
    <div>{{ $t("components.AppLoading.text") }}</div>
  </div>
</template>

<script>
export default {
  name: "AppLoading",
  props: {
    color: {
      type: String,
      default: "#4198f0"
    }
  }
}
</script>

<style lang="scss" scoped>
.AppLoading_loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.0rem;
  font-weight: 500;
  color: #2d2d2d;
  flex: 1;

  svg {
    height: 3.0rem;
    width: 5.0rem;
  }
}
</style>
