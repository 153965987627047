<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8775 2.82535L21.0304 2.96973L21.1748 3.1227C22.4261 4.52792 22.378 6.68283 21.0304 8.03039L9.0621 19.9987C8.78522 20.2756 8.44089 20.4754 8.06312 20.5784L2.94743 21.9736C2.38756 22.1263 1.87383 21.6126 2.02652 21.0527L3.42171 15.937C3.52474 15.5593 3.72456 15.2149 4.00144 14.9381L15.9698 2.96973C17.3173 1.62217 19.4722 1.57405 20.8775 2.82535ZM15.0003 6.06063L5.0621 15.9987C4.96981 16.091 4.9032 16.2058 4.86886 16.3317L3.81901 20.1811L7.66845 19.1313C7.79437 19.097 7.90915 19.0303 8.00144 18.9381L17.9393 8.99963L15.0003 6.06063ZM6.5251 10.9996L5.0251 12.4996L2.7501 12.5001C2.33588 12.5001 2.0001 12.1643 2.0001 11.7501C2.0001 11.3358 2.33588 11.0001 2.7501 11.0001L6.5251 10.9996ZM10.5251 6.99963L9.0251 8.49963L2.7501 8.50006C2.33588 8.50006 2.0001 8.16427 2.0001 7.75006C2.0001 7.33584 2.33588 7.00006 2.7501 7.00006L10.5251 6.99963ZM17.0304 4.03039L16.0603 4.99963L18.9993 7.93963L19.9698 6.96973C20.7814 6.15805 20.7814 4.84206 19.9698 4.03039C19.1581 3.21871 17.8421 3.21871 17.0304 4.03039ZM14.5251 2.99963L13.0251 4.49963L2.7501 4.50006C2.33588 4.50006 2.0001 4.16427 2.0001 3.75006C2.0001 3.33584 2.33588 3.00006 2.7501 3.00006L14.5251 2.99963Z" />
  </svg>
</template>

<script>
export default {
  name: "IconDraft",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>