<template>
  <span :class="'AppHint_' + position" :data-title="text">
    <slot />
  </span>
</template>

<script>
export default {
  name: "AppHint",
  props: {
    text: {
      type: String,
      default: ""
    },
    position: {
      type: String,
      default: "top",
      validator: (value) => {
        return [
          "top",
          "bottom",
          "left",
          "right"
        ].includes(value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[data-title]:hover:before {
  opacity: 1;
  transition: all 0s ease 0.1s;
  visibility: visible;
}
[data-title]:before {
  content: attr(data-title);
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;

  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  line-height: 1.0rem;
  font-size: 0.75rem;
  font-family: Hind, Arial, serif;
  padding: 0.25rem 0.33rem;
  font-weight: 400;
  border-radius: 0.25rem;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0s ease 0.1s;
  visibility: visible;
}
[data-title]:after {
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;

  content: '';
}
[data-title] {
  position: relative;
}
.AppHint_bottom:before {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}
.AppHint_bottom:after {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: solid 0.33rem rgba(0, 0, 0, 0.8);
  border-left: solid 0.33rem transparent;
  border-right: solid 0.33rem transparent;
}
.AppHint_top:before {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}
.AppHint_top:after {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: solid 0.33rem rgba(0, 0, 0, 0.8);
  border-left: solid 0.33rem transparent;
  border-right: solid 0.33rem transparent;
}
.AppHint_left:before {
  right: calc(100% + 0.5rem);
  margin: auto 0;
  display: inline-block;
}
.AppHint_left:after {
  left: -0.5rem;
  margin: 0.5rem 0;
  border-left: solid 0.33rem rgba(0, 0, 0, 0.8);
  border-top: solid 0.33rem transparent;
  border-bottom: solid 0.33rem transparent;
}
.AppHint_right:before {
  left: calc(100% + 0.5rem);
  margin: auto 0;
  display: inline-block;
}
.AppHint_right:after {
  right: -0.5rem;
  margin: 0.5rem 0;
  border-right: solid 0.33rem rgba(0, 0, 0, 0.8);
  border-top: solid 0.33rem transparent;
  border-bottom: solid 0.33rem transparent;
}
</style>
