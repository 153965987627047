export default class Transaction {
    constructor(data) {
        this._data = data;
    }

    get id() {
        return this._data.id;
    }

    get name() {
        if (this._data.name === "balance") {
            return "balance";
        }
        if (this._data.name.startsWith("signature_")) {
            let parts = this._data.name.split("=")[0].split("_");

            return "signature." + parts[1];
        }

        return "unknown";
    }

    get created() {
        return this._data.created;
    }

    get amount() {
        return this._data.amount;
    }
}
/*
    getTransactionName(name) {
      if (name == "balance") {
        return "Balance";
      }
      if (name.startsWith("signature_")) {
        let parts = name.split("=")[0].split("_");
        return "Signature (" + parts[1] + ")";
      }

      return "Unknown";
    }
 */