<template>
  <AppModal :active="active" :header="true" :title="$t('modals.OrganizationInvites.title')" @close="modalCloseOrganizationInvites()">
    <div class="ModalOrganizationInvites_container">
      <div class="ModalOrganizationInvites_text">
        <div>{{ $t("modals.OrganizationInvites.text") }}</div>
      </div>
      <div class="ModalOrganizationInvites_button">
        <AppButton @click="$router.push('/profile/organizations'); modalCloseOrganizationInvites()" :icon="false" size="medium">{{ $t("modals.OrganizationInvites.buttons.go") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseOrganizationInvites } from "@/modals/index";
import Sounds from "@/core/sounds";

export default {
  name: "ModalOrganizationInvites",
  components: {
    AppModal,
    AppButton
  },
  watch: {
    active: function (newValue, oldValue) {
      if (!oldValue && newValue) {
        Sounds.play("notification");
      }
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    organizationInvites: {
      type: Array
    }
  },
  setup() {
    return {
      modalCloseOrganizationInvites
    };
  }
}
</script>

<style lang="scss" scoped>
.ModalOrganizationInvites_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalOrganizationInvites_text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalOrganizationInvites_button {
    margin-left: auto;
  }
}
</style>
