<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.2732 9.75074C20.3845 9.75074 20.4953 9.76135 20.604 9.78229L20.7655 9.82141L20.9229 9.8758C21.7731 10.2157 22.2097 11.1476 21.9483 12.0078L21.8982 12.1504L19.3236 18.5899C19.0033 19.3912 18.2557 19.9338 17.4051 19.9982L17.2305 20.005H5.14177C5.10697 20.005 5.07221 20.0042 5.03751 20.0026L4.24933 20.0005C3.05906 19.9995 2.08501 19.0747 2.00527 17.9041L2 17.75V6.25783C2 5.06843 2.92298 4.09429 4.09225 4.01328L4.2461 4.00784L8.20404 4.00098C8.66587 4.00019 9.11508 4.14147 9.49177 4.4032L9.64859 4.52268L12.021 6.50004L16.75 6.50074C17.8867 6.50074 18.8266 7.34371 18.9785 8.43863L18.9948 8.59669L19 8.75074V9.75004L20.2732 9.75074ZM7.23486 11.2507C6.95454 11.2507 6.70085 11.4066 6.57222 11.6494L6.53004 11.7444L4.43695 17.4986C4.29536 17.8879 4.49613 18.3182 4.8854 18.4598C4.94018 18.4797 4.997 18.4932 5.0547 18.4999L17.2342 18.5047C17.3023 18.5047 17.369 18.4954 17.4327 18.478L17.5229 18.4457C17.668 18.3842 17.7918 18.2778 17.8742 18.14L17.9279 18.031L20.4763 11.5928C20.5271 11.4644 20.4642 11.3191 20.3358 11.2683L20.2906 11.2552L20.2438 11.2507H7.23486ZM8.20664 5.50098L4.2487 5.50784C3.86947 5.50849 3.55638 5.79052 3.50683 6.15618L3.5 6.25783L3.499 15.687L5.1204 11.2316C5.42358 10.3981 6.18521 9.82588 7.05876 9.75761L7.23486 9.75074L17.499 9.74998L17.5 8.75074C17.5 8.37105 17.2178 8.05725 16.8518 8.00759L16.75 8.00074H11.4784L8.68815 5.67488C8.58004 5.58476 8.44934 5.52735 8.3113 5.50814L8.20664 5.50098Z" />
  </svg>
</template>

<script>
export default {
  name: "IconFolder",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>