<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0303 4.71967C15.3232 5.01256 15.3232 5.48744 15.0303 5.78033L8.81066 12L15.0303 18.2197C15.3232 18.5126 15.3232 18.9874 15.0303 19.2803C14.7374 19.5732 14.2626 19.5732 13.9697 19.2803L7.21967 12.5303C6.92678 12.2374 6.92678 11.7626 7.21967 11.4697L13.9697 4.71967C14.2626 4.42678 14.7374 4.42678 15.0303 4.71967Z" />
  </svg>
</template>

<script>
export default {
  name: "IconChevronLeft",
  props: {
    color: {
      type: String,
      default: "#212121"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>