import { Axios } from 'axios';
import store from "@/store";
import router from "@/router";

class ConnectorService extends Axios {
    constructor(url, id) {
        super({
            baseURL: url,
            headers: {
                "Content-Type": "application/json",
                "X-Application-Id": id
            }
        });
    }

    async get(url, config) {
        let rawResponse = await super.get(url, config);
        let response = JSON.parse(rawResponse.data);

        if (response?.message !== "UNAUTHORIZED_ACCESS") {
            await store.dispatch("session/update");
        }

        if (response.status === "ERROR") {
            throw new Error(response.code, { cause: response.message });
        }

        return response;
    }

    async getp(url, config) {
        let sessionId = store.getters["session/get"];
        if (!router.currentRoute?.value?.meta?.public) {
            if (sessionId === null || sessionId === false) {
                throw new Error(500, { cause: "NO_SESSION" });
            }
        }

        config = { headers: { "X-Session-Id": sessionId, "X-Selected-Company": store.getters["session/selectedOrganization"] } };

        return this.get(url, config);
    }

    async post(url, data, config) {
        let rawResponse = await super.post(url, JSON.stringify(data), config);
        let response = JSON.parse(rawResponse.data);

        if (response?.message !== "UNAUTHORIZED_ACCESS") {
            await store.dispatch("session/update");
        }

        if (response.status === "ERROR") {
            throw new Error(response.code, { cause: response.message });
        }

        return response;
    }

    async postp(url, data, config) {
        let sessionId = store.getters["session/get"];
        if (!router.currentRoute?.value?.meta?.public) {
            if (sessionId === null || sessionId === false) {
                throw new Error(500, { cause: "NO_SESSION" });
            }
        }

        config = { headers: { "X-Session-Id": sessionId, "X-Selected-Company": store.getters["session/selectedOrganization"] } };

        return this.post(url, data, config);
    }

    async postform(url, data, config, callback) {
        config = { headers: { "Content-Type": "multipart/form-data", "X-Session-Id": store.getters["session/get"], "X-Selected-Company": store.getters["session/selectedOrganization"] } };

        if (callback != null) {
            config.onUploadProgress = pe => callback(Math.floor((pe.loaded / pe.total) * 100));
        }

        let rawResponse = await super.post(url, data, config);
        let response = JSON.parse(rawResponse.data);

        if (response?.message !== "UNAUTHORIZED_ACCESS") {
            await store.dispatch("session/update");
        }

        if (response.status === "ERROR") {
            throw new Error(response.code, { cause: response.message });
        }

        return response;
    }

    async put(url, data, config) {
        let rawResponse = await super.put(url, JSON.stringify(data), config);
        let response = JSON.parse(rawResponse.data);

        if (response?.message !== "UNAUTHORIZED_ACCESS") {
            await store.dispatch("session/update");
        }

        if (response.status === "ERROR") {
            throw new Error(response.code, { cause: response.message });
        }

        return response;
    }
}

export default ConnectorService;
