<template>
  <div class="NavigationSpacer_spacer" :class="fill ? ' NavigationSpacer_fill' : ''"></div>
</template>

<script>
export default {
  name: "NavigationSpacer",
  props: {
    fill: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.NavigationSpacer_spacer {
  width: 100%;
  height: 1.0rem;
  margin-bottom: 0.5rem;
  border-bottom: solid 0.0625rem #c3c7cc;
}
.NavigationSpacer_fill {
  flex: 1;
  border-bottom: none;
  margin-bottom: 0;
  height: auto;
}
</style>
