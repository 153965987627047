<template>
  <div class="AppNavigation_navigation">
    <AppButton v-guide="'create-button'" v-if="Account.permissions?.documents?.create?.documents" @click="toggleCreate" :class="createOpen ? 'AppNavigation_create-open' : 'AppNavigation_create-closed'" :icon="false" size="large" :stretch="true">{{ $t('components.AppNavigation.buttons.create') }}</AppButton>
    <div v-show="createOpen" class="AppNavigation_create-dropdown">
      <NavigationItem v-guide="'create-folder'" @click="modalOpenDocumentCreateName('folder'); closeCreate()" icon="folder">{{ $t('components.AppNavigation.create.newFolder') }}</NavigationItem>
      <NavigationItem v-guide="'create-document'" @click="modalOpenDocumentCreateName('contract'); closeCreate()" icon="signature-colored">{{ $t('components.AppNavigation.create.newDocument') }}</NavigationItem>
      <NavigationItem v-guide="'create-template'" @click="modalOpenDocumentCreateName('template'); closeCreate()" icon="draft-colored">{{ $t('components.AppNavigation.create.newTemplate') }}</NavigationItem>
      <NavigationItem v-guide="'create-upload'" @click="modalOpenDocumentUpload(); closeCreate()" icon="upload">{{ $t('components.AppNavigation.create.upload') }}</NavigationItem>
    </div>
    <NavigationOrganization v-guide="'select-organization'" class="AppNavigation_organization-select" />
    <!--
    TODO: Favorites
    <NavigationItemGroup title="Favorites">
      <NavigationItem icon="folder" type="grey">Folder name</NavigationItem>
      <NavigationItem icon="folder" type="grey">Folder name</NavigationItem>
      <NavigationItem icon="signature-colored" type="grey">Document name</NavigationItem>
      <NavigationItem icon="draft-colored" type="grey">Template name</NavigationItem>
    </NavigationItemGroup>
    <NavigationSpacer />
    -->
    <NavigationItemGroup style="flex: 1">
      <NavigationItem @click="$router.push({ name: 'Dashboard' })" :active="isActive('Dashboard')" icon="clipboard">{{ $t('components.AppNavigation.menu.dashboard') }}</NavigationItem>
      <NavigationItem @click="$router.push({ name: 'Documents' })" :active="isActive('Documents')" icon="document">{{ $t('components.AppNavigation.menu.documents') }}</NavigationItem>
      <NavigationItem @click="$router.push({ name: 'Templates' })" :active="isActive('Templates')" icon="draft">{{ $t('components.AppNavigation.menu.templateLibrary') }}</NavigationItem>
      <!--
      TODO: Archive
      <NavigationItem icon="archive">{{ $t('components.AppNavigation.menu.archive') }}</NavigationItem>
      TODO: Notifications
      <NavigationItem icon="alert">{{ $t('components.AppNavigation.menu.notifications') }}</NavigationItem>
      -->
    </NavigationItemGroup>
    <NavigationItemGroup class="bottom-group">
      <NavigationItem v-if="Account.permissions?.organization?.view?.details" @click="$router.push({ name: 'Settings' })" :active="isActive('Settings')" icon="settings">{{ $t('components.AppNavigation.menu.settings') }}</NavigationItem>
      <!--
      TODO: Send feedback
      <NavigationItem icon="comment">{{ $t('components.AppNavigation.menu.sendFeedback') }}</NavigationItem>
      -->
    </NavigationItemGroup>
  </div>
</template>

<script>
import NavigationItemGroup from "@/components/NavigationItemGroup";
import AppButton from "@/components/AppButton";
import NavigationItem from "@/components/NavigationItem";
import NavigationOrganization from "@/components/NavigationOrganization";
import { modalOpenDocumentCreateName, modalOpenDocumentUpload } from "@/modals";
import Account from "@/core/account";

export default {
  name: "AppNavigation",
  components: {
    NavigationOrganization,
    NavigationItem,
    AppButton,
    NavigationItemGroup
  },
  setup() {
    return {
      modalOpenDocumentUpload,
      modalOpenDocumentCreateName,
      Account
    };
  },
  data() {
    return {
      createOpen: false
    }
  },
  methods: {
    toggleCreate() {
      this.createOpen = !this.createOpen;
    },
    closeCreate() {
      this.createOpen = false;
    },
    isActive(route) {
      return this.$route.name === route;
    }
  }
}
</script>

<style lang="scss" scoped>
.AppNavigation_navigation {
  display: flex;
  flex-direction: column;
  width: calc(19.25rem - 2.0rem);
  padding: 1.5rem 1.0rem;
  background-color: #f7f7f7;
  flex: 1;
  height: calc(100vh - 4.5rem);
  max-height: calc(100vh - 4.5rem - 3.0rem);

  .AppNavigation_organization-select {
    margin-bottom: 1.5rem;
  }

  .AppNavigation_create-open {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  .AppNavigation_create-closed {
    margin-bottom: 2.0rem;
  }

  .AppNavigation_create-dropdown {
    border: 0.0625rem solid #c3c7cc;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: white;
    width: calc(19.25rem - 4.0rem - (0.0625rem * 2));
    padding: 1.0rem;
    margin-bottom: 2.0rem;
  }

  .bottom-group {
    bottom: 0;
  }
}
</style>
