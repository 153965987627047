<template>
  <!-- General modals -->

  <ModalSignOut :active="modals['sign-out'].active" />

  <!-- Document related modals -->

  <ModalDocumentDelete :active="modals['document-delete'].active" :document="modals['document-delete'].data.document" />
  <ModalDocumentRename :active="modals['document-rename'].active" :document="modals['document-rename'].data" />
  <ModalDocumentUpload :active="modals['document-upload'].active" />
  <ModalDocumentUploadFileType :active="modals['document-upload-file-type'].active" :file="modals['document-upload-file-type'].data" />
  <ModalDocumentUploadName
      :active="modals['document-upload-name'].active"
      :file="modals['document-upload-name'].data.file"
      :type="modals['document-upload-name'].data.type"
  />
  <ModalDocumentUploadProgress
      :active="modals['document-upload-progress'].active"
      :file="modals['document-upload-progress'].data.file"
      :type="modals['document-upload-progress'].data.type"
      :name="modals['document-upload-progress'].data.name"
  />
  <ModalDocumentUploadComplete :active="modals['document-upload-complete'].active" :document="modals['document-upload-complete'].data.document" />
  <ModalDocumentUploadFailed :active="modals['document-upload-failed'].active" />
  <ModalDocumentCreateName
      :active="modals['document-create-name'].active"
      :type="modals['document-create-name'].data.type"
      :template="modals['document-create-name'].data.template"
  />
  <ModalDocumentCreateProgress
      :active="modals['document-create-progress'].active"
      :type="modals['document-create-progress'].data.type"
      :name="modals['document-create-progress'].data.name"
      :template="modals['document-create-progress'].data.template"
  />
  <ModalDocumentCreateComplete :active="modals['document-create-complete'].active" :document="modals['document-create-complete'].data.document" />
  <ModalDocumentSend
      :active="modals['document-send'].active"
      :document="modals['document-send'].data.document"
      :signees="modals['document-send'].data.signees"
  />
  <ModalDocumentSendComplete :active="modals['document-send-complete'].active" />
  <ModalDocumentSendLowBalance :active="modals['document-send-low-balance'].active" />
  <ModalDocumentCancel :active="modals['document-cancel'].active" :document="modals['document-cancel'].data.document" />
  <ModalDocumentRoles
      :active="modals['document-roles'].active"
      :organization="modals['document-roles'].data.organization"
      :document="modals['document-roles'].data.document"
  />
  <ModalDocumentSign :active="modals['document-sign'].active" :signingUrl="modals['document-sign'].data.signingUrl" />
  <ModalDocumentError :active="modals['document-error'].active" :error="modals['document-error'].data.error" />
  <ModalDocumentMove :active="modals['document-move'].active" :document="modals['document-move'].data.document" />

  <!-- User related modals -->

  <ModalUserInvite :active="modals['user-invite'].active" :organization="modals['user-invite'].data.organization" />
  <ModalUserRemove :active="modals['user-remove'].active" :organization="modals['user-remove'].data.organization" :user="modals['user-remove'].data.user" />
  <ModalUserRoles :active="modals['user-roles'].active" :organization="modals['user-roles'].data.organization" :user="modals['user-roles'].data.user" />
  <ModalUserInviteCancel
      :active="modals['user-invite-cancel'].active"
      :organization="modals['user-invite-cancel'].data.organization"
      :user="modals['user-invite-cancel'].data.user"
  />

  <!-- Organization related modals -->

  <ModalOrganizationCreate :active="modals['organization-create'].active" />
  <ModalOrganizationCreateComplete
      :active="modals['organization-create-complete'].active"
      :name="modals['organization-create-complete'].data.name"
      :organizationId="modals['organization-create-complete'].data.organizationId"
  />
  <ModalOrganizationInvites :active="modals['organization-invites'].active" :organizationInvites="modals['organization-invites'].data.organizationInvites" />

  <!-- Billing related modals -->

  <ModalPaymentMethodAdd :active="modals['payment-method-add'].active" />
  <ModalBalanceAdd :active="modals['balance-add'].active" />

  <ModalMockInviteLawyer :active="modals['mock-invite-lawyer'].active" />
</template>

<script>
import ModalSignOut from "@/modals/ModalSignOut";
import events from "@/events";
import ModalDocumentDelete from "@/modals/ModalDocumentDelete";
import ModalDocumentRename from "@/modals/ModalDocumentRename";
import ModalDocumentUpload from "@/modals/ModalDocumentUpload";
import ModalDocumentUploadFileType from "@/modals/ModalDocumentUploadFileType";
import ModalDocumentUploadName from "@/modals/ModalDocumentUploadName";
import ModalDocumentUploadProgress from "@/modals/ModalDocumentUploadProgress";
import ModalDocumentUploadComplete from "@/modals/ModalDocumentUploadComplete";
import ModalDocumentUploadFailed from "@/modals/ModalDocumentUploadFailed";
import ModalDocumentCreateName from "@/modals/ModalDocumentCreateName";
import ModalDocumentCreateProgress from "@/modals/ModalDocumentCreateProgress";
import ModalDocumentCreateComplete from "@/modals/ModalDocumentCreateComplete";
import ModalUserInvite from "@/modals/ModalUserInvite";
import ModalUserRemove from "@/modals/ModalUserRemove";
import ModalUserInviteCancel from "@/modals/ModalUserInviteCancel";
import ModalOrganizationInvites from "@/modals/ModalOrganizationInvites";
import ModalDocumentSend from "@/modals/ModalDocumentSend";
import ModalDocumentSendComplete from "@/modals/ModalDocumentSendComplete";
import ModalDocumentSendLowBalance from "@/modals/ModalDocumentSendLowBalance";
import ModalDocumentCancel from "@/modals/ModalDocumentCancel";
import ModalUserRoles from "@/modals/ModalUserRoles";
import ModalDocumentRoles from "@/modals/ModalDocumentRoles";
import ModalPaymentMethodAdd from "@/modals/ModalPaymentMethodAdd";
import ModalBalanceAdd from "@/modals/ModalBalanceAdd";
import ModalOrganizationCreate from "@/modals/ModalOrganizationCreate";
import ModalOrganizationCreateComplete from "@/modals/ModalOrganizationCreateComplete";
import ModalDocumentSign from "@/modals/ModalDocumentSign";
import ModalDocumentError from "@/modals/ModalDocumentError";
import ModalDocumentMove from "@/modals/ModalDocumentMove";
import ModalMockInviteLawyer from "@/modals/ModalMockInviteLawyer";

export default {
  name: "AppModals",
  components: {
    ModalMockInviteLawyer,
    ModalDocumentMove,
    ModalDocumentError,
    ModalDocumentSign,
    ModalOrganizationCreate,
    ModalOrganizationCreateComplete,
    ModalBalanceAdd,
    ModalPaymentMethodAdd,
    ModalDocumentRoles,
    ModalUserRoles,
    ModalDocumentCancel,
    ModalDocumentSendLowBalance,
    ModalDocumentSendComplete,
    ModalDocumentSend,
    ModalOrganizationInvites,
    ModalUserInviteCancel,
    ModalUserRemove,
    ModalUserInvite,
    ModalDocumentCreateComplete,
    ModalDocumentCreateProgress,
    ModalDocumentCreateName,
    ModalDocumentUploadFailed,
    ModalDocumentUploadComplete,
    ModalDocumentUploadProgress,
    ModalDocumentUploadName,
    ModalDocumentUploadFileType,
    ModalDocumentUpload,
    ModalDocumentRename,
    ModalDocumentDelete,
    ModalSignOut
  },
  data() {
    return {
      modals: {
        "sign-out": { active: false, data: {} },

        "document-delete": { active: false, data: {} },
        "document-rename": { active: false, data: {} },
        "document-upload": { active: false, data: {} },
        "document-upload-file-type": { active: false, data: {} },
        "document-upload-name": { active: false, data: {} },
        "document-upload-progress": { active: false, data: {} },
        "document-upload-complete": { active: false, data: {} },
        "document-upload-failed": { active: false, data: {} },
        "document-create-name": { active: false, data: {} },
        "document-create-progress": { active: false, data: {} },
        "document-create-complete": { active: false, data: {} },
        "document-send": { active: false, data: {} },
        "document-send-complete": { active: false, data: {} },
        "document-send-low-balance": { active: false, data: {} },
        "document-cancel": { active: false, data: {} },
        "document-roles": { active: false, data: {} },
        "document-sign": { active: false, data: {} },
        "document-error": { active: false, data: {} },
        "document-move": { active: false, data: {} },

        "user-invite": { active: false, data: {} },
        "user-remove": { active: false, data: {} },
        "user-roles": { active: false, data: {} },
        "user-invite-cancel": { active: false, data: {} },

        "organization-create": { active: false, data: {} },
        "organization-create-complete": { active: false, data: {} },
        "organization-invites": { active: false, data: {} },

        "payment-method-add": { active: false, data: {} },
        "balance-add": { active: false, data: {} },

        "mock-invite-lawyer": { active: false, data: {} }
      }
    };
  },
  mounted() {
    events.$on("modal:open", (data) => {
      if (data.data instanceof File) {
        this.modals[data.name].data = data.data;
      } else {
        Object.assign(this.modals[data.name].data, data.data ?? {});
      }

      this.modals[data.name].active = true;
    });

    events.$on("modal:close", (data) => {
      this.modals[data.name].active = false;
      Object.assign(this.modals[data.name].data, data.data ?? {});
    });
  }
}
</script>

<style lang="scss" scoped>

</style>
