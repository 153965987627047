<template>
  <AppModal :active="active" :header="true" title="" :overlayClose="true" @close="modalCloseOrganizationCreateComplete()">
    <div class="ModalOrganizationCreateComplete_container">
      <img src="@/assets/images/splash-signed.png" alt="" />
      <div class="ModalOrganizationCreateComplete_title">{{ $t("modals.OrganizationCreateComplete.title") }}</div>
      <div class="ModalOrganizationCreateComplete_text">{{ $t("modals.OrganizationCreateComplete.text", { organizationName: this.name }) }}</div>
      <div class="buttons">
        <AppButton size="medium" :iconLeft="false" iconType="building" @click="go()">{{ $t("modals.OrganizationCreateComplete.buttons.go") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseOrganizationCreateComplete } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalOrganizationCreateComplete",
  components: {
    AppButton,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    organizationId: {
      type: String,
      default: ""
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseOrganizationCreateComplete,
      toast
    };
  },
  methods: {
    go() {
      modalCloseOrganizationCreateComplete();

      this.$store.dispatch("session/setSelectedOrganization", this.organizationId);
      this.$router.go();
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalOrganizationCreateComplete_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalOrganizationCreateComplete_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalOrganizationCreateComplete_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-left: auto;
  }
}
</style>
