<template>
  <AppModal :active="active" :header="true" :title="$t('modals.DocumentRename.title')" icon="edit" @close="modalCloseDocumentRename()">
    <div class="ModalDocumentRename_container">
      <div class="ModalDocumentRename_text">
        <div>{{ $t("modals.DocumentRename.text") }}</div>
        <div>
          <AppInputText @keyup.enter="rename(name); modalCloseDocumentRename()" ref="nameInput" size="small" v-model="name" stretch />
        </div>
      </div>
      <div class="ModalDocumentRename_button">
        <AppButton @click="rename(name); modalCloseDocumentRename()" :icon="false" size="medium">{{ $t("modals.DocumentRename.buttons.rename") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseDocumentRename } from "@/modals/index";
import DocumentsService from "@/services/documents";
import AppInputText from "@/components/AppInputText";
import { checkErrors } from "@/utilities/errors";
import { useToast } from "vue-toastification";
import events from "@/events";

export default {
  name: "ModalDocumentRename",
  components: {
    AppInputText,
    AppModal,
    AppButton
  },
  watch: {
    active: function (value) {
      this.name = this.document?.name;

      if (value) {
        setTimeout(() => {
          this.$refs.nameInput.$refs.textInput.focus();
        }, 500);
      }
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: null
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentRename,
      toast,
      events
    };
  },
  data() {
    return {
      name: ""
    };
  },
  methods: {
    rename(name) {
      if (this.document === null) {
        return;
      }

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.rename(this.document.id, name)
          .then(() => {
            this.toast.success(this.$t("modals.DocumentRename.toasts.success"));
            //TODO: Event to update document name by id, wrapper for document object to handle update events? Connector handles wrapping?
            this.events.$emit("document:rename", { id: this.document.id, name: name });
          })
          .catch((error) => {
            this.toast.error(this.$t("modals.DocumentRename.toasts.error"));

            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentRename_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentRename_text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalDocumentRename_button {
    margin-left: auto;
  }
}
</style>
