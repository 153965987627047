<template>
  <AppModal :active="active" :header="true" :title="$t('modals.BalanceAdd.title')" icon="price" @close="modalCloseBalanceAdd()">
    <div class="ModalBalanceAdd_container">
      <div class="ModalBalanceAdd_text">{{ $t("modals.BalanceAdd.text") }}</div>
      <AppInputNumber size="small" v-model="balanceAmount" stretch :placeholder="$t('modals.BalanceAdd.inputs.amount.placeholder')" />
      <div class="ModalBalanceAdd_warning"><span v-if="warning">{{ $t("modals.BalanceAdd.warning") }}</span></div>
      <div class="ModalBalanceAdd_button">
        <AppButton :disabled="warning" :loading="loading" @click="add()" iconType="checkmark" size="medium">{{ $t("modals.BalanceAdd.buttons.add") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseBalanceAdd } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppInputNumber from "@/components/AppInputNumber";
import PlanService from "@/services/plan";
import { checkErrors } from "@/utilities/errors";
import events from "@/events";

export default {
  name: "ModalBalanceAdd",
  components: {
    AppInputNumber,
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    balanceAmount: function (value) {
      this.warning = value < 10 || value > 500;
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseBalanceAdd,
      toast,
      events
    };
  },
  data() {
    return {
      loading: false,
      warning: false,
      balanceAmount: 10
    };
  },
  methods: {
    add() {
      this.loading = true;

      const Plan = new PlanService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Plan.addBalance(this.balanceAmount)
          .then((data) => {
            if (data.status === "OK") {
              this.toast.success(this.$t("modals.BalanceAdd.toasts.add.success"));

              this.events.$emit("balance:update", { value: this.balanceAmount });
            } else {
              this.toast.error(this.$t("modals.BalanceAdd.toasts.add.failed"));
            }

            modalCloseBalanceAdd();

            this.balanceAmount = 10;

            this.loading = false;
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("modals.BalanceAdd.toasts.add.failed"));

            this.loading = false;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalBalanceAdd_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalBalanceAdd_text {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
    white-space: pre-line;
  }

  .ModalBalanceAdd_warning {
    margin-top: 0.5rem;
    height: 1.5rem;
    font-size: 0.875rem;
    color: #f14a4a;
  }

  .ModalBalanceAdd_button {
    margin-top: 1.5rem;
    margin-left: auto;
  }
}
</style>
