<template>
  <svg :fill="active ? '#163B61' : 'transparent'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C13.1046 2 14 2.89543 14 4V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4C2 2.89543 2.89543 2 4 2H12ZM10.9798 5.31311L6.66667 9.62623L5.02022 7.97978C4.82496 7.78452 4.50838 7.78452 4.31311 7.97978C4.11785 8.17504 4.11785 8.49162 4.31311 8.68689L6.31311 10.6869C6.50838 10.8821 6.82496 10.8821 7.02022 10.6869L11.6869 6.02022C11.8821 5.82496 11.8821 5.50838 11.6869 5.31311C11.4916 5.11785 11.175 5.11785 10.9798 5.31311Z" />
    <path :fill="active ? '#163B61' : '#C3C8CC'" d="M3.83333 2H12.1667C13.1792 2 14 2.82081 14 3.83333V12.1667C14 13.1792 13.1792 14 12.1667 14H3.83333C2.82081 14 2 13.1792 2 12.1667V3.83333C2 2.82081 2.82081 2 3.83333 2ZM3.83333 3C3.3731 3 3 3.3731 3 3.83333V12.1667C3 12.6269 3.3731 13 3.83333 13H12.1667C12.6269 13 13 12.6269 13 12.1667V3.83333C13 3.3731 12.6269 3 12.1667 3H3.83333Z" />
  </svg>
</template>

<script>
export default {
  name: "IconCheckbox",
  props: {
    active: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>