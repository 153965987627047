import ConnectorService from "@/services/connector";

export default class PrivilegesService extends ConnectorService {
    constructor(url, id) {
        super(url + "privileges/", id);
    }

    async roles() {
        return await this.getp("roles");
    }

    async roleInfo(roleId) {
        return await this.getp("role-info/" + roleId);
    }

    async roleUpdate(roleId, name, permissions) {
        return await this.postp("role-update/" + roleId, {
            "name": name,
            "permissions": permissions
        });
    }

    async roleAdd(name) {
        return await this.postp("role-add", {
            "name": name
        });
    }

    async roleDelete(role) {
        return await this.getp("role-delete/" + role);
    }

    async addUserRole(userId, roleId) {
        return await this.postp("role/" + userId, {
            "roleId": roleId
        });
    }

    async deleteUserRole(userId, roleId) {
        return await this.getp("delete-role/" + userId + "/" + roleId);
    }

    async addDocumentRole(documentId, roleId) {
        return await this.getp("document-role/" + documentId + "/" + roleId);
    }

    async documentRoles(documentId) {
        return await this.getp("document-roles/" + documentId);
    }

    async documentDeleteRole(documentId, roleId) {
        return await this.getp("document-delete/" + documentId + "/" + roleId);
    }

    async accountRoles() {
        return await this.getp("account-roles");
    }
}
