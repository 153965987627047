<template>
  <AppModal :active="active" :header="true" :progress="40" :title="$t('modals.DocumentUploadFileType.title')" @close="close()">
    <div class="ModalDocumentUploadFileType_container">
      <div class="ModalDocumentUploadFileType_text">{{ $t("modals.DocumentUploadFileType.text") }}</div>
      <div class="ModalDocumentUploadFileType_file">
        <div class="ModalDocumentUploadFileType_icon"><AppIcon type="document" /></div>
        <div class="ModalDocumentUploadFileType_name">{{ file?.name }}</div>
      </div>
      <div class="warning" v-show="onlyPdf">{{ $t("modals.DocumentUploadFileType.onlyPdf") }}</div>
      <div class="ModalDocumentUploadFileType_types">
        <div class="ModalDocumentUploadFileType_type" v-for="typeName in types" :key="typeName">
          <div class="ModalDocumentUploadFileType_header">
            <div class="ModalDocumentUploadFileType_radio"><AppInputRadio :name="typeName" v-model="type" :disabled="disabled[typeName]" /></div>
            <div class="ModalDocumentUploadFileType_title">{{ $t('modals.DocumentUploadFileType.types.' + typeName + '.title') }}</div>
          </div>
          <div class="ModalDocumentUploadFileType_description">{{ $t('modals.DocumentUploadFileType.types.' + typeName + '.description') }}</div>
        </div>
      </div>
      <div class="ModalDocumentUploadFileType_button">
        <AppButton size="medium" :iconLeft="false" iconType="arrow-right" @click="this.continue()">{{ $t("modals.DocumentUploadFileType.buttons.continue") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppModal from "@/components/AppModal";
import { modalCloseDocumentUploadFileType, modalOpenDocumentUploadName } from "@/modals";
import AppIcon from "@/components/AppIcon";
import AppInputRadio from "@/components/AppInputRadio";
import { useToast } from "vue-toastification";
import Account from "@/core/account";

export default {
  name: "ModalDocumentUploadFileType",
  components: {
    AppInputRadio,
    AppIcon,
    AppModal,
    AppButton
  },
  data() {
    return {
      onlyPdf: false,
      types: ["contract-noedit", "contract", "template", "file"],
      type: {
        "contract-noedit": false,
        "contract": false,
        "template": false,
        "file": false
      },
      disabled: {
        "contract-noedit": false,
        "contract": false,
        "template": false,
        "file": false
      }
    };
  },
  watch: {
    active: function (value) {
      if (value) {
        this.disableTypes();
      } else {
        this.disabled = {
          "contract-noedit": false,
          "contract": false,
          "template": false,
          "file": false
        };
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentUploadFileType,
      toast
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    file: null
  },
  methods: {
    disableTypes() {
      let fileExtension = this.file.name.split(".").pop();
      let platform = Account.platform;

      this.disabled["contract"] = true;
      this.disabled["template"] = true;
      this.disabled["contract-noedit"] = true;

      let document = [];
      let pdf = [];

      if (platform === "google") {
        document = ["doc", "docx", "dot", "dotx", "rtf", "pdf"];
        pdf = ["docx", "rtf", "pdf", "epub", "xlsx", "ods", "csv"];
      } else if (platform === "microsoft") {
        document = ["doc", "docx", "dot", "rtf"];
        pdf = ["pdf", "csv", "doc", "docx", "odp", "ods", "odt", "pot", "potm", "potx", "pps", "ppsx", "ppsxm", "ppt", "pptm", "pptx", "rtf", "xls", "xlsx"];
      }

      if (document.includes(fileExtension)) {
        this.disabled["contract"] = false;
        this.disabled["template"] = false;
      }
      if (pdf.includes(fileExtension)) {
        this.disabled["contract-noedit"] = false;
      }
      if (this.$route.name === "Document") {
        this.disabled["contract-noedit"] = true;
        this.disabled["contract"] = true;
        this.disabled["template"] = true;
        this.disabled["file"] = true;

        if (fileExtension === "pdf") {
          this.disabled["contract-noedit"] = false;
          this.onlyPdf = false;
        } else {
          this.onlyPdf = true;
        }
      }
    },
    isTypeSelected() {
      return this.type["contract-noedit"] || this.type["contract"] || this.type["template"] || this.type["file"];
    },
    selectedType() {
      if (this.type["contract-noedit"]) {
        return "contract-noedit";
      }

      if (this.type["contract"]) {
        return "contract";
      }

      if (this.type["template"]) {
        return "template";
      }

      if (this.type["file"]) {
        return "file";
      }

      return "file";
    },
    continue() {
      if (!this.isTypeSelected()) {
        this.toast.error(this.$t("modals.DocumentUploadFileType.toasts.typeNotSelected"));

        return;
      }

      modalOpenDocumentUploadName(this.file, this.selectedType());

      this.close();
    },
    close() {
      modalCloseDocumentUploadFileType();

      this.type = {
        "contract-noedit": false,
        "contract": false,
        "template": false,
        "file": false
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentUploadFileType_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .warning {
    color: rgb(241, 74, 74);
    font-size: 0.875rem;
    line-height: 1.0rem;
    margin-bottom: 1.0rem;
    font-weight: 600;
  }

  .ModalDocumentUploadFileType_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .ModalDocumentUploadFileType_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;

    .ModalDocumentUploadFileType_icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }

    .ModalDocumentUploadFileType_name {
      padding-top: 0.25rem;
      line-height: 1.0rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #2d2d2d;
    }
  }

  .ModalDocumentUploadFileType_types {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 0.25rem;

    .ModalDocumentUploadFileType_type {
      .ModalDocumentUploadFileType_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.25rem;

        .ModalDocumentUploadFileType_radio {
          width: 1.5rem;
        }

        .ModalDocumentUploadFileType_title {
          padding-top: 0.33rem;
          font-size: 0.875rem;
          line-height: 1.0rem;
          color: #2d2d2d;
          font-weight: 500;
        }
      }

      .ModalDocumentUploadFileType_description {
        margin-left: 1.5rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.0rem;
        color: #626d78;
      }
    }
  }

  .ModalDocumentUploadFileType_button {
    height: 2.5rem;
    margin-top: 1.5rem;
    margin-left: auto;
  }
}
</style>
