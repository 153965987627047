import { createI18n } from "vue-i18n";
import * as en from "@/assets/locales/en.json";
import * as fi from "@/assets/locales/fi.json";

const i18n = createI18n({
    legacy: false,
    locale: "en",
    globalInjection: true,
    messages: {
        en: en,
        fi: fi
    }
});

export default i18n;

export const names = {
    "en": "English",
    "fi": "Suomi"
};
