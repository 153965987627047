<template>
  <div class="AppStepNavigation_steps">
    <div class="AppStepNavigation_step" :class="index === currentStep ? ' AppStepNavigation_active' : ''" v-for="(step, index) in steps" :key="index">
      <div class="AppStepNavigation_number">{{ (index + 1) }}</div>
      <div class="AppStepNavigation_name">{{ step.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppStepNavigation",
  props: {
    steps: {
      type: Array,
      default: () => [],
      validator: (value) => {
        return value.every((step) => {
          return step.name && step.name.length > 0;
        });
      }
    },
    currentStep: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.AppStepNavigation_steps {
  display: flex;
  flex-direction: row;
  color: #c3c7cc;
  font-size: 0.875rem;
  width: 100%;
  height: 1.5rem;
  gap: 5.0rem;

  .AppStepNavigation_step {
    display: flex;
    flex-direction: row;

    .AppStepNavigation_number {
      width: 1.5rem;
      height: 1.25rem;
      border-radius: 50%;
      border: solid 0.0625rem #c3c7cc;

      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.25rem;
      text-align: center;
      padding-top: 0.25rem;
      margin-right: 1.0rem;
    }

    .AppStepNavigation_name {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      padding-top: 0.25rem;
    }
  }

  .AppStepNavigation_active {
    color: #163b61;

    .AppStepNavigation_number {
      border-color: #163b61;
    }
  }
}
</style>
