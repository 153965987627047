<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0223 2.99928C11.7254 3.46286 11.4858 3.96661 11.3133 4.50057L5.25 4.5C4.2835 4.5 3.5 5.2835 3.5 6.25V14.75C3.5 15.7165 4.2835 16.5 5.25 16.5H7.49879L7.49986 20.2506L12.5135 16.5H18.75C19.7165 16.5 20.5 15.7165 20.5 14.75L20.5011 12.2672C21.052 11.98 21.5566 11.616 22.0008 11.1896L22 14.75C22 16.5449 20.5449 18 18.75 18H13.0125L7.99868 21.7507C7.44585 22.1642 6.6625 22.0512 6.24901 21.4984C6.08736 21.2822 6 21.0196 6 20.7499L5.99921 18H5.25C3.45507 18 2 16.5449 2 14.75V6.25C2 4.45507 3.45507 3 5.25 3L12.0223 2.99928ZM17.5 1C20.5376 1 23 3.46243 23 6.5C23 9.53757 20.5376 12 17.5 12C14.4624 12 12 9.53757 12 6.5C12 3.46243 14.4624 1 17.5 1ZM17.5 2.5L17.4101 2.50806C17.206 2.5451 17.0451 2.70603 17.0081 2.91012L17 3L16.999 6H14L13.9101 6.00806C13.706 6.0451 13.5451 6.20603 13.5081 6.41012L13.5 6.5L13.5081 6.58988C13.5451 6.79397 13.706 6.9549 13.9101 6.99194L14 7H16.999L17 10L17.0081 10.0899C17.0451 10.294 17.206 10.4549 17.4101 10.4919L17.5 10.5L17.5899 10.4919C17.794 10.4549 17.9549 10.294 17.9919 10.0899L18 10L17.999 7H21L21.0899 6.99194C21.294 6.9549 21.4549 6.79397 21.4919 6.58988L21.5 6.5L21.4919 6.41012C21.4549 6.20603 21.294 6.0451 21.0899 6.00806L21 6H17.999L18 3L17.9919 2.91012C17.9549 2.70603 17.794 2.5451 17.5899 2.50806L17.5 2.5Z" />
  </svg>
</template>

<script>
export default {
  name: "IconCommentPlus",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>