<template>
  <svg :fill="color" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
    <rect :fill="backgroundColor" width="24" height="24" rx="6" />
    <path d="M18.6582 5.11895L18.7729 5.22723L18.8812 5.34196C19.8196 6.39588 19.7835 8.01206 18.7729 9.02273L9.79664 17.999C9.58898 18.2066 9.33073 18.3565 9.0474 18.4338L5.21064 19.4802C4.79073 19.5947 4.40543 19.2094 4.51995 18.7895L5.56634 14.9527C5.64361 14.6694 5.79348 14.4111 6.00114 14.2035L14.9774 5.22723C15.9881 4.21657 17.6042 4.18047 18.6582 5.11895ZM14.2503 7.54541L6.79664 14.999C6.72742 15.0682 6.67746 15.1543 6.6517 15.2487L5.86432 18.1358L8.7514 17.3484C8.84584 17.3227 8.93192 17.2727 9.00114 17.2035L16.4545 9.74966L14.2503 7.54541ZM7.89388 11.2497L6.76888 12.3747L5.06263 12.375C4.75197 12.375 4.50013 12.1231 4.50013 11.8125C4.50013 11.5018 4.75197 11.25 5.06263 11.25L7.89388 11.2497ZM10.8939 8.24966L9.76888 9.37466L5.06263 9.37498C4.75197 9.37498 4.50013 9.12314 4.50013 8.81248C4.50013 8.50182 4.75197 8.24998 5.06263 8.24998L10.8939 8.24966ZM15.7729 6.02273L15.0453 6.74966L17.2495 8.95466L17.9774 8.22723C18.5861 7.61848 18.5861 6.63149 17.9774 6.02273C17.3686 5.41397 16.3816 5.41397 15.7729 6.02273ZM13.8939 5.24966L12.7689 6.37466L5.06263 6.37498C4.75197 6.37498 4.50013 6.12314 4.50013 5.81248C4.50013 5.50182 4.75197 5.24998 5.06263 5.24998L13.8939 5.24966Z" />
  </svg>
</template>

<script>
export default {
  name: "IconDraftColored",
  props: {
    color: {
      type: String,
      default: "white"
    },
    backgroundColor: {
      type: String,
      default: "#7761F2"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>