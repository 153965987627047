<template>
  <div class="DocumentNavigationSignees_container">
    <DocumentNavigationItemGroup :title="$t('components.DocumentNavigationSignees.title.' + (document.isDocument ? 'document' : 'template'))" @add="toggleAdd()" :addButton="Account.permissions?.documents?.edit?.documents && document.state < Document.State.Signing">
      <DocumentNavigationSigneesAdd v-if="Account.permissions?.documents?.edit?.documents" v-show="addOpen" :document="document" :signees="signees" @close="closeAdd()" />
      <template v-for="signee in signees" :key="signee.id">
        <DocumentNavigationSignee v-show="!addOpen" :signee="signee" :document="document" />
      </template>
    </DocumentNavigationItemGroup>
    <div class="DocumentNavigationSignees_send">
      <AppButton v-if="Account.permissions?.documents?.edit?.documents && signees.length > 0 && document.isDocument && document.state < Document.State.Signing" @click="send()" size="small" :icon="false" stretch style="margin-top: 1.0rem">{{ $t("components.DocumentNavigationSignees.buttons.send") }}</AppButton>
      <AppButton v-if="Account.permissions?.documents?.edit?.documents && document.state === Document.State.Signing" @click="cancel()" size="small" :icon="false" secondaryColor="#f14a4a" stretch style="margin-top: 1.0rem">{{ $t("components.DocumentNavigationSignees.buttons.cancel") }}</AppButton>
    </div>
  </div>
</template>

<script>
import DocumentNavigationItemGroup from "@/components/DocumentNavigationItemGroup";
import DocumentNavigationSigneesAdd from "@/components/DocumentNavigationSigneesAdd";
import DocumentNavigationSignee from "@/components/DocumentNavigationSignee";
import SigningService from "@/services/signing";
import { checkErrors } from "@/utilities/errors";
import events from "@/events";
import SigneeEvents from "@/events/signee";
import AppButton from "@/components/AppButton";
import { modalOpenDocumentCancel, modalOpenDocumentSend, modalOpenDocumentSign } from "@/modals";
import Document from "@/core/document";
import Account from "@/core/account";
import DocumentEvents from "@/events/document";

export default {
  name: "DocumentNavigationSignees",
  components: {
    AppButton,
    DocumentNavigationSignee,
    DocumentNavigationSigneesAdd,
    DocumentNavigationItemGroup,
  },
  props: {
    document: {
      type: Object,
      default: null
    }
  },
  setup() {
    return {
      Account,
      Document,
      events
    };
  },
  data() {
    return {
      addOpen: false,
      signees: [],
      selfAdded: false,
      selfSignee: null,
      interval: null
    };
  },
  methods: {
    toggleAdd() {
      this.addOpen = !this.addOpen;
    },
    closeAdd() {
      this.addOpen = false;
    },
    getSignees() {
      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      if (this.document == null) {
        return;
      }

      Signing.getSignees(this.document.id)
          .then((data) => {
            this.signees = [];

            let index = 1;
            for (let signee of data?.signees) {
              signee.index = index;
              index++;
              this.signees.push(signee);
            }

            this.checkSelfAdded();
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    checkSelfAdded() {
      this.selfAdded = false;

      let found = false;
      this.signees.forEach((signee) => {
        if (signee.email === Account.email) {
          found = true;
          this.selfSignee = signee;
        }
      });

      this.selfAdded = found;

      if (this.document) {
        if (found && this.document?.state === Document.State.Signing) {
          if (this.selfSignee !== null) {
            modalOpenDocumentSign(process.env.VUE_APP_FRONTEND_URL + "public/sign/" + this.selfSignee?.id);
            clearInterval(this.interval);
            this.interval = null;
          }
        }
      }
    },
    send() {
      modalOpenDocumentSend(this.document, this.signees);
    },
    cancel() {
      modalOpenDocumentCancel(this.document);
    }
  },
  mounted() {
    this.getSignees();

    DocumentEvents.send(this, (instance) => {
      instance.interval = setInterval(() => {
        instance.checkSelfAdded();
      }, 1000);
    });
    SigneeEvents.add(this, "signees");
    SigneeEvents.add(this, (instance) => {
      instance.checkSelfAdded();
    });
    SigneeEvents.remove(this, "signees");
  },
  unmounted() {
    this.signees = [];
  }
}
</script>

<style lang="scss" scoped>

</style>
