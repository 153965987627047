import store from "@/store";

export default class Session {
    static get id() {
        return store.getters["session/get"];
    }

    static get selectedOrganization() {
        return store.getters["account/selectedOrganization"];
    }
}
