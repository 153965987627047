<template>
  <div class="ResourceState_state" :class="'ResourceState_' + stateName">{{ $t("components.ResourceState.states." + stateName + "." + type) }}</div>
</template>

<script>
export default {
  name: "ResourceState",
  props: {
    state: {
      type: Number,
      required: true,
      validator: (value) => {
        return [1, 10, 20, 30, 40].includes(value);
        //return ["draft", "published", "signing", "signed", "archived"].includes(value);
      }
    },
    type: {
      type: String,
      default: "document",
      validator: (value) => {
        return ["document", "signee"].includes(value);
      }
    }
  },
  computed: {
    stateName() {
      switch (this.state) {
        case 1:
          return "draft";
        case 10:
          return "published";
        case 20:
          return "signing";
        case 30:
          return "signed";
        case 40:
          return "archived";
        default:
          return "draft";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ResourceState_state {
  border-radius: 0.5rem;
  height: 1.0rem;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.0rem;
}

.ResourceState_draft {
  color: #4198f0;
  background-color: rgba(65, 152, 240, 0.1);
}

.ResourceState_published {
  color: #7761f2;
  background-color: rgba(119, 97, 242, 0.1);
}

.ResourceState_signing {
  color: #ef9f31;
  background-color: rgba(239, 159, 49, 0.1);
}

.ResourceState_signed {
  color: #39c178;
  background-color: rgba(57, 193, 120, 0.1);
}

.ResourceState_archived {
  color: #f14a4a;
  background-color: rgba(241, 74, 74, 0.1);
}
</style>
