<template>
  <AppModal :active="active" :header="true" :progress="20" :title="$t('modals.DocumentUpload.title')" @close="close()">
    <div class="ModalDocumentUpload_container">
      <div class="ModalDocumentUpload_text">{{ $t("modals.DocumentUpload.text") }}</div>
      <div class="ModalDocumentUpload_dropbox">
        <AppUploadZone v-if="active" @file-selected="fileSelected" />
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentUpload, modalOpenDocumentUploadFileType } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppUploadZone from "@/components/AppUploadZone";

export default {
  name: "ModalDocumentUpload",
  components: {
    AppUploadZone,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentUpload,
      toast
    };
  },
  methods: {
    fileSelected(file) {
      this.close();

      modalOpenDocumentUploadFileType(file);
    },
    close() {

      modalCloseDocumentUpload();
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentUpload_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentUpload_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }
}
</style>
