<template>
  <div class="container">
    <AppLoading />
  </div>
</template>

<script>
import events from "@/events";
import AppLoading from "@/components/AppLoading";
import { accountRoles } from "@/utilities";

export default {
  name: "AppStripe",
  components: {
    AppLoading
  },
  setup() {
    return {
      events
    };
  },
  mounted() {
    accountRoles();
    setTimeout(() => {
      this.$router.push("/settings/billing");
    }, 2500);
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>
