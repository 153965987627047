import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import session from "@/store/modules/session";
import setup from "@/store/modules/setup";
import account from "@/store/modules/account";
import guides from "@/store/modules/guides";

const store = createStore({
    modules: {
        session,
        setup,
        account,
        guides
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage
        })
    ]
});

export default store;
