<template>
  <div class="container">
    <slot name="tabs" />
  </div>
  <div class="content">
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: "AppTabGroup"
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
</style>
