<template>
  <AppModal :active="active" :header="true" :progress="100" title="" :overlayClose="true" @close="modalCloseDocumentUploadComplete()">
    <div class="ModalDocumentUploadComplete_container">
      <img src="@/assets/images/splash-upload-complete.png" alt="" />
      <div class="ModalDocumentUploadComplete_title">{{ $t("modals.DocumentUploadComplete.title." + document?.type) }}</div>
      <div class="ModalDocumentUploadComplete_text">{{ $t("modals.DocumentUploadComplete.text." + document?.type) }}</div>
      <div class="buttons">
        <AppButton size="medium" :iconLeft="false" iconType="document" @click="this.open()">{{ $t("modals.DocumentUploadComplete.buttons.open." + document?.type) }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentUploadComplete } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalDocumentUploadComplete",
  components: {
    AppButton,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: () => { return { type: "file" }; }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentUploadComplete,
      toast
    };
  },
  methods: {
    open() {
      modalCloseDocumentUploadComplete();

      this.$router.push("/document/" + this.document?.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentUploadComplete_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentUploadComplete_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalDocumentUploadComplete_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-left: auto;
  }
}
</style>
