<template>
  <div class="SettingsUsers_container">
    <div class="SettingsUsers_header">
      <div class="SettingsUsers_title">{{ $t("components.SettingsUsers.header") }}</div>
      <div v-if="Account.permissions?.organization?.users?.invite" class="SettingsUsers_button">
        <AppButton @click="modalOpenUserInvite(organization)" type="outline" primaryColor="#4198f0" size="medium">{{ $t("components.SettingsUsers.buttons.add") }}</AppButton>
      </div>
    </div>
    <UserList :organization="organization" :users="organization.users" :userInvites="organization.userInvites" />
  </div>
</template>

<script>
import UserList from "@/components/UserList";
import AppButton from "@/components/AppButton";
import { modalOpenUserInvite } from "@/modals";
import Organization from "@/core/organization";
import events from "@/events";
import Account from "@/core/account";

export default {
  name: "SettingsUsers",
  components: {
    AppButton,
    UserList
  },
  props: {
    organization: {
      type: Organization,
      required: true
    }
  },
  setup() {
    return {
      modalOpenUserInvite,
      events,
      Account
    };
  }
}
</script>

<style lang="scss" scoped>
.SettingsUsers_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;

  .SettingsUsers_header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
    display: flex;
    flex-direction: row;
    align-items: center;

    .SettingsUsers_title {
      flex: 1;
    }

    .SettingsUsers_button {
    }
  }
}
</style>
