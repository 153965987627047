<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5ZM11.75 6C12.1297 6 12.4435 6.28215 12.4932 6.64823L12.5 6.75V12H16.2483C16.6625 12 16.9983 12.3358 16.9983 12.75C16.9983 13.1297 16.7161 13.4435 16.35 13.4932L16.2483 13.5H11.75C11.3703 13.5 11.0565 13.2178 11.0068 12.8518L11 12.75V6.75C11 6.33579 11.3358 6 11.75 6Z" />
  </svg>
</template>

<script>
export default {
  name: "IconClock",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>