<template>
  <div class="DocumentNavigationAttachments_container">
    <DocumentNavigationItemGroup :title="$t('components.DocumentNavigationAttachments.title')" :addButton="Account.permissions?.documents?.edit?.documents && document.state < Document.State.Signing" @add="modalOpenDocumentUpload()">
      <template v-for="attachment in document.children" :key="attachment">
        <DocumentNavigationAttachment :document="document" :attachment="attachment" />
      </template>
    </DocumentNavigationItemGroup>
  </div>
</template>

<script>
import DocumentNavigationItemGroup from "@/components/DocumentNavigationItemGroup";
import Account from "@/core/account";
import Document from "@/core/document";
import { modalOpenDocumentUpload } from "@/modals";
import DocumentNavigationAttachment from "@/components/DocumentNavigationAttachment";

export default {
  name: "DocumentNavigationAttachments",
  components: {
    DocumentNavigationAttachment,
    DocumentNavigationItemGroup
  },
  props: {
    document: {
      type: Object,
      default: null
    }
  },
  setup() {
    return {
      Document,
      Account,
      modalOpenDocumentUpload
    };
  },
  mounted() {
    console.log(this.document);
  }
}
</script>

<style lang="scss" scoped>
</style>
