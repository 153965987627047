<template>
  <div class="ResourceLabel_label" :style="style" @mouseover="hover = true" @mouseleave="hover = false">
    <div><slot /></div>
    <div v-show="hover && editable" @click="$emit('edit')"><IconEdit :color="currentColor" class="ResourceLabel_edit-icon" /></div>
    <div v-show="hover && closable" @click="$emit('close')"><IconClose :color="currentColor" class="ResourceLabel_close-icon" /></div>
  </div>
</template>

<script>
import IconClose from "@/assets/icons/IconClose";
import IconEdit from "@/assets/icons/IconEdit";

export default {
  name: "ResourceLabel",
  components: {
    IconEdit,
    IconClose
  },
  emits: [
    "edit",
    "close"
  ],
  props: {
    color: {
      type: String,
      default: "#626d78"
    },
    borderColor: {
      type: String,
      default: "#c3c7cc"
    },
    backgroundColor: {
      type: String,
      default: "transparent"
    },
    hoverColor: {
      type: String,
      default: "#626d78"
    },
    hoverBorderColor: {
      type: String,
      default: "#c3c7cc"
    },
    hoverBackgroundColor: {
      type: String,
      default: "transparent"
    },
    editable: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    currentColor() {
      if (!this.hover) {
        return this.color;
      } else {
        return this.hoverColor;
      }
    },
    style() {
      if (!this.hover) {
        return "color: " + this.color + "; border-color: " + this.borderColor + "; background-color: " + this.backgroundColor;
      } else {
        return "color: " + this.hoverColor + "; border-color: " + this.hoverBorderColor + "; background-color: " + this.hoverBackgroundColor;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ResourceLabel_label {
  border-radius: 0.5rem;
  height: 1.0rem;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.0rem;
  border: solid 0.5px;
  display: flex;
}

.ResourceLabel_close-icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 0.125rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.ResourceLabel_edit-icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 0.125rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
