<template>
  <AppModal :active="active" :header="true" :overlayClose="hasChanges()" :title="$t('modals.UserRoles.title')" @close="modalCloseUserRoles()">
    <div class="ModalUserRoles_container">
      <div class="ModalUserRoles_user">
        <span class="user-container">
          <span class="avatar"><AppAvatar :name="user?.fullName" size="small" /></span>
          <span class="info-container">
            <span class="name">{{ user?.fullName }}</span>
            <span class="email">{{ user?.email }}</span>
          </span>
        </span>
      </div>
      <AppInputMultiSelect :editable="Account.permissions?.users?.edit?.roles" v-model="selectedRoles" :options="availableRoles" :placeholder="$t('modals.UserRoles.inputs.roles.placeholder')" />
      <div class="buttons" v-if="active">
        <AppButton :loading="saveLoading" :disabled="hasChanges()" size="small" iconType="checkmark" @click="save()">{{ $t("modals.UserRoles.buttons.save") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseUserRoles } from "@/modals/index";
import { useToast } from "vue-toastification";
import Organization from "@/core/organization";
import User from "@/core/user";
import AppAvatar from "@/components/AppAvatar";
import AppInputMultiSelect from "@/components/AppInputMultiSelect";
import AppButton from "@/components/AppButton";
import { checkErrors } from "@/utilities/errors";
import PrivilegesService from "@/services/privileges";
import events from "@/events";
import Account from "@/core/account";

export default {
  name: "ModalUserRoles",
  components: {
    AppInputMultiSelect,
    AppModal,
    AppAvatar,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Organization
    },
    user: {
      type: User
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseUserRoles,
      toast,
      events,
      Account
    };
  },
  data() {
    return {
      saveLoading: false,
      selectedRoles: {},
      availableRoles: []
    };
  },
  watch: {
    active: function() {
      if (this.active) {
        let newSelectedRoles = {};
        for (let role of this.organization.roles) {
          newSelectedRoles[role.id] = this.user.roles.some(r => r.id === role.id);
        }
        this.selectedRoles = newSelectedRoles;
        this.availableRoles = this.organization.roles.map(role => {
          return {
            label: role.name,
            value: role.id
          };
        });
      } else {
        this.selectedRoles = {};
        this.availableRoles = [];
      }
    }
  },
  methods: {
    save() {
      this.saveLoading = true;

      let toAdd = 0;
      let toRemove = 0;

      //First pass
      this.availableRoles.forEach(role => {
        let isSelected = this.selectedRoles[role.value];
        if (isSelected) {
          let hasRole = this.user.roles.some(r => r.id === role.value);
          if (!hasRole) {
            toAdd++;
          }
        } else {
          let hasRole = this.user.roles.some(r => r.id === role.value);
          if (hasRole) {
            toRemove++;
          }
        }
      });

      let toAddCount = 0;
      let toRemoveCount = 0;

      const changeComplete = () => {
        return toAddCount === toAdd && toRemoveCount === toRemove;
      };

      const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      //Second pass
      this.availableRoles.forEach(role => {
        let isSelected = this.selectedRoles[role.value];
        if (isSelected) {
          let hasRole = this.user.roles.some(r => r.id === role.value);
          if (!hasRole) {
            Privileges.addUserRole(this.user.id, role.value)
                .then(() => {
                  toAddCount++;
                  if (changeComplete()) {
                    this.toast.success(this.$t("modals.UserRoles.toasts.save.success"));

                    this.events.$emit("role:edit");

                    this.saveLoading = false;

                    modalCloseUserRoles();
                  }
                })
                .catch((error) => {
                  checkErrors(error);
                });
          }
        } else {
          let hasRole = this.user.roles.some(r => r.id === role.value);
          if (hasRole) {
            Privileges.deleteUserRole(this.user.id, role.value)
                .then(() => {
                  toRemoveCount++;
                  if (changeComplete()) {
                    this.toast.success(this.$t("modals.UserRoles.toasts.save.success"));

                    this.events.$emit("role:edit");

                    this.saveLoading = false;

                    modalCloseUserRoles();
                  }
                })
                .catch((error) => {
                  checkErrors(error);
                });
          }
        }
      });
    },
    hasChanges() {
      return Object.keys(this.selectedRoles).every(key => {
        let isSelected = this.selectedRoles[key];
        if (isSelected) {
          return this.user.roles.some(r => r.id === key);
        } else {
          return !this.user.roles.some(r => r.id === key);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalUserRoles_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalUserRoles_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalUserRoles_user {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;

    .user-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.0rem;

      .avatar {
        height: 2.0rem;
        width: 2.0rem;
        margin-right: 1.0rem;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        height: 2.0rem;

        .name {
          font-size: 0.875rem;
          font-weight: 600;
          color: #2d2d2d;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }

        .email {
          font-size: 0.75rem;
          font-weight: 400;
          color: #626d78;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }
      }
    }
  }

  .buttons {
    margin-top: 1.5rem;
    margin-left: auto;
  }
}
</style>
