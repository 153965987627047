<template>
  <AppHeader />
  <div class="AppTemplates_container">
    <div class="AppTemplates_left">
      <AppNavigation />
    </div>
    <div class="AppTemplates_right">
      <div class="AppTemplates_sidebar">
        <div class="AppTemplates_search">
          <AppInputSearch placeholder="Search templates" size="small" v-model="searchFilter" />
        </div>
        <NavigationItemGroup title="Filters">
          <AppInputMultiSelect @changed="filterTemplates()" :options="[{'label':'Employment','value':'Employment'},{'label':'Confidentiality','value':'Confidentiality'},{'label':'Finance','value':'Finance'},{'label':'Loans','value':'Loans'},{'label':'Business','value':'Business'},{'label':'Lease','value':'Lease'},{'label':'Sublease','value':'Sublease'},{'label':'Real Estate','value':'Real Estate'},{'label':'Commercial Leases','value':'Commercial Leases'},{'label':'Business Formation','value':'Business Formation'},{'label':'Contracts','value':'Contracts'},{'label':'Customer Agreements','value':'Customer Agreements'},{'label':'Compliance','value':'Compliance'},{'label':'Customer Due Diligence','value':'Customer Due Diligence'},{'label':'Privacy and Data Protection','value':'Privacy and Data Protection'},{'label':'Lease Agreements','value':'Lease Agreements'},{'label':'Termination','value':'Termination'},{'label':'Meetings','value':'Meetings'}]" placeholder="Category" v-model="selectedCategory" style="width: 17.5rem; margin-left: 1.0rem" />
          <AppInputMultiSelect @changed="filterTemplates()" :options="[{ label: 'Finnish', value: 'fi' }]" placeholder="Language" v-model="selectedLanguage" style="width: 17.5rem; margin-left: 1.0rem" />
          <AppInputMultiSelect @changed="filterTemplates()" :options="[{ label: 'Finland', value: 'FI' }]" placeholder="Jurisdiction" v-model="selectedJurisdiction" style="width: 17.5rem; margin-left: 1.0rem" />
          <!--
          <AppInputMultiSelect @changed="filterTemplates()" :options="[{ label: 'Employment', value: 'Employment' }, { label: 'Confidentiality', value: 'Confidentiality' }, { label: 'Business Formation', value: 'Business Formation' }, { label: 'Financial Agreements', value: 'Financial Agreements' }, { label: 'Contract Termination', value: 'Contract Termination' }, { label: 'Data Protection', value: 'Data Protection' }, { label: 'Liabiility Agreements', value: 'Liabiility Agreements' }, { label: 'Liability Agreements', value: 'Liability Agreements' }]" placeholder="Category" v-model="selectedCategory" style="width: 17.5rem; margin-left: 1.0rem" />
          <AppInputMultiSelect @changed="filterTemplates()" :options="[{ label: 'English', value: 'en' }, { label: 'Finnish', value: 'fi' }, { label: 'Swedish', value: 'sv' }, { label: 'Spanish', value: 'es' }]" placeholder="Language" v-model="selectedLanguage" style="width: 17.5rem; margin-left: 1.0rem" />
          <AppInputMultiSelect @changed="filterTemplates()" :options="[{ label: 'Finland', value: 'FI' }, { label: 'Spain', value: 'ES' }]" placeholder="Jurisdiction" v-model="selectedJurisdiction" style="width: 17.5rem; margin-left: 1.0rem" />
          -->
        </NavigationItemGroup>
        <template v-for="(templates, category) in filtered" :key="category">
          <NavigationItemGroup v-if="templates.length > 0" :title="category">
            <NavigationItem @click="selectTemplate(template)" :active="selectedTemplate?.id === template.id" v-for="template in templates" :key="template.id" icon="draft-colored" type="blue">{{ template.name }}</NavigationItem>
          </NavigationItemGroup>
        </template>
      </div>
      <div class="AppTemplates_content">
        <div v-if="selectedTemplate === null" style="color: #2d2d2d">
          <strong>Download AI-Generated Documents for FREE!</strong><br />
          <span>Powered by artificial intelligence, effortlessly search and find the contract or any other essential document you require. Click a button, tailor the document to your needs, sign it digitally, and voilà — job done! Access our vast database with no technical expertise needed.</span>
          <br /><br /><strong>Disclaimer for Legal Templates Provided by Sowise</strong><br />
          <span>Please be aware that the legal templates provided by Sowise have not undergone review by legal experts. These templates are designed as starting points to assist in drafting legal documents. They are not intended to serve as a substitute for professional legal advice. Users are strongly encouraged to seek legal counsel to ensure all details are accurate and in compliance with the specific laws and regulations within their jurisdiction. Sowise does not offer legal advice, nor does it represent the effectiveness, completeness, or appropriateness of the templates for any particular purpose. As such, Sowise does not assume any responsibility or liability for any direct or indirect damages that may result from the use of these documents. By selecting ‘Use This Template’, you, the user, acknowledge and agree to these terms, understanding the limitations and nature of the legal templates provided by Sowise. It is your responsibility to ensure that any document you create using our templates is legally sound and meets your specific requirements.</span>
          <!--<div v-show="$route.query.c === 'lexia' || !$route.query.c"><br />
            <svg width="86px" height="20px" viewBox="0 0 86 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Page 1</title>
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="search_bar02" transform="translate(-80.000000, -30.000000)" fill="#0F1E73">
                  <path d="M131.338455,30 L127.838598,34.5667432 L129.866728,37.2530628 L135.601771,30 L131.338455,30 Z M97.8862747,30 L97.8862747,50 L112.771351,50 L112.771351,46.6877233 L101.378474,46.6877233 L101.378474,41.344755 L111.415436,41.344755 L111.415436,38.2446401 L101.378474,38.2446401 L101.378474,33.3263144 L112.771351,33.3263144 L112.771351,30 L97.8862747,30 Z M80,30 L80,49.9993619 L94.2447653,49.9993619 L94.2447653,46.6886804 L83.4918801,46.6886804 L83.4918801,30 L80,30 Z M154.323289,30.000319 L145.518774,50 L149.159007,50 L151.008475,45.58512 L160.422355,45.58512 L162.27597,50 L165.914289,50 L157.107541,30.000319 L154.323289,30.000319 Z M155.714298,34.3577718 L159.119081,42.4795814 L152.314301,42.4795814 L155.714298,34.3577718 Z M139.062704,50 L142.374327,50 L142.374327,30.000319 L139.062704,30.000319 L139.062704,50 Z M116.235475,30.000319 L123.945048,39.801876 L115.918031,50 L120.186452,50 L127.894111,39.801876 L120.500705,30.000319 L116.235475,30.000319 Z M127.834451,45.0341373 L131.65526,49.9993619 L135.917938,49.9993619 L129.867685,42.3423941 L127.834451,45.0341373 Z" id="Page-1"></path>
                </g>
              </g>
            </svg>
            <br />
            <span>Explore the realm of legal mastery with Lexia Attorneys, a distinguished Finnish law firm specializing in domestic and international business law, with a presence in Helsinki, Turku, Tampere, and Oulu. Our expertise spans a wide spectrum including ICT and Technology, Housing, EU, Competitive, Contract, Employment, Financial and Insurance Law, as well as Mergers and Acquisitions. With a robust team of over 80 legal experts, we offer tailored solutions ensuring your business navigates the legal landscape with ease. Our international collaboration networks exemplify our commitment to delivering reliable legal support, transcending geographic boundaries. Engage with Lexia Attorneys and embrace a partnership poised to fuel your business success, no matter where your operations unfold.</span>
          </div>
          <div v-show="$route.query.c === 'marimon'"><br />
            <img width="150" src="https://www.marimon-abogados.com/wp-content/themes/marimon/img/logomarimon.png" />
            <br />
            <span>Elevate your business operations with the seasoned expertise of Marimón Abogados, a revered Spanish law firm with a legacy rooted in 1931. Our adept team of nearly 70 lawyers across Barcelona, Madrid, and Seville, is ready to navigate the intricate legal terrains, ensuring your business adheres to regulatory standards while thriving in its endeavors. Specializing in a plethora of legal domains including Corporate Law, Banking and Finance, Intellectual & Industrial Property, IT Law, and Real Estate, we provide tailored solutions to meet your unique legal requirements1. Beyond generic legal counsel, our sector-specific expertise spans Consumer Goods and Retail, Credit Institutions, Energy, and more, offering a nuanced legal approach to industry-specific challenges. Engage with Marimón Abogados, your strategic legal partner, and propel your business towards a horizon of compliance, innovation, and success.</span>
          </div>
          <div v-show="$route.query.c === 'fondia'"><br />
            <svg xmlns="http://www.w3.org/2000/svg" id="fondia_logo_with_text" overflow="visible" viewBox="0 0 189.48 40" width="150">
              <path fill="#cac514" d="M20 20l-8.37 8.37-.57.85c-2.38 3.52-2.64 6.86 1.02 9.33A7.38 7.38 0 0016.04 40a2.74 2.74 0 002.4-1.16 2.02 2.02 0 00-.52-2.97 2.13 2.13 0 00-1.18-.37 1.92 1.92 0 00-1.62.9 1.84 1.84 0 00.08 2.23 1.48 1.48 0 01-.34.04 2.77 2.77 0 01-1.47-.49c-1.79-1.2-.54-3.47 1.38-6.33L20 24.08V20m3.96-20a2.74 2.74 0 00-2.4 1.16 2.02 2.02 0 00.52 2.97 2.13 2.13 0 001.18.37 1.92 1.92 0 001.62-.9 1.84 1.84 0 00-.08-2.23 1.49 1.49 0 01.34-.04 2.76 2.76 0 011.47.49c1.79 1.2.54 3.47-1.38 6.33L20 15.92V20l8.37-8.37.57-.85c2.38-3.52 2.64-6.86-1.02-9.33A7.38 7.38 0 0023.96 0"/>
              <path fill="#ec008c" d="M20 20v4.08l5.23 7.77c1.92 2.86 3.17 5.12 1.38 6.33a2.77 2.77 0 01-1.47.49 1.48 1.48 0 01-.34-.04 1.84 1.84 0 00.08-2.23 1.92 1.92 0 00-1.62-.9 2.13 2.13 0 00-1.18.37 2.02 2.02 0 00-.51 2.97 2.74 2.74 0 002.4 1.16 7.38 7.38 0 003.95-1.45c3.66-2.47 3.4-5.8 1.02-9.33l-.57-.85L20 20M16.04 0a7.38 7.38 0 00-3.96 1.45c-3.66 2.47-3.4 5.8-1.02 9.33l.57.85L20 20v-4.08l-5.23-7.77C12.85 5.3 11.6 3.03 13.4 1.82a2.77 2.77 0 011.47-.49 1.5 1.5 0 01.34.04 1.84 1.84 0 00-.08 2.23 1.92 1.92 0 001.62.9 2.13 2.13 0 001.18-.37 2.02 2.02 0 00.51-2.97A2.74 2.74 0 0016.03 0"/>
              <path fill="#58c4c7" d="M20 20l-8.37-8.37-.85-.57A8.79 8.79 0 006.03 9.3"/>
              <path fill="#58c4c7" d="M24.08 20H20l8.37 8.37.85.57a8.79 8.79 0 004.75 1.76 5.44 5.44 0 004.58-2.78c1.87-2.77 1.9-5.27.29-6.35a2.33 2.33 0 00-1.3-.43 2 2 0 00-1.67.94 2 2 0 001.61 3.14 1.78 1.78 0 001.15-.42 2.5 2.5 0 01-.45 1.81 1.8 1.8 0 01-1.58.87c-1.23 0-2.86-.98-4.75-2.25L24.08 20M6.03 9.3a5.44 5.44 0 00-4.58 2.78c-1.87 2.77-1.9 5.27-.29 6.35a2.33 2.33 0 001.3.43 2 2 0 001.67-.94 1.96 1.96 0 00-.53-2.8 1.94 1.94 0 00-1.08-.34 1.78 1.78 0 00-1.15.42 2.5 2.5 0 01.45-1.81 1.8 1.8 0 011.58-.87c1.23 0 2.86.98 4.75 2.25L15.92 20H20"/>
              <path fill="#9d0038" d="M15.92 20l-7.77 5.23c-1.89 1.27-3.52 2.25-4.75 2.25a1.8 1.8 0 01-1.58-.87 2.5 2.5 0 01-.45-1.8 1.78 1.78 0 001.15.41 1.94 1.94 0 001.08-.34 1.96 1.96 0 00.53-2.8 2 2 0 00-1.67-.94 2.33 2.33 0 00-1.3.43c-1.6 1.08-1.58 3.58.29 6.35a5.44 5.44 0 004.58 2.78 8.79 8.79 0 004.75-1.76l.85-.57L20 20h-4.08M33.97 9.3a8.79 8.79 0 00-4.75 1.76l-.85.57L20 20h4.08l7.77-5.23c1.89-1.27 3.52-2.25 4.75-2.25a1.8 1.8 0 011.58.87 2.5 2.5 0 01.45 1.8 1.78 1.78 0 00-1.15-.41 2 2 0 00-1.61 3.14 2 2 0 001.67.94 2.33 2.33 0 001.3-.43c1.6-1.08 1.58-3.58-.29-6.35a5.44 5.44 0 00-4.58-2.78"/>
              <path fill="#2b0004" d="M17.26 20l-.42.62 1.52 1.02-1.1-1.64m4.38-1.64l1.1 1.64.42-.62-1.52-1.02"/>
              <path fill="#bb000b" d="M20 22.74l-.62.42.62.92.62-.92-.62-.42m0-6.82l-.62.92.62.42.62-.42-.62-.92"/>
              <path fill="#51006d" d="M23.16 20.62l-1.52 1.02-1.02 1.52 7.75 5.21-5.21-7.75m-11.53-9l5.21 7.76 1.52-1.02 1.02-1.52-7.75-5.21"/>
              <path fill="#410009" d="M21.64 21.64L20 22.74l.62.42 1.02-1.52m-2.26-4.8l-1.02 1.52 1.64-1.1-.62-.42"/>
              <path fill="#7c0004" d="M16.84 20.62l-5.21 7.75 7.75-5.21-1.02-1.52-1.52-1.02m11.53-9l-7.75 5.22 1.02 1.52 1.52 1.02 5.21-7.75"/>
              <path fill="#730002" d="M18.36 21.64l1.02 1.52.62-.42-1.64-1.1m2.26-4.8l-.62.42 1.64 1.1-1.02-1.52"/>
              <path fill="#36002c" d="M16.84 19.38l-.92.62.92.62.42-.62-.42-.62m6.32 0l-.42.62.42.62.92-.62-.92-.62"/>
              <path fill="#320018" d="M22.74 20l-1.1 1.64 1.52-1.02-.42-.62m-4.38-1.64l-1.52 1.02.42.62 1.1-1.64"/>
              <path fill="#280002" d="M20 17.26l-1.64 1.1-1.1 1.64 1.1 1.64 1.64 1.1 1.64-1.1 1.1-1.64-1.1-1.64-1.64-1.1"/>
              <path fill="#333" d="M177.26 6.8h-.66l-8.52 19.5c-1.26 2.96-3.07 4.94-4.66 5.7v.48h7.62V32c-1.74-1-2.37-3-1.3-5.8l.45-1.13h9.18l.45 1.05c1.4 3.41.85 5.43-.6 5.88v.5h10.26v-.5c-1.7-1.08-2.85-2.77-4.19-5.96zm-6.63 17.1l4.06-9.8 4.2 9.8zm-11.55-12c0-2.58.7-3.67 1.56-4.2v-.48h-8.44v.49c.89.52 1.55 1.6 1.55 4.2V27.8c0 2.5-.66 3.67-1.55 4.2v.48h8.44V32c-.89-.56-1.56-1.68-1.56-4.2zm-33.72-4.68v.49c.89.52 1.55 1.6 1.55 4.2V27.8c0 2.58-.66 3.67-1.55 4.2v.48h9c9.73 0 14.4-5.28 14.4-12.7 0-7.8-4.63-12.56-14.26-12.56zm9.66 24.14c-1.74 0-2.78-.83-2.78-3.34V8.38h1.9c6.1 0 8.88 5.02 8.88 11.62 0 6.52-2.78 11.36-7.78 11.36zm-15.88-17.54c.11-3.45 1.26-5.48 2.63-6.11v-.5h-6.74v.5c1.4.63 2.52 2.58 2.63 6.07l-.15 10.37-14.47-16.93h-5v.49c1.66 1.38 2.74 3.93 2.81 7.57l-.26 10.5c-.1 3.55-1.26 5.58-2.63 6.21v.5l6.82-.01V32c-1.37-.63-2.56-2.58-2.67-6.22l.04-10.4 15.92 17.82h.66zM82.19 32.89c7.73 0 12.88-5.24 12.88-13.15 0-7.39-4.93-12.93-12.88-12.93-7.78 0-12.81 5.58-12.81 12.93 0 8.24 4.8 13.15 12.8 13.15m0-1.16c-4.62 0-6.95-5.55-6.95-12 0-6.21 2.18-11.76 6.96-11.76 4.66 0 7 5.55 7 11.77 0 6.48-2.2 12-7 12M66.67 7.21H48.68v.49c.89.52 1.56 1.6 1.56 4.2V27.8c0 2.58-.67 3.67-1.56 4.2v.48h8.63V32c-1-.52-1.74-1.57-1.74-4.2V20.5l2.22.06c2.44.23 3.63 1.58 4.59 3.6h.48v-8.43h-.48c-.93 2.13-2.11 3.45-4.6 3.67h-2.21V8.38H59c3.7 0 5.96 2.03 7.18 5.06h.48z"/>
            </svg>
            <br />
            <span>Founded in 2004, Fondia is a full-service business law firm and a pioneer in exploiting new types of service models and technologies. Fondia offers customers pre-packaged fixed-priced service concepts as well as new ways to purchase case-based legal services and utilize digital environments in its service models. Strong values guide us today and tomorrow – we work with integrity and rely on each other. Fondia already has more than two hundred legal department partners and more than 160 employees.</span>
          </div>-->
        </div>
        <template v-else>
          <div class="AppTemplates_template">
            <div class="AppTemplates_template-header">
              <div class="AppTemplates_template-header-title">
                <div class="AppTemplates_template-header-title-icon"><AppIcon type="draft-colored" /></div>
                {{ selectedTemplate.name }}
                <div class="AppTemplates_template-header-title-button">
                  <AppButton size="medium" :icon="false" :loading="useLoading" @click="useTemplate(selectedTemplate.id)">Use this template</AppButton>
                </div>
              </div>
              <div class="AppTemplates_template-header-description">{{ selectedTemplate.description }}</div>
            </div>
          </div>
          <div class="AppDocument_pdf">
            <iframe class="AppDocument_pdf" :src="'https://api-dev.sowise.fi/marketplace/' + selectedTemplate.id + '.pdf'" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppNavigation from "@/components/AppNavigation";
import NavigationItemGroup from "@/components/NavigationItemGroup";
import NavigationItem from "@/components/NavigationItem";
import AppInputSearch from "@/components/AppInputSearch";
import AppIcon from "@/components/AppIcon";
import AppButton from "@/components/AppButton";
import AppInputMultiSelect from "@/components/AppInputMultiSelect";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "AppTemplates",
  components: {
    AppInputMultiSelect,
    AppInputSearch,
    AppNavigation,
    AppHeader,
    NavigationItemGroup,
    NavigationItem,
    AppIcon,
    AppButton
  },
  data() {
    /*
Employment
Confidentiality
Business Formation
Financial Agreements
Contract Termination
Data Protection
Liabiility Agreements
Liability Agreements

     */
    return {
      templates:{
        "Employment": [
          {
            "id": "0ec98240d3910a4d993e34654f9a1744",
            "name": "Ty\u00f6ntekij\u00e4n salassapitosopimus",
            "category": "Employment",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "# Ty\u00f6ntekij\u00e4n Salassapitosopimus (Suomi)\n\n\n\nTy\u00f6ntekij\u00e4n salassapitosopimus on oikeudellinen asiakirja, jota k\u00e4ytet\u00e4\u00e4n Suomessa ty\u00f6suhteiden yhteydess\u00e4. Asiakirja m\u00e4\u00e4rittelee ty\u00f6ntekij\u00e4n velvoitteet ja rajoitukset liittyen ty\u00f6nantajan luottamuksellisten tietojen k\u00e4sittelyyn. Sopimuksessa korostetaan ty\u00f6ntekij\u00e4n vastuuta salassa pidett\u00e4vien tietojen suojaamisessa ja m\u00e4\u00e4ritell\u00e4\u00e4n seuraamukset mahdollisista rikkomuksista.\n\n"
          }
        ],
        "Confidentiality": [
          {
            "id": "0ec98240d3910a4d993e34654f9a1744",
            "name": "Ty\u00f6ntekij\u00e4n salassapitosopimus",
            "category": "Confidentiality",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "# Ty\u00f6ntekij\u00e4n Salassapitosopimus (Suomi)\n\n\n\nTy\u00f6ntekij\u00e4n salassapitosopimus on oikeudellinen asiakirja, jota k\u00e4ytet\u00e4\u00e4n Suomessa ty\u00f6suhteiden yhteydess\u00e4. Asiakirja m\u00e4\u00e4rittelee ty\u00f6ntekij\u00e4n velvoitteet ja rajoitukset liittyen ty\u00f6nantajan luottamuksellisten tietojen k\u00e4sittelyyn. Sopimuksessa korostetaan ty\u00f6ntekij\u00e4n vastuuta salassa pidett\u00e4vien tietojen suojaamisessa ja m\u00e4\u00e4ritell\u00e4\u00e4n seuraamukset mahdollisista rikkomuksista.\n\n"
          },
          {
            "id": "267f978dc79242f4704f99cd6d839a3d",
            "name": "Alihankintasopimus",
            "category": "Confidentiality",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Alihankintasopimus on oikeudellinen asiakirja, joka m\u00e4\u00e4rittelee p\u00e4\u00e4urakoitsijan ja alihankkijan v\u00e4liset oikeudet, velvollisuudet ja vastuut tietyss\u00e4 projektissa Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Sopimus sis\u00e4lt\u00e4\u00e4 yksityiskohtaiset ehdot liittyen ty\u00f6n laatuun, aikatauluihin, korvauksiin ja mahdollisiin sanktioihin sopimusrikkomuksen tapauksessa. Asiakirja varmistaa, ett\u00e4 kaikki projektin osapuolet ymm\u00e4rt\u00e4v\u00e4t velvoitteensa ja odotukset, edist\u00e4en n\u00e4in sujuvaa yhteisty\u00f6t\u00e4 ja projektin onnistunutta toteutusta.\n\n"
          }
        ],
        "Finance": [
          {
            "id": "b0ce25947a6fece11ef045a7fde0b0bb",
            "name": "Yrityslainasopimus",
            "category": "Finance",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Yrityslainasopimus on asiakirja, jossa m\u00e4\u00e4ritell\u00e4\u00e4n lainan ehdot yrityksen ja lainanantajan v\u00e4lill\u00e4 Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Se sis\u00e4lt\u00e4\u00e4 yksityiskohtaiset tiedot lainan m\u00e4\u00e4r\u00e4st\u00e4, takaisinmaksuaikataulusta ja koroista. Asiakirja varmistaa molempien osapuolten oikeudet ja velvollisuudet, edist\u00e4en selkeytt\u00e4 ja oikeudellista suojaa rahoitustransaktiossa."
          }
        ],
        "Loans": [
          {
            "id": "b0ce25947a6fece11ef045a7fde0b0bb",
            "name": "Yrityslainasopimus",
            "category": "Loans",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Yrityslainasopimus on asiakirja, jossa m\u00e4\u00e4ritell\u00e4\u00e4n lainan ehdot yrityksen ja lainanantajan v\u00e4lill\u00e4 Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Se sis\u00e4lt\u00e4\u00e4 yksityiskohtaiset tiedot lainan m\u00e4\u00e4r\u00e4st\u00e4, takaisinmaksuaikataulusta ja koroista. Asiakirja varmistaa molempien osapuolten oikeudet ja velvollisuudet, edist\u00e4en selkeytt\u00e4 ja oikeudellista suojaa rahoitustransaktiossa."
          }
        ],
        "Business": [
          {
            "id": "b0ce25947a6fece11ef045a7fde0b0bb",
            "name": "Yrityslainasopimus",
            "category": "Business",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Yrityslainasopimus on asiakirja, jossa m\u00e4\u00e4ritell\u00e4\u00e4n lainan ehdot yrityksen ja lainanantajan v\u00e4lill\u00e4 Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Se sis\u00e4lt\u00e4\u00e4 yksityiskohtaiset tiedot lainan m\u00e4\u00e4r\u00e4st\u00e4, takaisinmaksuaikataulusta ja koroista. Asiakirja varmistaa molempien osapuolten oikeudet ja velvollisuudet, edist\u00e4en selkeytt\u00e4 ja oikeudellista suojaa rahoitustransaktiossa."
          },
          {
            "id": "ba31f2af096b5ae0652333859d3917a6",
            "name": "Aiesopimus",
            "category": "Business",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Aiesopimus on oikeudellinen asiakirja, jossa yksi tai useampi osapuoli ilmaisee aikomuksensa tehd\u00e4 tai olla tekem\u00e4tt\u00e4 tiettyj\u00e4 toimia. Se on yleinen Suomen oikeusj\u00e4rjestelm\u00e4ss\u00e4 ja kirjoitetaan suomeksi. Aiesopimus ei v\u00e4ltt\u00e4m\u00e4tt\u00e4 sido osapuolia samalla tavalla kuin sopimus, mutta se voi olla oikeudellisesti merkitt\u00e4v\u00e4 esimerkiksi neuvotteluprosessissa tai luottamuksen rakentamisessa osapuolten v\u00e4lill\u00e4.\n\n"
          },
          {
            "id": "267f978dc79242f4704f99cd6d839a3d",
            "name": "Alihankintasopimus",
            "category": "Business",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Alihankintasopimus on oikeudellinen asiakirja, joka m\u00e4\u00e4rittelee p\u00e4\u00e4urakoitsijan ja alihankkijan v\u00e4liset oikeudet, velvollisuudet ja vastuut tietyss\u00e4 projektissa Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Sopimus sis\u00e4lt\u00e4\u00e4 yksityiskohtaiset ehdot liittyen ty\u00f6n laatuun, aikatauluihin, korvauksiin ja mahdollisiin sanktioihin sopimusrikkomuksen tapauksessa. Asiakirja varmistaa, ett\u00e4 kaikki projektin osapuolet ymm\u00e4rt\u00e4v\u00e4t velvoitteensa ja odotukset, edist\u00e4en n\u00e4in sujuvaa yhteisty\u00f6t\u00e4 ja projektin onnistunutta toteutusta.\n\n"
          },
          {
            "id": "4a5653c27b918222c6ad3d3e988efc4d",
            "name": "Liikehuoneiston alivuokrasopimus",
            "category": "Business",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Liikehuoneiston alivuokrasopimus on oikeudellinen asiakirja, joka s\u00e4\u00e4ntelee liikehuoneiston alivuokrausta Suomessa. Sopimuksessa m\u00e4\u00e4ritell\u00e4\u00e4n alivuokranantajan ja alivuokralaisen v\u00e4liset oikeudet ja velvollisuudet, kuten vuokra-aika, vuokran m\u00e4\u00e4r\u00e4 ja maksuehdot. Asiakirja sis\u00e4lt\u00e4\u00e4 my\u00f6s ehdot koskien liikehuoneiston k\u00e4ytt\u00f6\u00e4, huoltoa ja mahdollisia muutost\u00f6it\u00e4, turvaten molempien osapuolten intressit alivuokratilanteessa.\n\n"
          },
          {
            "id": "2fa82f29cd92a7b1d2dd032ae8ce4159",
            "name": "Apporttiomaisuuden luovutuskirja",
            "category": "Business",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Apporttiomaisuuden luovutuskirja on asiakirja, jota k\u00e4ytet\u00e4\u00e4n Suomessa apporttiomaisuuden, kuten kiinteist\u00f6n tai arvopapereiden, luovutukseen osakeyhti\u00f6n perustamisessa tai p\u00e4\u00e4oman korottamisessa. Asiakirjassa m\u00e4\u00e4ritell\u00e4\u00e4n luovutettavan omaisuuden arvo, luovutuksen ehdot sek\u00e4 osapuolten vastuut ja velvoitteet. \n\n"
          }
        ],
        "Lease": [
          {
            "id": "8e7642ca88077ac4289d342efe5a0407",
            "name": "Asuinhuoneiston alivuokrasopimus",
            "category": "Lease",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston alivuokrasopimus on juridinen asiakirja, jossa alivuokranantaja (usein vuokralainen ensisijaisesta vuokrasopimuksesta) sopii vuokraavansa osan tai koko asuinhuoneiston kolmannelle osapuolelle Suomessa. Sopimus m\u00e4\u00e4rittelee alivuokrauksen ehdot, kuten vuokra-ajan, vuokran suuruuden, ja muut k\u00e4ytt\u00f6ehdot. T\u00e4m\u00e4 asiakirja on t\u00e4rke\u00e4 sek\u00e4 alivuokranantajan ett\u00e4 alivuokralaisen oikeuksien ja velvollisuuksien selvent\u00e4miseksi ja turvaamiseksi suomalaisen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti.\n\n"
          }
        ],
        "Sublease": [
          {
            "id": "8e7642ca88077ac4289d342efe5a0407",
            "name": "Asuinhuoneiston alivuokrasopimus",
            "category": "Sublease",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston alivuokrasopimus on juridinen asiakirja, jossa alivuokranantaja (usein vuokralainen ensisijaisesta vuokrasopimuksesta) sopii vuokraavansa osan tai koko asuinhuoneiston kolmannelle osapuolelle Suomessa. Sopimus m\u00e4\u00e4rittelee alivuokrauksen ehdot, kuten vuokra-ajan, vuokran suuruuden, ja muut k\u00e4ytt\u00f6ehdot. T\u00e4m\u00e4 asiakirja on t\u00e4rke\u00e4 sek\u00e4 alivuokranantajan ett\u00e4 alivuokralaisen oikeuksien ja velvollisuuksien selvent\u00e4miseksi ja turvaamiseksi suomalaisen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti.\n\n"
          },
          {
            "id": "4a5653c27b918222c6ad3d3e988efc4d",
            "name": "Liikehuoneiston alivuokrasopimus",
            "category": "Sublease",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Liikehuoneiston alivuokrasopimus on oikeudellinen asiakirja, joka s\u00e4\u00e4ntelee liikehuoneiston alivuokrausta Suomessa. Sopimuksessa m\u00e4\u00e4ritell\u00e4\u00e4n alivuokranantajan ja alivuokralaisen v\u00e4liset oikeudet ja velvollisuudet, kuten vuokra-aika, vuokran m\u00e4\u00e4r\u00e4 ja maksuehdot. Asiakirja sis\u00e4lt\u00e4\u00e4 my\u00f6s ehdot koskien liikehuoneiston k\u00e4ytt\u00f6\u00e4, huoltoa ja mahdollisia muutost\u00f6it\u00e4, turvaten molempien osapuolten intressit alivuokratilanteessa.\n\n"
          }
        ],
        "Real Estate": [
          {
            "id": "8e7642ca88077ac4289d342efe5a0407",
            "name": "Asuinhuoneiston alivuokrasopimus",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston alivuokrasopimus on juridinen asiakirja, jossa alivuokranantaja (usein vuokralainen ensisijaisesta vuokrasopimuksesta) sopii vuokraavansa osan tai koko asuinhuoneiston kolmannelle osapuolelle Suomessa. Sopimus m\u00e4\u00e4rittelee alivuokrauksen ehdot, kuten vuokra-ajan, vuokran suuruuden, ja muut k\u00e4ytt\u00f6ehdot. T\u00e4m\u00e4 asiakirja on t\u00e4rke\u00e4 sek\u00e4 alivuokranantajan ett\u00e4 alivuokralaisen oikeuksien ja velvollisuuksien selvent\u00e4miseksi ja turvaamiseksi suomalaisen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti.\n\n"
          },
          {
            "id": "4a5653c27b918222c6ad3d3e988efc4d",
            "name": "Liikehuoneiston alivuokrasopimus",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Liikehuoneiston alivuokrasopimus on oikeudellinen asiakirja, joka s\u00e4\u00e4ntelee liikehuoneiston alivuokrausta Suomessa. Sopimuksessa m\u00e4\u00e4ritell\u00e4\u00e4n alivuokranantajan ja alivuokralaisen v\u00e4liset oikeudet ja velvollisuudet, kuten vuokra-aika, vuokran m\u00e4\u00e4r\u00e4 ja maksuehdot. Asiakirja sis\u00e4lt\u00e4\u00e4 my\u00f6s ehdot koskien liikehuoneiston k\u00e4ytt\u00f6\u00e4, huoltoa ja mahdollisia muutost\u00f6it\u00e4, turvaten molempien osapuolten intressit alivuokratilanteessa.\n\n"
          },
          {
            "id": "4bf0c62a6b3a11acb4cb8d04b53da2b3",
            "name": "Asuinhuoneiston vuokrasopimuksen irtisanominen, vuokralainen irtisanojana",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen irtisanominen, jossa vuokralainen toimii irtisanomisen aloittajana, on asiakirja, jonka avulla vuokralainen voi p\u00e4\u00e4tt\u00e4\u00e4 vuokrasopimuksen Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Asiakirjassa vuokralaisen tulee ilmoittaa irtisanomisaika ja perustella irtisanominen tarvittaessa, noudattaen asuinhuoneiston vuokrauksesta annetun lain s\u00e4\u00e4nn\u00f6ksi\u00e4. T\u00e4m\u00e4 malli on suunniteltu selke\u00e4ksi ja ytimekk\u00e4\u00e4ksi tavaksi toteuttaa vuokrasuhteen p\u00e4\u00e4tt\u00e4minen, ja se sis\u00e4lt\u00e4\u00e4 kaikki tarvittavat tiedot ja ohjeet asianmukaisen menettelyn varmistamiseksi.\n\n"
          },
          {
            "id": "c159b68b277d6212e688b4e3dfdfdf1e",
            "name": "Asuinhuoneiston vuokrasopimuksen irtisanominen, vuokranantaja irtisanojana",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen irtisanominen vuokranantajan toimesta on Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukainen menettelytapa, joka mahdollistaa vuokrasuhteen p\u00e4\u00e4tt\u00e4misen tietyin perustein. Irtisanomisilmoitus tulee toimittaa kirjallisesti ja siin\u00e4 tulee ilmoittaa irtisanomisen peruste sek\u00e4 irtisanomisaika, joka noudattaa laissa m\u00e4\u00e4riteltyj\u00e4 minimiaikoja. Vuokranantajan on noudatettava asuinhuoneiston vuokrauksesta annetun lain s\u00e4\u00e4nn\u00f6ksi\u00e4, mukaan lukien kohtuullisen irtisanomisajan kunnioittaminen ja perusteltu syy irtisanomiselle.\n\n"
          },
          {
            "id": "1699758526c194af878720e0d353787d",
            "name": "Asuinhuoneiston vuokrasopimuksen purkaminen, vuokralainen purkajana",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen purkaminen vuokralaisen toimesta Suomessa mahdollistaa vuokralaisen irtisanoa vuokrasopimuksen ennalta m\u00e4\u00e4r\u00e4tyin ehdoin. Vuokralaisen on noudatettava laissa s\u00e4\u00e4dettyj\u00e4 irtisanomisaikoja ja mahdollisesti vuokrasopimuksessa sovittuja erityisehtoja. Irtisanomisen syit\u00e4 voivat olla esimerkiksi muutto toiselle paikkakunnalle, taloudelliset syyt tai asunnon kuntoon liittyv\u00e4t seikat.\n\n"
          },
          {
            "id": "5ddb494582565db1d41dc45fa0e75840",
            "name": "Asuinhuoneiston vuokrasopimuksen purkaminen, vuokranantaja purkajana",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen purkaminen vuokranantajan toimesta Suomessa sis\u00e4lt\u00e4\u00e4 lailliset perusteet ja menettelytavat, joita vuokranantajan tulee noudattaa vuokrasuhteen p\u00e4\u00e4tt\u00e4miseksi. Vuokranantaja voi purkaa sopimuksen tietyiss\u00e4 olosuhteissa, kuten vuokran maksamatta j\u00e4tt\u00e4minen, huoneiston huomattava vahingoittaminen tai sopimuksen ehtojen vakava rikkominen. Purkamisilmoituksen on oltava kirjallinen, ja siin\u00e4 tulee selv\u00e4sti ilmoittaa purkamisen perusteet ja mahdollinen muutoksenhakukeino.\n\n"
          },
          {
            "id": "06d7d2243ed5abf8d398e5b6f600bf10",
            "name": "Asunto-osakeyhti\u00f6n hallituksen kokouskutsu",
            "category": "Real Estate",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asunto-osakeyhti\u00f6n hallituksen kokouskutsu on virallinen dokumentti, joka kutsuu yhti\u00f6n hallituksen j\u00e4senet koolle k\u00e4sittelem\u00e4\u00e4n yhti\u00f6n asioita. Kutsussa m\u00e4\u00e4ritell\u00e4\u00e4n kokouksen ajankohta, paikka ja esityslista, joka sis\u00e4lt\u00e4\u00e4 k\u00e4sitelt\u00e4v\u00e4t asiat. Dokumentti on laadittu suomeksi ja soveltuu k\u00e4ytett\u00e4v\u00e4ksi Suomen juridisessa kontekstissa, noudattaen maan asunto-osakeyhti\u00f6lains\u00e4\u00e4d\u00e4nt\u00f6\u00e4 ja hyv\u00e4\u00e4 hallintotapaa.\n\n"
          }
        ],
        "Commercial Leases": [
          {
            "id": "4a5653c27b918222c6ad3d3e988efc4d",
            "name": "Liikehuoneiston alivuokrasopimus",
            "category": "Commercial Leases",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Liikehuoneiston alivuokrasopimus on oikeudellinen asiakirja, joka s\u00e4\u00e4ntelee liikehuoneiston alivuokrausta Suomessa. Sopimuksessa m\u00e4\u00e4ritell\u00e4\u00e4n alivuokranantajan ja alivuokralaisen v\u00e4liset oikeudet ja velvollisuudet, kuten vuokra-aika, vuokran m\u00e4\u00e4r\u00e4 ja maksuehdot. Asiakirja sis\u00e4lt\u00e4\u00e4 my\u00f6s ehdot koskien liikehuoneiston k\u00e4ytt\u00f6\u00e4, huoltoa ja mahdollisia muutost\u00f6it\u00e4, turvaten molempien osapuolten intressit alivuokratilanteessa.\n\n"
          }
        ],
        "Business Formation": [
          {
            "id": "2fa82f29cd92a7b1d2dd032ae8ce4159",
            "name": "Apporttiomaisuuden luovutuskirja",
            "category": "Business Formation",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Apporttiomaisuuden luovutuskirja on asiakirja, jota k\u00e4ytet\u00e4\u00e4n Suomessa apporttiomaisuuden, kuten kiinteist\u00f6n tai arvopapereiden, luovutukseen osakeyhti\u00f6n perustamisessa tai p\u00e4\u00e4oman korottamisessa. Asiakirjassa m\u00e4\u00e4ritell\u00e4\u00e4n luovutettavan omaisuuden arvo, luovutuksen ehdot sek\u00e4 osapuolten vastuut ja velvoitteet. \n\n"
          }
        ],
        "Contracts": [
          {
            "id": "0032147dcb5c165631871241ca94fa2a",
            "name": "Asiakassopimus",
            "category": "Contracts",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asiakassopimus on juridinen asiakirja, joka m\u00e4\u00e4rittelee palveluntarjoajan ja asiakkaan v\u00e4liset oikeudet, velvollisuudet ja ehdot Suomessa. Se sis\u00e4lt\u00e4\u00e4 tietoa palvelun laajuudesta, maksuehdoista sek\u00e4 osapuolten vastuista ja oikeuksista erilaisissa tilanteissa. Asiakirja on laadittu suomen kielell\u00e4 ja on suunniteltu noudattamaan Suomen lains\u00e4\u00e4d\u00e4nt\u00f6\u00e4 ja kaupallisia k\u00e4yt\u00e4nt\u00f6j\u00e4.\n\n"
          }
        ],
        "Customer Agreements": [
          {
            "id": "0032147dcb5c165631871241ca94fa2a",
            "name": "Asiakassopimus",
            "category": "Customer Agreements",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asiakassopimus on juridinen asiakirja, joka m\u00e4\u00e4rittelee palveluntarjoajan ja asiakkaan v\u00e4liset oikeudet, velvollisuudet ja ehdot Suomessa. Se sis\u00e4lt\u00e4\u00e4 tietoa palvelun laajuudesta, maksuehdoista sek\u00e4 osapuolten vastuista ja oikeuksista erilaisissa tilanteissa. Asiakirja on laadittu suomen kielell\u00e4 ja on suunniteltu noudattamaan Suomen lains\u00e4\u00e4d\u00e4nt\u00f6\u00e4 ja kaupallisia k\u00e4yt\u00e4nt\u00f6j\u00e4.\n\n"
          }
        ],
        "Compliance": [
          {
            "id": "771919aecfae683b2d196dfa2e6853c5",
            "name": "Asiakkaan tuntemistiedot",
            "category": "Compliance",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asiakkaan tuntemistiedot -lomake on Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n vaatimus rahanpesun ja terrorismin rahoittamisen est\u00e4miseksi. Lomakkeessa pyydet\u00e4\u00e4n perustietoja asiakkaasta, kuten nimi, osoite, henkil\u00f6tunnus ja yhteystiedot, varmistaakseen asiakkaan henkil\u00f6llisyyden. Lomakkeen tarkoituksena on my\u00f6s arvioida ja dokumentoida asiakkaan taloudellista toimintaa ja riskiprofiilia rahanpesun torjuntatoimien tehostamiseksi.\n\n"
          }
        ],
        "Customer Due Diligence": [
          {
            "id": "771919aecfae683b2d196dfa2e6853c5",
            "name": "Asiakkaan tuntemistiedot",
            "category": "Customer Due Diligence",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asiakkaan tuntemistiedot -lomake on Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n vaatimus rahanpesun ja terrorismin rahoittamisen est\u00e4miseksi. Lomakkeessa pyydet\u00e4\u00e4n perustietoja asiakkaasta, kuten nimi, osoite, henkil\u00f6tunnus ja yhteystiedot, varmistaakseen asiakkaan henkil\u00f6llisyyden. Lomakkeen tarkoituksena on my\u00f6s arvioida ja dokumentoida asiakkaan taloudellista toimintaa ja riskiprofiilia rahanpesun torjuntatoimien tehostamiseksi.\n\n"
          }
        ],
        "Privacy and Data Protection": [
          {
            "id": "771919aecfae683b2d196dfa2e6853c5",
            "name": "Asiakkaan tuntemistiedot",
            "category": "Privacy and Data Protection",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asiakkaan tuntemistiedot -lomake on Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n vaatimus rahanpesun ja terrorismin rahoittamisen est\u00e4miseksi. Lomakkeessa pyydet\u00e4\u00e4n perustietoja asiakkaasta, kuten nimi, osoite, henkil\u00f6tunnus ja yhteystiedot, varmistaakseen asiakkaan henkil\u00f6llisyyden. Lomakkeen tarkoituksena on my\u00f6s arvioida ja dokumentoida asiakkaan taloudellista toimintaa ja riskiprofiilia rahanpesun torjuntatoimien tehostamiseksi.\n\n"
          }
        ],
        "Lease Agreements": [
          {
            "id": "4bf0c62a6b3a11acb4cb8d04b53da2b3",
            "name": "Asuinhuoneiston vuokrasopimuksen irtisanominen, vuokralainen irtisanojana",
            "category": "Lease Agreements",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen irtisanominen, jossa vuokralainen toimii irtisanomisen aloittajana, on asiakirja, jonka avulla vuokralainen voi p\u00e4\u00e4tt\u00e4\u00e4 vuokrasopimuksen Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Asiakirjassa vuokralaisen tulee ilmoittaa irtisanomisaika ja perustella irtisanominen tarvittaessa, noudattaen asuinhuoneiston vuokrauksesta annetun lain s\u00e4\u00e4nn\u00f6ksi\u00e4. T\u00e4m\u00e4 malli on suunniteltu selke\u00e4ksi ja ytimekk\u00e4\u00e4ksi tavaksi toteuttaa vuokrasuhteen p\u00e4\u00e4tt\u00e4minen, ja se sis\u00e4lt\u00e4\u00e4 kaikki tarvittavat tiedot ja ohjeet asianmukaisen menettelyn varmistamiseksi.\n\n"
          },
          {
            "id": "c159b68b277d6212e688b4e3dfdfdf1e",
            "name": "Asuinhuoneiston vuokrasopimuksen irtisanominen, vuokranantaja irtisanojana",
            "category": "Lease Agreements",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen irtisanominen vuokranantajan toimesta on Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukainen menettelytapa, joka mahdollistaa vuokrasuhteen p\u00e4\u00e4tt\u00e4misen tietyin perustein. Irtisanomisilmoitus tulee toimittaa kirjallisesti ja siin\u00e4 tulee ilmoittaa irtisanomisen peruste sek\u00e4 irtisanomisaika, joka noudattaa laissa m\u00e4\u00e4riteltyj\u00e4 minimiaikoja. Vuokranantajan on noudatettava asuinhuoneiston vuokrauksesta annetun lain s\u00e4\u00e4nn\u00f6ksi\u00e4, mukaan lukien kohtuullisen irtisanomisajan kunnioittaminen ja perusteltu syy irtisanomiselle.\n\n"
          },
          {
            "id": "1699758526c194af878720e0d353787d",
            "name": "Asuinhuoneiston vuokrasopimuksen purkaminen, vuokralainen purkajana",
            "category": "Lease Agreements",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen purkaminen vuokralaisen toimesta Suomessa mahdollistaa vuokralaisen irtisanoa vuokrasopimuksen ennalta m\u00e4\u00e4r\u00e4tyin ehdoin. Vuokralaisen on noudatettava laissa s\u00e4\u00e4dettyj\u00e4 irtisanomisaikoja ja mahdollisesti vuokrasopimuksessa sovittuja erityisehtoja. Irtisanomisen syit\u00e4 voivat olla esimerkiksi muutto toiselle paikkakunnalle, taloudelliset syyt tai asunnon kuntoon liittyv\u00e4t seikat.\n\n"
          },
          {
            "id": "5ddb494582565db1d41dc45fa0e75840",
            "name": "Asuinhuoneiston vuokrasopimuksen purkaminen, vuokranantaja purkajana",
            "category": "Lease Agreements",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen purkaminen vuokranantajan toimesta Suomessa sis\u00e4lt\u00e4\u00e4 lailliset perusteet ja menettelytavat, joita vuokranantajan tulee noudattaa vuokrasuhteen p\u00e4\u00e4tt\u00e4miseksi. Vuokranantaja voi purkaa sopimuksen tietyiss\u00e4 olosuhteissa, kuten vuokran maksamatta j\u00e4tt\u00e4minen, huoneiston huomattava vahingoittaminen tai sopimuksen ehtojen vakava rikkominen. Purkamisilmoituksen on oltava kirjallinen, ja siin\u00e4 tulee selv\u00e4sti ilmoittaa purkamisen perusteet ja mahdollinen muutoksenhakukeino.\n\n"
          }
        ],
        "Termination": [
          {
            "id": "4bf0c62a6b3a11acb4cb8d04b53da2b3",
            "name": "Asuinhuoneiston vuokrasopimuksen irtisanominen, vuokralainen irtisanojana",
            "category": "Termination",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen irtisanominen, jossa vuokralainen toimii irtisanomisen aloittajana, on asiakirja, jonka avulla vuokralainen voi p\u00e4\u00e4tt\u00e4\u00e4 vuokrasopimuksen Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukaisesti. Asiakirjassa vuokralaisen tulee ilmoittaa irtisanomisaika ja perustella irtisanominen tarvittaessa, noudattaen asuinhuoneiston vuokrauksesta annetun lain s\u00e4\u00e4nn\u00f6ksi\u00e4. T\u00e4m\u00e4 malli on suunniteltu selke\u00e4ksi ja ytimekk\u00e4\u00e4ksi tavaksi toteuttaa vuokrasuhteen p\u00e4\u00e4tt\u00e4minen, ja se sis\u00e4lt\u00e4\u00e4 kaikki tarvittavat tiedot ja ohjeet asianmukaisen menettelyn varmistamiseksi.\n\n"
          },
          {
            "id": "c159b68b277d6212e688b4e3dfdfdf1e",
            "name": "Asuinhuoneiston vuokrasopimuksen irtisanominen, vuokranantaja irtisanojana",
            "category": "Termination",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen irtisanominen vuokranantajan toimesta on Suomen lains\u00e4\u00e4d\u00e4nn\u00f6n mukainen menettelytapa, joka mahdollistaa vuokrasuhteen p\u00e4\u00e4tt\u00e4misen tietyin perustein. Irtisanomisilmoitus tulee toimittaa kirjallisesti ja siin\u00e4 tulee ilmoittaa irtisanomisen peruste sek\u00e4 irtisanomisaika, joka noudattaa laissa m\u00e4\u00e4riteltyj\u00e4 minimiaikoja. Vuokranantajan on noudatettava asuinhuoneiston vuokrauksesta annetun lain s\u00e4\u00e4nn\u00f6ksi\u00e4, mukaan lukien kohtuullisen irtisanomisajan kunnioittaminen ja perusteltu syy irtisanomiselle.\n\n"
          },
          {
            "id": "1699758526c194af878720e0d353787d",
            "name": "Asuinhuoneiston vuokrasopimuksen purkaminen, vuokralainen purkajana",
            "category": "Termination",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen purkaminen vuokralaisen toimesta Suomessa mahdollistaa vuokralaisen irtisanoa vuokrasopimuksen ennalta m\u00e4\u00e4r\u00e4tyin ehdoin. Vuokralaisen on noudatettava laissa s\u00e4\u00e4dettyj\u00e4 irtisanomisaikoja ja mahdollisesti vuokrasopimuksessa sovittuja erityisehtoja. Irtisanomisen syit\u00e4 voivat olla esimerkiksi muutto toiselle paikkakunnalle, taloudelliset syyt tai asunnon kuntoon liittyv\u00e4t seikat.\n\n"
          },
          {
            "id": "5ddb494582565db1d41dc45fa0e75840",
            "name": "Asuinhuoneiston vuokrasopimuksen purkaminen, vuokranantaja purkajana",
            "category": "Termination",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asuinhuoneiston vuokrasopimuksen purkaminen vuokranantajan toimesta Suomessa sis\u00e4lt\u00e4\u00e4 lailliset perusteet ja menettelytavat, joita vuokranantajan tulee noudattaa vuokrasuhteen p\u00e4\u00e4tt\u00e4miseksi. Vuokranantaja voi purkaa sopimuksen tietyiss\u00e4 olosuhteissa, kuten vuokran maksamatta j\u00e4tt\u00e4minen, huoneiston huomattava vahingoittaminen tai sopimuksen ehtojen vakava rikkominen. Purkamisilmoituksen on oltava kirjallinen, ja siin\u00e4 tulee selv\u00e4sti ilmoittaa purkamisen perusteet ja mahdollinen muutoksenhakukeino.\n\n"
          }
        ],
        "Meetings": [
          {
            "id": "06d7d2243ed5abf8d398e5b6f600bf10",
            "name": "Asunto-osakeyhti\u00f6n hallituksen kokouskutsu",
            "category": "Meetings",
            "language": "fi",
            "jurisdiction": "FI",
            "description": "Asunto-osakeyhti\u00f6n hallituksen kokouskutsu on virallinen dokumentti, joka kutsuu yhti\u00f6n hallituksen j\u00e4senet koolle k\u00e4sittelem\u00e4\u00e4n yhti\u00f6n asioita. Kutsussa m\u00e4\u00e4ritell\u00e4\u00e4n kokouksen ajankohta, paikka ja esityslista, joka sis\u00e4lt\u00e4\u00e4 k\u00e4sitelt\u00e4v\u00e4t asiat. Dokumentti on laadittu suomeksi ja soveltuu k\u00e4ytett\u00e4v\u00e4ksi Suomen juridisessa kontekstissa, noudattaen maan asunto-osakeyhti\u00f6lains\u00e4\u00e4d\u00e4nt\u00f6\u00e4 ja hyv\u00e4\u00e4 hallintotapaa.\n\n"
          }
        ]
      },
      filtered: {},
      searchFilter: "",
      selectedTemplate: null,
      "selectedCategory": {
        "Employment": true,
        "Confidentiality": true,
        "Finance": true,
        "Loans": true,
        "Business": true,
        "Lease": true,
        "Sublease": true,
        "Real Estate": true,
        "Commercial Leases": true,
        "Business Formation": true,
        "Contracts": true,
        "Customer Agreements": true,
        "Compliance": true,
        "Customer Due Diligence": true,
        "Privacy and Data Protection": true,
        "Lease Agreements": true,
        "Termination": true,
        "Meetings": true
      },
      "selectedLanguage": {
        "fi": true
      },
      "selectedJurisdiction": {
        "FI": true
      },
      useLoading: false
    }
  },
  watch: {
    searchFilter: function () {
      this.filterTemplates();
    }
  },
  methods: {
    filterTemplates() {
      let filtering = {};
      for (const category in this.templates) {
        const templates = this.templates[category];
        filtering[category] = templates.filter(
            template =>
                (
                    template.name.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
                    template.description.toLowerCase().includes(this.searchFilter.toLowerCase())
                ) &&
                this.selectedCategory[template.category] &&
                this.selectedLanguage[template.language] &&
                this.selectedJurisdiction[template.jurisdiction]
        );
      }
      this.filtered = filtering;
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
    },
    useTemplate(templateId) {
      this.useLoading = true;

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.useTemplate(templateId)
          .then((data) => {
            if (data.status === "OK") {
              this.useLoading = false;

              this.$router.push({ name: "Document", params: { document: data.document.id } });
            }
          })
          .catch((error) => {
            this.useLoading = false;

            checkErrors(error);
          });
    }
  },
  mounted() {
    this.selectedCategory = {
      "Employment": true,
      "Confidentiality": true,
      "Finance": true,
      "Loans": true,
      "Business": true,
      "Lease": true,
      "Sublease": true,
      "Real Estate": true,
      "Commercial Leases": true,
      "Business Formation": true,
      "Contracts": true,
      "Customer Agreements": true,
      "Compliance": true,
      "Customer Due Diligence": true,
      "Privacy and Data Protection": true,
      "Lease Agreements": true,
      "Termination": true,
      "Meetings": true
    };

    this.selectedLanguage = {
      "fi": true
    };

    this.selectedJurisdiction = {
      "FI": true
    };

    this.filterTemplates();
  }
}
</script>

<style lang="scss" scoped>
.AppTemplates_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppTemplates_left {
    display: flex;
    flex-direction: column;
  }

  .AppTemplates_right {
    flex: 1;
    display: flex;
    flex-direction: row;

    .AppTemplates_content {
      flex: 1;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .AppTemplates_template {
        border-radius: 0.5rem;
        background-color: #ecf4fd;
        padding: 1.5rem;

        .AppTemplates_template-header {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .AppTemplates_template-header-title {
            color: #2d2d2d;
            font-weight: 600;
            font-size: 1.5rem;
            height: 1.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 0.5rem;
            margin-bottom: 0.5rem;

            .AppTemplates_template-header-title-icon {
              display: inline-block;
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 0.75rem;
              margin-bottom: 0.25rem;
            }

            .AppTemplates_template-header-title-button {
              margin-left: auto;
            }
          }

          .AppTemplates_template-header-description {
            color: #626d78;
            font-weight: 400;
            font-size: 0.875rem;
          }
        }
      }
    }

    .AppTemplates_sidebar {
      background-color: white;
      width: 19.25rem;
      border-right: solid 0.03125rem #cbcfd2;

      .AppTemplates_search {
        padding: 1.25rem;
      }
    }
  }
}

.AppDocument_pdf {
  flex: 1;
  display: flex;
  flex-direction: row;
  pointer-events: unset!important;
  aspect-ratio: 650 / 919;
}
</style>
