import { createRouter, createWebHistory } from "vue-router";
import { base64Encode } from "@/utilities";
import store from "@/store";

// Views: SignUp
import SignUp from "@/views/SignUp";
import SignInRedirect from "@/views/SignInRedirect";
import SignUpCreateOrganization from "@/views/SignUpCreateOrganization";
import SignUpOrganizationName from "@/views/SignUpOrganizationName";
import SignUpInvites from "@/views/SignUpInvites";
import SignUpInviteUsers from "@/views/SignUpInviteUsers";
import SignUpChoosePlan from "@/views/SignUpChoosePlan";
import SignUpConfirmPlan from "@/views/SignUpConfirmPlan";

// Views: App
import AppNotFound from "@/views/AppNotFound";
import AppDashboard from "@/views/AppDashboard";
import AppDocuments from "@/views/AppDocuments";
import AppDocument from "@/views/AppDocument";
import AppProfile from "@/views/AppProfile";
import AppSettings from "@/views/AppSettings";
import AppDocumentsFiltered from "@/views/AppDocumentsFiltered";
import AppSearch from "@/views/AppSearch";
import AppStripe from "@/views/AppStripe";
import AppSigningStart from "@/views/AppSigningStart";
import AppSigningResult from "@/views/AppSigningResult";
import AppSigningComplete from "@/views/AppSigningComplete";
import AppIntegrationRequest from "@/views/AppIntegrationRequest";
import AppTemplates from "@/views/AppTemplates";
import SignUpTemplate from "@/views/SignUpTemplate";

const routes = [
    {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
        meta: { public: true, onlyUnauthorized: true }
    },
    {
        path: "/signin",
        name: "SignIn",
        component: SignUp,
        meta: { public: true, onlyUnauthorized: true }
    },
    {
        path: "/session-expired",
        name: "SessionExpired",
        component: SignUp,
        meta: { public: true, onlyUnauthorized: true }
    },
    {
        path: "/google-unauthorized",
        name: "GoogleUnauthorized",
        component: SignUp,
        meta: { public: true }
    },
    {
        path: '/redirect/:type?',
        name: 'Redirect',
        component: SignInRedirect,
        meta: { public: true }
    },
    {
        path: "/",
        name: "Dashboard",
        component: AppDashboard
    },
    {
        path: "/setup/create-organization",
        name: "SignUpCreateOrganization",
        component: SignUpCreateOrganization,
        meta: { setup: true }
    },
    {
        path: "/setup/organization-name",
        name: "SignUpOrganizationName",
        component: SignUpOrganizationName,
        meta: { setup: true }
    },
    {
        path: "/setup/invites",
        name: "SignUpInvites",
        component: SignUpInvites,
        meta: { setup: true }
    },
    {
        path: "/setup/invite-users",
        name: "SignUpInviteUsers",
        component: SignUpInviteUsers,
        meta: { setup: true }
    },
    {
        path: "/setup/choose-plan",
        name: "SignUpChoosePlan",
        component: SignUpChoosePlan,
        meta: { setup: true }
    },
    {
        path: "/setup/confirm-plan",
        name: "SignUpConfirmPlan",
        component: SignUpConfirmPlan,
        meta: { setup: true }
    },
    {
        path: "/documents/:parent?",
        name: "Documents",
        component: AppDocuments
    },
    {
        path: '/document/:document?',
        name: 'Document',
        component: AppDocument
    },
    {
        path: "/profile/:page?",
        name: "Profile",
        component: AppProfile,
        meta: { back: true }
    },
    {
        path: "/page-not-found",
        name: "NotFound",
        component: AppNotFound,
        meta: { public: true }
    },
    {
        path: "/settings/:page?/:subPage?",
        name: "Settings",
        component: AppSettings,
        meta: { back: true }
    },
    {
        path: "/filtered/:filter",
        name: "Filtered",
        component: AppDocumentsFiltered
    },
    {
        path: "/search/:text",
        name: "Search",
        component: AppSearch
    },
    {
        path: "/stripe/:message?",
        name: "Stripe",
        component: AppStripe
    },
    {
        path: "/public/sign/:signee",
        name: "SigningStart",
        component: AppSigningStart,
        meta: { public: true }
    },
    {
        path: "/public/signing/:result/:document",
        name: "SigningResult",
        component: AppSigningResult,
        meta: { public: true }
    },
    {
        path: '/public/signed/:signee',
        name: 'SigningComplete',
        component: AppSigningComplete,
        meta: { public: true }
    },
    {
        path: '/integration/:integration',
        name: 'IntegrationRequest',
        component: AppIntegrationRequest
    },
    {
        path: '/templates',
        name: 'Templates',
        component: AppTemplates
    },
    {
        path: '/signup-template/:template',
        name: 'SignUpTemplate',
        component: SignUpTemplate,
        meta: { public: true }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Page not found Navigation Guard
router.beforeEach((to, from, next) => {
    let found = false;
    for (let i = 0; i < routes.length; i++) {
        if (routes[i].name === to.name) {
            found = true;

            break;
        }
    }

    if (!found) {
        return { name: "NotFound" };
    }

    next();
});

// Session Navigation Guard
router.beforeEach((to) => {
    if (!to?.meta?.public) {
        const sessionId = store.getters["session/get"];
        let path = "/signin";

        if (sessionId == null) {
            path = "/signin";
        } else if (sessionId === false) {
            path = "/session-expired";
            store.dispatch("session/clear");
        } else {
            if (!to?.meta?.setup) {
                if (store.getters["session/status"] === "SETUP_REQUIRED") {
                    return store.getters["setup/route"];
                }
            } else {
                if (store.getters["session/complete"]) {
                    return { path: "/" };
                }
            }

            return true;
        }

        return {
            path: path,
            query: { redirect: base64Encode(to.fullPath) }
        }
    } else {
        const sessionId = store.getters["session/get"];

        if (sessionId != null && sessionId !== false && to?.meta?.onlyUnauthorized) {
            return { path: "/" };
        }
    }
});

// Back Navigation Guard
router.beforeEach((to, from) => {
    if (to?.meta?.back && !from?.meta?.back && !to?.query?.back) {
        return {
            path: to.fullPath,
            query: { back: base64Encode(from.fullPath === "/stripe/success" ? "/" : from.fullPath) }
        }
    }

    if (from?.query?.back && to?.meta?.back && !to?.query?.back) {
        return {
            path: to.fullPath,
            query: { back: from?.query?.back }
        }
    }
});

//TODO: Check for unsaved work before leaving a page?
// router.beforeRouteLeave((to, from) => {});

export default router;
