<template>
  <div class="SettingsPricing_container">
    <div class="SettingsPricing_header">{{ $t("components.SettingsPricing.header") }}</div>
    <div class="SettingsPricing_content">
      <div v-for="price in organization.plan.signaturePrices" :key="price.name" class="method">
        <!--
        TODO: Toggle signature methods
        <div class="toggle"></div>
        -->
        <div class="name">{{ getSigningMethodName(price.name) }}</div>
        <div class="price">
          <AppPrice :amount="price.value" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Organization from "@/core/organization";
import { useToast } from "vue-toastification";
import AppPrice from "@/components/AppPrice";

export default {
  name: "SettingsPricing",
  components: { AppPrice },
  props: {
    organization: {
      type: Organization,
      required: true
    }
  },
  setup() {
    const toast = useToast();

    return {
      toast
    };
  },
  data() {
    const prices = [
      {"name":"Estonian ID card","type":"id-signature"},
      {"name":"Estonian Mobile-ID","type":"mid-signature"},
      {"name":"Lithuanian Mobile-ID","type":"lt-mid-signature"},
      {"name":"SPID","type":"spid-qes-signature"},
      {"name":"Smart-ID","type":"smart-id-signature"},
      {"name":"Lithuanian ID card","type":"lt-id-signature"},
      {"name":"Latvian ID card","type":"lv-id-signature"},
      {"name":"Finnish ID card","type":"fi-id-signature"},
      {"name":"CertEurope USB token","type":"certeurope-usb-token-signature"},
      {"name":"certSIGN USB token","type":"certsign-usb-token-signature"},
      {"name":"Austrian Handy-Signatur","type":"at-handy-signatur-signature"},
      {"name":"Latvian eParaksts Mobile","type":"lv-eparaksts-mobile-signature"},
      {"name":"E-mail/SMS","type":"otp-email-non-qualified"},
      {"name":"Finnish Trust Network / Luottamusverkosto","type":"fi-ftn-advanced-signature"},
      {"name":"Evrotrust","type":"evrotrust-signature"},
      {"name":"MojeId","type":"cz-mojeid-advanced-signature"},
      {"name":"Google","type":"google-signature"},
      {"name":"Swedish BankId","type":"swedish-bankid-signature"},
      {"name":"D-Trust sign-me","type":"d-trust-sign-me-qes-signature"},
      {"name":"Chave Movel","type":"chave-movel-qes-signature"},
      {"name":"Mit ID","type":"mit-id-simple-signature"},
      {"name":"Norwegian Bank ID","type":"norwegian-bankid-adv-signature"},
      {"name":"Verifai","type":"verifai-simple-signature"},
      {"name":"IDIN","type":"idin-simple-signature"},
      {"name":"Freja eID","type":"freja-simple-signature"},
      {"name":"Simply Sign","type":"simply-sign-qes-signature"},
      {"name":"Yes.com","type":"yes-com-qes-signature"},
      {"name":"Uanataca","type":"uanataca-qes-signature"}
    ];

    return {
      prices
    };
  },
  methods: {
    getSigningMethodName(type) {
      for (let method of this.prices) {
        if (method.type === type) {
          return method.name;
        }
      }

      return "-";
    },
  }
}
</script>

<style lang="scss" scoped>
.SettingsPricing_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;

  .SettingsPricing_header {
    display: flex;
    flex-direction: row;
    height: 2.875rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
  }

  .SettingsPricing_content {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, 24.0rem);
    grid-template-rows: repeat(auto-fill, 3.5rem);
    grid-row-gap: 0;
    grid-column-gap: 3.25rem;

    .method {
      height: 3.5rem;
      width: 24.0rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: solid 0.03125rem #c3c8cc;

      .toggle {
        width: 0;
        flex: 0;
      }

      .name {
        width: 16.0rem;
        flex: 1;
        color: #626d78;
        font-weight: 400;
        font-size: 0.875rem;
      }

      .price {
        color: #2d2d2d;
        font-weight: 600;
        font-size: 1.125rem;
        margin-left: auto;
      }
    }
  }
}
</style>
