<template>
  <AppModal :active="active" :header="true" :title="$t('modals.DocumentMove.title')" :overlayClose="true" @close="close()">
    <div class="ModalDocumentMove_container">
      <div class="ModalDocumentMove_text">{{ $t("modals.DocumentMove.text") }}</div>
      <AppLoading v-show="loaderActive" :active="loaderActive" />
      <div v-show="!loaderActive" class="mover">
        <div class="title">{{ rootName === null ? $t("modals.DocumentMove.documents") : rootName }}</div>
        <div v-for="document in documents" :key="document.id">
          <div class="item" @click="getDocuments(document.id)"><div class="icon"><AppIcon type="folder" color="#626d78" /></div><div class="text">{{ document?.name }}</div></div>
        </div>
        <div class="empty" v-show="documents !== null && documents.length === 0">{{ $t("modals.DocumentMove.empty") }}</div>
      </div>
      <div class="controls">
        <AppButton v-show="this.root != null || this.previousParent != null" :disabled="loaderActive" size="small" type="outline" iconType="arrow-left" primary-color="#626d78" @click="goBack">{{ $t("modals.DocumentMove.buttons.back") }}</AppButton>
        <AppButton style="margin-left: auto" :disabled="loaderActive" iconType="checkmark" size="small" @click="moveHere">{{ $t("modals.DocumentMove.buttons.move") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentMove } from "@/modals/index";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";
import AppButton from "@/components/AppButton";
import events from "@/events";
import AppIcon from "@/components/AppIcon";
import AppLoading from "@/components/AppLoading";

export default {
  name: "ModalDocumentMove",
  components: {
    AppLoading,
    AppIcon,
    AppButton,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object
    }
  },
  setup() {
    return {
      events,
      modalCloseDocumentMove
    };
  },
  watch: {
    document(value) {
      if (value) {
        this.root = null;
        this.rootName = "";
        this.previousParent = null;
        this.documents = null;
        this.getDocuments();
      }
    }
  },
  data() {
    return {
      root: null,
      rootName: "",
      previousParent: null,
      documents: null,
      loaderActive: true
    };
  },
  methods: {
    close() {
      modalCloseDocumentMove();
    },
    goBack() {
      this.getDocuments(this.root);
    },
    moveHere() {
      this.loaderActive = true;
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.move(this.document.id, this.previousParent)
          .then(() => {
            this.loaderActive = false;

            //this.toast.success(this.$t("toast.document.move.success"));

            this.events.$emit("document:delete", { document: this.document });

            modalCloseDocumentMove();
          })
          .catch((error) => {
            this.loaderActive = false;
            modalCloseDocumentMove();

            checkErrors(error);

            //this.toast.error(this.$t("toast.document.move.failed"));
          });
    },
    getDocuments(parent = null) {
      this.loaderActive = true;
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.list(parent)
          .then((data) => {
            this.root = data.root;
            this.rootName = data.rootName;
            this.previousParent = parent;
            this.documents = data.documents;
            this.documents = this.documents.filter((document) => document.type === "folder" && document.id !== this.document.id);
            this.loaderActive = false;
          })
          .catch((error) => {
            this.loaderActive = false;
            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentMove_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentMove_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    white-space: pre-line;
  }

  .mover {
    .title {
      font-weight: 600;
      font-size: 0.875rem;
      color: #2d2d2d;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .empty {
      font-size: 0.875rem;
      color: #626d78;
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.75rem;
      border-top: solid 0.03125rem #c3c8cc;
      cursor: pointer;

      .icon {
        height: 1.25rem;
        width: 1.25rem;
      }

      .text {
        margin-left: 1.0rem;
        padding-top: 0.125rem;
        font-size: 0.875rem;
        color: #626d78;
      }
    }
  }

  .controls {
    margin-top: 0.5rem;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}
</style>
