export default class UserInvite {
    constructor(data, organization) {
        this._organization = organization;

        this.id = data?.id;
        this.organizationName = data?.company;
        this.email = data?.account;
        this.created = data?.created;
        this.platform = data?.platform;
    }

    get fullName() {
        return "";
    }
}
