<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.25 4H17.75C19.483 4 20.8992 5.35645 20.9949 7.06558L21 7.25V16.75C21 18.483 19.6435 19.8992 17.9344 19.9949L17.75 20H6.25C4.51697 20 3.10075 18.6435 3.00514 16.9344L3 16.75V7.25C3 5.51697 4.35645 4.10075 6.06558 4.00514L6.25 4H17.75H6.25ZM17.75 5.5H6.25C5.33183 5.5 4.57881 6.20711 4.5058 7.10647L4.5 7.25V16.75C4.5 17.6682 5.20711 18.4212 6.10647 18.4942L6.25 18.5H17.75C18.6682 18.5 19.4212 17.7929 19.4942 16.8935L19.5 16.75V7.25C19.5 6.33183 18.7929 5.57881 17.8935 5.5058L17.75 5.5ZM10.0528 9.58541C10.1626 9.36586 10.4121 9.26237 10.6396 9.32882L10.7236 9.3618L15.1056 11.5528C15.2023 11.6012 15.2808 11.6796 15.3292 11.7764C15.439 11.9959 15.372 12.2576 15.1824 12.3998L15.1056 12.4472L10.7236 14.6382C10.6542 14.6729 10.5776 14.691 10.5 14.691C10.2545 14.691 10.0504 14.5141 10.0081 14.2809L10 14.191V9.80902C10 9.73139 10.0181 9.65484 10.0528 9.58541Z" />
  </svg>
</template>

<script>
export default {
  name: "IconVideo",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>