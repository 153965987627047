<template>
  <AppModal :active="active" :header="true" title="" :overlayClose="true" @close="modalCloseDocumentError()">
    <div class="ModalDocumentError_container">
      <img src="@/assets/images/splash-something-went-wrong.png" alt="" />
      <div class="ModalDocumentError_title">{{ $t("modals.DocumentError.title." + error) }}</div>
      <div class="ModalDocumentError_text">{{ $t("modals.DocumentError.text." + error) }}</div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentError } from "@/modals/index";

export default {
  name: "ModalDocumentError",
  components: {
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: "generic"
    }
  },
  setup() {
    return {
      modalCloseDocumentError
    };
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentError_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentError_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalDocumentError_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    white-space: pre-line;
  }
}
</style>
