<template>
  <div class="AppInputCheckbox_container" @click="editable && $emit('update:modelValue', !modelValue)">
    <IconCheckbox :active="modelValue" />
  </div>
</template>

<script>
import IconCheckbox from "@/assets/icons/IconCheckbox";

export default {
  name: "AppInputCheckbox",
  components: {
    IconCheckbox
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    "update:modelValue"
  ]
}
</script>

<style lang="scss" scoped>
.AppInputCheckbox_container {
  height: 1.0rem;
  width: 1.0rem;
  cursor: pointer;
}
</style>
