import ConnectorService from "@/services/connector";

export default class AuthenticationService extends ConnectorService {
    constructor(url, id) {
        super(url + "authentication/", id);
    }

    async login(type = AuthenticationType.API, credential) {
        return await this.post("login", {
            type: type,
            credential: credential
        });
    }

    async authorize(type = AuthenticationType.CLIENT_GOOGLE, code, scope) {
        return await this.postp("authorize", {
            type: type,
            code: code,
            scope: scope
        });
    }

    async setup(companies, language) {
        return await this.postp("setup", {
            companies: companies,
            language: language
        });
    }

    async accounts() {
        return await this.getp("accounts");
    }

    async invite(email) {
        return await this.postp("invite", {
            email: email
        });
    }

    async uninvite(id, type = "account") {
        return await this.getp("uninvite/" + id + "/" + type);
    }

    async getApiRequests() {
        return await this.getp("apikey-requests");
    }

    async createApiKey(integration) {
        return await this.getp("apikey" + (integration ? "/" + integration : "none"));
    }

    async declineApiRequest(id) {
        return await this.getp("decline-request/" + id);
    }

    async startWizard() {
        return await this.getp("wizard/start");
    }

    async skipWizard() {
        return await this.getp("wizard/skip");
    }

    async getSteps() {
        return await this.getp("wizard-steps");
    }

    async completeStep(step) {
        return await this.getp("wizard-step/" + step);
    }

    async transferOwner(userId) {
        return await this.getp("transfer-owner/" + userId);
    }

    async resetWizard() {
        return await this.getp("wizard-reset");
    }

    async getSessions(full = false) {
        return await this.getp("sessions/" + (full ? "full" : "minimal"));
    }

    async getInvites(type, id) {
        return await this.getp("invites/" + type + "/" + id);
    }

    async acceptInvite(id) {
        return await this.getp("invite-accept/" + id);
    }

    async declineInvite(id) {
        return await this.getp("invite-decline/" + id);
    }

    async setLanguage(language) {
        return await this.getp("language/" + language);
    }

    async setDefaultCompany(id) {
        return await this.getp("set-default/" + id);
    }

    async invalidateSession(sessionId) {
        return await this.getp("invalidate/" + sessionId);
    }

    async createCompany(name) {
        return await this.postp("create-company", {
            name: name
        });
    }
}

export const AuthenticationType = Object.freeze({
    API: "API",
    SESSION: "SESSION",
    CLIENT_GOOGLE: "CLIENT_GOOGLE",
    CLIENT_MICROSOFT: "CLIENT_MICROSOFT"
});
