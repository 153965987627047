<template>
  <AppModal :active="active" :header="true" :title="$t('modals.UserRemove.title')" @close="modalCloseUserRemove()">
    <div class="ModalUserRemove_container">
      <div class="ModalUserRemove_text">
        <span class="user-container">
          <span class="avatar"><AppAvatar :name="user?.fullName" size="small" /></span>
          <span class="info-container">
            <span class="name">{{ user?.fullName }}</span>
            <span class="email">{{ user?.email }}</span>
          </span>
        </span>
        <div>{{ $t("modals.UserRemove.text") }}</div>
      </div>
      <div class="ModalUserRemove_button">
        <AppButton :loading="removeLoading" @click="remove()" :icon="false" size="medium" type="outline" primaryColor="#f14a4a">{{ $t("modals.UserRemove.buttons.remove") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppAvatar from "@/components/AppAvatar";
import AppButton from "@/components/AppButton";
import { modalCloseUserRemove } from "@/modals/index";
import { useToast } from "vue-toastification";
import events from "@/events";
import Organization from "@/core/organization";
import User from "@/core/user";
import AuthenticationService from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "ModalUserRemove",
  components: {
    AppModal,
    AppButton,
    AppAvatar
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Organization
    },
    user: {
      type: User
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseUserRemove,
      toast,
      events
    };
  },
  data() {
    return {
      removeLoading: false
    };
  },
  methods: {
    remove() {
      this.removeLoading = true;

      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.uninvite(this.user.id)
          .then((data) => {
            if (data.status === "OK") {
              this.events.$emit("user:remove", { user: this.user });

              this.toast.success(this.$t("modals.UserRemove.toasts.remove.success"));

              modalCloseUserRemove();
            } else {
              this.toast.error(this.$t("modals.UserRemove.toasts.remove.failed"));
            }

            this.removeLoading = false;
          })
          .catch((error) => {
            checkErrors(error);

            this.removeLoading = false;

            this.toast.error(this.$t("modals.UserRemove.toasts.remove.failed"));
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalUserRemove_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalUserRemove_text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;

    .user-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.0rem;

      .avatar {
        height: 2.0rem;
        width: 2.0rem;
        margin-right: 1.0rem;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        height: 2.0rem;

        .name {
          font-size: 0.875rem;
          font-weight: 600;
          color: #2d2d2d;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }

        .email {
          font-size: 0.75rem;
          font-weight: 400;
          color: #626d78;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }
      }
    }
  }

  .ModalUserRemove_button {
    margin-left: auto;
  }
}
</style>
