<template>
  <AppHeader />
  <div class="AppDocuments_container">
    <div class="AppDocuments_left">
      <AppNavigation />
    </div>
    <div class="AppDocuments_right">
      <div class="AppDocuments_content">
        <div class="AppDocuments_document-list-title">{{ $t('views.AppDocuments.title') }}</div>
        <DocumentList :documents="documents" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppNavigation from "@/components/AppNavigation";
import DocumentList from "@/components/DocumentList";
import { checkErrors } from "@/utilities/errors";
import DocumentsService from "@/services/documents";
import events from "@/events";
import DocumentEvents from "@/events/document";

export default {
  name: "AppDocuments",
  components: {
    DocumentList,
    AppNavigation,
    AppHeader
  },
  watch: {
    $route() {
      this.$nextTick(this.getDocuments);
    }
  },
  setup() {
    return {
      events
    };
  },
  mounted() {
    this.getDocuments();

    DocumentEvents.add(this, "documents");
    DocumentEvents.delete(this, "documents");
    DocumentEvents.rename(this, "documents");
  },
  data() {
    return {
      documents: null,
      gettingDocuments: false,
      root: null
    }
  },
  methods: {
    getDocuments() {
      if (this.gettingDocuments) {
        return;
      }

      this.gettingDocuments = true;

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.list(this.$route.params.parent)
          .then((data) => {
            this.root = data?.root;
            this.documents = data?.documents;
            this.documents.sort((a, b) => {
              if (a.type === "folder" && b.type !== "folder") {
                return -1;
              }

              if (a.type !== "folder" && b.type === "folder") {
                return 1;
              }

              return 0;
            });

            this.gettingDocuments = false;
          })
          .catch((error) => {
            this.gettingDocuments = false;

            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.AppDocuments_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppDocuments_left {
    display: flex;
    flex-direction: column;
  }

  .AppDocuments_right {
    flex: 1;
    display: flex;
    flex-direction: row;

    .AppDocuments_content {
      flex: 1;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .AppDocuments_document-list-title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.0rem;
        color: #163b61;
        margin-top: 1.0rem;
      }
    }
  }
}
</style>
