<template>
  <div class="signup">
    <AppStepNavigation :steps="steps" :currentStep="currentStep" />
    <AppButton @click="goBack()" iconType="arrow-left" size="small" type="link-like" primary-color="#626d78">{{ $t("views.SignUpChoosePlan.buttons.goBack") }}</AppButton>
    <div class="content">
      <div class="header">{{ $t("views.SignUpChoosePlan.header") }}</div>
      <div class="content">
        <span>{{ $t("views.SignUpChoosePlan.text") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AppStepNavigation from "@/components/AppStepNavigation";
import AppButton from "@/components/AppButton";
import router from "@/router";

export default {
  name: "SignUpChoosePlan",
  components: {
    AppButton,
    AppStepNavigation
  },
  mounted() {
    this.$store.dispatch("setup/skipStep"); // this
    router.push(this.$store.getters["setup/route"]);
  },
  data() {
    return {
      currentStep: 2,
      steps: [
        {
          name: this.$t("views.SignUpInviteUsers.steps.organizationName")
        },
        {
          name: this.$t("views.SignUpInviteUsers.steps.inviteUsers")
        },
        {
          name: this.$t("views.SignUpInviteUsers.steps.finishSetup")
        }
      ]
    };
  },
  methods: {
    goBack() {
      this.$store.dispatch("setup/previousStep"); // this
      this.$router.push(this.$store.getters["setup/route"]);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.5rem;
  width: 78.5rem;
  gap: 2.5rem;
}
.content {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;

  .header {
    line-height: 2.0rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #163b61;
  }

  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .buttons {
      margin-top: 1.5rem;
    }
  }
}
</style>
