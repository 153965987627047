<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0001 1.99634C16.05 1.99634 19.3568 5.19108 19.4959 9.24539L19.5001 9.49634V13.5933L20.8801 16.7493C20.9492 16.9072 20.9848 17.0777 20.9848 17.2501C20.9848 17.9405 20.4252 18.5001 19.7348 18.5001L15.0001 18.5016C15.0001 20.1585 13.657 21.5016 12.0001 21.5016C10.4024 21.5016 9.09645 20.2527 9.0052 18.6779L8.99966 18.4993L4.27498 18.5001C4.10364 18.5001 3.93413 18.4649 3.77697 18.3966C3.14377 18.1216 2.85342 17.3853 3.12846 16.7521L4.50011 13.5943V9.49623C4.50071 5.34144 7.8522 1.99634 12.0001 1.99634ZM13.4997 18.4993L10.5001 18.5016C10.5001 19.33 11.1717 20.0016 12.0001 20.0016C12.7798 20.0016 13.4206 19.4067 13.4932 18.6461L13.4997 18.4993ZM12.0001 3.49634C8.67995 3.49634 6.00059 6.17059 6.00011 9.49634V13.906L4.65613 17.0001H19.3526L18.0001 13.9069L18.0002 9.50919L17.9965 9.28399C17.8854 6.05052 15.2417 3.49634 12.0001 3.49634Z" />
  </svg>
</template>

<script>
export default {
  name: "IconAlert",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>