<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6471 6.30373L14.7198 6.21961C14.9861 5.95338 15.4027 5.92921 15.6963 6.1471L15.7804 6.21972L20.777 11.2174C21.0431 11.4835 21.0674 11.8999 20.8499 12.1935L20.7774 12.2776L15.7807 17.2811C15.488 17.5742 15.0132 17.5745 14.7201 17.2818C14.4536 17.0157 14.4291 16.5991 14.6468 16.3053L14.7194 16.2211L18.4302 12.504L3.75024 12.5049C3.37055 12.5049 3.05675 12.2228 3.00709 11.8567L3.00024 11.7549C3.00024 11.3752 3.2824 11.0615 3.64847 11.0118L3.75024 11.0049L18.4432 11.004L14.7197 7.28028C14.4534 7.01398 14.4293 6.59732 14.6471 6.30373L14.7198 6.21961L14.6471 6.30373Z" />
  </svg>
</template>

<script>
export default {
  name: "IconArrowRight",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
