<template>
  <div class="SignInButton_button" @click="$emit('click', platform, action)">
    <div class="SignInButton_icon">
      <img v-if="platform === 'google'" src="@/assets/icons/google.svg" alt="Google" />
      <img v-if="platform === 'microsoft'" src="@/assets/icons/microsoft.svg" alt="Microsoft" />
    </div>
    <div class="SignInButton_text">
      {{ $t("components.SignInButton.text." + platform + "." + action) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInButton",
  props: {
    platform: {
      type: String,
      required: true,
      default: "google",
      validator: function(value) {
        return ["google", "microsoft"].includes(value);
      }
    },
    action: {
      type: String,
      required: true,
      default: "signIn",
      validator: function(value) {
        return ["signIn", "signUp"].includes(value);
      }
    }
  },
  emits: ["click"]
}
</script>

<style lang="scss" scoped>
.SignInButton_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.5rem;
  width: 21.25rem;
  border: 1px solid #c3c7cc;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 1.0rem;

  .SignInButton_icon {
    height: 1.75rem;
    margin-right: 1.0rem;
  }

  .SignInButton_text {
    font-weight: 500;
    color: #163b61;
  }
}
</style>
