<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.55153 14.6469L6.46742 14.7195C6.20118 14.9858 6.17702 15.4025 6.3949 15.6961L6.46753 15.7802L11.4652 20.7768C11.7313 21.0429 12.1477 21.0672 12.4413 20.8496L12.5254 20.7771L17.5289 15.7805C17.822 15.4878 17.8223 15.0129 17.5296 14.7198C17.2635 14.4534 16.8469 14.4289 16.5531 14.6465L16.4689 14.7191L12.7518 18.43L12.7527 3.75C12.7527 3.3703 12.4706 3.05651 12.1045 3.00685L12.0027 3C11.6231 3 11.3093 3.28215 11.2596 3.64823L11.2527 3.75L11.2518 18.443L7.52808 14.7194C7.26178 14.4532 6.84512 14.429 6.55153 14.6469L6.46742 14.7195L6.55153 14.6469Z" />
  </svg>
</template>

<script>
export default {
  name: "IconArrowDown",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>