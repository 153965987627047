<template>
  <div class="NavigationItem_item" :class="(active ? ' NavigationItem_active_' + type : '') + ' NavigationItem_item_' + type" @click="$emit('click')">
    <div class="NavigationItem_icon">
      <AppIcon :type="icon" :color="iconColor" />
    </div>
    <div class="NavigationItem_text" :class="'NavigationItem_' + type">
      <slot />
    </div>
    <div v-if="type === 'dark'" class="NavigationItem_chevron">
      <AppIcon type="chevron-right" color="#626d78" />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "NavigationItem",
  components: {
    AppIcon
  },
  props: {
    type: {
      type: String,
      default: "dark"
    },
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: "#626d78"
    }
  },
  emits: ["click"]
}
</script>

<style lang="scss" scoped>
.NavigationItem_item {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  gap: 0.75rem;
  align-items: center;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .NavigationItem_icon {
    height: 1.25rem;
    width: 1.25rem;
  }

  .NavigationItem_text {
    flex: 1;
    line-height: 1.0rem;
    font-size: 0.875rem;
    padding-top: 0.1875rem;
  }

  .NavigationItem_chevron {
    height: 1.0rem;
    width: 1.0rem;
  }

  .NavigationItem_grey {
    color: #626d78;
    font-weight: 400;
  }

  .NavigationItem_dark {
    color: #2c2c2c;
    font-weight: 500;
  }

  .NavigationItem_blue {
    color: #626d78;
    font-weight: 400;
  }
}

.NavigationItem_item_blue {
  margin: 0 0.5rem;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

.NavigationItem_item:hover {
  background-color: white;
  border-radius: 0.5rem;
}

.NavigationItem_active_blue.NavigationItem_item:hover {
  background-color: #ecf5fe;
  border-radius: 0.5rem;
}

.NavigationItem_active_dark {
  background-color: white;
  border-radius: 0.5rem;
}

.NavigationItem_active_grey {
  background-color: white;
  border-radius: 0.5rem;
}

.NavigationItem_active_blue {
  background-color: #ecf5fe;
  border-radius: 0.5rem;
}

.NavigationItem_active_blue>.NavigationItem_text {
  font-weight: 500;
}
</style>
