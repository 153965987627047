<template>
  <AppLoading v-show="loading" />
  <div v-show="!loading" class="signup">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views." + routeName + ".header") }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views." + routeName + ".text") }}</span>
      </div>
      <div class="buttons">
        <!--
        TODO: Google new flow
        <SignInButton platform="google" :action="isSignUp ? 'signUp' : 'signIn'" />
        -->
        <div id="test-google"><GoogleLogin :callback="signInGoogle"></GoogleLogin></div>
        <div id="test-microsoft"><SignInButton @click="signInMicrosoft" platform="microsoft" :action="isSignUp ? 'signUp' : 'signIn'" /></div>
      </div>
      <div class="language">
        <SignInLanguageSwitcher />
      </div>
    </div>
    <div class="right">
      <img v-if="routeName === 'SignUp'" class="splash" src="@/assets/images/splash-signup.png" alt="Sign Up" />
      <img v-if="routeName === 'SignIn'" class="splash" src="@/assets/images/splash-signin.png" alt="Sign In" />
      <img v-if="routeName === 'SessionExpired'" class="splash" src="@/assets/images/splash-session-expired.png" alt="Session Expired" />
      <img v-if="routeName === 'GoogleUnauthorized'" class="splash" src="@/assets/images/splash-session-expired.png" alt="Session Expired" />
    </div>
  </div>
</template>

<script>
import { GoogleLogin } from "vue3-google-login";
import SignInButton from "@/components/SignInButton";
import SignInLanguageSwitcher from "@/components/SignInLanguageSwitcher";
import { signInCallback as callbackGoogle } from "@/utilities/google";
import { signInCallback as callbackMicrosoft } from "@/utilities/microsoft";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";
import router from "@/router";
import AppLoading from "@/components/AppLoading";

export default {
  name: "SignUpTemplate",
  components: {
    GoogleLogin,
    SignInLanguageSwitcher,
    SignInButton,
    AppLoading
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    routeName() {
      return 'SignUp';
    },
    isSignUp() {
      return this.$route?.name === "SignUpTemplate" || this.$route == null;
    },
    template() {
      return this.$store.getters['setup/template'];
    }
  },
  mounted() {
    this.$store.dispatch("setup/setTemplate", this.$route.params.template);

    let session = this.$store.getters["session/get"];

    if (session != null && session !== false) {
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      let templateId = this.$store.getters["setup/template"];
      this.$store.dispatch("setup/setTemplate", "");

      Documents.useTemplate(templateId)
          .then((data) => {
            if (data.status === "OK") {
              this.$router.push({ name: "Document", params: { document: data.document.id } });
            }
          })
          .catch((error) => {
            checkErrors(error);

            router.push(this.$store.getters["setup/route"]);
          });
    } else {
      this.loading = false;
    }
  },
  methods: {
    signInGoogle(data) {
      callbackGoogle(this, data);
    },
    signInMicrosoft(data) {
      callbackMicrosoft(this, data);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 83.5rem;

  .left {
    width: 25.0rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      margin-top: 4.0rem;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
