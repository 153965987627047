import Account from "@/core/account";
import Plan from "@/core/plan";
import User from "@/core/user";
import UserInvite from "@/core/userInvite";
import Role from "@/core/role";

export default class Organization {
    constructor(organization) {
        this.organization = organization;
    }

    get id() {
        return this.organization?.id;
    }

    get name() {
        return this.organization?.name;
    }

    get platform() {
        return this.organization?.platform;
    }

    get isDefault() {
        return Account.defaultOrganization === this.id;
    }

    get isSelected() {
        return Account.selectedOrganization.id === this.id;
    }

    get isMine() {
        return Account.id === this.organization?.ownerAccount;
    }

    get isPersonal() {
        return this.name === "Personal";
    }

    get canLeave() {
        return !this.isMine && !this.isPersonal;
    }

    get plan() {
        return this._plan;
    }

    setPlan(data) {
        Object.assign(this, { _plan: new Plan(data, this) });
    }

    get users() {
        return this._users;
    }

    setUsers(data) {
        let result = [];

        for (let i = 0; i < data?.accounts.length; i++) {
            result.push(new User(data?.accounts[i], this));
        }

        this._users = result;
    }

    get userInvites() {
        return this._userInvites;
    }

    setUserInvites(data) {
        let result = [];

        for (let i = 0; i < data?.companyInvites.length; i++) {
            result.push(new UserInvite(data?.companyInvites[i], this));
        }

        this._userInvites = result;
    }

    get roles() {
        return this._roles;
    }

    setRoles(data) {
        let roles = [];

        for (let role of data) {
            roles.push(new Role(role));
        }

        this._roles = roles;

        if (this._users) {
            for (let user of this._users) {
                user.setRoles();
            }
        }
    }
}
