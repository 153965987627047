<template>
  <div class="container">
    <div class="info">{{ attachment.name }}</div>
    <div v-if="Account.permissions?.documents?.edit?.documents && document.state < Document.State.Signing" class="button" @click="remove()">
      <AppIcon type="minus" />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import { useToast } from "vue-toastification";
import Account from "@/core/account";
import Document from "@/core/document";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";
import events from "@/events";

export default {
  name: "DocumentNavigationAttachment",
  components: {
    AppIcon
  },
  props: {
    document: {
      type: Object,
      default: null
    },
    attachment: {
      type: Object,
      default: null
    }
  },
  setup() {
    const toast = useToast();

    return {
      Account,
      Document,
      toast,
      events
    };
  },
  methods: {
    remove() {
      let Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);
      Documents.delete(this.attachment.id, true)
          .then(() => {
            this.toast.success(this.$t("components.DocumentNavigationAttachment.toasts.remove.success"));

            this.events.$emit("document:delete", { document: this.attachment });
          })
          .catch((error) => {
            this.toast.error(this.$t("components.DocumentNavigationAttachment.toasts.remove.failed"));

            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  align-items: center;

  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding-top: 0.25rem;
    font-weight: 600;
    color: #2d2d2d;
    font-size: 0.875rem;
    line-height: 1.0rem;
  }

  .button {
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
  }
}
</style>
