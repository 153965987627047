<template>
  <!-- Dashboard guides -->
  <AppGuideModal v-if="!guides.Dashboard.complete && guides.Dashboard['start']">
    <img src="@/assets/images/splash-document-list-empty.png" alt="Dashboard guide" class="splash" />
    <div class="title">{{ $t("guides.Dashboard.start.title") }}</div>
    <div class="text">{{ $t("guides.Dashboard.start.text") }}</div>
    <div class="buttons">
      <AppButton @click="skip('Dashboard')" size="medium" primaryColor="#2d2d2d" secondaryColor="#ffffff" :icon="false" style="border: 0.03125rem solid #c3c8cc">{{ $t("guides.Dashboard.start.buttons.skip") }}</AppButton>
      <AppButton @click="next('Dashboard')" size="medium" :icon="false">{{ $t("guides.Dashboard.start.buttons.next") }}</AppButton>
    </div>
  </AppGuideModal>
  <AppGuideStep v-if="!guides.Dashboard.complete && guides.Dashboard['select-organization']" @next="next('Dashboard')" @skip="skip('Dashboard')" name="select-organization" position="right">
    <template #title>{{ $t("guides.Dashboard.selectOrganization.title") }}</template>
    <template #text>{{ $t("guides.Dashboard.selectOrganization.text") }}</template>
  </AppGuideStep>
  <AppGuideStep v-if="!guides.Dashboard.complete && guides.Dashboard['create-button']" @next="next('Dashboard')" @skip="skip('Dashboard')" name="create-button" position="right">
    <template #title>{{ $t("guides.Dashboard.createButton.title") }}</template>
    <template #text>{{ $t("guides.Dashboard.createButton.text") }}<br /><ul style="margin: 0; padding-left: 1.5rem"><li>{{ $t("guides.Dashboard.createButton.list.item1") }}</li><li>{{ $t("guides.Dashboard.createButton.list.item2") }}</li><li>{{ $t("guides.Dashboard.createButton.list.item3") }}</li></ul></template>
  </AppGuideStep>
  <AppGuideStep v-if="!guides.Dashboard.complete && guides.Dashboard['create-folder']" @next="next('Dashboard')" @skip="skip('Dashboard')" name="create-folder" position="right">
    <template #title>{{ $t("guides.Dashboard.createFolder.title") }}</template>
    <template #text>{{ $t("guides.Dashboard.createFolder.text") }}</template>
  </AppGuideStep>
  <AppGuideStep v-if="!guides.Dashboard.complete && guides.Dashboard['create-document']" @next="next('Dashboard')" @skip="skip('Dashboard')" name="create-document" position="right">
    <template #title>{{ $t("guides.Dashboard.createDocument.title") }}</template>
    <template #text>{{ $t("guides.Dashboard.createDocument.text") }}</template>
  </AppGuideStep>
  <AppGuideStep v-if="!guides.Dashboard.complete && guides.Dashboard['create-template']" @next="next('Dashboard')" @skip="skip('Dashboard')" name="create-template" position="right">
    <template #title>{{ $t("guides.Dashboard.createTemplate.title") }}</template>
    <template #text>{{ $t("guides.Dashboard.createTemplate.text") }}</template>
  </AppGuideStep>
  <AppGuideStep v-if="!guides.Dashboard.complete && guides.Dashboard['create-upload']" @next="next('Dashboard')" @skip="skip('Dashboard')" name="create-upload" position="right">
    <template #title>{{ $t("guides.Dashboard.createUpload.title") }}</template>
    <template #text>{{ $t("guides.Dashboard.createUpload.text") }}</template>
  </AppGuideStep>
  <AppGuideModal v-if="!guides.Dashboard.complete && guides.Dashboard['end']">
    <div class="title">{{ $t("guides.Dashboard.end.title") }}</div>
    <div class="text">{{ $t("guides.Dashboard.end.text") }}</div>
    <div class="buttons">
      <AppButton @click="skip('Dashboard')" size="medium" :icon="false" style="margin-left: auto">{{ $t("guides.Dashboard.end.buttons.done") }}</AppButton>
    </div>
  </AppGuideModal>
</template>

<script>
import AppGuideStep from "@/components/AppGuideStep";
import { getGuideObject } from "@/directives/guide";
import GuidesService from "@/services/guides";
import { checkErrors } from "@/utilities/errors";
import AppGuideModal from "@/components/AppGuideModal";
import AppButton from "@/components/AppButton";

export default {
  name: "AppGuides",
  components: {
    AppButton,
    AppGuideModal,
    AppGuideStep
  },
  watch: {
    $route: function (route) {
      setTimeout(() => {
        this.fetch(route);
      }, 1000);
    }
  },
  data() {
    return {
      fetched: false,
      guides: {
        Dashboard: {
          complete: true,
          handler: async (step) => {
            if (step === "create-folder") {
              getGuideObject("create-button").binding.instance.createOpen = true;
            }
            if (step === "end") {
              getGuideObject("create-button").binding.instance.createOpen = false;
            }
          },
          "start": false,
          "select-organization": false,
          "create-button": false,
          "create-folder": false,
          "create-document": false,
          "create-template": false,
          "create-upload": false,
          "end": false
        }
      }
    };
  },
  mounted() {
    setTimeout(() => {
      this.fetch(this.$route);
    }, 1000);
  },
  unmounted() {
    this.fetched = false;
  },
  methods: {
    fetch(route) {
      if (!this.fetched) {
        if (this.$store.getters["account/id"]) {
          const Guides = new GuidesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

          Guides.getStates()
              .then((data) => {
                const states = data?.guides?.states;
                if (states) {
                  const guides = Object.keys(states);

                  for (const guide of guides) {
                    this.setGuide(guide, states[guide]);
                  }

                  this.checkGuides(route.name);
                }
              })
              .catch((error) => {
                checkErrors(error);
              });
        }
      } else {
        this.checkGuides(route.name);
      }
    },
    setGuide(guide, complete) {
      this.$store.dispatch("guides/set", { guide, complete });
      this.guides[guide].complete = complete;
    },
    async checkGuides(guide) {
      if (this.guides[guide]?.complete) {
        return;
      }

      const steps = this.$store.getters["guides/steps"][guide];
      if (Object.keys(this.guides).includes(guide) && !steps[guide]?.complete) {
        const step = this.$store.getters["guides/step"](guide);

        if (step !== false) {
          await this.guides[guide].handler(step);
          this.guides[guide][step] = true;
        }

        const previousSteps = this.$store.getters["guides/previousSteps"](guide);

        for (const previousStep of previousSteps) {
          this.guides[guide][previousStep] = false;
        }
      } else {
        if (this.guides[guide]) {
          for (const key of Object.keys(this.guides[guide])) {
            this.guides[guide][key] = false;
          }
          this.guides[guide].complete = true;
        }
      }
    },
    skip(guide) {
      this.guides[guide].complete = true;
      this.$store.dispatch("guides/skip", guide)
          .then(() => {
            this.checkGuides(guide);
          });
      const Guides = new GuidesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Guides.setState(guide, true)
          .catch((error) => {
            checkErrors(error);
          });
    },
    next(guide) {
      this.$store.dispatch("guides/next", guide);
      this.checkGuides(guide);
    }
  }
}
</script>

<style lang="scss" scoped>
.splash {
  width: 22.5rem;
  height: 12.75rem;
}

.title {
  font-size: 1.125rem;
  color: #2d2d2d;
  font-weight: 600;
}

.text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #626d78;
  margin-top: 0.5rem;
  white-space: pre-line;
}

.buttons {
  margin-top: 1.0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
