<template>
  <AppModal :active="active" :header="true" icon="building" :title="$t('modals.OrganizationCreate.title')" @close="close()">
    <div class="ModalOrganizationCreate_container">
      <div class="ModalOrganizationCreate_text">{{ $t("modals.OrganizationCreate.text") }}</div>
      <div class="ModalOrganizationCreate_input">
        <AppInputText v-model="name" size="small" stretch :placeholder="$t('modals.OrganizationCreate.inputs.name.placeholder')" />
      </div>
      <div class="ModalOrganizationCreate_button">
        <AppButton :loading="loading" size="medium" iconType="plus-circle" @click="this.continue()">{{ $t("modals.OrganizationCreate.buttons.create") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseOrganizationCreate, modalOpenOrganizationCreateComplete } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppInputText from "@/components/AppInputText";
import AppButton from "@/components/AppButton";
import { checkErrors } from "@/utilities/errors";
import AuthenticationService, { AuthenticationType } from "@/services/authentication";
import Session from "@/core/session";
import { signIn } from "@/utilities";
import Account from "@/core/account";

export default {
  name: "ModalOrganizationCreate",
  components: {
    AppInputText,
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      loading: false
    };
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseOrganizationCreate,
      toast
    };
  },
  methods: {
    continue() {
      if (this.name?.length <= 0) {
        this.toast.error(this.$t("modals.OrganizationCreate.toasts.nameEmpty"));

        return;
      }

      this.loading = true;

      let oldOrganizationIds = [];
      Account.organizations.forEach((organization) => {
        oldOrganizationIds.push(organization.id);
      });

      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.createCompany(this.name)
          .then((data) => {
            if (data.status === "OK") {
              const sessionId = Session.id;
              if (sessionId !== null && sessionId !== false) {
                Authentication.login(AuthenticationType.SESSION, sessionId)
                    .then((data) => {
                      signIn(this, data, false);

                      let newOrganizationIds = [];
                      Account.organizations.forEach((organization) => {
                        if (!oldOrganizationIds.includes(organization.id)) {
                          newOrganizationIds.push(organization.id);
                        }
                      });

                      this.loading = false;

                      modalOpenOrganizationCreateComplete(this.name, newOrganizationIds[0]);

                      this.close();
                    })
                    .catch((error) => {
                      checkErrors(error);
                    });
              }
            }
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.success(this.$t("modals.OrganizationCreate.toasts.create.failed"));
          });
    },
    close() {
      this.name = "";

      modalCloseOrganizationCreate();
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalOrganizationCreate_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalOrganizationCreate_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .ModalOrganizationCreate_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;

    .ModalOrganizationCreate_icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }

    .ModalOrganizationCreate_name {
      padding-top: 0.25rem;
      line-height: 1.0rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #2d2d2d;
    }
  }

  .ModalOrganizationCreate_button {
    margin-top: 1.5rem;
    margin-left: auto;
  }
}
</style>
