import router from "@/router";

const SESSION_VALID_TIME = 1000 * 60 * 60 * process.env.VUE_APP_SESSION_VALID_TIME;

const initialState = {
    id: null,
    updated: null,
    status: "",
    selectedOrganization: ""
};

function isValid(state) {
    const diff = new Date() - new Date(state.updated);

    return diff < SESSION_VALID_TIME;
}

const session = {
    namespaced: true,
    state: initialState,
    getters: {
        get(state) {
            return state.id ? (isValid(state) ? state.id : false) : null;
        },
        status(state) {
            return state.status;
        },
        selectedOrganization(state) {
            return state.selectedOrganization;
        },
        lastBalance(state) {
            return parseFloat(state.lastBalance);
        },
        integrationRequest(state) {
            return state.integrationRequest;
        }
    },
    mutations: {
        set(state, id) {
            state.id = id;
            state.updated = new Date();
        },
        setStatus(state, status) {
            state.status = status;
        },
        setSelectedOrganization(state, organizationId) {
            state.selectedOrganization = organizationId;
        },
        setLastBalance(context, value) {
            context.lastBalance = value;
        },
        setIntegrationRequest(context, value) {
            context.integrationRequest = value;
            if (value) {
                router.push("/integration/" + value.integration.toLowerCase());
            }
        }
    },
    actions: {
        set(context, value) {
            context.commit("set", value);
        },
        update(context) {
            context.commit("set", context.state.id);
        },
        updateStatus(context, value) {
            context.commit("setStatus", value);
        },
        clear(context) {
            context.commit("set", null);
        },
        setSelectedOrganization(context, organizationId) {
            context.commit("setSelectedOrganization", organizationId);
        },
        setLastBalance(context, value) {
            context.commit("setLastBalance", value);
        },
        setIntegrationRequest(context, value) {
            context.commit("setIntegrationRequest", value);
        }
    }
};

export default session;
