<template>
  <div class="NavigationOrganization_container">
    <div class="NavigationOrganization_organization" @click="toggle()" :class="open ? ' NavigationOrganization_open' : ''">
      <div class="NavigationOrganization_left">
        <div class="NavigationOrganization_name">{{ selectedOrganization.name === 'Personal' ? personalFullName : selectedOrganization.name }}</div>
        <div v-if="Account.permissions?.organization?.view?.balance" class="NavigationOrganization_balance">{{ $t("components.NavigationOrganization.balance") }} <AppPrice :amount="balance" /></div>
      </div>
      <div class="NavigationOrganization_right">
        <AppIcon :type="open ? 'chevron-up' : 'chevron-down'" />
      </div>
    </div>
    <div v-show="open" class="NavigationOrganization_dropdown">
      <NavigationOrganizationItem
          @click="selectOrganization(organization)"
          v-for="organization in organizations()"
          :key="organization.id"
          :name="organization.name === 'Personal' ? personalFullName : organization.name"
          :description="organization.name === 'Personal' ? $t('components.NavigationOrganization.description.personal') : $t('components.NavigationOrganization.description.organization')"
          :icon="isSelected(organization)"
      />

      <NavigationOrganizationItem @click="create()" :name="$t('components.NavigationOrganization.buttons.create')" />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import NavigationOrganizationItem from "@/components/NavigationOrganizationItem";
import AppPrice from "@/components/AppPrice";
import Account from "@/core/account";
import events from "@/events";
import PlanService from "@/services/plan";
import { checkErrors } from "@/utilities/errors";
import { modalOpenOrganizationCreate } from "@/modals";

export default {
  name: "NavigationOrganization",
  components: {
    AppPrice,
    NavigationOrganizationItem,
    AppIcon
  },
  setup() {
    return {
      Account,
      events
    };
  },
  data() {
    return {
      open: false,
      balance: this.$store.getters["session/lastBalance"]
    }
  },
  mounted() {
    let instance = this;

    events.$on("plan:update", (plan) => {
      instance.updateBalance(plan);
    });

    this.getPlan();
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    organizations() {
      return this.$store.getters["account/organizations"];
    },
    isSelected(organization) {
      return this.$store.getters["session/selectedOrganization"] === organization.id;
    },
    selectOrganization(organization) {
      this.$store.dispatch("session/setSelectedOrganization", organization.id);
      this.open = false;
      this.$router.go();
    },
    updateBalance(plan) {
      this.balance = plan.balance;
      this.$store.dispatch("session/setLastBalance", plan.balance);
    },
    getPlan() {
      const Plan = new PlanService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Plan.getPlan()
          .then((data) => {
            Account.selectedOrganization.setPlan(data);
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    create() {
      this.open = false;

      modalOpenOrganizationCreate();
    }
  },
  computed: {
    selectedOrganization() {
      return Account.selectedOrganization;
    },
    personalFullName() {
      return this.$store.getters["account/fullName"];
    }
  }
}
</script>

<style lang="scss" scoped>
.NavigationOrganization_container {
  position: relative;
  width: calc(19.25rem - 2.0rem);

  .NavigationOrganization_organization {
    background-color: white;
    border-radius: 0.5rem;
    border: 0.0625rem solid #c3c7cc;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: calc(19.25rem - 2.0rem);

    .NavigationOrganization_left {
      flex: 1;
      margin-left: 1.0rem;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;

      .NavigationOrganization_name {
        font-weight: 600;
        font-size: 1.125rem;
        color: #2c2c2c;
      }

      .NavigationOrganization_balance {
        font-weight: 400;
        font-size: 0.875rem;
        color: #626d78;
      }
    }

    .NavigationOrganization_right {
      height: 1.0rem;
      width: 1.0rem;
      margin-right: 1.0rem;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .NavigationOrganization_open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .NavigationOrganization_dropdown {
    display: flex;
    flex-direction: column;
    gap: 1.0rem;
    position: absolute;
    z-index: 1;
    background-color: white;
    border: 0.0625rem solid #c3c7cc;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 1.0rem;
    width: calc(19.25rem - 4.0rem);
  }
}
</style>
