<template>
  <a @click="(type !== 'empty' && !loading && !disabled) && $emit('click')" class="AppButton_button" :style="style" :class="'AppButton_' + type + ' AppButton_' + size + (iconOnly ? ' AppButton_no-text' : '') + (stretch ? ' AppButton_stretch' : '') + (disabled ? ' AppButton_disabled' : '')">
    <div class="AppButton_overlay"></div>
    <template v-if="loading && !disabled">
      <div class="AppButton_loader">
        <div style="flex: 1; height: 1.0rem">
          <svg style="height: 1.0rem" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="1.0rem"
               viewBox="0 0 24 30" xml:space="preserve">
            <rect x="0" y="10" width="4" height="10" :fill="primaryColor" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
            </rect>
              <rect x="8" y="10" width="4" height="10" :fill="primaryColor"  opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            </rect>
              <rect x="16" y="10" width="4" height="10" :fill="primaryColor"  opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      </div>
    </template>
    <div v-if="icon" class="AppButton_icon">
      <AppIcon :type="iconType" :color="disabled ? '#c4c4c4' : ((!loading && !disabled) ? primaryColor : 'transparent')" />
    </div>
    <div v-if="!iconOnly" class="AppButton_text" @click="(type === 'empty' && !loading && !disabled) && $emit('click')" :style="loading ? 'color: transparent' : ''">
      <slot />
    </div>
  </a>
</template>

<script>
import AppIcon from "@/components/AppIcon.vue";

export default {
  name: "AppButton",
  components: {
    AppIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "filled",
      validator: (value) => {
        return [
          "filled",
          "outline",
          "empty",
          "link-like"
        ].includes(value);
      }
    },
    icon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: "plus-circle"
    },
    iconLeft: {
      type: Boolean,
      default: true
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    primaryColor: {
      type: String,
      default: "white"
    },
    secondaryColor: {
      type: String,
      default: "#4198f0"
    },
    size: {
      type: String,
      default: "large",
      validator: (value) => {
        return [
            "small",
            "medium",
            "large",
            "extra-large"
        ].includes(value);
      }
    },
    stretch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    realSecondaryColor() {
      if (this.type === "outline" && this.secondaryColor === "#4198f0") {
        return this.primaryColor;
      }

      return this.secondaryColor;
    },
    style() {
      if (this.disabled) {
        return "color: #c4c4c4; background-color: #f5f5f5; border-color: #f5f5f5";
      }

      if (this.type === "filled") {
        return "color: " + this.primaryColor + "; background-color: " + this.realSecondaryColor + (this.iconLeft ? "" : "; flex-direction: row-reverse");
      } else if (this.type === "outline") {
        return "color: " + this.primaryColor + "; border-color: " + this.realSecondaryColor + (this.iconLeft ? "" : "; flex-direction: row-reverse");
      } else if (this.type === "empty") {
        return "color: " + this.primaryColor + (this.iconLeft ? "" : "; flex-direction: row-reverse");
      } else if (this.type === "link-like") {
        return "color: " + this.primaryColor + (this.iconLeft ? "" : "; flex-direction: row-reverse");
      }

      return "";
    }
  },
  emits: ["click"]
}
</script>

<style lang="scss" scoped>
.AppButton_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 0.5rem;
  text-decoration: none;
  position: relative;

  .AppButton_overlay {
    display: none;
    pointer-events: none;
  }
}

.AppButton_button:hover {
  .AppButton_overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: rgba(255, 255, 255, 0.15);
    transition: background-color 0.2s ease-in-out;
  }
}

.AppButton_loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center
}

.AppButton_filled {
  border: none;
  cursor: pointer;
}

.AppButton_outline {
  border: solid 0.0625rem;
  cursor: pointer;
}

.AppButton_empty {
  border: none;

  .AppButton_text {
    cursor: pointer;
  }
}

.AppButton_icon {
  width: 1.25rem;
  height: 1.25rem;
}

.AppButton_small {
  gap: 0.75rem;
  padding: 0.375rem 1.5rem;
}

.AppButton_small.AppButton_no-text {
  padding: 0.375rem;
}

.AppButton_medium {
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
}

.AppButton_medium.AppButton_no-text {
  padding: 0.75rem;
}

.AppButton_large {
  gap: 1.125rem;
  padding: 1.25rem 5.25rem;

  .AppButton_icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.AppButton_large.AppButton_no-text {
  padding: 1.0rem;
}

.AppButton_extra-large {
  gap: 1.125rem;
  padding: 1.5rem 5.25rem;

  .AppButton_icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .AppButton_text {
    padding-top: 0.25rem;
    height: 1.25rem;
  }
}

.AppButton_extra-large.AppButton_no-text {
  padding: 1.0rem;
}

.AppButton_link-like {
  border: none;
  padding: 0;
  cursor: pointer;
}

.AppButton_text {
  margin-top: 0.125rem;
  line-height: 1.0rem;
  font-size: 1.0rem;
  font-weight: 500;
  vertical-align: center;
}

.AppButton_stretch {
  width: auto;
}

.AppButton_disabled {
  cursor: default;
}
</style>
