<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M2 6.25C2 5.00736 3.00736 4 4.25 4H19.75C20.9926 4 22 5.00736 22 6.25V17.75C22 18.9926 20.9926 20 19.75 20H4.25C3.00736 20 2 18.9926 2 17.75V6.25ZM4.25 5.5C3.83579 5.5 3.5 5.83579 3.5 6.25V17.75C3.5 18.1642 3.83579 18.5 4.25 18.5H19.75C20.1642 18.5 20.5 18.1642 20.5 17.75V6.25C20.5 5.83579 20.1642 5.5 19.75 5.5H4.25Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.21967 8.21515C8.51256 7.92226 8.98744 7.92226 9.28033 8.21515L12.0007 10.9356L14.7249 8.21893C15.0182 7.92644 15.4931 7.9271 15.7856 8.2204C16.0781 8.5137 16.0774 8.98857 15.7841 9.28106L13.0614 11.9962L15.7848 14.7197C16.0777 15.0126 16.0777 15.4874 15.7848 15.7803C15.4919 16.0732 15.0171 16.0732 14.7242 15.7803L12.0004 13.0565L9.2807 15.78C8.98801 16.073 8.51314 16.0734 8.22004 15.7807C7.92694 15.488 7.92661 15.0131 8.2193 14.72L10.9397 11.9958L8.21967 9.27581C7.92678 8.98292 7.92678 8.50805 8.21967 8.21515Z" />
  </svg>
</template>

<script>
export default {
  name: "IconXSquare",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>