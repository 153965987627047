import ConnectorService from "@/services/connector";

export default class GuidesService extends ConnectorService {
    constructor(url, id) {
        super(url + "guides/", id);
    }

    async getStates() {
        return await this.getp("states");
    }

    async setState(name, complete) {
        return await this.postp("set", {
            name: name,
            complete: complete
        });
    }
}
