<template>
  <AppModal :active="active" :header="true" :title="$t('modals.DocumentCancel.title')" icon="warning" @close="modalCloseDocumentCancel()">
    <div class="ModalDocumentCancel_container">
      <div class="ModalDocumentCancel_text">{{ $t("modals.DocumentCancel.text") }}</div>
      <div class="ModalDocumentCancel_button">
        <AppButton :loading="loaders.cancel" @click="cancel()" type="outline" :icon="false" size="medium" primaryColor="#f14a4a">{{ $t("modals.DocumentCancel.buttons.cancel") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseDocumentCancel } from "@/modals/index";
import { useToast } from "vue-toastification";
import events from "@/events";
import SigningService from "@/services/signing";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "ModalDocumentCancel",
  components: {
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: null
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentCancel,
      toast,
      events
    };
  },
  data() {
    return {
      loaders: {
        cancel: false
      }
    };
  },
  methods: {
    cancel() {
      if (this.document === null) {
        return;
      }

      this.loaders.cancel = true;

      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Signing.cancel(this.document.id)
          .then(() => {
            this.events.$emit("document:cancel", { document: this.document });

            modalCloseDocumentCancel();

            this.loaders.cancel = false;
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("modals.DocumentCancel.toasts.cancel.failed"));

            this.loaders.cancel = false;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentCancel_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentCancel_text {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalDocumentCancel_button {
    margin-left: auto;
  }
}
</style>
