const guideObjects = {};

const directive = {
    mounted: (element, binding) => {
        guideObjects[binding.value] = { element: element, binding: binding };
    },
    unmounted: (element, binding)  => {
        delete guideObjects[binding.value];
    }
};

export const getGuideElement = (name) => {
    if (!Object.keys(guideObjects).includes(name)) {
        return false;
    }

    return guideObjects[name].element;
}

export const getGuideObject = (name) => {
    if (!Object.keys(guideObjects).includes(name)) {
        return false;
    }

    return guideObjects[name];
}

export default directive;
