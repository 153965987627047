<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <rect :fill="backgroundColor" width="24" height="24" rx="6" />
    <path d="M14.0626 15.375C15.044 15.375 15.4263 15.8119 15.7166 16.7807L15.7675 16.9586C15.9044 17.4516 15.9862 17.5993 16.152 17.6676C16.3463 17.7477 16.4759 17.7363 16.6795 17.6316L16.7897 17.5698C16.8294 17.546 16.8726 17.5186 16.9215 17.4863L17.4189 17.1477C17.8808 16.8439 18.2958 16.6431 18.8012 16.5168C19.1026 16.4414 19.408 16.6247 19.4833 16.9261C19.5587 17.2274 19.3754 17.5328 19.0741 17.6082C18.7688 17.6845 18.5054 17.8 18.2151 17.9752L17.9911 18.1183L17.6319 18.3644C17.4595 18.4815 17.3294 18.5624 17.1939 18.6321C16.7118 18.88 16.2415 18.9213 15.7233 18.7077C15.1647 18.4775 14.9394 18.1228 14.7286 17.4165L14.6117 17.0101C14.4757 16.5681 14.383 16.5 14.0626 16.5C13.8306 16.5 13.5904 16.6161 13.2614 16.8922L13.1228 17.0129L12.4321 17.6694C11.376 18.6621 10.4757 19.1441 9.18661 19.1441C7.9219 19.1441 6.8281 18.9536 5.9099 18.5675L8.1206 17.9643C8.45605 18.0008 8.81133 18.0191 9.18661 18.0191C10.0737 18.0191 10.7104 17.7079 11.5013 16.9972L11.6949 16.8182L12.0938 16.4348C12.2713 16.2647 12.4043 16.1428 12.5382 16.0304C13.0509 15.6002 13.5169 15.375 14.0626 15.375ZM17.2729 5.22723C18.321 6.27533 18.321 7.97463 17.2729 9.02273L17.0567 9.23926C17.9203 10.2903 17.8891 11.4041 17.086 12.2097L15.585 13.7106C15.3652 13.9301 15.009 13.9297 14.7895 13.7099C14.5701 13.49 14.5704 13.1339 14.7902 12.9144L16.2893 11.4153C16.6531 11.0505 16.6951 10.5976 16.2594 10.0365L9.79664 16.499C9.58898 16.7066 9.33073 16.8565 9.0474 16.9338L5.21064 17.9802C4.79073 18.0947 4.40543 17.7094 4.51995 17.2895L5.56634 13.4527C5.64361 13.1694 5.79348 12.9111 6.00114 12.7035L13.4774 5.22723C14.5255 4.17914 16.2248 4.17914 17.2729 5.22723ZM14.2729 6.02273L6.79664 13.499C6.72742 13.5682 6.67746 13.6543 6.6517 13.7487L5.86432 16.6358L8.7514 15.8484C8.84584 15.8227 8.93192 15.7727 9.00114 15.7035L16.4774 8.22723C17.0861 7.61848 17.0861 6.63149 16.4774 6.02273C15.8686 5.41397 14.8816 5.41397 14.2729 6.02273Z" />
  </svg>
</template>

<script>
export default {
  name: "IconSignatureColored",
  props: {
    color: {
      type: String,
      default: "white"
    },
    backgroundColor: {
      type: String,
      default: "#4198F0"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>