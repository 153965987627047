<template>
  <AppModal :active="active" :header="true" :title="$t('modals.SignOut.title')" icon="sign-out" :overlayClose="true" @close="modalCloseSignOut()">
    <div class="ModalSignOut_container">
      <div class="ModalSignOut_text">{{ $t("modals.SignOut.text") }}</div>
      <div class="ModalSignOut_button">
        <AppButton @click="modalCloseSignOut(); signOut()" type="outline" :icon="false" size="medium" primaryColor="#f14a4a">{{ $t("modals.SignOut.buttons.signOut") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseSignOut } from "@/modals/index";
import { signOut } from "@/utilities";

export default {
  name: "ModalSignOut",
  components: {
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: null
    }
  },
  setup() {
    return {
      modalCloseSignOut,
      signOut
    };
  }
}
</script>

<style lang="scss" scoped>
.ModalSignOut_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalSignOut_text {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalSignOut_button {
    margin-left: auto;
  }
}
</style>
