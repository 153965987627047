<template>
  <AppDropdown :open="open" :offset="4" @close="$emit('close')">
    <div class="container">
      <AppButton @click="$router.push({ name: 'Profile' }); $emit('action')" class="link" type="link-like" iconType="profile" size="small" primaryColor="#626d78">{{ $t("components.HeaderUserAvatarDropdown.items.profile") }}</AppButton>
      <NavigationSpacer />
      <AppButton @click="modalOpenSignOut(); $emit('action')" class="link" type="link-like" iconType="sign-out" size="small" primaryColor="#f14a4a">{{ $t("components.HeaderUserAvatarDropdown.items.signOut") }}</AppButton>
    </div>
  </AppDropdown>
</template>

<script>
import AppDropdown from "@/components/AppDropdown";
import AppButton from "@/components/AppButton";
import NavigationSpacer from "@/components/NavigationSpacer";
import { modalOpenSignOut } from "@/modals";

export default {
  name: "HeaderUserAvatarDropdown",
  components: {
    NavigationSpacer,
    AppButton,
    AppDropdown
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      modalOpenSignOut
    };
  },
  emits: ["action", "close"]
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .link {
    height: 1.0rem;
  }
}
</style>
