import Permission from "@/core/permission";

export default class Permissions {
    constructor(roles, owner) {
        this._roles = roles;
        this._owner = owner;
    }

    get isOwner() {
        return this._owner;
    }

    hasPermission(permission) {
        if (this.isOwner) {
            return true;
        }

        return this._roles.some(r => r.hasPermission(permission));
    }

    get organization() {
        return {
            view: {
                details: this.hasPermission(Permission.OrganizationViewDetails),
                balance: this.hasPermission(Permission.OrganizationViewBalance),
                users: this.hasPermission(Permission.OrganizationViewUsers),
                roles: this.hasPermission(Permission.OrganizationViewRoles)
            },
            edit: {
                details: this.hasPermission(Permission.OrganizationEditDetails),
                roles: this.hasPermission(Permission.OrganizationEditRoles)
            },
            users: {
                invite: this.hasPermission(Permission.OrganizationUserInvite),
                remove: this.hasPermission(Permission.OrganizationUserRemove)
            }
        }
    }

    get users() {
        return {
            view: {
                details: this.hasPermission(Permission.UserViewDetails),
                roles: this.hasPermission(Permission.UserViewRoles)
            },
            edit: {
                details: this.hasPermission(Permission.UserEditDetails),
                roles: this.hasPermission(Permission.UserEditRoles)
            }
        };
    }

    get documents() {
        return {
            view: {
                documents: this.hasPermission(Permission.DocumentViewDetails),
                roles: this.hasPermission(Permission.DocumentViewRoles)
            },
            edit: {
                documents: this.hasPermission(Permission.DocumentEditDetails),
                roles: this.hasPermission(Permission.DocumentEditRoles)
            },
            create: {
                documents: this.hasPermission(Permission.DocumentCreate)
            },
            delete: {
                documents: this.hasPermission(Permission.DocumentDeleteDetails),
                roles: this.hasPermission(Permission.DocumentDeleteRoles),
            }
        };
    }
}
