<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7501 16.5001C16.0586 16.5001 16.5683 17.0826 16.9554 18.3743L17.0232 18.6116C17.2057 19.2688 17.3148 19.4658 17.5359 19.5569C17.795 19.6636 17.9677 19.6485 18.2393 19.5089L18.3862 19.4265C18.4391 19.3948 18.4967 19.3582 18.5619 19.3151L19.2251 18.8637C19.841 18.4586 20.3944 18.1909 21.0682 18.0224C21.47 17.922 21.8772 18.1663 21.9777 18.5682C22.0782 18.97 21.8338 19.3772 21.432 19.4777C21.025 19.5794 20.6738 19.7334 20.2867 19.967L19.9881 20.1579L19.5091 20.4859C19.2793 20.6421 19.1058 20.75 18.9251 20.8429C18.2823 21.1734 17.6553 21.2285 16.9643 20.9437C16.2195 20.6367 15.9191 20.1638 15.638 19.222L15.4822 18.6802C15.3008 18.0909 15.1772 18.0001 14.7501 18.0001C14.4407 18.0001 14.1205 18.1548 13.6817 18.523L13.497 18.6839L12.5761 19.5593C11.168 20.8829 9.9675 21.5256 8.24874 21.5256C6.56245 21.5256 5.10405 21.2716 3.87979 20.7567L6.82738 19.9525C7.27465 20.0011 7.74836 20.0256 8.24874 20.0256C9.43148 20.0256 10.2805 19.6106 11.335 18.663L11.5932 18.4243L12.125 17.9131C12.3617 17.6863 12.539 17.5238 12.7175 17.374C13.4011 16.8004 14.0224 16.5001 14.7501 16.5001ZM19.0304 2.96973C20.4279 4.36719 20.4279 6.63293 19.0304 8.03039L18.7422 8.3191C19.8937 9.72044 19.8521 11.2056 18.7812 12.2796L16.78 14.2809C16.4868 14.5735 16.0119 14.5731 15.7193 14.2799C15.4267 13.9868 15.4271 13.5119 15.7202 13.2193L17.719 11.2205C18.204 10.7341 18.2601 10.1302 17.6792 9.38214L9.0621 17.9987C8.78522 18.2756 8.44089 18.4754 8.06312 18.5784L2.94743 19.9736C2.38756 20.1263 1.87383 19.6126 2.02652 19.0527L3.42171 13.937C3.52474 13.5593 3.72456 13.2149 4.00144 12.9381L13.9698 2.96973C15.3672 1.57227 17.633 1.57227 19.0304 2.96973ZM15.0304 4.03039L5.0621 13.9987C4.96981 14.091 4.9032 14.2058 4.86886 14.3317L3.81901 18.1811L7.66845 17.1313C7.79437 17.097 7.90915 17.0303 8.00144 16.9381L17.9698 6.96973C18.7814 6.15805 18.7814 4.84206 17.9698 4.03039C17.1581 3.21871 15.8421 3.21871 15.0304 4.03039Z" />
  </svg>
</template>

<script>
export default {
  name: "IconSignature",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>