import store from "@/store";

export default class Settings {
    static get get() {
        return store.getters["account/settings"];
    }

    static get language() {
        return store.getters["account/language"];
    }

    static get region() {
        return Settings.get.region;
    }

    static get timezone() {
        return Settings.get.timezone;
    }
}
