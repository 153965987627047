<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.69533 11.9999L2.30104 3.27174C2.0648 2.66427 2.65715 2.08334 3.24311 2.28983L3.33546 2.32909L21.3355 11.3291C21.8537 11.5882 21.8861 12.2977 21.4326 12.6132L21.3355 12.6707L3.33546 21.6707C2.75248 21.9622 2.11917 21.4262 2.27051 20.8237L2.30104 20.7281L5.69533 11.9999L2.30104 3.27174L5.69533 11.9999ZM4.40381 4.54032L7.0128 11.2493L13.6404 11.2499C14.0201 11.2499 14.3339 11.5321 14.3835 11.8981L14.3904 11.9999C14.3904 12.3796 14.1082 12.6934 13.7421 12.7431L13.6404 12.7499L7.0128 12.7493L4.40381 19.4595L19.323 11.9999L4.40381 4.54032Z" />
  </svg>
</template>

<script>
export default {
  name: "IconSend",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>