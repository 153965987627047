<template>
  <div class="signup">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views.AppNotFound.header") }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views.AppNotFound.text." + (isSignedIn() ? 'signedIn' : 'signedOut')) }}</span>
      </div>
      <div class="buttons">
        <AppButton @click="$router.push('/')" iconType="arrow-right" :iconLeft="false">{{ $t("views.AppNotFound.buttons.continue." + (isSignedIn() ? 'signedIn' : 'signedOut')) }}</AppButton>
      </div>
    </div>
    <div class="right">
      <img class="splash" src="@/assets/images/splash-session-expired.png" alt="Page not found" />
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
export default {
  name: "AppNotFound",
  components: {
    AppButton
  },
  methods: {
    isSignedIn() {
      let session = this.$store.getters["session/get"];

      return (session !== false && session !== null);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 83.5rem;

  .left {
    width: 25.0rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      margin-top: 4.0rem;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
