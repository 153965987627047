<template>
  <div class="signup">
    <AppStepNavigation :steps="steps" :currentStep="currentStep" />
    <AppButton @click="goBack()" iconType="arrow-left" size="small" type="link-like" primary-color="#626d78">{{ $t("views.SignUpOrganizationName.buttons.goBack") }}</AppButton>
    <div class="content">
      <div class="header">{{ $t("views.SignUpOrganizationName.header") }}</div>
      <div class="content">
        <span>{{ $t("views.SignUpOrganizationName.text") }}</span>
        <AppInputText v-model="organizationName" :placeholder="$t('views.SignUpOrganizationName.inputs.organizationName.placeholder')" />
        <div class="continue"><AppButton @click="next()" iconType="arrow-right" :iconLeft="false" size="extra-large">{{ $t("views.SignUpOrganizationName.buttons.continue") }}</AppButton></div>
      </div>
    </div>
    <AppButton @click="signOut()" style="margin-top: 5.0rem" type="outline" primaryColor="#f14a4a" iconType="sign-out" size="medium">{{ $t("components.HeaderUserAvatarDropdown.items.signOut") }}</AppButton>
  </div>
</template>

<script>
import AppStepNavigation from "@/components/AppStepNavigation";
import AppButton from "@/components/AppButton";
import AppInputText from "@/components/AppInputText";
import { useToast } from "vue-toastification";
import { signOut } from "@/utilities";

export default {
  name: "SignUpOrganizationName",
  components: {
    AppInputText,
    AppButton,
    AppStepNavigation
  },
  setup() {
    const toast = useToast();

    return {
      signOut,
      toast
    };
  },
  mounted() {
    let data = this.$store.getters["setup/data"];
    if (data.length > 1) {
      this.organizationName = data[2]?.organizationName ?? "";
    }
  },
  data() {
    return {
      currentStep: 0,
      steps: [
        {
          name: this.$t("views.SignUpOrganizationName.steps.organizationName")
        },
        /* TODO: Uncomment when invites actually work
        {
          name: this.$t("views.SignUpOrganizationName.steps.inviteUsers")
        },*/
        {
          name: this.$t("views.SignUpOrganizationName.steps.finishSetup")
        }
      ],
      organizationName: ""
    };
  },
  methods: {
    goBack() {
      this.$store.dispatch("setup/previousStep"); // this
      this.$router.push(this.$store.getters["setup/route"]);
    },
    next() {
      if (this.organizationName.length > 0) {
        this.$store.dispatch("setup/nextStep", { organizationName: this.organizationName }); // this
        this.$router.push(this.$store.getters["setup/route"]);
      } else {
        this.toast.error(this.$t("views.SignUpOrganizationName.toasts.organizationNameEmpty"));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.5rem;
  width: 78.5rem;
  gap: 2.5rem;
}
.content {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;

  .header {
    line-height: 2.0rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #163b61;
  }

  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .continue {
      margin-top: 1.5rem;
    }
  }
}
</style>
