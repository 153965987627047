<template>
  <div class="HeaderSearch_container" :class="dropdown ? ' HeaderSearch_white' : ''">
    <div class="HeaderSearch_search" :class="dropdown ? ' HeaderSearch_search-white' : ''">
      <div class="HeaderSearch_left">
        <IconSearch @click="search()" style="cursor: pointer" />
      </div>
      <div class="HeaderSearch_middle">
        <input @keyup.enter="search()" @focus="showDropdown()" @blur="hideDropdown()" class="HeaderSearch_search-input" type="text" ref="search" name="search" :placeholder="$t('components.HeaderSearch.inputs.search.placeholder')" />
      </div>
      <!--
      TODO: Advanced search
      <div class="right">
        <img src="@/assets/icons/search-close.svg" alt="Close" />
      </div>
      -->
    </div>
  </div>
</template>

<script>
import IconSearch from "@/assets/icons/IconSearch";

export default {
  name: "HeaderSearch",
  components: {
    IconSearch
  },
  data() {
    return {
      dropdown: false
    };
  },
  methods: {
    showDropdown() {
      this.dropdown = true;
    },

    hideDropdown() {
      this.dropdown = false;
    },
    search() {
      const searchValue = this.$refs.search.value;
      if (searchValue.length > 3) {
        this.$router.push("/search/" + searchValue);
        this.$refs.search.value = "";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderSearch_search-input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}
.HeaderSearch_search-input::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}
.HeaderSearch_search-input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}
.HeaderSearch_search-input::-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}
.HeaderSearch_search-input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}

.HeaderSearch_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 0 9.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 4.5rem;
  width: 100%;

  .HeaderSearch_search {
    height: 3.0rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    background-color: #1e4670;
    border-radius: 0.5rem;

    .HeaderSearch_left {
      display: flex;
      height: 3.0rem;
      width: 1.25rem;
      align-items: center;
      margin-left: 1.5rem;
      margin-right: 1.0rem;
    }

    .HeaderSearch_middle {
      display: flex;
      align-items: center;
      height: 3.0rem;
      width: 100%;

      .HeaderSearch_search-input {
        height: 2.5rem;
        width: 100%;
        margin-right: 1.5rem;
        color: white;
      }
    }

    .HeaderSearch_right {
      display: flex;
      height: 3.0rem;
      width: 1.5rem;
      align-items: center;
      margin-left: 1.5rem;
      margin-right: 1.0rem;
    }
  }

  .HeaderSearch_search-white {
    //background-color: white;

    .HeaderSearch_search-input {
      //background-color: white;
    }
  }
}

.HeaderSearch_white {
  //background-color: white;
}
</style>
