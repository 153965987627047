<template>
  <div class="ProfileOrganizations_container">
    <div class="ProfileOrganizations_header">
      <div class="ProfileOrganizations_title">{{ $t("components.ProfileOrganizations.header") }}</div>
      <div class="ProfileOrganizations_button">
        <AppButton @click="create()" type="outline" primaryColor="#4198f0" size="medium">{{ $t("components.ProfileOrganizations.buttons.create") }}</AppButton>
      </div>
    </div>
    <div class="organizations">
      <div class="organization" v-for="organization in Account.organizations" :key="organization.id">
        <div class="icon">
          <AppIcon :type="isPersonal(organization) ? 'profile' : 'building'" />
        </div>
        <div class="name">{{ isPersonal(organization) ? $t('components.ProfileOrganizations.personal') : organization.name }}</div>
        <div class="status">
          <ResourceLabel v-if="isDefault(organization)" :closable="false" color="#4198f0" backgroundColor="rgba(65, 152, 240, 0.1)" borderColor="#4198f0" hoverColor="#4198f0" hoverBackgroundColor="rgba(65, 152, 240, 0.1)" hoverBorderColor="#4198f0">
            {{ $t("components.ProfileOrganizations.default") }}
          </ResourceLabel>
        </div>
        <div class="actions">
          <AppButton :loading="makeDefaultsLoading[organization.id]" @click="this.default(organization)" v-if="!isDefault(organization)" :icon="false" type="outline" size="small" primaryColor="#4198f0">{{ $t("components.ProfileOrganizations.buttons.default") }}</AppButton>
        </div>
      </div>
      <div class="organization" v-for="invite in organizationInvites" :key="invite.id">
        <div class="icon">
          <AppIcon type="building" />
        </div>
        <div class="name">{{ invite.company }}</div>
        <div class="status">
          <ResourceLabel :closable="false">
            {{ $t("components.ProfileOrganizations.invite") }}
          </ResourceLabel>
        </div>
        <div class="actions">
          <AppButton :loading="declinesLoading[invite.id]" @click="decline(invite)" :icon="false" type="outline" size="small" primaryColor="#626d78">
            {{ $t("components.ProfileOrganizations.buttons.decline") }}
          </AppButton>
          <AppButton :loading="acceptsLoading[invite.id]" @click="accept(invite)" iconType="checkmark-circle" :iconLeft="false" type="outline" size="small" primaryColor="#3ac178">
            {{ $t("components.ProfileOrganizations.buttons.accept") }}
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import Account from "@/core/account";
import ResourceLabel from "@/components/ResourceLabel";
import AppButton from "@/components/AppButton";
import AuthenticationService, {AuthenticationType} from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";
import { useToast } from "vue-toastification";
import Session from "@/core/session";
import { signIn } from "@/utilities";
import { modalOpenOrganizationCreate } from "@/modals";

export default {
  name: "ProfileOrganizations",
  components: {
    AppButton,
    ResourceLabel,
    AppIcon
  },
  setup() {
    const toast = useToast();

    return {
      Account,
      toast
    };
  },
  mounted() {
    this.getPersonalOrganization();
    this.getOrganizationInvites();
  },
  data() {
    return {
      personalOrganizationId: "",
      organizationInvites: [],
      acceptsLoading: {},
      declinesLoading: {},
      makeDefaultsLoading: {}
    };
  },
  methods: {
    getPersonalOrganization() {
      const organizations = Account.organizations;

      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].name === "Personal") {
          this.personalOrganizationId = organizations[i].id;

          break;
        }
      }

      return "";
    },
    isPersonal(organization) {
      return organization.id === this.personalOrganizationId;
    },
    isDefault(organization) {
      const result = organization.id === Account.defaultOrganization;

      if (!result) {
        this.makeDefaultsLoading[organization.id] = false;
      }

      return result;
    },
    getOrganizationInvites() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.getInvites("account", Account.id)
          .then((data) => {
            this.organizationInvites = data?.companyInvites;
            this.organizationInvites.forEach((invite) => {
              this.acceptsLoading[invite.id] = false;
              this.declinesLoading[invite.id] = false;
            });
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    default(organization) {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.setDefaultCompany(organization.id)
          .then(() => {
            Account.setDefaultOrganization(organization.id);

            this.makeDefaultsLoading[organization.id] = false;

            this.toast.success(this.$t("components.ProfileOrganizations.toasts.makeDefault.success"));
          })
          .catch((error) => {
            checkErrors(error);

            this.makeDefaultsLoading[organization.id] = false;

            this.toast.error(this.$t("components.ProfileOrganizations.toasts.makeDefault.failed"));
          });
    },
    accept(invite) {
      const platform = Account.platform;
      const canAccept = invite.platform === platform;

      if (!canAccept) {
        if (platform === "microsoft") {
          this.toast.error(this.$t("components.ProfileOrganizations.toasts.noAcceptMicrosoft"));

          return;
        } else {
          this.toast.error(this.$t("components.ProfileOrganizations.toasts.noAcceptGoogle"));

          return;
        }
      }

      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      this.acceptsLoading[invite.id] = true;

      Authentication.acceptInvite(invite.id)
          .then(() => {
            const sessionId = Session.id;
            if (sessionId !== null && sessionId !== false) {
              Authentication.login(AuthenticationType.SESSION, sessionId)
                  .then((data) => {
                    signIn(this, data, false);

                    let newOrganizationInvites = [];
                    for (let i = 0; i < this.organizationInvites.length; i++) {
                      if (this.organizationInvites[i].id !== invite.id) {
                        newOrganizationInvites.push(this.organizationInvites[i]);
                      }
                    }

                    this.organizationInvites = newOrganizationInvites;

                    this.acceptsLoading[invite.id] = false;
                  })
                  .catch((error) => {
                    checkErrors(error);

                    this.acceptsLoading[invite.id] = false;
                  });
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    decline(invite) {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      this.declinesLoading[invite.id] = true;

      Authentication.declineInvite(invite.id)
          .then(() => {
            let newOrganizationInvites = [];
            for (let i = 0; i < this.organizationInvites.length; i++) {
              if (this.organizationInvites[i].id !== invite.id) {
                newOrganizationInvites.push(this.organizationInvites[i]);
              }
            }

            this.organizationInvites = newOrganizationInvites;

            this.declinesLoading[invite.id] = false;
          })
          .catch((error) => {
            checkErrors(error);

            this.declinesLoading[invite.id] = false;
          });
    },
    create() {
      modalOpenOrganizationCreate();
    }
  }
}
</script>

<style lang="scss" scoped>
.ProfileOrganizations_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .ProfileOrganizations_header {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;

    .ProfileOrganizations_title {
      flex: 1;
    }
  }

  .organizations {
    max-width: 38.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.0rem;
    flex: 1;

    .organization {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      height: 2.0rem;

      .icon {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 1.0rem;
      }

      .name {
        font-weight: 500;
        font-size: 1.0rem;
        line-height: 1.25rem;
        color: #2d2d2d;
        margin-top: 0.125rem;
        margin-right: 0.75rem;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: right;
        flex: 1;
        gap: 0.5rem;
      }
    }
  }
}
</style>
