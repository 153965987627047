export default class DocumentEvents {
    static add(instance, field, options = { prepend: false, sort: true }) {
        instance.events.$on("document:add", ({ document }) => {
            if (field instanceof Function) {
                field(instance, document);

                return;
            }

            if (instance[field] === null) {
                instance[field] = [];
            }

            if (options.prepend) {
                instance[field].unshift(document);
            } else {
                instance[field].push(document);
            }

            if (options.sort) {
                instance[field].sort((a, b) => {
                    if (a.type === "folder" && b.type !== "folder") {
                        return -1;
                    }

                    if (a.type !== "folder" && b.type === "folder") {
                        return 1;
                    }

                    return 0;
                });
            }
        });
    }

    static delete(instance, field) {
        instance.events.$on("document:delete", ({ document }) => {
            if (field instanceof Function) {
                field(instance, document);

                return;
            }

            if (instance[field] === null) {
                return;
            }

            let newDocumentList = [];

            instance[field].forEach((d) => {
                if (d.id !== document.id) {
                    newDocumentList.push(d);
                }
            });

            instance[field] = newDocumentList;
        });
    }

    static rename(instance, field) {
        instance.events.$on("document:rename", ({ id, name }) => {
            if (instance[field] === null) {
                return;
            }

            instance[field].forEach((document, index) => {
                if (document.id === id) {
                    document.name = name;
                    Object.assign(instance[field][index], document);
                }
            });
        });
    }

    static send(instance, field) {
        instance.events.$on("document:send", ({ document }) => {
            if (field instanceof Function) {
                field(instance, document);
            }
        });
    }

    static cancel(instance, field) {
        instance.events.$on("document:cancel", ({ document }) => {
            if (field instanceof Function) {
                field(instance, document);
            }
        });
    }
}
