<template>
  <div class="SignInLanguageSwitcher_container">
    <AppInputSelect v-model="language" :selected="language" :options="[{ name: 'English', value: 'en' }, { name: 'Suomi', value: 'fi' }]" />
  </div>
</template>

<script>
import AppInputSelect from "@/components/AppInputSelect";
import { changeLanguage } from "@/utilities";

export default {
  name: "SignInLanguageSwitcher",
  components: {
    AppInputSelect
  },
  watch: {
    language: function(value) {
      changeLanguage(this, value, true);
    }
  },
  data() {
    return {
      language: "en"
    };
  },
  mounted() {
    this.language = this.$i18n.locale;
  }
}
</script>

<style lang="scss" scoped>
.SignInLanguageSwitcher_container {
  width: 10.0rem;
  margin-top: 2.5rem;
}
</style>
