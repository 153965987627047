<template>
  <AppModal :active="active" :header="true" :progress="60" :title="$t('modals.DocumentUploadName.title')" @close="modalCloseDocumentUploadName()">
    <div class="ModalDocumentUploadName_container">
      <div class="ModalDocumentUploadName_text">{{ $t("modals.DocumentUploadName.text") }}</div>
      <div class="ModalDocumentUploadName_file">
        <div class="ModalDocumentUploadName_icon"><AppIcon type="document" /></div>
        <div class="ModalDocumentUploadName_name">{{ file?.name }}</div>
      </div>
      <div class="input">
        <AppInputText @keyup.enter="this.continue()" ref="nameInput" v-model="name" size="small" stretch :placeholder="$t('modals.DocumentUploadName.inputs.name.placeholder.' + type)" />
      </div>
      <div class="ModalDocumentUploadName_button">
        <AppButton size="medium" :iconLeft="false" iconType="upload" @click="this.continue()">{{ $t("modals.DocumentUploadName.buttons.upload") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import {modalCloseDocumentUploadName, modalOpenDocumentUploadProgress} from "@/modals/index";
import { useToast } from "vue-toastification";
import AppIcon from "@/components/AppIcon";
import AppInputText from "@/components/AppInputText";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalDocumentUploadName",
  components: {
    AppInputText,
    AppModal,
    AppIcon,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    file: null,
    type: {
      type: String,
      default: "file"
    }
  },
  data() {
    return {
      name: ""
    };
  },
  watch: {
    active: function(value) {
      let name = this.file?.name;
      let parts = name.split(".");
      this.name = name.substring(0, name.length - parts[parts.length - 1].length - 1);

      if (value) {
        setTimeout(() => {
          this.$refs.nameInput.$refs.textInput.focus();
        }, 500);
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentUploadName,
      toast
    };
  },
  methods: {
    continue() {
      if (this.name?.length <= 0) {
        this.toast.error(this.$t("modals.DocumentUploadName.toasts.nameEmpty"));

        return;
      }

      modalCloseDocumentUploadName();

      modalOpenDocumentUploadProgress(this.file, this.type, this.name);
      this.name = "";
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentUploadName_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentUploadName_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .ModalDocumentUploadName_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;

    .ModalDocumentUploadName_icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }

    .ModalDocumentUploadName_name {
      padding-top: 0.25rem;
      line-height: 1.0rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #2d2d2d;
    }
  }

  .ModalDocumentUploadName_button {
    margin-top: 1.5rem;
    margin-left: auto;
  }
}
</style>
