<template>
  <AppHeader />
  <div class="AppSearch_container">
    <div class="AppSearch_left">
      <AppNavigation />
    </div>
    <div class="AppSearch_right">
      <div class="AppSearch_content">
        <div class="AppSearch_header">
          <div class="AppSearch_button">
            <AppButton size="medium" type="outline" primaryColor="#4198f0" iconType="arrow-left" style="border-color: #c3c8cc; gap: 0; padding: 0.75rem" @click="$router.go(-1)" />
          </div>
          <div class="AppSearch_title">{{ $t('views.AppSearch.title') }}"{{ $route.params.text }}"</div>
        </div>
        <DocumentList :documents="documents" :omit="['actions']" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppNavigation from "@/components/AppNavigation";
import DocumentList from "@/components/DocumentList";
import { checkErrors } from "@/utilities/errors";
import DocumentsService from "@/services/documents";
import events from "@/events";
import AppButton from "@/components/AppButton";

export default {
  name: "AppSearch",
  components: {
    DocumentList,
    AppNavigation,
    AppHeader,
    AppButton
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      if (newRoute.params.text !== oldRoute.params.text) {
        this.documents = null;
        this.getDocuments();
      }
    }
  },
  setup() {
    return {
      events
    };
  },
  mounted() {
    this.getDocuments();
  },
  data() {
    return {
      documents: null,
      gettingDocuments: false,
      root: null
    }
  },
  methods: {
    getDocuments() {
      if (this.gettingDocuments) {
        return;
      }

      this.gettingDocuments = true;

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.search(this.$route.params.text)
          .then((data) => {
            this.root = data?.root;
            this.documents = data?.documents;
            this.documents.sort((a, b) => {
              if (a.type === "folder" && b.type !== "folder") {
                return -1;
              }
              if (a.type !== "folder" && b.type === "folder") {
                return 1;
              }

              return 0;
            });

            this.gettingDocuments = false;
          })
          .catch((error) => {
            this.gettingDocuments = false;
            this.documents = [];

            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.AppSearch_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppSearch_left {
    display: flex;
    flex-direction: column;
  }

  .AppSearch_right {
    flex: 1;
    display: flex;
    flex-direction: row;

    .AppSearch_content {
      flex: 1;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .AppSearch_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;

        .AppSearch_title {
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 2.0rem;
          color: #163b61;
          padding-top: 0.25rem;
        }
      }
    }
  }
}
</style>
