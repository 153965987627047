<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 10.3757C12.897 10.3757 13.6243 11.1029 13.6243 12C13.6243 12.897 12.897 13.6243 12 13.6243C11.1029 13.6243 10.3757 12.897 10.3757 12C10.3757 11.1029 11.1029 10.3757 12 10.3757ZM18 10.3757C18.897 10.3757 19.6243 11.1029 19.6243 12C19.6243 12.897 18.897 13.6243 18 13.6243C17.1029 13.6243 16.3757 12.897 16.3757 12C16.3757 11.1029 17.1029 10.3757 18 10.3757ZM5.99999 10.3757C6.89705 10.3757 7.62426 11.1029 7.62426 12C7.62426 12.897 6.89705 13.6243 5.99999 13.6243C5.10294 13.6243 4.37573 12.897 4.37573 12C4.37573 11.1029 5.10294 10.3757 5.99999 10.3757Z" fill="#163B61"/>
  </svg>
</template>

<script>
export default {
  name: "IconMoreHorizontal",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>