<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0302 2.96973C22.4276 4.36719 22.4276 6.63293 21.0302 8.03039L9.06186 19.9987C8.78498 20.2756 8.44064 20.4754 8.06288 20.5784L2.94719 21.9736C2.38732 22.1263 1.87359 21.6126 2.02628 21.0527L3.42147 15.937C3.52449 15.5593 3.72432 15.2149 4.0012 14.9381L15.9695 2.96973C17.367 1.57227 19.6327 1.57227 21.0302 2.96973ZM15 6.06063L5.06186 15.9987C4.96956 16.091 4.90296 16.2058 4.86861 16.3317L3.81877 20.1811L7.6682 19.1313C7.79412 19.097 7.9089 19.0303 8.0012 18.9381L17.939 8.99963L15 6.06063ZM17.0302 4.03039L16.06 4.99963L18.999 7.93963L19.9695 6.96973C20.7812 6.15805 20.7812 4.84206 19.9695 4.03039C19.1578 3.21871 17.8419 3.21871 17.0302 4.03039Z" />
  </svg>
</template>

<script>
export default {
  name: "IconEdit",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
