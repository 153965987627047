<template>
  <div></div>
</template>

<script>
import AuthenticationService, { AuthenticationType } from "@/services/authentication";
import { signIn } from "@/utilities";
import store from "@/store";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "SignInRedirect",
  mounted() {
    const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

    let instance = this;

    if (this.$route.params.type === "microsoft") {
      Authentication.login(AuthenticationType.CLIENT_MICROSOFT, this.$route.query.code)
          .then((data) => {
            if (data?.session) {
              store.dispatch("session/set", data.session.id);
            }

            store.dispatch("setup/reset");

            signIn(instance, data);
          })
          .catch((error) => {
            checkErrors(error);
          });
    } else if (this.$route.query.code) {
      Authentication.authorize(AuthenticationType.CLIENT_GOOGLE, this.$route.query.code)
          .then(function(data) {
            if (data?.session) {
              store.dispatch("session/set", data.session.id);
            }

            if (data.status === "AUTHORIZATION_REQUIRED") {
              window.location.href = data?.redirectUrl;

              return;
            }

            store.dispatch("setup/reset");

            signIn(instance, data);
          })
          .catch((error) => {
            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
