<template>
  <div class="AppInputSearch_input" :class="'AppInputSearch_' + size + (stretch ? ' AppInputSearch_stretch' : '')">
    <div class="AppInputSearch_icon">
      <AppIcon color="#163b61" type="search" />
    </div>
    <input ref="textInput" @blur="$emit('blur')" :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" type="text" />
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "AppInputSearch",
  components: {
    AppIcon
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => {
        return [
          "small",
          "medium",
          "large"
        ].includes(value);
      }
    },
    stretch: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "blur"]
}
</script>

<style lang="scss" scoped>
.AppInputSearch_input {
  background-color: #f7f7f7;
  border: 0.03125rem solid #caced1;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  .AppInputSearch_icon {
    padding-top: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.75rem;
  }

  input {
    color: #626d78;
  }
}

.AppInputSearch_small {
  height: 2.25rem;
  min-width: 10.0rem;
  max-width: 18.0rem;

  input {
    height: calc(100% - 1.25rem);
    width: calc(100% - 2.0rem);
    padding: 0.625rem 1.0rem;
    line-height: 1.0rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.AppInputSearch_medium {
  height: 3.0rem;
  min-width: 28.5rem;
  max-width: 30.0rem;

  input {
    height: calc(100% - 2.0rem);
    width: calc(100% - 2.0rem);
    padding: 1.0rem 1.0rem;
    line-height: 1.0rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.AppInputSearch_large {
  height: 3.0rem;
  min-width: 28.5rem;
  max-width: 30.0rem;

  input {
    height: calc(100% - 2.0rem);
    width: calc(100% - 2.0rem);
    padding: 1.0rem 1.0rem;
    line-height: 1.0rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.AppInputSearch_stretch {
  flex: 1;
  width: auto;
  max-width: none;

  input {
    width: calc(100% - 2.0rem);
    max-width: none;
  }
}
</style>
