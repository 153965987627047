<template>
  <div class="signup">
    <AppStepNavigation :steps="steps" :currentStep="currentStep" />
    <AppButton @click="goBack()" iconType="arrow-left" size="small" type="link-like" primary-color="#626d78">{{ $t("views.SignUpInviteUsers.buttons.goBack") }}</AppButton>
    <div class="content">
      <div class="header">{{ $t("views.SignUpInviteUsers.header") }}</div>
      <div class="content">
        <span>{{ $t("views.SignUpInviteUsers.text") }}</span>
        <div class="inputs">
          <AppInputText v-for="(email, index) in emails" :key="index" v-model="emails[index]" :placeholder="$t('views.SignUpInviteUsers.inputs.email.placeholder')" />
        </div>
        <AppButton @click="add" :icon="false" type="outline" size="small" primaryColor="#4198f0">{{ $t("views.SignUpInviteUsers.buttons.add") }}</AppButton>
        <div class="buttons">
          <AppButton @click="next" iconType="arrow-right" :iconLeft="false" size="extra-large">{{ $t("views.SignUpInviteUsers.buttons.continue") }}</AppButton>
          <AppButton @click="skip" :icon="false" size="extra-large" type="empty" primary-color="#626d78">{{ $t("views.SignUpInviteUsers.buttons.skip") }}</AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppStepNavigation from "@/components/AppStepNavigation";
import AppButton from "@/components/AppButton";
import AppInputText from "@/components/AppInputText";
import { useToast } from "vue-toastification";

export default {
  name: "SignUpInviteUsers",
  components: {
    AppInputText,
    AppButton,
    AppStepNavigation
  },
  setup() {
    const toast = useToast();

    return {
      toast
    };
  },
  mounted() {
    //TODO: Remove when invites actually work
    this.$store.dispatch("setup/skipStep"); // this
    this.$router.push(this.$store.getters["setup/route"]);

    let data = this.$store.getters["setup/data"];
    if (data.length > 2) {
      this.emails = data[3]?.emails ?? [""];
    }
  },
  data() {
    return {
      currentStep: 1,
      steps: [
        {
          name: this.$t("views.SignUpInviteUsers.steps.organizationName")
        },
        {
          name: this.$t("views.SignUpInviteUsers.steps.inviteUsers")
        },
        {
          name: this.$t("views.SignUpInviteUsers.steps.finishSetup")
        }
      ],
      emails: [""]
    };
  },
  methods: {
    goBack() {
      this.$store.dispatch("setup/previousStep"); // this
      this.$router.push(this.$store.getters["setup/route"]);
    },
    add() {
      this.emails.push("");
    },
    next() {
      let result = [];

      for (let email of this.emails) {
        if (email !== "") {
          if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            result.push(email);
          } else {
            this.toast.error(this.$t("views.SignUpInviteUsers.toasts.invalidEmail", { email: email }));

            return;
          }
        }
      }

      this.$store.dispatch("setup/nextStep", { emails: result }); // this
      this.$router.push(this.$store.getters["setup/route"]);
    },
    skip() {
      this.$store.dispatch("setup/nextStep"); // this
      this.$router.push(this.$store.getters["setup/route"]);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.5rem;
  width: 78.5rem;
  gap: 2.5rem;
}
.content {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;

  .header {
    line-height: 2.0rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #163b61;
  }

  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .buttons {
      margin-top: 1.5rem;
    }
  }
}
</style>
