<template>
  <svg :fill="color" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33341 2.29175C11.6701 2.29175 14.3751 4.99669 14.3751 8.33341C14.3751 9.77664 13.869 11.1017 13.0247 12.1408L17.1087 16.2248C17.3528 16.4689 17.3528 16.8646 17.1087 17.1087C16.8868 17.3306 16.5396 17.3507 16.2949 17.1692L16.2248 17.1087L12.1408 13.0247C11.1017 13.869 9.77664 14.3751 8.33341 14.3751C4.99669 14.3751 2.29175 11.6701 2.29175 8.33341C2.29175 4.99669 4.99669 2.29175 8.33341 2.29175ZM8.33341 3.54175C5.68705 3.54175 3.54175 5.68705 3.54175 8.33341C3.54175 10.9798 5.68705 13.1251 8.33341 13.1251C10.9798 13.1251 13.1251 10.9798 13.1251 8.33341C13.1251 5.68705 10.9798 3.54175 8.33341 3.54175Z" />
  </svg>
</template>

<script>
export default {
  name: "IconSearch",
  props: {
    color: {
      type: String,
      default: "white"
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
