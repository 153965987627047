<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.50977 3.31845C5.12129 3.31845 4.80024 3.60713 4.74943 3.98168L4.74242 4.0858V6.97966C4.74242 7.36814 5.0311 7.68919 5.40565 7.74L5.50977 7.74701H8.40363C8.82743 7.74701 9.17098 7.40345 9.17098 6.97966C9.17098 6.59118 8.8823 6.27013 8.50776 6.21931L8.40363 6.21231L7.4249 6.21185C10.3376 3.8711 14.6072 4.05212 17.31 6.75491C20.2068 9.65171 20.2068 14.3483 17.31 17.2451C14.4132 20.1419 9.71652 20.1419 6.81972 17.2451C5.05676 15.4822 4.31864 12.9926 4.78168 10.5893C4.86185 10.1731 4.58949 9.77079 4.17335 9.69062C3.75721 9.61044 3.35486 9.8828 3.27469 10.2989C2.7163 13.1972 3.60759 16.2034 5.73453 18.3303C9.23066 21.8265 14.899 21.8265 18.3952 18.3303C21.8913 14.8342 21.8913 9.16585 18.3952 5.66971C15.0745 2.3491 9.79436 2.18239 6.27698 5.16957L6.27712 4.0858C6.27712 3.662 5.93357 3.31845 5.50977 3.31845Z" />
  </svg>
</template>

<script>
export default {
  name: "IconReload",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>