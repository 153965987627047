<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.55153 9.34944L6.46742 9.27681C6.20118 9.01052 6.17702 8.59385 6.3949 8.30026L6.46753 8.21615L11.4652 3.21954C11.7313 2.95345 12.1477 2.92914 12.4413 3.14671L12.5254 3.21923L17.5289 8.21584C17.822 8.50854 17.8223 8.98341 17.5296 9.2765C17.2635 9.54295 16.8469 9.56744 16.5531 9.34979L16.4689 9.27723L12.7518 5.56634L12.7527 20.2463C12.7527 20.626 12.4706 20.9398 12.1045 20.9895L12.0027 20.9963C11.6231 20.9963 11.3093 20.7142 11.2596 20.3481L11.2527 20.2463L11.2518 5.55334L7.52808 9.27692C7.26178 9.54316 6.84512 9.56732 6.55153 9.34944L6.46742 9.27681L6.55153 9.34944Z" />
  </svg>

</template>

<script>
export default {
  name: "IconArrowUp",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>