<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 1.99634C16.0498 1.99634 19.3565 5.19108 19.4957 9.24539L19.4999 9.49634V13.5933L20.8799 16.7493C20.9489 16.9072 20.9846 17.0777 20.9846 17.2501C20.9846 17.9405 20.4249 18.5001 19.7346 18.5001L14.9999 18.5016C14.9999 20.1585 13.6567 21.5016 11.9999 21.5016C10.4022 21.5016 9.09621 20.2527 9.00496 18.6779L8.99941 18.4993L4.27473 18.5001C4.10339 18.5001 3.93388 18.4649 3.77673 18.3966C3.14353 18.1216 2.85318 17.3853 3.12822 16.7521L4.49987 13.5943V9.49623C4.50046 5.34144 7.85195 1.99634 11.9999 1.99634ZM13.4994 18.4993L10.4999 18.5016C10.4999 19.33 11.1714 20.0016 11.9999 20.0016C12.7796 20.0016 13.4203 19.4067 13.493 18.6461L13.4994 18.4993ZM11.9999 3.49634C8.67971 3.49634 6.00034 6.17059 5.99987 9.49634V13.906L4.65589 17.0001H19.3524L17.9999 13.9069L18 9.50919L17.9962 9.28399C17.8852 6.05052 15.2414 3.49634 11.9999 3.49634Z" />
  </svg>
</template>

<script>
export default {
  name: "IconNotification",
  props: {
    color: {
      type: String,
      default: "white"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>