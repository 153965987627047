<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3757 12C10.3757 11.1029 11.1029 10.3757 12 10.3757C12.897 10.3757 13.6243 11.1029 13.6243 12C13.6243 12.897 12.897 13.6243 12 13.6243C11.1029 13.6243 10.3757 12.897 10.3757 12ZM10.3757 5.99999C10.3757 5.10294 11.1029 4.37573 12 4.37573C12.897 4.37573 13.6243 5.10294 13.6243 5.99999C13.6243 6.89705 12.897 7.62426 12 7.62426C11.1029 7.62426 10.3757 6.89705 10.3757 5.99999ZM10.3757 18C10.3757 17.1029 11.1029 16.3757 12 16.3757C12.897 16.3757 13.6243 17.1029 13.6243 18C13.6243 18.897 12.897 19.6243 12 19.6243C11.1029 19.6243 10.3757 18.897 10.3757 18Z" />
  </svg>
</template>

<script>
export default {
  name: "IconMoreVertical",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>