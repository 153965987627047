<template>
  <div class="AppInputToggle_toggle" @click="$emit('update:modelValue', !modelValue)">
    <img v-if="modelValue" src="@/assets/icons/switch-on.svg" alt="Toggle On" />
    <img v-else src="@/assets/icons/switch-off.svg" alt="Toggle Off" />
  </div>
</template>

<script>
export default {
  name: "AppInputToggle",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:modelValue"
  ]
}
</script>

<style lang="scss" scoped>
.AppInputToggle_toggle {
  width: 2.0rem;
  height: 1.0rem;
  cursor: pointer;
}
</style>
