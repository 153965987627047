export default class SigneeEvents {
    static add(instance, field) {
        instance.events.$on("signee:add", ({ signee }) => {
            if (field instanceof Function) {
                field(instance, signee);

                return;
            }

            if (instance[field] === null) {
                instance[field] = [];
            }

            instance[field].push(signee);
        });
    }

    static remove(instance, field) {
        instance.events.$on("signee:remove", ({ signee }) => {
            if (field instanceof Function) {
                field(instance, signee);

                return;
            }

            if (instance[field] === null) {
                return;
            }

            let newSigneeList = [];

            instance[field].forEach((s) => {
                if (s.id !== signee.id) {
                    newSigneeList.push(s);
                }
            });

            instance[field] = newSigneeList;
        });
    }
}
