export default class UserEvents {
    static remove(instance, field) {
        instance.events.$on("user:remove", ({ user }) => {
            if (field instanceof Function) {
                field(instance, user);

                return;
            }

            if (instance[field] === null) {
                return;
            }

            let newUserList = [];

            instance[field].forEach((u) => {
                if (u.id !== user.id) {
                    newUserList.push(u);
                }
            });

            instance[field] = newUserList;
        });
    }
}
