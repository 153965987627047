<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.25019 4.49992H10.7496C11.1638 4.49992 11.4996 4.83571 11.4996 5.24992C11.4996 5.62962 11.2174 5.94341 10.8513 5.99308L10.7496 5.99992H7.24983C6.07088 5.99936 5.10359 6.90632 5.00795 8.06087L5.00037 8.21979L5.00321 16.7504C5.00352 17.9413 5.92894 18.9158 7.09975 18.9947L7.2538 18.9998L15.7519 18.9882C16.9416 18.9865 17.9146 18.0618 17.9936 16.8921L17.9988 16.7382V13.2319C17.9988 12.8177 18.3346 12.4819 18.7488 12.4819C19.1285 12.4819 19.4423 12.764 19.4919 13.1301L19.4988 13.2319V16.7382C19.4988 18.7405 17.9294 20.3766 15.9529 20.4827L15.7539 20.4882L7.25836 20.4998L7.05504 20.4947C5.14248 20.3951 3.60904 18.8625 3.50846 16.95L3.50321 16.7508L3.50098 8.25246L3.50539 8.04996C3.60548 6.13724 5.13876 4.60424 7.05105 4.50503L7.25019 4.49992H10.7496H7.25019ZM13.7482 3.00122L20.3019 3.00173L20.4015 3.0155L20.5023 3.04369L20.5591 3.06779C20.6123 3.09097 20.6635 3.12139 20.7112 3.1586L20.7805 3.22131L20.8642 3.3199L20.9184 3.41001L20.9571 3.50032L20.9763 3.56452L20.9899 3.62838L20.9992 3.72258L20.9997 10.2551C20.9997 10.6693 20.664 11.0051 20.2497 11.0051C19.87 11.0051 19.5563 10.723 19.5066 10.3569L19.4997 10.2551L19.499 5.56122L12.2797 12.7844C12.0135 13.0508 11.5969 13.0751 11.3032 12.8573L11.2191 12.7847C10.9528 12.5185 10.9285 12.1018 11.1462 11.8082L11.2188 11.724L18.437 4.50122H13.7482C13.3685 4.50122 13.0547 4.21907 13.0051 3.85299L12.9982 3.75122C12.9982 3.37152 13.2804 3.05773 13.6464 3.00807L13.7482 3.00122Z" />
  </svg>
</template>

<script>
export default {
  name: "IconOpen",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>