<template>
  <div class="SettingsInformation_container">
    <div class="SettingsInformation_header">{{ $t("components.SettingsInformation.header") }}</div>
    <div class="SettingsInformation_item">
      <div class="SettingsInformation_title">{{ $t("components.SettingsInformation.items.name.title") }}</div>
      <div class="SettingsInformation_content">
        <!--
        TODO: Change organization name
        <AppInputText v-if="Account.permissions?.organization?.edit?.details && organization.name !== 'Personal'" v-model="name" />
        <div class="SettingsInformation_fake-input" v-else>{{ name }}</div>
        -->
        <div class="SettingsInformation_fake-input">{{ name }}</div>
        <div class="SettingsInformation_current-plan">{{ $t("components.SettingsInformation.items.currentPlan") }}<span class="SettingsInformation_plan-name">{{ $t("components.SettingsInformation.plans." + organization?.plan?.name) }}</span></div>
        <div class="SettingsInformation_upgrade" v-if="false && Account.permissions?.isOwner && organization?.plan?.canUpgrade && billing.paymentMethod">
          <AppButton iconType="wand" size="medium">{{ $t("components.SettingsInformation.buttons.upgrade") }}</AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import AppInputText from "@/components/AppInputText";
import Organization from "@/core/organization";
import AppButton from "@/components/AppButton";
import { useToast } from "vue-toastification";
import Account from "@/core/account";
import PaymentsService from "@/services/payments";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "SettingsInformation",
  components: {
    AppButton
    //AppInputText
  },
  props: {
    organization: {
      type: Organization,
      required: true
    }
  },
  setup() {
    const toast = useToast();

    return {
      toast,
      Account
    };
  },
  watch: {
    name: function(value) {
      let newEdit = (new Date()).getTime();

      if (newEdit - this.lastEdit < 2500000) {
        this.lastEdit = newEdit;

        if (this.editInterval != null) {
          clearInterval(this.editInterval);
        }

        this.editInterval = setInterval(() => {
          this.changeName(value);
        }, 2500);

        return;
      }

      this.changeName(value);
    }
  },
  data() {
    return {
      name: this.organization?.name,
      lastEdit: (new Date()).getTime(),
      editInterval: null,
      billing: {
        paymentMethod: false
      }
    }
  },
  methods: {
    getPaymentMethod() {
      const Payments = new PaymentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Payments.canCharge()
          .then((data) => {
            if (data.status === "OK") {
              this.billing.paymentMethod = true;
            } else if (data.status === "PAYMENT_METHOD_REQUIRED") {
              this.billing.paymentMethod = false;
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    changeName(name) {
      if (name.length === 0) {
        this.toast.error("components.SettingsInformation.toasts.nameEmpty");

        return;
      }

      console.log("changeName", name);
      clearInterval(this.editInterval);
    }
  },
  mounted() {
    this.getPaymentMethod();
  }
}
</script>

<style lang="scss" scoped>
.SettingsInformation_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 28.5rem;

  .SettingsInformation_header {
    display: flex;
    flex-direction: row;
    height: 2.875rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
  }

  .SettingsInformation_item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .SettingsInformation_title {
      font-weight: 500;
      font-size: 1.0rem;
      line-height: 1.25rem;
      color: #2d2d2d;
    }

    .SettingsInformation_content {
      flex: 1;

      .SettingsInformation_fake-input {
        border: 0.03125rem solid #c3c7cc;
        border-radius: 0.3125rem;
        min-width: 26.5rem;
        max-width: 28.0rem;
        padding: 1.0rem 1.0rem;
        line-height: 1.0rem;
        font-size: 0.875rem;
        font-weight: 400;
        color: #626d78;
      }

      .SettingsInformation_current-plan {
        font-weight: 400;
        font-size: 1.0rem;
        color: #626d78;
        margin-top: 1.5rem;

        .SettingsInformation_plan-name {
          font-weight: 600;
          font-size: 1.0rem;
          color: #2d2d2d;
        }
      }

      .SettingsInformation_upgrade {
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
