<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.75 11.25H21.25C21.6642 11.25 22 11.5858 22 12C22 12.3797 21.7178 12.6935 21.3518 12.7432L21.25 12.75H2.75C2.33579 12.75 2 12.4142 2 12C2 11.6203 2.28215 11.3065 2.64823 11.2568L2.75 11.25Z" />
  </svg>
</template>

<script>
export default {
  name: "IconMinus",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>