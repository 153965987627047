<template>
  <AppModal :active="active" :header="true" :title="$t('modals.PaymentMethodAdd.title')" icon="credit-card" :overlayClose="true" @close="modalClosePaymentMethodAdd()">
    <div class="ModalPaymentMethodAdd_container">
      <div class="ModalPaymentMethodAdd_text">{{ $t("modals.PaymentMethodAdd.text") }}</div>
      <div class="ModalPaymentMethodAdd_button">
        <AppButton :loading="loading" @click="go()" :iconLeft="false" iconType="arrow-right" size="medium">{{ $t("modals.PaymentMethodAdd.buttons.go") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalClosePaymentMethodAdd } from "@/modals/index";
import PaymentsService from "@/services/payments";
import { checkErrors } from "@/utilities/errors";
import { useToast } from "vue-toastification";

export default {
  name: "ModalPaymentMethodAdd",
  components: {
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalClosePaymentMethodAdd,
      toast
    };
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    go() {
      this.loading = true;

      const Payments = new PaymentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Payments.setup()
          .then((data) => {
            if (data.status === "PAYMENT_METHOD_REQUIRED") {
              modalClosePaymentMethodAdd();

              this.loading = false;

              window.location.href = data?.redirectUrl;
            } else {
              this.toast.error(this.$t("modals.PaymentMethodAdd.toasts.go.failed"));

              modalClosePaymentMethodAdd();

              this.loading = false;
            }
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("modals.PaymentMethodAdd.toasts.go.failed"));

            modalClosePaymentMethodAdd();

            this.loading = false;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalPaymentMethodAdd_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalPaymentMethodAdd_text {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalPaymentMethodAdd_button {
    margin-left: auto;
  }
}
</style>
