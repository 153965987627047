<template>
  <svg viewBox="40 37 35 24" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="color" style="fill: none; stroke-width: 2px;" d="M 58.067 37.318 L 58.3 37.318 L 58.3 61.922 L 58.067 61.922 L 49.382 53.788 L 41.801 53.714 L 41.886 49.201 L 41.941 44.627 L 49.816 44.623 L 58.067 37.318 Z"/>
    <line :stroke="color" style="fill: none; stroke-width: 2px;" x1="62.968" y1="43.837" x2="74.052" y2="54.994"/>
    <line :stroke="color" style="fill: none; stroke-width: 2px;" x1="62.968" y1="54.994" x2="74.052" y2="43.837"/>
  </svg>
</template>

<script>
export default {
  name: "IconSpeakerMute",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
