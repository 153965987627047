<template>
  <div class="list">
    <ListHeader :fields="userFields" resource="user" />
    <UserListItemUserInvite @action="action" v-for="userInvite in userInvites" :key="userInvite.id" :user="userInvite" :fields="userInviteFields" />
    <UserListItemUser @action="action" v-for="user in users" :key="user.id" :user="user.setRoles() ? user : user" :fields="userFields" />
    <div class="empty" v-if="users != null && users.length === 0 && userInvites != null && userInvites.length === 0">
      <img class="splash" src="@/assets/images/splash-document-list-empty.png" alt="Empty" />
      <div class="header">{{ $t('components.UserList.empty.header') }}</div>
      <div class="text">{{ $t('components.UserList.empty.text') }}</div>
    </div>
  </div>
</template>

<script>
import UserListItemUser from "@/components/UserListItemUser";
import ListHeader from "@/components/ListHeader";
import UserListItemUserInvite from "@/components/UserListItemUserInvite";
import { modalOpenUserInviteCancel, modalOpenUserRemove, modalOpenUserRoles } from "@/modals";
import Organization from "@/core/organization";
import Account from "@/core/account";

export default {
  name: "UserList",
  components: {
    UserListItemUserInvite,
    ListHeader,
    UserListItemUser
  },
  props: {
    organization: {
      type: Organization,
      required: true
    },
    users: {
      required: true,
      default: null
    },
    userInvites: {
      required: true,
      default: null
    }
  },
  setup() {
    return {
      Account
    };
  },
  methods: {
    action({ user, action }) {
      switch (action) {
        case "remove": modalOpenUserRemove(this.organization, user); break;
        case "permissions": modalOpenUserRoles(this.organization, user); break;
        case "cancel": modalOpenUserInviteCancel(this.organization, user); break;
        default: console.log(action, user); break;
      }
    }
  },
  data() {
    return {
      userFields: [
        { name: "fullName", type: "avatar", weight: "4", header: true },
        Account.permissions?.users?.view?.roles ? { name: "roles", type: "roles", weight: "2", header: true } : null,
        { name: "actions", type: "actions", weight: "1", header: true,
          data: [
            { name: "more", list: true },
            Account.permissions?.organization?.users?.remove ? { name: "remove", list: false } : null,
            Account.permissions?.users?.view?.roles ? { name: "permissions", list: false } : null
          ].filter(element => element != null)
        }
      ].filter(element => element != null),
      userInviteFields: [
        { name: "fullName", type: "avatar", weight: "4", header: true },
        Account.permissions?.users?.view?.roles ? { name: "roles", type: "roles", weight: "2", header: true } : null,
        { name: "actions", type: "actions", weight: "1", header: true,
          data: [
            Account.permissions?.organization?.users?.remove ? { name: "cancel", list: false } : null
          ].filter(element => element != null)
        }
      ].filter(element => element != null)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  flex: 1;

  .empty {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 38.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.0rem;

    .splash {
      width: 30.0rem;
      height: 17.5rem;
      margin-bottom: 2.5rem;
    }

    .header {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.0rem;
      text-align: center;
      color: #2d2d2d;
      margin-bottom: 0.5rem;
    }

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #626d78;
    }
  }
}
</style>
