<template>
  <AppModal :active="active" :header="true" :title="$t('modals.DocumentSign.title')" icon="signature" @close="modalCloseDocumentSign()">
    <div class="ModalDocumentSign_container">
      <div class="ModalDocumentSign_text">{{ $t("modals.DocumentSign.text") }}</div>
      <div class="ModalDocumentSign_button">
        <AppButton @click="go()" iconType="signature" size="medium">{{ $t("modals.DocumentSign.buttons.go") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseDocumentSign } from "@/modals/index";

export default {
  name: "ModalDocumentSign",
  components: {
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    signingUrl: {
      type: String,
      default: ""
    }
  },
  setup() {
    return {
      modalCloseDocumentSign
    };
  },
  methods: {
    go() {
      modalCloseDocumentSign();

      window.location.href = this.signingUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentSign_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentSign_text {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalDocumentSign_button {
    margin-left: auto;
  }
}
</style>
