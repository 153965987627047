import events from "@/events";

export default class Sounds {
    static _volume = 0.5;

    static volume(volume) {
        Sounds._volume = volume;
    }

    static mute() {
        Sounds._muted = true;
    }

    static unmute() {
        Sounds._muted = false;
    }

    static play(sound) {
        if (!this._muted) {
            events.$emit("sound:play", { sound: sound, volume: Sounds._volume });
        }
    }
}
