<template>
  <div ref="AppInputMultiSelect" class="AppInputMultiSelect_container">
    <div @click="open = !open" class="AppInputMultiSelect_select" :class="open ? ' AppInputMultiSelect_open' : ''">
      <div class="AppInputMultiSelect_selected-option"><span class="AppInputMultiSelect_placeholder">{{ placeholder }}</span> {{ selected }}</div>
      <div class="AppInputMultiSelect_icon">
        <AppIcon v-if="open" type="chevron-up" color="#626d78" />
        <AppIcon v-else type="chevron-down" color="#626d78" />
      </div>
    </div>
    <div v-show="open" class="AppInputMultiSelect_dropdown">
      <template v-for="option in options" :key="option.value">
        <div @click="editable && select(option)" class="AppInputMultiSelect_option">
          <div class="AppInputMultiSelect_checkbox">
            <AppInputCheckbox @click.prevent="editable && select(option)" :editable="editable" v-model="valueData[option.value]" />
          </div>
          <div class="AppInputMultiSelect_label">{{ option.label }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppInputCheckbox from "@/components/AppInputCheckbox";

export default {
  name: "AppInputMultiSelect",
  components: {
    AppInputCheckbox,
    AppIcon
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    modelValue: function(value) {
      this.valueData = value;

      this.checkSelections();
    }
  },
  data() {
    const closeListener = (e) => {
      if (!this.$refs.AppInputMultiSelect.contains(e.target)) {
        this.open = false;
      }
    };

    return {
      closeListener,
      open: false,
      valueData: {},
      selectedOptions: [],
      selected: ""
    };
  },
  methods: {
    select(option) {
      this.valueData[option.value] = !this.valueData[option.value];
      this.checkSelections();

      this.$emit("changed", this.valueData);
      this.$emit("update:modelValue", this.valueData);
    },
    checkSelections() {
      this.selectedOptions = [];

      this.options.forEach((option) => {
        if (this.valueData[option.value]) {
          this.selectedOptions.push(option.label);
        }
      });

      if (this.selectedOptions.length === 0) {
        this.selected = this.$t("components.AppInputMultiSelect.none");
      } else if (this.selectedOptions.length === this.options.length) {
        this.selected = this.$t("components.AppInputMultiSelect.all");
      } else {
        this.selected = this.selectedOptions.join(", ");
      }
    }
  },
  mounted() {
    this.checkSelections();
    document.addEventListener("click", this.closeListener);
  },
  unmounted() {
    document.removeEventListener("click", this.closeListener);
  },
  emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
.AppInputMultiSelect_container {
  position: relative;
  min-width: 15.0rem;
  max-width: 24.0rem;

  .AppInputMultiSelect_select {
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    border: 0.03125rem solid #d9d9d9;
    height: 2.3125rem;
    background-color: white;
    align-items: center;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
    cursor: pointer;

    .AppInputMultiSelect_selected-option {
      flex: 1;
      color: #626d78;
      line-height: 1.0rem;
      font-size: 0.875rem;
      font-weight: 400;
      padding-top: 0.125rem;

      .AppInputMultiSelect_placeholder {
        font-weight: 500;
      }
    }

    .AppInputMultiSelect_icon {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .AppInputMultiSelect_dropdown {
    z-index: 10;
    display: flex;
    flex-direction: column;
    border: 0.03125rem solid #d9d9d9;
    border-radius: 0 0 0.5rem 0.5rem;
    border-top: none;
    background-color: white;
    position: absolute;
    width: calc(100% - (0.03125rem * 2));

    .AppInputMultiSelect_option {
      padding: 0.5rem 1.0rem;
      line-height: 1.0rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #2d2d2d;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      border-bottom: solid 0.03125rem #c3c8cc;

      .AppInputMultiSelect_checkbox {
        height: 0.75rem;
        width: 0.75rem;
        margin-right: 0.625rem;
      }

      .AppInputMultiSelect_label {
        color: #626d78;
        padding-top: 0.125rem;
      }
    }

    .AppInputMultiSelect_option:hover {
      background-color: #f0f0f0;
    }
  }

  .AppInputMultiSelect_open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
