import ConnectorService from "@/services/connector";

export default class PaymentsService extends ConnectorService {
    constructor(url, id) {
        super(url + "payments/", id);
    }

    async canCharge() {
        return await this.getp("can-charge");
    }

    async setup() {
        return await this.getp("setup");
    }
}
