<template>
  <div></div>
</template>

<script>
import events from "@/events";

import error from "@/assets/sounds/error.mp3";
import notification from "@/assets/sounds/notification.mp3";

export default {
  name: "AppSounds",
  setup() {
    return {
      events
    };
  },
  data() {
    return {
      sounds: {
        error: new Audio(error),
        notification: new Audio(notification)
      }
    };
  },
  mounted() {
    this.events.$on("sound:play", ({ sound, volume }) => {
      this.sounds[sound].volume = volume;
      this.sounds[sound].play();
    });
  }
}
</script>

<style lang="scss" scoped>

</style>
