<template>
  <DocumentHeader v-model="navigationOpen" @edit="edit()" @refresh="refresh()" :loaders="{ refresh: refreshLoader }" @rename="rename($event)" @delete="this.delete()" :document="document" :download="{ fileName: getFileName(), href: src }" />
  <div class="AppDocument_container">
    <div class="AppDocument_left">
      <AppNavigation v-show="navigationOpen" />
      <DocumentNavigation v-show="!navigationOpen" :document="document" />
    </div>
    <div class="AppDocument_right">
      <div class="AppDocument_content">
        <div v-if="documentHash == null" style="margin-top: 5.0rem; flex: 1">
          <AppLoading />
        </div>
        <iframe class="AppDocument_document" v-if="documentHash != null && !noEmbed" :src="src" :class="contentMimeType === 'application/pdf' ? 'AppDocument_pdf' : 'AppDocument_other'" />
        <div v-if="documentHash != null && noEmbed" style="margin-top: 5.0rem; flex: 1">
          <div style="width: fit-content; margin-left: auto; margin-right: auto; text-align: center; font-size: 1.0rem; font-weight: 600; color: #2d2d2d">
            <img src="@/assets/images/splash-something-went-wrong.png" alt="" /><br />
            {{ $t("views.AppDocument.noPreview") }}
          </div>
        </div>
      </div>
      <div v-if="auditEvents.length > 0" class="AppDocument_sidebar">
        <AppTabGroup>
          <template #tabs><AppTab :active="true">Timeline</AppTab></template>
          <template #content>
            <DocumentAuditEvent v-for="event of auditEvents" :key="event.id" :event="event" />
          </template>
        </AppTabGroup>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
import DocumentHeader from "@/components/DocumentHeader";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";
import { base64ToBlob } from "@/utilities";
import DocumentNavigation from "@/components/DocumentNavigation";
import { modalOpenDocumentDelete } from "@/modals";
import Document from "@/core/document";
import AppLoading from "@/components/AppLoading";
import { useToast } from "vue-toastification";
import DocumentEvents from "@/events/document";
import events from "@/events";
import AppTabGroup from "@/components/AppTabGroup";
import AppTab from "@/components/AppTab";
import AuditorService from "@/services/auditor";
import DocumentAuditEvent from "@/components/DocumentAuditEvent";

export default {
  name: "AppDocument",
  components: {
    DocumentAuditEvent,
    AppTab,
    AppTabGroup,
    AppLoading,
    DocumentNavigation,
    DocumentHeader,
    AppNavigation
  },
  watch: {
    $route: function(newRoute, oldRoute) {
      if (newRoute.params.document !== oldRoute.params.document) {
        this.document = null;
        this.editUrl = "";
        this.documentHash = null;
        this.getDocument();
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalOpenDocumentDelete,
      toast,
      events
    };
  },
  data() {
    return {
      navigationOpen: false,
      src: "",
      editUrl: "",
      document: null,
      documentHash: null,
      contentMimeType: "",
      contentTimer: null,
      noEmbed: false,
      refreshLoader: false,
      renaming: false,
      auditEvents: []
    }
  },
  methods: {
    getFileName() {
      let extension = this.document?.extension;
      if (this.contentMimeType === "application/pdf") {
        extension = "pdf";
      }

      return this.document?.name + "." + extension;
    },
    getEvents(documentId) {
      const Auditor = new AuditorService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Auditor.getEvents("DocumentResource", documentId, true)
          .then((data) => {
            console.log(data);
            this.auditEvents = data.events;
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    getDocument(refresh = false) {
      const documentId = this.$route.params.document !== "" ? this.$route.params.document : null;

      if (documentId != null) {
        const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

        this.getEvents(documentId);

        Documents.info(documentId)
            .then((data) => {
              this.document = new Document(data?.document);

              if (this.document.platform === "microsoft") {
                //this.editUrl = "https://onedrive.live.com/edit.aspx?resid=" + this.document?.driveId;
                this.editUrl = this.document?.driveUrl;
              } else {
                this.editUrl = "https://docs.google.com/document/d/" + this.document?.driveId + "/edit";
              }

              if (refresh) {
                this.refreshLoader = false;
              }

              if (!refresh) {
                this.getDocumentContent(this.document.id, this.documentHash);

                this.contentTimer = window.setInterval(() => {
                  if (this.document === null) {
                    return;
                  }

                  this.getDocumentContent(this.document.id, this.documentHash);
                }, 15000);
              }
            })
            .catch((error) => {
              this.document = null;

              checkErrors(error);

              this.$router.push("/documents");
            });
      }
    },
    getDocumentContent(documentId, hash, force = false) {
      if (this.document === null) {
        return;
      }

      if (this.$route.name !== "Document") {
        if (this.contentTimer != null) {
          window.clearInterval(this.contentTimer);

          return;
        }
      }

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      const embedFileTypes = [
        "application/pdf"
      ];

      Documents.getContent(documentId, hash, force)
          .then((data) => {
            if (data?.hash == null || data.hash !== hash || force) {
              const blob = base64ToBlob(data?.content, data?.contentMimeType);
              let urlExtension = data?.contentMimeType === "application/pdf" ? "#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0" : "";
              if (!embedFileTypes.includes(data?.contentMimeType) && !data?.contentMimeType.startsWith("image/")) {
                this.noEmbed = true;

                if (this.contentTimer != null) {
                  window.clearInterval(this.contentTimer);
                }
              }

              if (force) {
                this.src = "";
              }

              this.src = URL.createObjectURL(blob) + urlExtension;
              this.contentMimeType = data?.contentMimeType;
              this.documentHash = data?.hash;
            }

            this.refreshLoader = false;
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    refresh() {
      if (this.document === null) {
        return;
      }

      if (this.document.state >= 20) {
        this.refreshLoader = true;
        this.getDocument(true);
      } else {
        this.refreshLoader = true;
        this.getDocumentContent(this.document.id, this.documentHash, true);
      }
    },
    edit() {
      window.open(this.editUrl ?? "", "_blank");
    },
    rename(name) {
      if (this.renaming) {
        return;
      }

      this.renaming = true;

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.rename(this.document.id, name)
          .then(() => {
            this.document.name = name;

            this.renaming = false;

            this.toast.success(this.$t("views.AppDocument.toasts.rename.success"));
          })
          .catch((error) => {
            checkErrors(error);

            this.renaming = false;

            this.toast.error(this.$t("views.AppDocument.toasts.rename.failed"));
          });
    },
    delete() {
      modalOpenDocumentDelete(this.document);
    }
  },
  mounted() {
    this.getDocument();

    DocumentEvents.send(this, () => {
      this.getDocument();
    });
    DocumentEvents.cancel(this, () => {
      this.getDocument();
    });
    DocumentEvents.add(this, () => {
      this.getDocument();
      this.refresh();
    });
    DocumentEvents.delete(this, () => {
      this.getDocument();
      this.refresh();
    });
  },
  unmounted() {
    if (this.contentTimer != null) {
      window.clearInterval(this.contentTimer);
    }
    this.document = null;
  }
}
</script>

<style lang="scss" scoped>
.AppDocument_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppDocument_left {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
  }

  .AppDocument_right {
    flex: 1;
    display: flex;
    flex-direction: row;

    .AppDocument_content {
      flex: 1;
      display: flex;
      flex-direction: row;

      .AppDocument_document {
        flex: 1;
        border: none;
        background-color: white;
      }

      .AppDocument_other {
        width: 100%;
      }

      .AppDocument_pdf {
        pointer-events: unset!important;
        aspect-ratio: 650 / 919;
      }

      .AppDocument_document iframe {
        border: none;
        background-color: white;
        pointer-events: unset!important;
      }
    }

    .AppDocument_sidebar {
      min-width: 19.25rem;
      max-width: 19.25rem;
    }
  }
}
</style>
