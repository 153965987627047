if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test") {
    console.log = function () { };
    console.error = function () { };
}

import { createApp } from "vue";
import App from "./App.vue";

import i18n from "@/utilities/i18n";
import router from "@/router";
import vue3GoogleLogin from "vue3-google-login";
import { options as optionsGoogle } from "@/utilities/google";
import Toast, { POSITION, TYPE } from "vue-toastification";
import "@/assets/css/toast.css";
import store from "@/store";
import IconClose from "@/assets/icons/IconClose.vue";
import IconBlocked from "@/assets/icons/IconBlocked.vue";
import IconCheckmarkCircle from "@/assets/icons/IconCheckmarkCircle.vue";
import guideDirective from "@/directives/guide";

const options = {
    toastDefaults: {
        [TYPE.ERROR]: {
            timeout: 10000,
            position: POSITION.BOTTOM_RIGHT,
            icon: IconBlocked,
            closeButton: IconClose
        },
        [TYPE.SUCCESS]: {
            timeout: 5000,
            position: POSITION.BOTTOM_RIGHT,
            icon: IconCheckmarkCircle,
            closeButton: IconClose
        }
    }
}

const app = createApp(App)
    .use(router)
    .use(i18n)
    .use(vue3GoogleLogin, optionsGoogle)
    .use(Toast, options)
    .use(store)
    .directive("guide", guideDirective);

router.isReady().then(() => app.mount('#app'));
