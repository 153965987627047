<template>
  <div class="ProfileLanguage_container">
    <div class="ProfileLanguage_header">{{ $t("components.ProfileLanguage.header") }}</div>
    <div class="ProfileLanguage_item">
      <div class="ProfileLanguage_title">{{ $t("components.ProfileLanguage.items.language.title") }}</div>
      <div class="ProfileLanguage_content">
        <AppInputSelect v-model="language" :selected="language" :options="[{ name: 'English', value: 'en' }, { name: 'Suomi', value: 'fi' }]" />
      </div>
    </div>
  </div>
</template>

<script>
import AppInputSelect from "@/components/AppInputSelect";
import { changeLanguage } from "@/utilities";
import AuthenticationService from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";
import Account from "@/core/account";

export default {
  name: "ProfileLanguage",
  components: {
    AppInputSelect
  },
  watch: {
    language: function(value) {
      changeLanguage(this, value, true);

      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.setLanguage(value)
          .catch((error) => {
            checkErrors(error);
          });
    }
  },
  data() {
    return {
      language: Account.settings.language
    }
  }
}
</script>

<style lang="scss" scoped>
.ProfileLanguage_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 28.5rem;

  .ProfileLanguage_header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
  }

  .ProfileLanguage_item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .ProfileLanguage_title {
      font-weight: 500;
      font-size: 1.0rem;
      line-height: 1.25rem;
      color: #2d2d2d;
    }

    .ProfileLanguage_content {
      flex: 1;
    }
  }
}
</style>
