<template>
  <AppLoading v-show="loading" />
  <div v-show="!loading" class="signup">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views.SignUpInvites.header") }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views.SignUpInvites.text", { inviteSender: "Rasmus Pohjoiskoski", inviteOrganization: "Sowise" }) }}</span>
      </div>
      <div class="buttons">
        <AppButton @click="accept" :icon="false" size="extra-large">{{ $t("views.SignUpInvites.buttons.accept") }}</AppButton>
        <AppButton @click="skip" :icon="false" size="extra-large" type="empty" primary-color="#626d78">{{ $t("views.SignUpInvites.buttons.skip") }}</AppButton>
      </div>
    </div>
    <div class="right">
      <img class="splash" src="@/assets/images/splash-invites.png" alt="Organization Invites" />
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import router from "@/router";
import AuthenticationService from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";
import DocumentsService from "@/services/documents";
import AppLoading from "@/components/AppLoading";

export default {
  name: "SignUpInvites",
  components: {
    AppLoading,
    AppButton
  },
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

    if (!this.$store.getters["setup/template"] != null) {
      let templateId = this.$store.getters["setup/template"];
      this.$store.dispatch("setup/setTemplate", "");

      let lang = window.localStorage.getItem("language") ?? "en";
      Authentication.setup([], lang)
          .then((data) => {
            if (data.status === "OK") {
              if (data?.account) {
                this.$store.dispatch("account/set", data.account);
              }

              this.$store.dispatch("setup/skipStep"); // this
              this.$store.dispatch("setup/nextStep"); // create organization
              this.$store.dispatch("setup/skipStep"); // organization name
              this.$store.dispatch("setup/skipStep"); // invite users
              this.$store.dispatch("setup/skipStep"); // choose plan
              this.$store.dispatch("setup/skipStep"); // confirm plan

              const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

              Documents.useTemplate(templateId)
                  .then((data) => {
                    if (data.status === "OK") {
                      this.$router.push({ name: "Document", params: { document: data.document.id } });
                    }
                  })
                  .catch((error) => {
                    checkErrors(error);

                    router.push(this.$store.getters["setup/route"]);
                  });
            }
          })
          .catch((error) => {
            checkErrors(error);
          });
    } else {
      this.$store.dispatch("setup/skipStep"); // this
      router.push(this.$store.getters["setup/route"]);
    }
  },
  methods: {
    accept() {
      //TODO: Accept invite
      //this.$store.dispatch("setup/nextStep"); // this
      //router.push(this.$store.getters["setup/route"]);
    },
    skip() {
      this.$store.dispatch("setup/nextStep"); // this
      router.push(this.$store.getters["setup/route"]);
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 87.0rem;

  .left {
    width: 28.5rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      width: fit-content;
      margin-top: 4.0rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
