<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M18.0168 2.75C17.3176 2.75174 16.75 3.32011 16.75 4.01V10.25H19.42C20.0494 10.25 20.4903 10.054 20.7722 9.77217C21.054 9.4903 21.25 9.04943 21.25 8.42V6C21.25 5.10967 20.8867 4.29651 20.2979 3.69852C19.7158 3.11742 18.9052 2.75912 18.0168 2.75ZM15.25 4.01C15.25 2.48095 16.5006 1.25 18.02 1.25H18.0269C19.3161 1.26183 20.5033 1.78264 21.3603 2.63967L21.364 2.64333C22.214 3.50517 22.75 4.69125 22.75 6V8.42C22.75 9.37056 22.446 10.2197 21.8328 10.8328C21.2197 11.446 20.3706 11.75 19.42 11.75H16C15.5858 11.75 15.25 11.4142 15.25 11V4.01Z" />
    <path :fill-rule="evenodd" clip-rule="evenodd" d="M2.30736 2.68106C3.11215 1.74912 4.34646 1.25 6 1.25H18C18.4142 1.25 18.75 1.58579 18.75 2C18.75 2.41421 18.4142 2.75 18 2.75C17.3142 2.75 16.75 3.31421 16.75 4V21C16.75 22.4418 15.1035 23.2564 13.9529 22.4022L13.9506 22.4004L12.2327 21.1145C12.1465 21.0482 12.0086 21.0521 11.9203 21.1403L10.2403 22.8203C9.55744 23.5032 8.44256 23.5032 7.75967 22.8203L7.75808 22.8187L6.09967 21.1503C6.09948 21.1501 6.09986 21.1505 6.09967 21.1503C6.00044 21.0516 5.85765 21.0468 5.76 21.12L4.05289 22.3978C4.05221 22.3983 4.05153 22.3989 4.05084 22.3994C2.89266 23.2743 1.25 22.4434 1.25 21V6C1.25 4.79713 1.52033 3.59244 2.30736 2.68106ZM15.5517 2.75H6C4.65354 2.75 3.88785 3.14588 3.44264 3.66144C2.97967 4.19756 2.75 4.99287 2.75 6V21C2.75 21.2157 2.98539 21.3247 3.14711 21.2022L3.15056 21.1996L4.86 19.92C5.56217 19.3934 6.53959 19.4689 7.16033 20.0897L7.16192 20.0913L8.82033 21.7597C8.82015 21.7595 8.82051 21.7598 8.82033 21.7597C8.91751 21.8563 9.08274 21.8566 9.17967 21.7597L10.8597 20.0797C11.4701 19.4693 12.4493 19.3922 13.1428 19.9221L14.8471 21.1978C14.8473 21.198 14.8476 21.1982 14.8479 21.1984C15.0173 21.3232 15.25 21.1979 15.25 21V4C15.25 3.55044 15.3589 3.12542 15.5517 2.75Z" />
    <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M8.25 13.01C8.25 12.5958 8.58579 12.26 9 12.26H12C12.4142 12.26 12.75 12.5958 12.75 13.01C12.75 13.4242 12.4142 13.76 12 13.76H9C8.58579 13.76 8.25 13.4242 8.25 13.01Z" />
    <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M8.25 9.01C8.25 8.59578 8.58579 8.26 9 8.26H12C12.4142 8.26 12.75 8.59578 12.75 9.01C12.75 9.42421 12.4142 9.76 12 9.76H9C8.58579 9.76 8.25 9.42421 8.25 9.01Z" />
    <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M5.24561 13C5.24561 12.5858 5.58139 12.25 5.99561 12.25H6.00459C6.4188 12.25 6.75459 12.5858 6.75459 13C6.75459 13.4142 6.4188 13.75 6.00459 13.75H5.99561C5.58139 13.75 5.24561 13.4142 5.24561 13Z" />
    <path :fill="color" fill-rule="evenodd" clip-rule="evenodd" d="M5.24561 9C5.24561 8.58579 5.58139 8.25 5.99561 8.25H6.00459C6.4188 8.25 6.75459 8.58579 6.75459 9C6.75459 9.41421 6.4188 9.75 6.00459 9.75H5.99561C5.58139 9.75 5.24561 9.41421 5.24561 9Z" />
  </svg>
</template>

<script>
export default {
  name: "IconBilling",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>