<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" viewBox="0 0 1000 1000" xml:space="preserve">
    <g transform="matrix(-27.9111 28.0995 -28.0995 -27.9111 500 500)" id="332726">
      <path :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-12.0025, -12.0021)" d="M 7.59551 15.3497 C 7.8884 15.0568 8.36327 15.0568 8.65617 15.3497 C 8.94906 15.6426 8.94906 16.1174 8.65617 16.4103 L 5.561 19.5042 H 7.75 C 8.1297 19.5042 8.44349 19.7864 8.49315 20.1524 L 8.5 20.2542 C 8.5 20.6684 8.16421 21.0042 7.75 21.0042 H 3.75 C 3.33579 21.0042 3 20.6684 3 20.2542 V 16.2542 C 3 15.84 3.33579 15.5042 3.75 15.5042 C 4.16421 15.5042 4.5 15.84 4.5 16.2542 V 18.4432 L 7.59551 15.3497 Z M 16.255 21.0042 C 15.8408 21.0042 15.505 20.6684 15.505 20.2542 C 15.505 19.84 15.8408 19.5042 16.255 19.5042 H 18.441 L 15.3495 16.4101 C 15.0833 16.1438 15.0593 15.7271 15.2772 15.4336 L 15.3499 15.3495 C 15.6429 15.0567 16.1178 15.0569 16.4105 15.3499 L 19.505 18.4462 V 16.2542 C 19.505 15.8745 19.7872 15.5607 20.1532 15.5111 L 20.255 15.5042 C 20.6692 15.5042 21.005 15.84 21.005 16.2542 V 20.2542 C 21.005 20.6684 20.6692 21.0042 20.255 21.0042 H 16.255 Z M 7.75 3 C 8.16421 3 8.5 3.33579 8.5 3.75 C 8.5 4.16421 8.16421 4.5 7.75 4.5 H 5.563 L 8.65554 7.59488 C 8.9217 7.86125 8.94574 8.27792 8.72777 8.57145 L 8.65512 8.65554 C 8.36211 8.94832 7.88724 8.94813 7.59446 8.65512 L 4.5 5.558 V 7.75 C 4.5 8.1297 4.21785 8.44349 3.85177 8.49315 L 3.75 8.5 C 3.33579 8.5 3 8.16421 3 7.75 V 3.75 C 3 3.33579 3.33579 3 3.75 3 H 7.75 Z M 20.255 3 C 20.6692 3 21.005 3.33579 21.005 3.75 V 7.75 C 21.005 8.16421 20.6692 8.5 20.255 8.5 C 19.8408 8.5 19.505 8.16421 19.505 7.75 V 5.559 L 16.4104 8.65522 C 16.1442 8.92154 15.7276 8.94583 15.4339 8.72804 L 15.3498 8.65544 C 15.0568 8.3626 15.0567 7.88773 15.3496 7.59478 L 18.442 4.5 H 16.255 C 15.8753 4.5 15.5615 4.21785 15.5118 3.85177 L 15.505 3.75 C 15.505 3.33579 15.8408 3 16.255 3 H 20.255 Z" stroke-linecap="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconOpen",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
