<template>
  <AppModal :active="active" :header="true" :title="$t('modals.DocumentSendLowBalance.title')" icon="warning" :overlayClose="true" @close="modalCloseDocumentSendLowBalance()">
    <div class="ModalDocumentSendLowBalance_container">
      <div class="ModalDocumentSendLowBalance_text">{{ $t("modals.DocumentSendLowBalance.text") }}</div>
      <div class="buttons">
        <AppButton size="medium" :icon="false" @click="this.continue()">{{ $t("modals.DocumentSendLowBalance.buttons.continue") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentSendLowBalance } from "@/modals/index";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalDocumentSendLowBalance",
  components: {
    AppButton,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      modalCloseDocumentSendLowBalance
    };
  },
  methods: {
    continue() {
      modalCloseDocumentSendLowBalance();

      this.$router.push("/settings/billing");
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentSendLowBalance_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentSendLowBalance_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalDocumentSendLowBalance_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-left: auto;
  }
}
</style>
