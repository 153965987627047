<template>
  <AppModal :active="active" :header="true" :title="$t('modals.UserInviteCancel.title')" @close="modalCloseUserInviteCancel()">
    <div class="ModalUserInviteCancel_container">
      <div class="ModalUserInviteCancel_text">
        <span class="user-container">
          <span class="avatar"><AppAvatar name="" size="small" /></span>
          <span class="info-container">
            <span class="name">{{ $t("modals.UserInviteCancel.invitePending") }}</span>
            <span class="email">{{ user?.email }}</span>
          </span>
        </span>
        <div>{{ $t("modals.UserInviteCancel.text") }}</div>
      </div>
      <div class="ModalUserInviteCancel_button">
        <AppButton :loading="cancelLoading" @click="cancel()" :icon="false" size="medium" type="outline" primaryColor="#f14a4a">{{ $t("modals.UserInviteCancel.buttons.cancel") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppAvatar from "@/components/AppAvatar";
import AppButton from "@/components/AppButton";
import { modalCloseUserInviteCancel } from "@/modals/index";
import { useToast } from "vue-toastification";
import events from "@/events";
import Organization from "@/core/organization";
import UserInvite from "@/core/userInvite";
import AuthenticationService from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "ModalUserInviteCancel",
  components: {
    AppModal,
    AppButton,
    AppAvatar
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Organization
    },
    user: {
      type: UserInvite
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseUserInviteCancel,
      toast,
      events
    };
  },
  data() {
    return {
      cancelLoading: false
    };
  },
  methods: {
    cancel() {
      this.cancelLoading = true;

      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.declineInvite(this.user.id)
          .then(() => {
            this.events.$emit("user-invite:cancel", { user: this.user });

            this.toast.success(this.$t("modals.UserInviteCancel.toasts.cancel.success"));

            this.cancelLoading = false;

            modalCloseUserInviteCancel();
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("modals.UserInviteCancel.toasts.cancel.failed"));

            this.cancelLoading = false;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalUserInviteCancel_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalUserInviteCancel_text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;

    .user-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.0rem;

      .avatar {
        height: 2.0rem;
        width: 2.0rem;
        margin-right: 1.0rem;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        height: 2.0rem;

        .name {
          font-size: 0.875rem;
          font-weight: 600;
          color: #2d2d2d;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }

        .email {
          font-size: 0.75rem;
          font-weight: 400;
          color: #626d78;
          vertical-align: center;
          line-height: calc(1.0rem - 0.03125rem);
          padding-top: 0.03125rem;
        }
      }
    }
  }

  .ModalUserInviteCancel_button {
    margin-left: auto;
  }
}
</style>
