<template>
  <AppModal :active="active" :header="true" title="" :overlayClose="true" @close="modalCloseDocumentSendComplete()">
    <div class="ModalDocumentSendComplete_container">
      <img src="@/assets/images/splash-document-sent-for-signing.png" alt="" />
      <div class="ModalDocumentSendComplete_title">{{ $t("modals.DocumentSendComplete.title") }}</div>
      <div class="ModalDocumentSendComplete_text">{{ $t("modals.DocumentSendComplete.text") }}</div>
      <div class="buttons">
        <AppButton size="medium" :icon="false" @click="modalCloseDocumentSendComplete()">{{ $t("modals.DocumentSendComplete.buttons.close") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentSendComplete } from "@/modals/index";
import { useToast } from "vue-toastification";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalDocumentSendComplete",
  components: {
    AppButton,
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: () => { return { type: "folder" }; }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentSendComplete,
      toast
    };
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentSendComplete_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentSendComplete_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalDocumentSendComplete_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-left: auto;
  }
}
</style>
