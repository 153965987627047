import store from "@/store";
import router from "@/router";
import "moment/min/locales.min";
import moment from "moment-timezone";
import PrivilegesService from "@/services/privileges";
import { checkErrors } from "@/utilities/errors";
import Account from "@/core/account";
import PlanService from "@/services/plan";

export const base64Encode = (value) => {
    return new Buffer(value).toString("base64");
};

export const base64Decode = (value) => {
    return new Buffer(value, "base64").toString("ascii");
};

export const base64ToBlob = (base64, type = "application/octet-stream") => {
    const binStr = atob(base64); // TODO: Change atob to base64Decode (breaks document content)
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt( i );
    }

    return new Blob([arr], { type: type });
};

export const accountRoles = (refresh = false, target = null) => {
    if (!store.getters["setup/complete"] && store.getters["session/status"] === "SETUP_REQUIRED") {
        return;
    }

    const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

    Privileges.accountRoles()
        .then((data) => {
            Account.setRoles(data);
        })
        .catch((error) => {
            checkErrors(error);
        });

    const Plan = new PlanService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

    Plan.getPlan()
        .then((data) => {
            Account.selectedOrganization.setPlan(data);

            if (refresh) {
                if (target === null) {
                    router.go();
                } else {
                    router.push(target);
                }
            }
        })
        .catch((error) => {
            checkErrors(error);
        });
};

export const signIn = (instance, data, forward = true) => {
    store.dispatch("session/updateStatus", data.status);

    if (data.account) {
        store.dispatch("account/set", data.account);
        if (!window.localStorage.getItem("language")) {
            changeLanguage(instance, data.account.language);
        }
    }

    if (data.status === "OK") {
        if (forward) {
            accountRoles(true, { name: "Dashboard" });
        } else {
            accountRoles();
        }
    } else if (data.status === "SETUP_REQUIRED") {
        router.push(store.getters["setup/route"]);
    } else if (data.status === "SESSION_INVALID") {
        signOut();
    }
};

export const signOut = (path = "/signin") => {
    store.dispatch("session/clear");
    store.dispatch("account/clear");
    window.localStorage.clear();
    localStorage.clear();

    if (!router.currentRoute.value?.meta?.public) {
        router.push(path);
    }
};

export const changeLanguage = (instance, lang, force = false) => {
    instance.$i18n.locale = lang;
    moment.locale(instance.$i18n.locale);
    store.dispatch("account/set", { language: lang });

    if (force) {
        window.localStorage.setItem("language", lang);
    }
};

export const formatDate = (date, format = "L") => {
    if (format !== "relative") {
        return moment.tz(date, "Europe/Helsinki").format(format);
    }

    return moment.tz(date, "Europe/Helsinki").fromNow();
}
