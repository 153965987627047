<template>
  <div class="AppHeader_header">
    <div class="AppHeader_left">
      <div class="AppHeader_logo" @click="$router.push('/')">
        <img src="@/assets/images/logo-full-white.svg" alt="Sowise" style="cursor: pointer" />
      </div>
      <HeaderSearch />
    </div>
    <div class="AppHeader_right">
      <!--
      TODO: Help
      <HeaderHelp />
      TODO: Notifications
      <HeaderNotification />
      -->
      <HeaderUserAvatar />
    </div>
  </div>
</template>

<script>
import HeaderSearch from "@/components/HeaderSearch";
//import HeaderHelp from "@/components/HeaderHelp";
//import HeaderNotification from "@/components/HeaderNotification";
import HeaderUserAvatar from "@/components/HeaderUserAvatar";

export default {
  name: "AppHeader",
  components: {
    HeaderSearch,
    //HeaderHelp,
    //HeaderNotification,
    HeaderUserAvatar
  }
}
</script>

<style lang="scss" scoped>
.AppHeader_header {
  background-color: #163b61;
  display: flex;
  flex-direction: row;
  align-items: center;

  .AppHeader_left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: left;

    .AppHeader_logo {
      height: 2.5rem;
      margin-left: 0.75rem;
    }
  }

  .AppHeader_right {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>
