import Permission from "@/core/permission";

export default class Role {
    constructor(data) {
        this._id = data.id;
        this._accounts = data.accounts;
        this._name = data.name;
        let permissions = [];

        for (let permission of data.permissions) {
            permissions.push(new Permission(permission));
        }
        this._permissions = permissions;
        this._isDefault = data.isDefault;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get default() {
        return this._isDefault;
    }

    get permissions() {
        return this._permissions;
    }

    get permissionsObject() {
        let permissions = {};

        for (let permission of this._permissions) {
            permissions[permission.key] = permission.value === 1;
        }

        return permissions;
    }

    get accounts() {
        return this._accounts;
    }

    hasPermission(permission) {
        return this._permissions.some(p => p.key === permission && p.value === 1);
    }
}
