import AuthenticationService, { AuthenticationType } from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";
import store from "@/store";
import { signIn } from "@/utilities/index";

export const signInCallback = (instance, data) => {
    const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

    Authentication.login(AuthenticationType.CLIENT_GOOGLE, data.credential)
        .then(function(data) {
            if (data?.session) {
                store.dispatch("session/set", data.session.id);
            }

            if (data.status === "AUTHORIZATION_REQUIRED") {
                window.location.href = data?.redirectUrl;
            } else {
                store.dispatch("setup/reset");

                signIn(instance, data);
            }
        })
        .catch(function(error) {
            checkErrors(error);
        });
};

export const options = {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    buttonConfig: {
        width: 342,
        theme: 'outline',
        shape: 'rectangular',
        text: 'signin_with',
        logo_alignment: 'left'
    }
};
