<template>
  <div class="container">
    <div class="actions">
      <AppButton v-if="Account.permissions?.documents?.edit?.documents && document?.isEditable" @click="$emit('edit')" size="medium" iconType="edit">{{ $t("components.HeaderDocument.buttons.edit") }}</AppButton>
      <!--
      TODO: Archive
      <AppButton size="medium" type="outline" secondaryColor="#466b91" iconType="archive">{{ $t("components.HeaderDocument.buttons.archive") }}</AppButton>
      -->
      <AppButton :loading="loaders.refresh" @click="$emit('refresh')" size="medium" type="outline" secondaryColor="#466b91" iconType="reload">{{ $t("components.HeaderDocument.buttons.refresh") }}</AppButton>
      <AppButton v-if="Account.permissions?.documents?.delete?.documents" @click="$emit('delete')" size="medium" type="outline" secondaryColor="#466b91" iconType="delete">{{ $t("components.HeaderDocument.buttons.delete") }}</AppButton>
    </div>
    <div v-show="!renaming" class="name" @click="Account.permissions?.documents?.edit?.documents && toggleRename()">{{ document?.name }}</div>
    <div v-show="renaming" class="name">
      <AppInputText size="small" stretch ref="nameInput" v-model="name" @blur="rename()" @keyup.enter="rename()" />
    </div>
    <div class="actions-more">
      <AppButton :href="download?.href" target="_blank" :download="download?.fileName" size="medium" type="outline" secondaryColor="#466b91" iconType="download">{{ $t("components.HeaderDocument.buttons.download") }}</AppButton>
      <!--
      TODO: Share
      <AppButton size="medium" iconType="share">{{ $t("components.HeaderDocument.buttons.share") }}</AppButton>
      -->
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppInputText from "@/components/AppInputText";
import { ref } from "vue";
import Account from "@/core/account";

export default {
  name: "HeaderDocument",
  components: {
    AppInputText,
    AppButton
  },
  props: {
    document: {
      type: Object
    },
    download: {
      type: Object
    },
    loaders: {
      type: Object
    }
  },
  setup() {
    const nameInput = ref(null);

    return {
      nameInput,
      Account
    };
  },
  data() {
    return {
      name: "",
      renaming: false
    };
  },
  methods: {
    toggleRename() {
      if (!this.renaming) {
        this.name = this.document?.name;
      }

      this.renaming = !this.renaming;

      if (this.renaming) {
        setTimeout(() => {
          this.nameInput.$refs.textInput.focus();
        }, 250);
      }
    },
    rename() {
      if (this.name !== this.document?.name) {
        this.$emit("rename", this.name);
      }
      this.renaming = false;
    }
  },
  emits: ["edit", "rename", "refresh", "delete", "download"]
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 2.5rem;
  margin-right: 1.0rem;

  .actions {
    height: 2.5rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 1.0rem;
  }

  .name {
    height: 2.5rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1.0rem;
    font-size: 1.0rem;
    font-weight: 400;
    color: white;
  }

  .actions-more {
    height: 2.5rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 1.0rem;
  }
}
</style>
