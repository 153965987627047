export default class User {
    constructor(data, organization) {
        this._organization = organization;

        this.id = data?.id;
        this.fullName = data?.name;
        this.email = data?.email;
    }

    get roles() {
        return this._roles;
    }

    setRoles() {
        let roles = [];

        if (this._organization?.roles) {
            for (let role of this._organization.roles) {
                if (role.accounts.includes(this.id)) {
                    roles.push(role);
                }
            }
        }

        this._roles = roles;
    }
}
