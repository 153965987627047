<template>
  <AppModal :active="active" :header="true" :title="$t('modals.UserInvite.title')" @close="close()">
    <div class="ModalUserInvite_container">
      <div class="ModalUserInvite_text">
        <div>{{ $t("modals.UserInvite.text") }}</div>
        <div>
          <AppInputText ref="emailInput" size="small" v-model="email" :placeholder="$t('modals.UserInvite.inputs.email.placeholder')" stretch />
        </div>
      </div>
      <div class="ModalUserInvite_button">
        <AppButton :loading="inviteLoading" @click="invite()" :icon="false" size="medium">{{ $t("modals.UserInvite.buttons.invite") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseUserInvite } from "@/modals/index";
import AppInputText from "@/components/AppInputText";
import { useToast } from "vue-toastification";
import { checkErrors } from "@/utilities/errors";
import AuthenticationService from "@/services/authentication";
import events from "@/events";
import Organization from "@/core/organization";

export default {
  name: "ModalUserInvite",
  components: {
    AppInputText,
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Organization
    }
  },
  watch: {
    active: function (value) {
      if (value) {
        setTimeout(() => {
          this.$refs.emailInput.$refs.textInput.focus();
        }, 500);
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseUserInvite,
      toast,
      events
    };
  },
  data() {
    return {
      email: "",
      inviteLoading: false
    };
  },
  methods: {
    invite() {
      if (this.email.length === 0) {
        this.toast.error(this.$t("modals.UserInvite.toasts.emailEmpty"));

        return;
      }

      if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
        this.toast.error(this.$t("modals.UserInvite.toasts.emailInvalid"));

        return;
      }

      this.inviteLoading = true;

      let users = this.organization.users;

      for (let i = 0; i < users.length; i++) {
        if (users[i].email === this.email) {
          this.toast.error(this.$t("modals.UserInvite.toasts.invite.alreadyInvited"));

          this.inviteLoading = false;

          return;
        }
      }

      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.invite(this.email)
          .then((data) => {
            if (data.status === "OK") {
              this.toast.success(this.$t("modals.UserInvite.toasts.invite.success"));

              this.events.$emit("user-invite:add"); //TODO: Add -> Authentication::Routes/Invite::InviteResponse::CompanyInvite

              this.close();
            }

            if (data.status === "ALREADY_INVITED") {
              this.toast.error(this.$t("modals.UserInvite.toasts.invite.alreadyInvited"));
            }

            this.inviteLoading = false;
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("modals.UserInvite.toasts.invite.failed"));

            this.inviteLoading = false;
          });
    },
    close() {
      modalCloseUserInvite();

      this.email = "";
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalUserInvite_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalUserInvite_text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalUserInvite_button {
    margin-left: auto;
  }
}
</style>
