<template>
  <Teleport to="body">
    <div class="overlay" :style="offsetStyle">
      <div class="top" :style="topStyle"></div>
      <div class="center">
        <div class="left" :style="leftStyle"></div>
        <div class="center" :style="centerStyle">
          <AppOverlayMask :height="height - 32" :width="width - 32" />
          <div class="help" :style="helpStyle">
            <div class="title"><slot name="title" /></div>
            <div class="text"><slot name="text" /></div>
            <div class="buttons">
              <AppButton @click="$emit('skip')" type="empty" size="medium" primaryColor="#626d78" :icon="false">{{ $t("components.AppGuideStep.buttons.skip") }}</AppButton>
              <AppButton @click="$emit('next')" size="medium" :iconLeft="false" iconType="arrow-right">{{ $t("components.AppGuideStep.buttons.next") }}</AppButton>
            </div>
          </div>
        </div>
        <div class="right" :style="rightStyle"></div>
      </div>
      <div class="bottom" :style="bottomStyle"></div>
    </div>
  </Teleport>
</template>

<script>
import { getGuideElement } from "@/directives/guide";
import AppOverlayMask from "@/components/AppOverlayMask";
import AppButton from "@/components/AppButton";

export default {
  name: "AppGuideStep",
  components: {
    AppButton,
    AppOverlayMask
  },
  props: {
    name: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: "bottom",
      validator: value => ["top", "bottom", "left", "right"].includes(value)
    }
  },
  data() {
    return {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: 0,
      width: 0,
      windowHeight: 0,
      windowWidth: 0,
      overlayHeight: 0,
      overlayWidth: 0
    };
  },
  mounted() {
    this.getBounds();

    window.addEventListener("resize", this.getBounds);
    window.addEventListener("scroll", this.getBounds);
    window.addEventListener("wheel", this.getBounds);
    window.addEventListener("mousewheel", this.getBounds);
  },
  unmounted() {
    window.removeEventListener("resize", this.getBounds);
    window.removeEventListener("scroll", this.getBounds);
    window.removeEventListener("wheel", this.getBounds);
    window.removeEventListener("mousewheel", this.getBounds);
  },
  methods: {
    getBounds() {
      let element = getGuideElement(this.name);
      if (!element) {
        return;
      }

      const bounds = this.getOffset(element);

      this.top = bounds.top - 16;
      this.left = bounds.left - 16;
      this.right = bounds.right;
      this.bottom = bounds.bottom;

      this.height = element.offsetHeight + 32;
      this.width = element.offsetWidth + 32;

      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;

      this.overlayHeight = (Math.abs((-this.top) + this.top) + this.windowHeight) + window.scrollY;
      this.overlayWidth = (Math.abs((-this.left) + this.left) + this.windowWidth) + window.scrollX;
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect();

      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }
  },
  computed: {
    offsetStyle() {
      return {
        top: (-this.top) + this.top + "px",
        left: (-this.left) + this.left + "px",
        height: this.overlayHeight + "px",
        width: this.overlayWidth + "px"
      };
    },
    helpStyle() {
      if (this.position === "top") {
        return {
          top: (this.top - 8) + "px",
          left: this.left + "px"
        };
      }
      if (this.position === "bottom") {
        return {
          top: this.top + this.height + "px",
          left: (this.left + 8) + "px"
        };
      }
      if (this.position === "left") {
        return {
          top: (this.top + 8) + "px",
          left: (this.left - 8) + "px"
        };
      }
      if (this.position === "right") {
        return {
          top: (this.top + 8) + "px",
          left: this.left + this.width + "px"
        };
      }

      return {
        top: this.top + this.height + "px",
        left: (this.left + 8) + "px"
      };
    },
    topStyle() {
      return {
        height: this.top + "px"
      };
    },
    bottomStyle() {
      return {
        height: (this.windowHeight - this.top - this.height) + "px"
      };
    },
    leftStyle() {
      return {
        width: (this.left) + "px"
      };
    },
    rightStyle() {
      return {
        width: (this.windowWidth - this.right) + "px"
      };
    },
    centerStyle() {
      return {
        height: this.height + "px",
        width: this.width + "px"
      };
    }
  },
  emits: ["skip", "next"]
}
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .top {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .center {
    display: flex;
    flex-direction: row;

    .left {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .center {
      .help {
        position: absolute;
        z-index: 1501;
        background-color: white;
        padding: 1.0rem 1.5rem;
        box-shadow: 0 1.25rem 1.5rem rgba(0, 0, 0, 0.12);
        border-radius: 0.75rem;
        max-width: 22.0rem;

        .title {
          font-weight: 600;
          font-size: 1.125rem;
          color: #2d2d2d;
          margin-bottom: 0.5rem;
        }

        .text {
          color: #626d78;
          font-size: 0.875rem;
          font-weight: 400;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 1.0rem;
          gap: 0.5rem;
        }
      }
    }

    .right {
      flex: 1;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .bottom {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
