<template>
  <div class="AppInputNumber_input" :class="'AppInputNumber_' + size + (stretch ? ' AppInputNumber_stretch' : '')">
    <input ref="numberInput" :placeholder="placeholder" :value="modelValue.toString()" @input="change($event.target.value)" type="text" />
  </div>
</template>

<script>
export default {
  name: "AppInputNumber",
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => {
        return [
          "small",
          "medium",
          "large"
        ].includes(value);
      }
    },
    stretch: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    change(value) {
      value = parseInt(value);

      if (isNaN(value) && value !== "") {
        value = 0;
        this.$refs.numberInput.value = value;
      }

      if (value !== "") {
        this.$emit('update:modelValue', value);
      }
    }
  },
  emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
.AppInputNumber_input {
  background-color: white;
  border: 0.03125rem solid #c3c7cc;
  border-radius: 0.3125rem;

  input {
    color: #626d78;
  }
}

.AppInputNumber_small {
  height: 2.25rem;
  min-width: 10.0rem;
  max-width: 18.0rem;

  input {
    height: calc(100% - 1.25rem);
    width: calc(100% - 2.0rem);
    padding: 0.625rem 1.0rem;
    line-height: 1.0rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.AppInputNumber_medium {
  height: 3.0rem;
  min-width: 28.5rem;
  max-width: 30.0rem;

  input {
    height: calc(100% - 2.0rem);
    width: calc(100% - 2.0rem);
    padding: 1.0rem 1.0rem;
    line-height: 1.0rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.AppInputNumber_large {
  height: 3.0rem;
  min-width: 28.5rem;
  max-width: 30.0rem;

  input {
    height: calc(100% - 2.0rem);
    width: calc(100% - 2.0rem);
    padding: 1.0rem 1.0rem;
    line-height: 1.0rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.AppInputNumber_stretch {
  flex: 1;
  width: auto;
  max-width: none;

  input {
    width: calc(100% - 2.0rem);
    max-width: none;
  }
}
</style>
