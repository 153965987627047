<template>
  <AppModal :active="active" :header="true" title="Invite a lawyer" icon="help-chat" @close="modalCloseMockInviteLawyer()">
    <div class="ModalMockInviteLawyer_container">
      <div class="ModalMockInviteLawyer_text">
        <div>
          Customized KYC process +<br />
          Conflict of Interest Questions<br /><br />
          * Information of the Other Party<br />
          * Client Identification<br />
          * Document Verification<br />
          * PEP and Sanctions List Monitoring<br />
        </div>
      </div>
      <div class="ModalMockInviteLawyer_button">
        <AppButton @click="modalCloseMockInviteLawyer()" :icon="false" size="medium">Invite</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseMockInviteLawyer } from "@/modals/index";
import { useToast } from "vue-toastification";
import events from "@/events";

export default {
  name: "ModalMockInviteLawyer",
  components: {
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseMockInviteLawyer,
      toast,
      events
    };
  }
}
</script>

<style lang="scss" scoped>
.ModalMockInviteLawyer_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalMockInviteLawyer_text {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalMockInviteLawyer_button {
    margin-left: auto;
  }
}
</style>
