<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 2.49829C18.934 2.49829 20.5018 4.06609 20.5018 6.00006C20.5018 7.93403 18.934 9.50183 17 9.50183C15.97 9.50183 15.0439 9.05716 14.4032 8.34934L9.84455 10.9538C9.94786 11.2842 10.0035 11.6356 10.0035 12.0001C10.0035 12.3645 9.94786 12.716 9.84455 13.0464L14.4023 15.6518C15.043 14.9434 15.9695 14.4983 17 14.4983C18.934 14.4983 20.5018 16.0661 20.5018 18.0001C20.5018 19.934 18.934 21.5018 17 21.5018C15.066 21.5018 13.4982 19.934 13.4982 18.0001C13.4982 17.6356 13.5539 17.2842 13.6572 16.9538L9.09951 14.3483C8.45873 15.0567 7.53223 15.5018 6.50177 15.5018C4.5678 15.5018 3 13.934 3 12.0001C3 10.0661 4.5678 8.49829 6.50177 8.49829C7.53173 8.49829 8.45784 8.94296 9.09859 9.65078L13.6572 7.04636C13.5539 6.71596 13.4982 6.36451 13.4982 6.00006C13.4982 4.06609 15.066 2.49829 17 2.49829ZM17 15.9983C15.8945 15.9983 14.9982 16.8945 14.9982 18.0001C14.9982 19.1056 15.8945 20.0018 17 20.0018C18.1055 20.0018 19.0018 19.1056 19.0018 18.0001C19.0018 16.8945 18.1055 15.9983 17 15.9983ZM6.50177 9.99829C5.39622 9.99829 4.5 10.8945 4.5 12.0001C4.5 13.1056 5.39622 14.0018 6.50177 14.0018C7.60732 14.0018 8.50354 13.1056 8.50354 12.0001C8.50354 10.8945 7.60732 9.99829 6.50177 9.99829ZM17 3.99829C15.8945 3.99829 14.9982 4.89451 14.9982 6.00006C14.9982 7.10561 15.8945 8.00183 17 8.00183C18.1055 8.00183 19.0018 7.10561 19.0018 6.00006C19.0018 4.89451 18.1055 3.99829 17 3.99829Z" />
  </svg>
</template>

<script>
export default {
  name: "IconShare",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>