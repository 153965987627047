import ConnectorService from "@/services/connector";

export default class PlanService extends ConnectorService {
    constructor(url, id) {
        super(url + "plan/", id);
    }

    async create(plan, monthly) {
        return await this.postp("create", {
            "plan": plan,
            "monthly": monthly
        });
    }

    async getPlan() {
        return await this.getp("plan");
    }

    async upgrade(plan) {
        return await this.postp("upgrade", {
            "plan": plan
        });
    }

    async addBalance(amount) {
        return await this.postp("balance", {
            "amount": amount
        });
    }

    async getTransactions() {
        return await this.getp("transactions");
    }
}
