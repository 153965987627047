<template>
  <div class="signing-result">
    <div class="left">
      <div>
        <img class="logo" src="@/assets/images/logo-full-blue.svg" alt="Sowise" />
      </div>
      <div class="header">
        <span>{{ $t("views.AppSigningResult.header." + $route.params.result + "." + (validSession ? 'signedIn' : 'signedOut')) }}</span>
      </div>
      <div class="text">
        <span>{{ $t("views.AppSigningResult.text." + $route.params.result + "." + (validSession ? 'signedIn' : 'signedOut')) }}</span>
      </div>
      <div class="buttons">
        <AppButton @click="this.continue()" :icon="false" size="extra-large">{{ $t("views.AppSigningResult.buttons.continue." + (validSession ? 'signedIn' : 'signedOut')) }}</AppButton>
      </div>
    </div>
    <div class="right">
      <img class="splash" src="@/assets/images/splash-signed.png" alt="Signing" />
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import Session from "@/core/session";

export default {
  name: "AppSigningResult",
  components: {
    AppButton
  },
  computed: {
    validSession() {
      const sessionId = Session.id;

      return sessionId !== false && sessionId !== null;
    }
  },
  methods: {
    continue() {
      const sessionId = Session.id;

      if (sessionId !== false && sessionId !== null) {
        this.$router.push("/");
      } else {
        this.$router.push("/signup");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.signing-result {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.0rem;
  width: 87.0rem;

  .left {
    width: 28.5rem;
    justify-content: normal;

    .logo {
      height: 4.5rem;
      margin-bottom: 4.0rem;
    }

    .header {
      min-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
      margin-bottom: 0.5rem;
    }

    .text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      display: flex;
      flex-direction: column;
      white-space: pre-line;
    }

    .buttons {
      width: fit-content;
      margin-top: 4.0rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
