import store from "@/store";

const initialState = {
    id: "",
    created: "",
    updated: "",
    subId: "",
    type: "",
    email: "",
    person: {
        id: "",
        created: "",
        updated: "",
        givenName: "",
        familyName: "",
        address: null
    },
    picture: null,
    companies: [],
    language: "",
    wizard: 0,
    wizardSteps: "",
    defaultCompany: "",
    settings: {
        region: "US",
        timezone: "Europe/Helsinki"
    },
    permissions: {}
};

const account = {
    namespaced: true,
    state: initialState,
    getters: {
        id(state) {
            return state.id;
        },
        platform(state) {
            return state.type;
        },
        givenName(state) {
            return state.person.givenName;
        },
        familyName(state) {
            return state.person.familyName;
        },
        fullName(state) {
            if (state.person.givenName === null && state.person.familyName === null) {
                return state.email;
            }

            return (state.person.givenName === null ? '' : state.person.givenName) + ' ' + (state.person.familyName === null ? '' : state.person.familyName);
        },
        email(state) {
            return state.email;
        },
        organizations(state) {
            let organizations = state.companies;

            organizations.sort((a, b) => {
                if (a.name === "Personal" && b.name !== "Personal") {
                    return -1;
                }

                if (a.name !== "Personal" && b.name === "Personal") {
                    return 1;
                }

                return 0;
            });

            return organizations;
        },
        defaultOrganization(state) {
            return state.defaultCompany;
        },
        selectedOrganization(state) {
            let selectedOrganizationId = store.getters["session/selectedOrganization"];

            let selectedOrganization = { name: "" };

            for (let i = 0; i < state.companies.length; i++) {
                if (state.companies[i].id === selectedOrganizationId) {
                    selectedOrganization = state.companies[i];
                    break;
                }
            }

            return selectedOrganization;
        },
        settings(state) {
            return state.settings;
        },
        language(state) {
            return state.language;
        },
        permissions(state) {
            return state.permissions;
        }
    },
    mutations: {
        set(state, value) {
            let selectedChanged = state.selectedOrganization !== value.defaultCompany;
            Object.assign(state, value);
            if (selectedChanged) {
                store.dispatch("session/setSelectedOrganization", state.defaultCompany);
            }
        }
    },
    actions: {
        set(context, value) {
            context.commit("set", value);
        },
        clear(context) {
            context.commit("set", initialState);
        }
    }
};

export default account;
