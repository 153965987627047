import store from "@/store";

const MAX_STEPS = 6; //TODO: Put actual maximum step here

const stepRoutes = [
    { name: "SignUpInvites" },
    { name: "SignUpCreateOrganization" },
    { name: "SignUpOrganizationName" },
    { name: "SignUpInviteUsers" },
    { name: "SignUpChoosePlan" },
    //{ name: "SignUpContactSSales" },
    { name: "SignUpConfirmPlan" },
];

const initialState = {
    step: 0,
    data: [],
    skips: [],
    template: ""
};

const setup = {
    namespaced: true,
    state: initialState,
    getters: {
        step(state) {
            return state.step;
        },
        route(state, getters) {
            if (getters.complete) {
                store.dispatch("session/updateStatus", "OK");

                return { name: "Dashboard" };
            }

            return stepRoutes[state.step];
        },
        data(state) {
            return state.data;
        },
        complete(state) {
            return state.step > (MAX_STEPS - 1);
        },
        template(state) {
            if (state.template === "") {
                return null;
            }

            return state.template;
        }
    },
    mutations: {
        increaseStep(state) {
            state.step++;
        },
        decreaseStep(state) {
            state.step--;
        },
        skipStep(state) {
            state.skips[state.step] = true;
            state.step++;
        },
        addData(state, data) {
            state.data[state.step] = data;
        },
        reset(state) {
            state.step = 0;
            state.data = [];
            state.skips = [];
        },
        resetSkip(state, step) {
            for (let i = step; i < state.skips.length; i++) {
                if (state.skips[i] === true) {
                    state.skips[i] = false;
                }
            }
        },
        setTemplate(state, template) {
            state.template = template;
        }
    },
    actions: {
        nextStep(context, data) {
            context.commit("addData", data);
            context.commit("resetSkip", context.state.step);
            context.commit("increaseStep");
        },
        skipStep(context) {
            context.commit("skipStep");
        },
        previousStep(context) {
            context.commit("decreaseStep");
            if (context.state.skips[context.state.step] === true) {
                store.dispatch("setup/previousStep");
            }
            context.commit("resetSkip", context.state.step);
        },
        reset(context) {
            context.commit("reset");
        },
        setTemplate(context, template) {
            context.commit("setTemplate", template);
        }
    }
};

export default setup;
