<template>
  <svg :fill="active ? color : 'transparent'" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM12.0001 3.49902C7.30481 3.49902 3.49854 7.3053 3.49854 12.0006C3.49854 16.6959 7.30481 20.5021 12.0001 20.5021C16.6954 20.5021 20.5016 16.6959 20.5016 12.0006C20.5016 7.3053 16.6954 3.49902 12.0001 3.49902ZM11.9966 5.99902C15.3093 5.99902 17.9948 8.68447 17.9948 11.9971C17.9948 15.3098 15.3093 17.9953 11.9966 17.9953C8.68398 17.9953 5.99854 15.3098 5.99854 11.9971C5.99854 8.68447 8.68398 5.99902 11.9966 5.99902Z" />
    <path :fill="color" d="M12.0001 22.0021C17.5238 22.0021 22.0016 17.5243 22.0016 12.0006C22.0016 6.47687 17.5238 1.99902 12.0001 1.99902C6.47638 1.99902 1.99854 6.47687 1.99854 12.0006C1.99854 17.5243 6.47638 22.0021 12.0001 22.0021ZM12.0001 20.5021C7.30481 20.5021 3.49854 16.6959 3.49854 12.0006C3.49854 7.3053 7.30481 3.49902 12.0001 3.49902C16.6954 3.49902 20.5016 7.3053 20.5016 12.0006C20.5016 16.6959 16.6954 20.5021 12.0001 20.5021Z" />
  </svg>
</template>

<script>
export default {
  name: "IconRadioButton",
  props: {
    active: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
