<template>
  <span>{{ format() }}</span>
</template>

<script>
export default {
  name: "AppPrice",
  props: {
    type: {
      type: String,
      default: "EUR",
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    format() {
      //TODO change when region is in settings
      return new Intl.NumberFormat(this.$i18n.locale + "-US", { style: "currency", currency: this.type }).format(this.amount);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
