<template>
  <AppModal :active="active" :header="true" title="" :overlayClose="true" @close="modalCloseDocumentUploadFailed()">
    <div class="ModalDocumentUploadFailed_container">
      <img src="@/assets/images/splash-something-went-wrong.png" alt="" />
      <div class="ModalDocumentUploadFailed_title">{{ $t("modals.DocumentUploadFailed.title") }}</div>
      <div class="ModalDocumentUploadFailed_text">{{ $t("modals.DocumentUploadFailed.text") }}</div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import { modalCloseDocumentUploadFailed } from "@/modals/index";
import { useToast } from "vue-toastification";

export default {
  name: "ModalDocumentUploadFailed",
  components: {
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentUploadFailed,
      toast
    };
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentUploadFailed_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentUploadFailed_title {
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    color: #2d2d2d;
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }

  .ModalDocumentUploadFailed_text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4a4a4a;
  }
}
</style>
