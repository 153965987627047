<template>
  <div class="container">
    <AppButton @click="self()" size="small" :icon="false" type="outline" primaryColor="#4198f0" stretch>{{ $t('components.DocumentNavigationSigneesAdd.buttons.self') }}</AppButton>
    <AppInputText size="small" v-model="givenName" :placeholder="$t('components.DocumentNavigationSigneesAdd.inputs.givenName.placeholder')" />
    <AppInputText size="small" v-model="familyName" :placeholder="$t('components.DocumentNavigationSigneesAdd.inputs.familyName.placeholder')" />
    <AppInputText size="small" v-model="email" :placeholder="$t('components.DocumentNavigationSigneesAdd.inputs.email.placeholder')" />
    <div class="level">
      <div class="label">
        {{ $t('components.DocumentNavigationSigneesAdd.inputs.level.label') }}
        <AppTooltip position="right" :text="$t('components.DocumentNavigationSigneesAdd.inputs.level.tooltip')">
          <span class="help-icon"><AppIcon type="help" color="#2d2d2d" /></span>
        </AppTooltip>
      </div>
      <div class="select">
        <AppInputSelect v-model="level" selected="10" :options="[
            { name: $t('components.DocumentNavigationSigneesAdd.levels.simple'), value: '1' },
            { name: $t('components.DocumentNavigationSigneesAdd.levels.advanced'), value: '10' },
            { name: $t('components.DocumentNavigationSigneesAdd.levels.qualified'), value: '20' }
        ]" />
      </div>
    </div>
    <div class="buttons">
      <AppButton @click="cancel()" style="flex: 1" size="small" :icon="false" type="outline" primaryColor="#626d78" stretch>{{ $t('components.DocumentNavigationSigneesAdd.buttons.cancel') }}</AppButton>
      <AppButton :loading="loaders.add" @click="add()" style="flex: 1" size="small" :icon="false" stretch>{{ $t('components.DocumentNavigationSigneesAdd.buttons.add') }}</AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppInputText from "@/components/AppInputText";
import AppInputSelect from "@/components/AppInputSelect";
import SigningService from "@/services/signing";
import { checkErrors } from "@/utilities/errors";
import events from "@/events";
import { useToast } from "vue-toastification";
import AppIcon from "@/components/AppIcon";
import AppTooltip from "@/components/AppTooltip";

export default {
  name: "DocumentNavigationSigneesAdd",
  components: {
    AppTooltip,
    AppIcon,
    AppInputSelect,
    AppInputText,
    AppButton
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    signees: {
      type: Array,
      required: true
    }
  },
  setup() {
    const toast = useToast();

    return {
      toast,
      events
    }
  },
  data() {
    return {
      loaders: {
        add: false
      },
      givenName: "",
      familyName: "",
      email: "",
      level: "10"
    };
  },
  methods: {
    self() {
      this.givenName = this.$store.getters["account/givenName"];
      this.familyName = this.$store.getters["account/familyName"];
      this.email = this.$store.getters["account/email"];
    },
    add() {
      this.loaders.add = true;

      if (this.email === "") {
        this.toast.error(this.$t("components.DocumentNavigationSigneesAdd.toasts.emailRequired"));
        this.loaders.add = false;

        return;
      }

      if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
        this.toast.error(this.$t("components.DocumentNavigationSigneesAdd.toasts.emailInvalid"));
        this.loaders.add = false;

        return;
      }

      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      let alreadyAdded = false;
      this.signees.forEach((signee) => {
        if (signee.email === this.email) {
          alreadyAdded = true;
        }
      });

      if (alreadyAdded) {
        this.toast.error(this.$t("components.DocumentNavigationSigneesAdd.toasts.alreadyAdded"));
        this.loaders.add = false;

        return;
      }

      Signing.addSignee(this.document.id, this.givenName, this.familyName, this.email, Number.parseInt(this.level))
          .then((data) => {
            this.givenName = "";
            this.familyName = "";
            this.email = "";

            this.toast.success(this.$t("components.DocumentNavigationSigneesAdd.toasts.added.success"));

            this.events.$emit("signee:add", { signee: data?.signee });
            this.loaders.add = false;

            this.$emit("close");
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("components.DocumentNavigationSigneesAdd.toasts.added.failed"));
            this.loaders.add = false;
          });
    },
    cancel() {
      this.givenName = "";
      this.familyName = "";
      this.email = "";

      this.$emit("close");
    }
  },
  emits: ["close"]
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .level {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    .label {
      display: inline-flex;
      flex: 1;
      line-height: 1.25rem;
      font-size: 1.0rem;
      font-weight: 500;
      color: #2d2d2d;

      .help-icon {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.5rem;
      }
    }

    .select {
      flex: 1.5;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    height: 1.875rem;
    gap: 0.5rem;
  }
}
</style>
