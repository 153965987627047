<template>
  <div v-if="!document.attachment || $route.name === 'Search'" class="item">
    <template v-for="field in fields" :key="field.name">
      <template v-if="!omit.includes(field.name)">
        <div @click="(field.type !== 'actions') && $emit('click')" class="column" :style="'flex: ' + field.weight">
          <span class="icon" v-if="field.type === 'icon'">
            <AppHint position="bottom" :text="$t('components.DocumentListItem.hints.' + document[field.name])">
              <AppIcon :type="$t('components.DocumentListItem.icons.' + document[field.name])" />
            </AppHint>
          </span>
          <span class="text" v-if="field.type === 'state'"><ResourceState :state="document[field.name]" /></span>
          <span class="text" v-if="field.type === 'text'">{{ document[field.name] }}</span>
          <span class="date" v-if="field.type === 'date'"><AppHint :text="formatDate(document[field.name], 'LLLL')">{{ this["relativeDate_" + field.name].value }}</AppHint></span>
          <span class="actions" v-if="field.type === 'actions'">
            <template v-for="action in field.data" :key="action.name">
              <span @click="$emit('action', { document: document, action: action.name })" class="action" v-if="!action.list">
                <AppHint position="left" :text="$t('components.DocumentListItem.actions.' + action.name + '.label')">
                  <AppIcon color="#2d2d2d" :type="$t('components.DocumentListItem.actions.' + action.name + '.icon')" />
                </AppHint>
              </span>
            </template>
          </span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import ResourceState from "@/components/ResourceState";
import { formatDate } from "@/utilities";
import AppHint from "@/components/AppHint";
import { ref } from "vue";

export default {
  name: "DocumentListItem",
  components: {
    AppHint,
    ResourceState,
    AppIcon
  },
  setup() {
    return {
      formatDate,
      interval: null
    };
  },
  props: {
    document: {
      type: Object,
      required: true,
      default: () => {}
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    omit: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.fields.forEach(field => {
      if (field.type === "date") {
        this["relativeDate_" + field.name] = ref(formatDate(this.document[field.name], "relative"));
      }
    });
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fields.forEach(field => {
        if (field.type === "date") {
          this["relativeDate_" + field.name].value = formatDate(this.document[field.name], "relative");
        }
      });
    }, 15 * 1000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  emits: ["click", "action"]
}
</script>

<style lang="scss" scoped>
.item {
  height: calc(4.5rem - (0.75rem * 2));
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem;
  cursor: pointer;
  border-bottom: solid 0.03125rem #c3c8cc;

  .column {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0rem;
    color: #2d2d2d;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    .icon {
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
    }

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0rem;
      color: #2d2d2d;
    }

    .date {
      color: #626d78;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: right;
      gap: 1.25rem;

      .action {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}
</style>
