<template>
  <div :id="id" class="AppDropdown_dropdown" v-show="open" :style="(offset >= 0) ? ('top: ' + offset + 'rem') : ''">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppDropdown",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Number,
      default: -1
    }
  },
  data() {
    let firstClick = false;

    const closeListener = (e) => {
      if (!this.open) {
        return;
      }

      if (!firstClick) {
        firstClick = true;

        return;
      }

      if (e.target.id !== this.id) {
        firstClick = false;
        this.$emit("close");
      }
    };

    return {
      id: Math.random().toString(36).substring(7),
      closeListener
    };
  },
  mounted() {
    document.addEventListener("click", this.closeListener);
  },
  unmounted() {
    document.removeEventListener("click", this.closeListener);
  },
  emits: ["close"]
}
</script>

<style lang="scss" scoped>
.AppDropdown_dropdown {
  background-color: white;
  box-shadow: 0 1.25rem 1.5rem rgba(0, 0, 0, 0.12);
  border-radius: 0.75rem;
  padding: 1.0rem 1.5rem;
  position: absolute;
  right: 0;
  z-index: 1;
  min-width: 16.0rem;
}
</style>
