import store from "@/store";
import Settings from "@/core/settings";
import Organization from "@/core/organization";
import Role from "@/core/role";
import Permissions from "@/core/permissions";
import events from "@/events";

export default class Account {
    static get id() {
        return store.getters["account/id"];
    }

    static get platform() {
        return store.getters["account/platform"];
    }

    static get fullName() {
        return store.getters["account/fullName"];
    }

    static get email() {
        return store.getters["account/email"];
    }

    static get organizations() {
        let result = [];
        let organizations = store.getters["account/organizations"];
        for (let i = 0; i < organizations.length; i++) {
            result.push(new Organization(organizations[i]));
        }

        return result;
    }

    static get defaultOrganization() {
        return store.getters["account/defaultOrganization"];
    }

    static setDefaultOrganization(organizationId) {
        store.dispatch("account/set", { defaultCompany: organizationId });
    }

    static get selectedOrganization() {
        if (this._organization == null || this._organization.id !== store.getters["session/selectedOrganization"]) {
            this._organization = new Organization(store.getters["account/selectedOrganization"]);
        }

        return this._organization;
    }

    static roles() {
        return Account._roles;
    }

    static setRoles(data) {
        Account._roles = [];

        for (let i = 0; i < data.roles.length; i++) {
            Account._roles.push(new Role(data.roles[i]));
        }

        store.dispatch("account/set", { permissions: new Permissions(Account.roles(), data.isOwner) });
        events.$emit("account:update");
    }

    static get permissions() {
        return store.getters["account/permissions"];
    }

    static get settings() {
        return Settings;
    }
}
