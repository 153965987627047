<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25022 20.5L18.2502 20.5037C18.6644 20.5038 19 20.8397 19 21.2539C19 21.6336 18.7177 21.9473 18.3516 21.9969L18.2498 22.0037L5.24978 22C4.83557 21.9998 4.5 21.664 4.5 21.2497C4.5 20.87 4.78234 20.5563 5.14844 20.5068L5.25022 20.5ZM6.21972 7.21842L11.2174 2.22181C11.4835 1.95572 11.8999 1.93142 12.1935 2.14898L12.2776 2.22151L17.2811 7.21812C17.5742 7.51081 17.5745 7.98568 17.2818 8.27878C17.0157 8.54523 16.5991 8.56972 16.3053 8.35206L16.2211 8.2795L12.5 4.5642V18.2488C12.5 18.6285 12.2178 18.9423 11.8518 18.992L11.75 18.9988C11.3703 18.9988 11.0565 18.7167 11.0068 18.3506L11 18.2488V4.5592L7.28028 8.27919C7.01398 8.54543 6.59732 8.5696 6.30373 8.35171L6.21961 8.27909C5.95338 8.01279 5.92921 7.59613 6.1471 7.30254L6.21972 7.21842L11.2174 2.22181L6.21972 7.21842Z" />
  </svg>
</template>

<script>
export default {
  name: "IconUpload",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>