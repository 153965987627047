<template>
  <AppModal :active="active" :header="false" :progress="80" @close="modalCloseDocumentUploadProgress()">
    <div class="ModalDocumentUploadProgress_container">
      <div class="ModalDocumentUploadProgress_loader-container">
        <div class="ModalDocumentUploadProgress_loader"><img style="height: 10.0rem; width: 10.0rem" src="@/assets/images/upload-circle.svg" alt="" /></div>
        <div class="ModalDocumentUploadProgress_folder"><img src="@/assets/images/upload-folder.svg" alt="" /></div>
      </div>
      <div class="ModalDocumentUploadProgress_percentage">{{ percentage }}%</div>
      <div class="ModalDocumentUploadProgress_text">{{ $t('modals.DocumentUploadProgress.text') }}</div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import {
  modalCloseDocumentUploadProgress,
  modalOpenDocumentUploadComplete,
  modalOpenDocumentUploadFailed
} from "@/modals/index";
import { useToast } from "vue-toastification";
import { checkErrors } from "@/utilities/errors";
import DocumentsService from "@/services/documents";
import events from "@/events";

export default {
  name: "ModalDocumentUploadProgress",
  components: {
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    file: null,
    type: {
      type: String,
      default: "file"
    },
    name: {
      type: String,
      default: "file"
    }
  },
  watch: {
    active: function(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.upload(this.file, this.type, this.name);
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentUploadProgress,
      toast,
      events
    };
  },
  data() {
    return {
      percentage: 0
    }
  },
  created() {
    this.progress = this.progress.bind(this);
  },
  methods: {
    progress(percentage) {
      this.percentage = percentage;
    },
    upload(file, type, name) {
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      let isAttachment = this.$route.name === "Document";
      let documentParent = this.$route.params.parent !== "" ? this.$route.params.parent : null;

      if (isAttachment) {
        documentParent = this.$route.params.document;
      }

      Documents.upload(type, name, file, documentParent, this.progress)
          .then((data) => {
            modalCloseDocumentUploadProgress();

            if (!isAttachment) {
              modalOpenDocumentUploadComplete(data.document);
            }

            this.events.$emit("document:add", { document: data.document });
          })
          .catch((error) => {
            checkErrors(error);

            modalCloseDocumentUploadProgress();

            modalOpenDocumentUploadFailed();
          });
    },
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentUploadProgress_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentUploadProgress_loader-container {
    position: relative;
    display: inline;
    height: 10.0rem;
    width: 10.0rem;
    margin: 3.0rem auto 1.25rem;

    .ModalDocumentUploadProgress_loader {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;

      img {
        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 50% 50%;
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .ModalDocumentUploadProgress_folder {
      position: absolute;
      display: inline;
      top: 2.75rem;
      left: 3.25rem;
    }
  }

  .ModalDocumentUploadProgress_percentage {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #626d78;
    margin-bottom: 1.25rem;
    text-align: center;
  }

  .ModalDocumentUploadProgress_text {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: #2d2d2d;
    margin-bottom: 1.25rem;
    text-align: center;
  }
}
</style>
