<template>
  <IconAlert v-if="type === 'alert'" :color="color" />
  <IconArchive v-if="type === 'archive'" :color="color" />
  <IconArrowDown v-if="type === 'arrow-down'" :color="color" />
  <IconArrowLeft v-if="type === 'arrow-left'" :color="color" />
  <IconArrowRight v-if="type === 'arrow-right'" :color="color" />
  <IconArrowUp v-if="type === 'arrow-up'" :color="color" />
  <IconAttach v-if="type === 'attach'" :color="color" />
  <IconBilling v-if="type === 'billing'" :color="color" />
  <IconBlocked v-if="type === 'blocked'" :color="color" />
  <IconBuilding v-if="type === 'building'" :color="color" />
  <IconBurger v-if="type === 'burger'" :color="color" />
  <IconCalendar v-if="type === 'calendar'" :color="color" />
  <IconChannel v-if="type === 'channel'" :color="color" />
  <IconCheckmark v-if="type === 'checkmark'" :color="color" />
  <IconCheckmarkCircle v-if="type === 'checkmark-circle'" :color="color" />
  <IconChevronDown v-if="type === 'chevron-down'" :color="color" />
  <IconChevronRight v-if="type === 'chevron-right'" :color="color" />
  <IconChevronUp v-if="type === 'chevron-up'" :color="color" />
  <IconChevronLeft v-if="type === 'chevron-left'" :color="color" />
  <IconClipboard v-if="type === 'clipboard'" :color="color" />
  <IconClock v-if="type === 'clock'" :color="color" />
  <IconClose v-if="type === 'close'" :color="color" />
  <IconComment v-if="type === 'comment'" :color="color" />
  <IconCommentPlus v-if="type === 'comment-plus'" :color="color" />
  <IconCopyLink v-if="type === 'copy-link'" :color="color" />
  <IconCreditCard v-if="type === 'credit-card'" :color="color" />
  <IconDelete v-if="type === 'delete'" :color="color" />
  <IconDocument v-if="type === 'document'" :color="color" />
  <IconDownload v-if="type === 'download'" :color="color" />
  <IconDraft v-if="type === 'draft'" :color="color" />
  <IconDraftColored v-if="type === 'draft-colored'" color="white" />
  <IconEdit v-if="type === 'edit'" :color="color" />
  <IconEmoji v-if="type === 'emoji'" :color="color" />
  <IconEmojiAdd v-if="type === 'emoji-add'" :color="color" />
  <IconExpand v-if="type === 'expand'" :color="color" />
  <IconFilter v-if="type === 'filter'" :color="color" />
  <IconFolder v-if="type === 'folder'" :color="color" />
  <IconHelp v-if="type === 'help'" :color="color" />
  <IconHelpBook v-if="type === 'help-book'" :color="color" />
  <IconHelpChat v-if="type === 'help-chat'" :color="color" />
  <IconHistory v-if="type === 'history'" :color="color" />
  <IconImage v-if="type === 'image'" :color="color" />
  <IconInfo v-if="type === 'info'" :color="color" />
  <IconLaptop v-if="type === 'laptop'" :color="color" />
  <IconMail v-if="type === 'mail'" :color="color" />
  <IconMinimize v-if="type === 'minimize'" :color="color" />
  <IconMinus v-if="type === 'minus'" :color="color" />
  <IconMoreHorizontal v-if="type === 'more-horizontal'" :color="color" />
  <IconMoreSquareHorizontal v-if="type === 'more-square-horizontal'" :color="color" />
  <IconMoreSquareVertical v-if="type === 'more-square-vertical'" :color="color" />
  <IconMoreVertical v-if="type === 'more-vertical'" :color="color" />
  <IconNotification v-if="type === 'notification'" :color="color" />
  <IconOpen v-if="type === 'open'" :color="color" />
  <IconPeople v-if="type === 'people'" :color="color" />
  <IconPlusCircle v-if="type === 'plus-circle'" :color="color" />
  <IconPrice v-if="type === 'price'" :color="color" />
  <IconProfile v-if="type === 'profile'" :color="color" />
  <IconReload v-if="type === 'reload'" :color="color" />
  <IconSearch v-if="type === 'search'" :color="color" />
  <IconSearchClose v-if="type === 'search-close'" :color="color" />
  <IconSend v-if="type === 'send'" :color="color" />
  <IconSettings v-if="type === 'settings'" :color="color" />
  <IconShare v-if="type === 'share'" :color="color" />
  <IconSignOut v-if="type === 'sign-out'" :color="color" />
  <IconSignature v-if="type === 'signature'" :color="color" />
  <IconSignatureColored v-if="type === 'signature-colored'" color="white" />
  <IconSigned v-if="type === 'signed'" :color="color" />
  <IconTeam v-if="type === 'team'" :color="color" />
  <IconUpload v-if="type === 'upload'" :color="color" />
  <IconVideo v-if="type === 'video'" :color="color" />
  <IconWand v-if="type === 'wand'" :color="color" />
  <IconWarning v-if="type === 'warning'" :color="color" />
  <IconXSquare v-if="type === 'x-square'" :color="color" />
  <IconZoomIn v-if="type === 'zoom-in'" :color="color" />
  <IconZoomOut v-if="type === 'zoom-out'" :color="color" />
  <IconSpeaker v-if="type === 'speaker'" :color="color" />
  <IconSpeakerMute v-if="type === 'speaker-mute'" :color="color" />
  <IconMove v-if="type === 'move'" :color="color" />
</template>

<script>
import IconAlert from "@/assets/icons/IconAlert.vue";
import IconArchive from "@/assets/icons/IconArchive.vue";
import IconArrowDown from "@/assets/icons/IconArrowDown.vue";
import IconArrowLeft from "@/assets/icons/IconArrowLeft.vue";
import IconArrowRight from "@/assets/icons/IconArrowRight.vue";
import IconArrowUp from "@/assets/icons/IconArrowUp.vue";
import IconAttach from "@/assets/icons/IconAttach.vue";
import IconBilling from "@/assets/icons/IconBilling.vue";
import IconBlocked from "@/assets/icons/IconBlocked.vue";
import IconBuilding from "@/assets/icons/IconBuilding.vue";
import IconBurger from "@/assets/icons/IconBurger.vue";
import IconCalendar from "@/assets/icons/IconCalendar.vue";
import IconChannel from "@/assets/icons/IconChannel.vue";
import IconCheckmark from "@/assets/icons/IconCheckmark.vue";
import IconCheckmarkCircle from "@/assets/icons/IconCheckmarkCircle.vue";
import IconChevronDown from "@/assets/icons/IconChevronDown.vue";
import IconChevronRight from "@/assets/icons/IconChevronRight.vue";
import IconClipboard from "@/assets/icons/IconClipboard.vue";
import IconClock from "@/assets/icons/IconClock.vue";
import IconClose from "@/assets/icons/IconClose.vue";
import IconComment from "@/assets/icons/IconComment.vue";
import IconCommentPlus from "@/assets/icons/IconCommentPlus.vue";
import IconCopyLink from "@/assets/icons/IconCopyLink.vue";
import IconCreditCard from "@/assets/icons/IconCreditCard.vue";
import IconDelete from "@/assets/icons/IconDelete.vue";
import IconDocument from "@/assets/icons/IconDocument.vue";
import IconDownload from "@/assets/icons/IconDownload.vue";
import IconDraft from "@/assets/icons/IconDraft.vue";
import IconEdit from "@/assets/icons/IconEdit.vue";
import IconEmoji from "@/assets/icons/IconEmoji.vue";
import IconEmojiAdd from "@/assets/icons/IconEmojiAdd.vue";
import IconExpand from "@/assets/icons/IconExpand.vue";
import IconFilter from "@/assets/icons/IconFilter.vue";
import IconFolder from "@/assets/icons/IconFolder.vue";
import IconHelp from "@/assets/icons/IconHelp.vue";
import IconHelpBook from "@/assets/icons/IconHelpBook.vue";
import IconHelpChat from "@/assets/icons/IconHelpChat.vue";
import IconHistory from "@/assets/icons/IconHistory.vue";
import IconImage from "@/assets/icons/IconImage.vue";
import IconInfo from "@/assets/icons/IconInfo.vue";
import IconLaptop from "@/assets/icons/IconLaptop.vue";
import IconMail from "@/assets/icons/IconMail.vue";
import IconMinimize from "@/assets/icons/IconMinimize.vue";
import IconMinus from "@/assets/icons/IconMinus.vue";
import IconMoreHorizontal from "@/assets/icons/IconMoreHorizontal.vue";
import IconMoreVertical from "@/assets/icons/IconMoreVertical.vue";
import IconNotification from "@/assets/icons/IconNotification.vue";
import IconOpen from "@/assets/icons/IconOpen.vue";
import IconPeople from "@/assets/icons/IconPeople.vue";
import IconPlusCircle from "@/assets/icons/IconPlusCircle.vue";
import IconPrice from "@/assets/icons/IconPrice.vue";
import IconProfile from "@/assets/icons/IconProfile.vue";
import IconReload from "@/assets/icons/IconReload.vue";
import IconSearch from "@/assets/icons/IconSearch.vue";
import IconSearchClose from "@/assets/icons/IconSearchClose.vue";
import IconSend from "@/assets/icons/IconSend.vue";
import IconSettings from "@/assets/icons/IconSettings.vue";
import IconShare from "@/assets/icons/IconShare.vue";
import IconSignature from "@/assets/icons/IconSignature.vue";
import IconSigned from "@/assets/icons/IconSigned.vue";
import IconSignOut from "@/assets/icons/IconSignOut.vue";
import IconTeam from "@/assets/icons/IconTeam.vue";
import IconUpload from "@/assets/icons/IconUpload.vue";
import IconVideo from "@/assets/icons/IconVideo.vue";
import IconWand from "@/assets/icons/IconWand.vue";
import IconWarning from "@/assets/icons/IconWarning.vue";
import IconXSquare from "@/assets/icons/IconXSquare.vue";
import IconZoomIn from "@/assets/icons/IconZoomIn.vue";
import IconZoomOut from "@/assets/icons/IconZoomOut.vue";
import IconSignatureColored from "@/assets/icons/IconSignatureColored.vue";
import IconDraftColored from "@/assets/icons/IconDraftColored.vue";
import IconChevronUp from "@/assets/icons/IconChevronUp.vue";
import IconChevronLeft from "@/assets/icons/IconChevronLeft.vue";
import IconMoreSquareHorizontal from "@/assets/icons/IconMoreSquareHorizontal.vue";
import IconMoreSquareVertical from "@/assets/icons/IconMoreSquareVertical.vue";
import IconSpeaker from "@/assets/icons/IconSpeaker";
import IconSpeakerMute from "@/assets/icons/IconSpeakerMute";
import IconMove from "@/assets/icons/IconMove";

export default {
  name: "AppIcon",
  components: {
    IconSpeakerMute,
    IconSpeaker,
    IconMoreSquareVertical,
    IconMoreSquareHorizontal,
    IconChevronLeft,
    IconChevronUp,
    IconDraftColored,
    IconSignatureColored,
    IconZoomOut,
    IconZoomIn,
    IconXSquare,
    IconWarning,
    IconWand,
    IconVideo,
    IconUpload,
    IconTeam,
    IconSignOut,
    IconSigned,
    IconSignature,
    IconShare,
    IconSettings,
    IconSend,
    IconSearchClose,
    IconSearch,
    IconReload,
    IconProfile,
    IconPrice,
    IconPlusCircle,
    IconPeople,
    IconOpen,
    IconNotification,
    IconMoreVertical,
    IconMoreHorizontal,
    IconMinus,
    IconMinimize,
    IconMail,
    IconLaptop,
    IconInfo,
    IconImage,
    IconHistory,
    IconHelpChat,
    IconHelpBook,
    IconHelp,
    IconFolder,
    IconFilter,
    IconExpand,
    IconEmojiAdd,
    IconEmoji,
    IconEdit,
    IconDraft,
    IconDownload,
    IconDocument,
    IconDelete,
    IconCreditCard,
    IconCopyLink,
    IconCommentPlus,
    IconComment,
    IconClose,
    IconClock,
    IconClipboard,
    IconChevronRight,
    IconChevronDown,
    IconCheckmarkCircle,
    IconCheckmark,
    IconChannel,
    IconCalendar,
    IconBurger,
    IconBuilding,
    IconBlocked,
    IconBilling,
    IconAttach,
    IconArrowUp,
    IconArrowRight,
    IconArrowLeft,
    IconArrowDown,
    IconArchive,
    IconAlert,
    IconMove
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return [
            "alert",
            "archive",
            "arrow-down",
            "arrow-left",
            "arrow-right",
            "arrow-up",
            "attach",
            "billing",
            "blocked",
            "building",
            "burger",
            "calendar",
            "channel",
            "checkmark",
            "checkmark-circle",
            "chevron-down",
            "chevron-right",
            "chevron-up",
            "chevron-left",
            "clipboard",
            "clock",
            "close",
            "comment",
            "comment-plus",
            "copy-link",
            "credit-card",
            "delete",
            "document",
            "download",
            "draft-colored",
            "draft",
            "edit",
            "emoji",
            "emoji-add",
            "expand",
            "filter",
            "folder",
            "help",
            "help-book",
            "help-chat",
            "history",
            "image",
            "info",
            "laptop",
            "mail",
            "minimize",
            "minus",
            "more-horizontal",
            "more-square-horizontal",
            "more-square-vertical",
            "more-vertical",
            "notification",
            "open",
            "people",
            "plus-circle",
            "price",
            "profile",
            "reload",
            "search",
            "search-close",
            "send",
            "settings",
            "share",
            "signature-colored",
            "signature",
            "signed",
            "sign-out",
            "team",
            "upload",
            "video",
            "wand",
            "warning",
            "x-square",
            "zoom-in",
            "zoom-out",
            "speaker",
            "speaker-mute",
            "move"
        ].includes(value);
      }
    },
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
