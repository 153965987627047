<template>
  <div v-if="subPage === '' || subPage === undefined" class="SettingsPermissions_container">
    <div class="SettingsPermissions_header">
      <div class="SettingsPermissions_title">{{ $t("components.SettingsPermissions.header") }}</div>
      <div v-if="Account.permissions?.organization?.edit?.roles" class="SettingsPermissions_button">
        <AppButton @click="$router.push('/settings/permissions/edit')" type="outline" primaryColor="#4198f0" size="medium">{{ $t("components.SettingsPermissions.buttons.add") }}</AppButton>
      </div>
    </div>
    <div class="SettingsPermissions_content">
      <RoleList @edit="edit($event)" @delete="this.delete($event)" :organization="organization" :roles="organization.roles" />
    </div>
  </div>
  <SettingsPermissionsEdit v-else-if="subPage === 'edit'" :organization="organization" :role="role" />
</template>

<script>
import Organization from "@/core/organization";
import AppButton from "@/components/AppButton";
import RoleList from "@/components/RoleList";
import SettingsPermissionsEdit from "@/components/SettingsPermissionsEdit";
import PrivilegesService from "@/services/privileges";
import { checkErrors } from "@/utilities/errors";
import { useToast } from "vue-toastification";
import events from "@/events";
import Account from "@/core/account";

export default {
  name: "SettingsPermissions",
  components: {
    SettingsPermissionsEdit,
    RoleList,
    AppButton
  },
  props: {
    organization: {
      type: Organization,
      required: true
    }
  },
  watch: {
    $route: function(route) {
      if (route.params.subPage === "" || route.params.subPage === null || route.params.subPage === undefined) {
        this.role = null;
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      toast,
      events,
      Account
    };
  },
  data() {
    return {
      role: null
    };
  },
  methods: {
    edit(role) {
      this.role = role;
      this.$router.push(`/settings/permissions/edit`);
    },
    delete(role) {
      const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Privileges.roleDelete(role.id)
          .then(() => {
            this.toast.success(this.$t("components.SettingsPermissions.toasts.delete.success"));

            this.events.$emit("role:delete");
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("components.SettingsPermissions.toasts.delete.failed"));
          });
    }
  },
  computed: {
    subPage() {
      return this.$route.params.subPage;
    }
  }
}
</script>

<style lang="scss" scoped>
.SettingsPermissions_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;

  .SettingsPermissions_header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
    display: flex;
    flex-direction: row;
    align-items: center;

    .SettingsPermissions_title {
      flex: 1;
    }

    .SettingsPermissions_button {
    }
  }

  .SettingsPermissions_content {
    flex: 1;
  }
}
</style>
