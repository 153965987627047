<template>
  <div class="AppAvatar_avatar" :class="'AppAvatar_' + size + ' ' + (getInitials(name) === '' ? ' AppAvatar_noName' : '')" :style="'background-color: ' + getColor(getInitials(name))">
    <span v-if="getInitials(name) !== ''">{{ getInitials(name) }}</span>
    <span class="AppAvatar_icon-container" v-else>
      <span class="AppAvatar_icon" :class="'AppAvatar_' + size">
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "AppAvatar",
  props: {
    type: {
      type: String,
      default: "initials"
    },
    name: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => {
        return ["small", "medium", "large"].includes(value);
      }
    }
  },
  data() {
    return {
      initials: ""
    }
  },
  methods: {
    getInitials(name) {
      const names = name.split(" ");
      let initials = "";
      names.forEach(name => {
        initials += name.charAt(0);
      });

      return initials.toUpperCase().substring(0, 2);
    },
    getColor(initials) {
      if (initials === "") {
        return "transparent";
      }

      const colors = [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
      ];

      let colorIndex = 0;

      initials.split("").forEach(initial => {
        colorIndex += initial.charCodeAt(0) - 64;
      });

      return colors[Math.round(colorIndex % colors.length)];
    }
  }
}
</script>

<style lang="scss" scoped>
.AppAvatar_avatar {
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
  position: relative;
}

.AppAvatar_small {
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.70rem;
  font-size: 0.75rem;
}

.AppAvatar_medium {
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.5rem;
}

.AppAvatar_large {
  height: 3.0rem;
  width: 3.0rem;
  line-height: 3.0rem;
}

.AppAvatar_noName {
  border: 0.125rem dashed #4198f0;
}

.AppAvatar_icon-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .AppAvatar_icon {
    border-radius: 50%;
    background-color: rgba(65, 152, 240, 0.1);
  }

  .AppAvatar_small {
    width: 1.25rem;
    height: 1.25rem;
  }

  .AppAvatar_medium {
    width: 2.1875rem;
    height: 2.1875rem;
  }

  .AppAvatar_large {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
