<template>
  <div class="cards">
    <DashboardStateCard @click="(stats.draft > 0) && $router.push('/filtered/draft')" state="draft" :number="stats.draft" />
    <DashboardStateCard @click="(stats.signing > 0) && $router.push('/filtered/signing')" state="signing" :number="stats.signing" />
    <DashboardStateCard @click="(stats.signed > 0) && $router.push('/filtered/signed')" state="signed" :number="stats.signed" />
    <DashboardStateCard @click="(stats.archived > 0) && $router.push('/filtered/archived')" state="archived" :number="stats.archived" />
  </div>
</template>

<script>
import DashboardStateCard from "@/components/DashboardStateCard";

export default {
  name: "DashboardStateCards",
  components: {
    DashboardStateCard
  },
  props: {
    stats: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
}
</style>
