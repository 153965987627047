<template>
  <Teleport to="body">
    <div v-show="active" class="AppModal_container" :id="id">
      <div class="AppModal_modal" :class="shake ? 'shake' : ''">
        <div v-if="progress >= 0" class="AppModal_progress" :style="'width: ' + progress + '%' + (progress >= 100 ? '; border-top-right-radius: 0.75rem' : '')"></div>
        <div v-if="header" class="AppModal_header">
          <div v-if="icon !== ''" class="AppModal_icon">
            <AppIcon color="#2d2d2d" :type="icon" />
          </div>
          <div class="AppModal_title">{{ title }}</div>
          <div @click="$emit('close')" class="AppModal_close">
            <AppIcon color="#f14a4a" type="close" />
          </div>
        </div>
        <div class="AppModal_content">
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import Sounds from "@/core/sounds";

export default {
  name: "AppModal",
  components: {
    AppIcon
  },
  props: {
    icon: {
      type: String,
      default: ""
    },
    progress: {
      type: Number,
      default: -1
    },
    header: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    },
    overlayClose: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active: function() {
      this.id = Math.random().toString(36).substring(7);
    }
  },
  mounted() {
    this.id = Math.random().toString(36).substring(7);

    window.addEventListener("click", (e) => {
      if (e.target.id === this.id) {
        if (this.overlayClose) {
          this.$emit("close");
        } else {
          if (this.interval == null) {
            this.shake = true;

            Sounds.play("error");

            this.interval = setInterval(() => {
              this.shake = false;
              clearInterval(this.interval);
              this.interval = null;
            }, 400);
          }
        }
      }
    });
  },
  data() {
    return {
      audio: null,
      interval: null,
      shake: false,
      id: ""
    };
  },
  emits: ["close"]
}
</script>

<style lang="scss" scoped>
.AppModal_container {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  .AppModal_modal {
    display: flex;
    flex-direction: column;
    width: 25.0rem;
    border-radius: 0.75rem;
    box-shadow: 0 1.25rem 1.5rem rgba(0, 0, 0, 0.12);
    position: relative;
    background-color: white;

    .AppModal_progress {
      height: 0.3125rem;
      background-color: #4198f0;
      border-top-left-radius: 0.75rem;
    }

    .AppModal_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.25rem;

      .AppModal_icon {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.75rem;
        padding-bottom: 0.25rem;
      }

      .AppModal_title {
        line-height: 1.25rem;
        font-weight: 600;
        font-size: 1.125rem;
        color: #2d2d2d;
        flex: 1;
      }

      .AppModal_close {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }

    .AppModal_content {
      padding: 0 1.25rem 1.25rem;
    }
  }
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(0.33rem) }
  50% { transform: translateX(-0.33rem) }
  75% { transform: translateX(0.33rem) }
  100% { transform: translateX(0) }
}

.shake {
  animation: horizontal-shaking 0.30s infinite;
}
</style>
