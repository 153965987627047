<template>
  <div class="DocumentNavigationItemGroup_container">
    <div class="DocumentNavigationItemGroup_header">
      <div class="DocumentNavigationItemGroup_title">{{ title }}</div>
      <div class="DocumentNavigationItemGroup_button" @click="$emit('add')">
        <AppIcon v-if="addButton" type="plus-circle" />
      </div>
    </div>
    <NavigationSpacer />
    <div class="DocumentNavigationItemGroup_items">
      <slot />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import NavigationSpacer from "@/components/NavigationSpacer";

export default {
  name: "DocumentNavigationItemGroup",
  components: {
    NavigationSpacer,
    AppIcon
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    addButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ["add"]
}
</script>

<style lang="scss" scoped>
.DocumentNavigationItemGroup_container {
  display: flex;
  flex-direction: column;

  .DocumentNavigationItemGroup_header {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;

    .DocumentNavigationItemGroup_title {
      flex: 1;
      font-size: 1.0rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: #2d2d2d;
    }

    .DocumentNavigationItemGroup_button {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  .DocumentNavigationItemGroup_items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
