import events from "@/events";

export default class Plan {
    constructor(data, organization) {
        this._organization = organization;

        this.company = data?.company;
        this.unpaidPlan = data?.unpaidPlan;
        this.plan = data?.plan;

        events.$emit("plan:update", this);
        /*  this.prices = data.plan.prices;
            this.balance = (data?.company?.freeBalance + data?.company?.paidBalance);
            this.plan = data.plan.name;
            this.unpaidPlan = data.unpaidPlan?.name == null ? '' : data.unpaidPlan?.name;
        */
    }

    get name() {
        return this.plan?.name;
    }

    get balance() {
        return ((this.company?.paid ? this.company?.freeBalance : 0) + this.company?.paidBalance);
    }

    get prices() {
        return this.plan?.prices;
    }

    get signaturePrices() {
        return this.plan?.prices.filter(p => p.name.includes('-'));
    }

    get monthlyPrice() {
        return this.plan?.prices.filter(p => p.name.includes('MONTHLY_PRICE'))[0];
    }

    get canUpgrade() {
        return this.unpaidPlan?.name == null && this.plan?.name !== "personal";
    }
}
