<template>
  <div class="signup">
    <div class="content">
      <div class="left">
        <div class="header">{{ $t("views.AppIntegrationRequest.header") }}</div>
        <div class="content">
          <span>{{ $t("views.AppIntegrationRequest.text") }}</span>
        </div>
        <div class="buttons">
          <AppButton @click="accept()" iconType="checkmark" size="medium">{{ $t("views.AppIntegrationRequest.buttons.accept") }}</AppButton>
          <AppButton @click="decline()" type="outline" primaryColor="#f14a4a" iconType="close" size="medium">{{ $t("views.AppIntegrationRequest.buttons.decline") }}</AppButton>
        </div>
      </div>
      <div class="right">
        <img class="splash" src="@/assets/images/splash-integration-request.png" alt="New Integration" />
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AuthenticationService from "@/services/authentication";
import { checkErrors } from "@/utilities/errors";

export default {
  name: "AppIntegrationRequest",
  components: {
    AppButton
  },
  data() {
    return {
      integrationRequest: null
    };
  },
  methods: {
    accept() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.createApiKey(this.$store.getters["session/integrationRequest"].integration)
          .then(() => {
            this.decline();
          })
          .catch((error) => {
            checkErrors(error);
          });
    },
    decline() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.declineApiRequest(this.$store.getters["session/integrationRequest"].id)
          .then(() => {
            this.$store.commit("session/setIntegrationRequest", null);
            this.$router.push("/");
          })
          .catch((error) => {
            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.5rem;
  width: 78.5rem;
  gap: 2.5rem;
}
.logo {
  height: 4.5rem;
  margin-bottom: 2.5rem;
}
.content {
  display: flex;
  flex-direction: row;

  .left {
    width: 28.5rem;
    justify-content: normal;
    padding-top: 5.0rem;

    .header {
      line-height: 2.0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #163b61;
    }

    .content {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: #626d78;
      white-space: pre-line;
    }

    .buttons {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;
      gap: 1.0rem;
    }
  }

  .right {
    justify-content: center;

    .splash {
      width: 58.5rem;
      aspect-ratio: 468 / 289;
    }
  }
}
</style>
