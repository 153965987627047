<template>
  <div class="ProfileNavigation_navigation">
    <AppButton @click="back()" type="outline" iconType="arrow-left" primaryColor="#626d78" secondaryColor="#c3c8cc">{{ $t("components.ProfileNavigation.buttons.goBack") }}</AppButton>
    <NavigationItemGroup :title="$t('components.ProfileNavigation.personalSettings.title')" stretch style="flex: 1">
      <NavigationItem @click="$router.push('/profile/language-and-region')" icon="clipboard" type="grey">{{ $t("components.ProfileNavigation.personalSettings.items.language") }}</NavigationItem>
      <NavigationItem @click="$router.push('/profile/organizations')" icon="team" type="grey">{{ $t("components.ProfileNavigation.personalSettings.items.organizations") }}</NavigationItem>
      <!--
      TODO: Notifications
      <NavigationItem @click="$router.push('/profile/notifications')" icon="notification" type="grey">{{ $t("components.ProfileNavigation.personalSettings.items.notifications") }}</NavigationItem>
      TODO: Sounds
      <NavigationItem @click="$router.push('/profile/sounds')" icon="speaker" type="grey">{{ $t("components.ProfileNavigation.personalSettings.items.sounds") }}</NavigationItem>
      TODO: Sessions
      <NavigationItem @click="$router.push('/profile/sessions')" icon="history" type="grey">{{ $t("components.ProfileNavigation.personalSettings.items.sessions") }}</NavigationItem>
      -->
    </NavigationItemGroup>
    <NavigationItemGroup class="bottom-group">
      <NavigationItem v-if="Account.permissions?.organization?.view?.details" @click="$router.push({ name: 'Settings' })" icon="settings">{{ $t('components.AppNavigation.menu.settings') }}</NavigationItem>
    </NavigationItemGroup>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import NavigationItemGroup from "@/components/NavigationItemGroup";
import NavigationItem from "@/components/NavigationItem";
import { base64Decode } from "@/utilities";
import Account from "@/core/account";

export default {
  name: "ProfileNavigation",
  components: {
    NavigationItem,
    NavigationItemGroup,
    AppButton
  },
  setup() {
    return {
      Account
    };
  },
  methods: {
    back() {
      if (this.$route.query?.back) {
        this.$router.push(base64Decode(this.$route.query?.back));

        return;
      }

      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
.ProfileNavigation_navigation {
  display: flex;
  flex-direction: column;
  width: calc(19.25rem - 2.0rem);
  padding: 1.5rem 1.0rem;
  background-color: #f7f7f7;
  flex: 1;
  gap: 0.75rem;

  .bottom-group {
    bottom: 0;
  }
}
</style>
