<template>
  <AppModal :active="active" :header="false" :progress="67" @close="modalCloseDocumentCreateProgress()">
    <div class="ModalDocumentCreateProgress_container">
      <div class="ModalDocumentCreateProgress_loader-container">
        <div class="ModalDocumentCreateProgress_loader"><img style="height: 10.0rem; width: 10.0rem" src="@/assets/images/create-circle.svg" alt="" /></div>
        <div class="ModalDocumentCreateProgress_folder"><img src="@/assets/images/create-folder.svg" alt="" /></div>
      </div>
      <div class="ModalDocumentCreateProgress_text">{{ $t('modals.DocumentCreateProgress.text.' + type) }}</div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import {
  modalCloseDocumentCreateProgress,
  modalOpenDocumentCreateComplete
} from "@/modals/index";
import { useToast } from "vue-toastification";
import { checkErrors } from "@/utilities/errors";
import DocumentsService from "@/services/documents";
import events from "@/events";

export default {
  name: "ModalDocumentCreateProgress",
  components: {
    AppModal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "folder"
    },
    name: {
      type: String,
      default: ""
    },
    template: {
      type: Object,
      default: null
    }
  },
  watch: {
    active: function(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.create(this.type, this.name);
      }
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentCreateProgress,
      toast,
      events
    };
  },
  methods: {
    create(type, name) {
      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      if (this.template == null) {
        Documents.create(type, name, this.$route.params.parent !== "" ? this.$route.params.parent : null)
            .then((data) => {
              modalCloseDocumentCreateProgress();

              modalOpenDocumentCreateComplete(data?.document);

              this.events.$emit("document:add", { document: data?.document });
            })
            .catch((error) => {
              checkErrors(error);

              modalCloseDocumentCreateProgress();

              this.toast.error(this.$t("modals.DocumentCreateProgress.toasts.failed." + this.type));
            });
      } else {
        Documents.convert(this.template.id, this.name)
            .then((data) => {
              modalCloseDocumentCreateProgress();

              modalOpenDocumentCreateComplete({ id: data?.documentId, type: "contract" });
            })
            .catch((error) => {
              checkErrors(error);

              modalCloseDocumentCreateProgress();

              this.toast.error(this.$t("modals.DocumentCreateProgress.toasts.failed." + this.type));
            });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentCreateProgress_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentCreateProgress_loader-container {
    position: relative;
    display: inline;
    height: 10.0rem;
    width: 10.0rem;
    margin: 3.0rem auto;

    .ModalDocumentCreateProgress_loader {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;

      img {
        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 50% 50%;
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .ModalDocumentCreateProgress_folder {
      position: absolute;
      display: inline;
      top: 2.75rem;
      left: 3.25rem;
    }
  }

  .ModalDocumentCreateProgress_text {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: #2d2d2d;
    margin-bottom: 1.25rem;
    text-align: center;
  }
}
</style>
