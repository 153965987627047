<template>
  <div class="HeaderUserAvatar_user-avatar">
    <AppAvatar @click="toggle()" class="HeaderUserAvatar_avatar" :name="userFullName" />
    <HeaderUserAvatarDropdown :open="dropdownOpen" @action="toggle()" @close="dropdownOpen = false" />
  </div>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import HeaderUserAvatarDropdown from "@/components/HeaderUserAvatarDropdown";

export default {
  name: "HeaderUserAvatar",
  components: {
    HeaderUserAvatarDropdown,
    AppAvatar
  },
  data() {
    return {
      dropdownOpen: false
    };
  },
  computed: {
    userFullName() {
      return this.$store.getters["account/fullName"];
    }
  },
  methods: {
    toggle() {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderUserAvatar_user-avatar {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1.0rem 0.75rem 1.0rem 1.125rem;

  .HeaderUserAvatar_avatar {
    cursor: pointer;
  }
}
</style>
