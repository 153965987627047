<template>
  <div class="DashboardStateCard_card" :class="'DashboardStateCard_' + state">
    <div class="DashboardStateCard_icon">
      <AppIcon :type="icon" :color="color" />
    </div>
    <div class="DashboardStateCard_text">{{ $t('components.DashboardStateCard.states.' + state) }} <span class="DashboardStateCard_subtext">{{ $t('components.DashboardStateCard.subtext') }}</span></div>
    <div class="DashboardStateCard_number">{{ number }}</div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "DashboardStateCard",
  components: {
    AppIcon
  },
  props: {
    state: {
      type: String,
      required: true,
      validator: (value) => {
        return ["draft", "published", "signing", "signed", "archived"].includes(value);
      }
    },
    number: {
      type: Number,
      required: true,
      default: 0,
      validator: (value) => {
        return value >= 0;
      }
    }
  },
  computed: {
    icon() {
      switch (this.state) {
        case "draft":
          return "draft";
        case "published":
          return "checkmark-circle";
        case "signing":
          return "clock";
        case "signed":
          return "signed";
        case "archived":
          return "archive";
      }

      return "draft";
    },
    color() {
      switch (this.state) {
        case "draft":
          return "#4198f0";
        case "published":
          return "#7761f2";
        case "signing":
          return "#ef9f31";
        case "signed":
          return "#39c178";
        case "archived":
          return "#f14a4a";
      }

      return "#4198f0";
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardStateCard_card {
  flex: 1;
  height: calc(3.5rem - 2.0rem);
  width: calc(19.0rem - 3.0rem);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  padding: 1.0rem 1.5rem;
  align-items: center;
  border: solid 0.0625rem;
  cursor: pointer;

  .DashboardStateCard_icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .DashboardStateCard_text {
    flex: 1;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #2d2d2d;

    .DashboardStateCard_subtext {
      color: #626d78;
      margin-left: 0.5rem;
    }
  }

  .DashboardStateCard_number {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

.DashboardStateCard_draft {
  color: #4198f0;
  background-color: rgba(65, 152, 240, 0.1);
}

.DashboardStateCard_published {
  color: #7761f2;
  background-color: rgba(119, 97, 242, 0.1);
}

.DashboardStateCard_signing {
  color: #ef9f31;
  background-color: rgba(239, 159, 49, 0.1);
}

.DashboardStateCard_signed {
  color: #39c178;
  background-color: rgba(57, 193, 120, 0.1);
}

.DashboardStateCard_archived {
  color: #f14a4a;
  background-color: rgba(241, 74, 74, 0.1);
}
</style>
