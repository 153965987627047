<template>
  <Teleport to="body">
    <div class="AppGuideModal_container">
      <div class="AppGuideModal_modal">
        <div class="AppGuideModal_content">
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: "AppGuideModal"
}
</script>

<style lang="scss" scoped>
.AppGuideModal_container {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);

  .AppGuideModal_modal {
    display: flex;
    flex-direction: column;
    width: 25.0rem;
    border-radius: 0.75rem;
    box-shadow: 0 1.25rem 1.5rem rgba(0, 0, 0, 0.12);
    position: relative;
    background-color: white;

    .AppGuideModal_content {
      padding: 1.25rem;
    }
  }
}
</style>
