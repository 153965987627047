<template>
  <div class="field">
    <div class="label">{{ $t('components.NavigationFieldItem.labels.' + field.name) }}</div>
    <div class="value">
      <span class="text" v-if="field.type === 'state'"><ResourceState :state="document[field.name]" /></span>
      <span class="text" v-if="field.type === 'text'">{{ document[field.name] }}</span>
      <span class="date" v-if="field.type === 'date'"><AppHint position="right" :text="formatDate(document[field.name], 'LLLL')">{{ formatDate(document[field.name]) }}</AppHint></span>
    </div>
  </div>
</template>

<script>
import ResourceState from "@/components/ResourceState";
import AppHint from "@/components/AppHint";
import { formatDate } from "@/utilities";

export default {
  name: "NavigationFieldItem",
  components: {
    ResourceState,
    AppHint
  },
  props: {
    document: {
      type: Object,
      required: true,
      default: null
    },
    field: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  setup() {
    return {
      formatDate
    };
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    flex: 1;
    line-height: 1.0rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: #626d78;
  }

  .value {
    flex: 1;
    line-height: 1.0rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: #2d2d2d;
  }
}
</style>
