<template>
  <div class="SettingsPermissionsEdit_container">
    <div class="SettingsPermissionsEdit_header">
      <div class="SettingsPermissionsEdit_button">
        <AppButton size="medium" type="outline" primaryColor="#4198f0" iconType="arrow-left" style="border-color: #c3c8cc; gap: 0; padding: 0.75rem" @click="$router.push('/settings/permissions')" />
      </div>
      <div class="SettingsPermissionsEdit_title">{{ $t("components.SettingsPermissionsEdit.header." + mode) }}</div>
    </div>
    <div class="SettingsPermissionsEdit_content">
      <div class="SettingsPermissionsEdit_item">
        <div class="SettingsPermissionsEdit_label">{{ $t("components.SettingsPermissionsEdit.inputs.name.label") }}</div>
        <div class="SettingsPermissionsEdit_input">
          <AppInputText v-model="name" size="small" :placeholder="$t('components.SettingsPermissionsEdit.inputs.name.placeholder')" />
        </div>
      </div>
    </div>

    <div class="SettingsPermissionsEdit_header">
      <div class="SettingsPermissionsEdit_title">{{ $t("components.SettingsPermissionsEdit.permissions.header") }}</div>
    </div>
    <div class="SettingsPermissionsEdit_content">
      <div class="SettingsPermissionsEdit_item-toggle">
        <div class="SettingsPermissionsEdit_input"><AppInputToggle v-model="advanced" /></div>
        <div class="SettingsPermissionsEdit_label">{{ $t("components.SettingsPermissionsEdit.inputs.advanced.label") }}</div>
      </div>
      <div v-show="!advanced" class="SettingsPermissionsEdit_simple">
        <AppInputMultiSelect
            v-model="simplePermissions.organization"
            :options="simpleOptions.organization"
            :placeholder="$t('components.SettingsPermissionsEdit.inputs.organization.placeholder')"
        />
        <AppInputMultiSelect
            v-model="simplePermissions.users"
            :options="simpleOptions.users"
            :placeholder="$t('components.SettingsPermissionsEdit.inputs.users.placeholder')"
        />
        <AppInputMultiSelect
            v-model="simplePermissions.documents"
            :options="simpleOptions.documents"
            :placeholder="$t('components.SettingsPermissionsEdit.inputs.documents.placeholder')"
        />
      </div>
      <div v-show="advanced" class="SettingsPermissionsEdit_advanced">
        <div class="SettingsPermissionsEdit_item">
          <div class="SettingsPermissionsEdit_label">{{ $t('components.SettingsPermissionsEdit.inputs.organization.label') }}</div>
          <div class="SettingsPermissionsEdit_input">
            <AppInputMultiSelect
                v-model="advancedPermissions.organization.view"
                :options="advancedOptions.organization.view"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.organizationView.placeholder')"
            />
            <AppInputMultiSelect
                v-model="advancedPermissions.organization.edit"
                :options="advancedOptions.organization.edit"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.organizationEdit.placeholder')"
            />
            <AppInputMultiSelect
                v-model="advancedPermissions.organization.users"
                :options="advancedOptions.organization.users"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.organizationUsers.placeholder')"
            />
          </div>
        </div>

        <div class="SettingsPermissionsEdit_item">
          <div class="SettingsPermissionsEdit_label">{{ $t('components.SettingsPermissionsEdit.inputs.users.label') }}</div>
          <div class="SettingsPermissionsEdit_input">
            <AppInputMultiSelect
                v-model="advancedPermissions.users.view"
                :options="advancedOptions.users.view"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.usersView.placeholder')"
            />
            <AppInputMultiSelect
                v-model="advancedPermissions.users.edit"
                :options="advancedOptions.users.edit"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.usersEdit.placeholder')"
            />
          </div>
        </div>

        <div class="SettingsPermissionsEdit_item">
          <div class="SettingsPermissionsEdit_label">{{ $t('components.SettingsPermissionsEdit.inputs.documents.label') }}</div>
          <div class="SettingsPermissionsEdit_input">
            <AppInputMultiSelect
                v-model="advancedPermissions.documents.view"
                :options="advancedOptions.documents.view"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.documentsView.placeholder')"
            />
            <AppInputMultiSelect
                v-model="advancedPermissions.documents.edit"
                :options="advancedOptions.documents.edit"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.documentsEdit.placeholder')"
            />
            <AppInputMultiSelect
                v-model="advancedPermissions.documents.create"
                :options="advancedOptions.documents.create"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.documentsCreate.placeholder')"
            />
            <AppInputMultiSelect
                v-model="advancedPermissions.documents.delete"
                :options="advancedOptions.documents.delete"
                :placeholder="$t('components.SettingsPermissionsEdit.inputs.documentsDelete.placeholder')"
            />
          </div>
        </div>
      </div>
      <div class="SettingsPermissionsEdit_button">
        <AppButton size="medium" @click="add()">{{ $t("components.SettingsPermissionsEdit.buttons." + mode) }}</AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import Organization from "@/core/organization";
import AppButton from "@/components/AppButton";
import AppInputText from "@/components/AppInputText";
import AppInputToggle from "@/components/AppInputToggle";
import AppInputMultiSelect from "@/components/AppInputMultiSelect";
import Role from "@/core/role";
import PrivilegesService from "@/services/privileges";
import { checkErrors } from "@/utilities/errors";
import { useToast } from "vue-toastification";
import events from "@/events";

export default {
  name: "SettingsPermissionsEdit",
  components: {
    AppInputMultiSelect,
    AppInputToggle,
    AppInputText,
    AppButton
  },
  props: {
    organization: {
      type: Organization,
      required: true
    },
    role: {
      type: Role,
      default: null
    }
  },
  setup() {
    const toast = useToast();

    return {
      toast,
      events
    };
  },
  mounted() {
    if (this.role != null) {
      this.mode = "edit";
      this.name = this.role.name;
      this.objectToSimple(this.role.permissionsObject);
      this.objectToAdvanced(this.role.permissionsObject);
    } else {
      this.name = "";
    }
  },
  data() {
    return {
      mode: "add",
      name: "",
      advanced: false,
      simplePermissions: {
        organization: {
          view: false,
          edit: false
        },
        users: {
          view: false,
          edit: false
        },
        documents: {
          create: false,
          view: false,
          edit: false,
          delete: false
        }
      },
      simpleOptions: {
        organization: [
          { label: this.$t("components.SettingsPermissionsEdit.inputs.organization.options.view"), value: "view" },
          { label: this.$t("components.SettingsPermissionsEdit.inputs.organization.options.edit"), value: "edit" }
        ],
        users: [
          { label: this.$t("components.SettingsPermissionsEdit.inputs.users.options.view"), value: "view" },
          { label: this.$t("components.SettingsPermissionsEdit.inputs.users.options.edit"), value: "edit" }
        ],
        documents: [
          { label: this.$t("components.SettingsPermissionsEdit.inputs.documents.options.create"), value: "create" },
          { label: this.$t("components.SettingsPermissionsEdit.inputs.documents.options.view"), value: "view" },
          { label: this.$t("components.SettingsPermissionsEdit.inputs.documents.options.edit"), value: "edit" },
          { label: this.$t("components.SettingsPermissionsEdit.inputs.documents.options.delete"), value: "delete" }
        ]
      },
      advancedPermissions: {
        organization: {
          view: {
            details: false,
            balance: false,
            users: false,
            roles: false
          },
          edit: {
            details: false,
            roles: false
          },
          users: {
            invite: false,
            remove: false
          }
        },
        users: {
          view: {
            details: false,
            roles: false
          },
          edit: {
            details: false,
            roles: false
          }
        },
        documents: {
          view: {
            documents: false,
            roles: false
          },
          edit: {
            documents: false,
            roles: false
          },
          create: {
            documents: false,
          },
          delete: {
            documents: false,
            roles: false
          }
        }
      },
      advancedOptions: {
        organization: {
          view: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationView.options.details"), value: "details" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationView.options.balance"), value: "balance" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationView.options.users"), value: "users" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationView.options.roles"), value: "roles" }
          ],
          edit: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationEdit.options.details"), value: "details" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationEdit.options.roles"), value: "roles" }
          ],
          users: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationUsers.options.invite"), value: "invite" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.organizationUsers.options.remove"), value: "remove" }
          ]
        },
        users: {
          view: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.usersView.options.details"), value: "details" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.usersView.options.roles"), value: "roles" }
          ],
          edit: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.usersEdit.options.details"), value: "details" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.usersEdit.options.roles"), value: "roles" }
          ]
        },
        documents: {
          view: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.documentsView.options.documents"), value: "documents" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.documentsView.options.roles"), value: "roles" }
          ],
          edit: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.documentsEdit.options.documents"), value: "documents" },
            { label: this.$t("components.SettingsPermissionsEdit.inputs.documentsEdit.options.roles"), value: "roles" }
          ],
          create: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.documentsCreate.options.documents"), value: "documents" },
          ],
          delete: [
            { label: this.$t("components.SettingsPermissionsEdit.inputs.documentsDelete.options.documents"), value: "documents" },
            //{ label: this.$t("components.SettingsPermissionsEdit.inputs.documentsDelete.options.roles"), value: "roles" }
          ]
        }
      }
    }
  },
  methods: {
    simpleToObject() {
      return {
        "1": false,
        "2": this.simplePermissions.organization.view,
        "3": this.simplePermissions.organization.view,
        "4": this.simplePermissions.organization.view,
        "5": this.simplePermissions.organization.view,
        "6": this.simplePermissions.organization.edit,
        "16": this.simplePermissions.organization.edit,
        "7": false,
        "8": this.simplePermissions.organization.edit,
        "9": this.simplePermissions.organization.edit,

        "10": false,
        "11": this.simplePermissions.users.view,
        "12": this.simplePermissions.users.view,
        "13": false,
        "14": this.simplePermissions.users.edit,
        "15": this.simplePermissions.users.edit,

        "17": false,
        "18": this.simplePermissions.documents.view,
        "19": this.simplePermissions.documents.view,
        "20": this.simplePermissions.documents.create,
        "21": false,
        "22": this.simplePermissions.documents.edit,
        "23": this.simplePermissions.documents.edit,
        "24": false,
        "25": this.simplePermissions.documents.delete,
        "26": this.simplePermissions.documents.delete
      };
    },
    advancedToObject() {
      return {
        "1": false,
        "2": this.advancedPermissions.organization.view.details,
        "3": this.advancedPermissions.organization.view.balance,
        "4": this.advancedPermissions.organization.view.users,
        "5": this.advancedPermissions.organization.view.roles,
        "6": this.advancedPermissions.organization.users.invite,
        "16": this.advancedPermissions.organization.users.remove,
        "7": false,
        "8": this.advancedPermissions.organization.edit.details,
        "9": this.advancedPermissions.organization.edit.roles,

        "10": false,
        "11": this.advancedPermissions.users.view.details,
        "12": this.advancedPermissions.users.view.roles,
        "13": false,
        "14": this.advancedPermissions.users.edit.details,
        "15": this.advancedPermissions.users.edit.roles,

        "17": false,
        "18": this.advancedPermissions.documents.view.documents,
        "19": this.advancedPermissions.documents.view.roles,
        "20": this.advancedPermissions.documents.create.documents,
        "21": false,
        "22": this.advancedPermissions.documents.edit.documents,
        "23": this.advancedPermissions.documents.edit.roles,
        "24": false,
        "25": this.advancedPermissions.documents.delete.documents,
        "26": this.advancedPermissions.documents.edit.roles
      };
    },
    objectToSimple(data) {
      let simplePermissions = {
        organization: {
          view: false,
          edit: false
        },
        users: {
          view: false,
          edit: false
        },
        documents: {
          view: false,
          edit: false,
          create: false,
          delete: false
        }
      };

      simplePermissions.organization.view = data["2"] || data["3"] || data["4"] || data["5"];
      simplePermissions.organization.edit = data["6"] || data["16"] || data["8"] || data["9"];
      simplePermissions.users.view = data["11"] || data["12"];
      simplePermissions.users.edit = data["14"] || data["15"];
      simplePermissions.documents.view = data["18"] || data["19"];
      simplePermissions.documents.edit = data["22"] || data["23"];
      simplePermissions.documents.create = data["20"];
      simplePermissions.documents.delete = data["25"] || data["26"];

      Object.assign(this.simplePermissions, simplePermissions);
    },
    objectToAdvanced(data) {
      let advancedPermissions = {
        organization: {
          view: {
            details: false,
            balance: false,
            users: false,
            roles: false
          },
          edit: {
            details: false,
            roles: false
          },
          users: {
            invite: false,
            remove: false
          }
        },
        users: {
          view: {
            details: false,
            roles: false
          },
          edit: {
            details: false,
            roles: false
          }
        },
        documents: {
          view: {
            documents: false,
            roles: false
          },
          edit: {
            documents: false,
            roles: false
          },
          create: {
            documents: false
          },
          delete: {
            documents: false,
            roles: false
          }
        }
      };

      advancedPermissions.organization.view.details = data["2"];
      advancedPermissions.organization.view.balance = data["3"];
      advancedPermissions.organization.view.users = data["4"];
      advancedPermissions.organization.view.roles = data["5"];
      advancedPermissions.organization.users.invite = data["6"];
      advancedPermissions.organization.users.remove = data["16"];
      advancedPermissions.organization.edit.details = data["8"];
      advancedPermissions.organization.edit.roles = data["9"];

      advancedPermissions.users.view.details = data["11"];
      advancedPermissions.users.view.roles = data["12"];
      advancedPermissions.users.edit.details = data["14"];
      advancedPermissions.users.edit.roles = data["15"];

      advancedPermissions.documents.view.documents = data["18"];
      advancedPermissions.documents.view.roles = data["19"];
      advancedPermissions.documents.create.documents = data["20"];
      advancedPermissions.documents.edit.documents = data["22"];
      advancedPermissions.documents.edit.roles = data["23"];
      advancedPermissions.documents.delete.documents = data["25"];
      advancedPermissions.documents.delete.roles = data["26"];

      Object.assign(this.advancedPermissions, advancedPermissions);
    },
    add() {
      if (this.mode === "add") {
        const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

        Privileges.roleAdd(this.name)
            .then((data) => {
              let role = { id: data?.roleId };

              this.edit(role);
            })
            .catch((error) => {
              checkErrors(error);

              this.toast.error(this.$t("components.SettingsPermissionsEdit.toasts." + this.mode + ".failed"));
            });
      } else {
        this.edit(this.role);
      }
    },
    edit(role) {
      const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      let roleObject = null;

      if (!this.advanced) {
        roleObject = this.simpleToObject();
      } else {
        roleObject = this.advancedToObject();
      }

      Privileges.roleUpdate(role.id, this.name, roleObject)
          .then(() => {
            this.toast.success(this.$t("components.SettingsPermissionsEdit.toasts." + this.mode + ".success"));

            this.events.$emit("role:edit");

            this.$router.push("/settings/permissions");
          })
          .catch((error) => {
            checkErrors(error);

            this.toast.error(this.$t("components.SettingsPermissionsEdit.toasts." + this.mode + ".failed"));
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.SettingsPermissionsEdit_container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .SettingsPermissionsEdit_header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #163b61;
    display: flex;
    flex-direction: row;
    align-items: center;

    .SettingsPermissionsEdit_button {
      margin-right: 1.5rem;
    }

    .SettingsPermissionsEdit_title {
      flex: 1;
    }
  }

  .SettingsPermissionsEdit_content {
    margin-top: 1.0rem;
    margin-bottom: 2.5rem;

    .SettingsPermissionsEdit_item {
      display: flex;
      flex-direction: column;

      .SettingsPermissionsEdit_label {
        color: #2d2d2d;
        font-size: 1.0rem;
        font-weight: 500;
      }
    }

    .SettingsPermissionsEdit_item-toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 1.0rem;

      .SettingsPermissionsEdit_input {
        margin-right: 1.0rem;
      }

      .SettingsPermissionsEdit_label {
        padding-top: 0.25rem;
        color: #626d78;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    .SettingsPermissionsEdit_simple {
      display: flex;
      flex-direction: column;
      gap: 1.0rem;
      margin-top: 2.5rem;
    }

    .SettingsPermissionsEdit_advanced {
      display: flex;
      flex-direction: column;
      gap: 1.0rem;
      margin-top: 2.5rem;

      .SettingsPermissionsEdit_item {
        display: flex;
        flex-direction: column;
        gap: 1.0rem;

        .SettingsPermissionsEdit_label {
          color: #2d2d2d;
          font-weight: 600;
          font-size: 1.0rem;
        }

        .SettingsPermissionsEdit_input {
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
        }
      }
    }

    .SettingsPermissionsEdit_button {
      margin-top: 2.5rem;
    }
  }
}
</style>
