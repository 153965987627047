<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path :fill="color" d="M12 23C9.0618 23 6.29942 21.8558 4.2218 19.7782C2.14417 17.7006 1 14.9382 1 12C1 9.0618 2.14421 6.29947 4.2218 4.2218C6.29938 2.14413 9.0618 1 12 1C14.9382 1 17.7006 2.14417 19.7782 4.2218C21.8558 6.29942 23 9.0618 23 12C23 14.9382 21.8558 17.7005 19.7782 19.7782C17.7006 21.8559 14.9382 23 12 23ZM12 2.375C6.69276 2.375 2.375 6.69276 2.375 12C2.375 17.3072 6.69276 21.625 12 21.625C17.3072 21.625 21.625 17.3072 21.625 12C21.625 6.69276 17.3072 2.375 12 2.375Z" />
    <path d="M12 11.3125C11.0523 11.3125 10.2812 10.5415 10.2812 9.59375C10.2812 8.64603 11.0523 7.875 12 7.875C12.9477 7.875 13.7188 8.64603 13.7188 9.59375C13.7188 9.97342 14.0265 10.2812 14.4062 10.2812C14.786 10.2812 15.0938 9.97342 15.0938 9.59375C15.0938 8.12418 14.0634 6.89153 12.6875 6.57795V5.8125C12.6875 5.43283 12.3797 5.125 12 5.125C11.6203 5.125 11.3125 5.43283 11.3125 5.8125V6.57795C9.93655 6.89153 8.90625 8.12418 8.90625 9.59375C8.90625 11.2997 10.2941 12.6875 12 12.6875C12.9477 12.6875 13.7188 13.4585 13.7188 14.4062C13.7188 15.354 12.9477 16.125 12 16.125C11.0523 16.125 10.2812 15.354 10.2812 14.4062C10.2812 14.0266 9.97346 13.7188 9.59375 13.7188C9.21404 13.7188 8.90625 14.0266 8.90625 14.4062C8.90625 15.8758 9.93655 17.1085 11.3125 17.4221V18.1875C11.3125 18.5672 11.6203 18.875 12 18.875C12.3797 18.875 12.6875 18.5672 12.6875 18.1875V17.4221C14.0634 17.1085 15.0938 15.8758 15.0938 14.4062C15.0938 12.7003 13.7059 11.3125 12 11.3125Z" />
  </svg>
</template>

<script>
export default {
  name: "IconPrice",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>