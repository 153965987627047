import events from '@/events';

export const modalOpenSignOut = () => {
    events.$emit("modal:open", { name: "sign-out" });
};

export const modalCloseSignOut = () => {
    events.$emit("modal:close", { name: "sign-out" });
};



export const modalOpenDocumentDelete = (document) => {
    events.$emit("modal:open", { name: "document-delete", data: { document: document } });
};

export const modalCloseDocumentDelete = () => {
    events.$emit("modal:close", { name: "document-delete" });
};



export const modalOpenDocumentRename = (document) => {
    events.$emit("modal:open", { name: "document-rename", data: document });
};

export const modalCloseDocumentRename = () => {
    events.$emit("modal:close", { name: "document-rename" });
};



export const modalOpenDocumentUpload = () => {
    events.$emit("modal:open", { name: "document-upload" });
};

export const modalCloseDocumentUpload = () => {
    events.$emit("modal:close", { name: "document-upload" });
};



export const modalOpenDocumentUploadFileType = (file) => {
    events.$emit("modal:open", { name: "document-upload-file-type", data: file });
};

export const modalCloseDocumentUploadFileType = () => {
    events.$emit("modal:close", { name: "document-upload-file-type" });
};



export const modalOpenDocumentUploadName = (file, type) => {
    events.$emit("modal:open", { name: "document-upload-name", data: { file: file, type: type } });
};

export const modalCloseDocumentUploadName = () => {
    events.$emit("modal:close", { name: "document-upload-name" });
};



export const modalOpenDocumentUploadProgress = (file, type, name) => {
    events.$emit("modal:open", { name: "document-upload-progress", data: { file: file, type: type, name: name } });
};

export const modalCloseDocumentUploadProgress = () => {
    events.$emit("modal:close", { name: "document-upload-progress" });
};



export const modalOpenDocumentUploadComplete = (document) => {
    events.$emit("modal:open", { name: "document-upload-complete", data: { document: document } });
};

export const modalCloseDocumentUploadComplete = () => {
    events.$emit("modal:close", { name: "document-upload-complete" });
};



export const modalOpenDocumentUploadFailed = () => {
    events.$emit("modal:open", { name: "document-upload-failed" });
};

export const modalCloseDocumentUploadFailed = () => {
    events.$emit("modal:close", { name: "document-upload-failed" });
};



export const modalOpenDocumentCreateName = (type, template = null) => {
    events.$emit("modal:open", { name: "document-create-name", data: { type: type, template: template } });
};

export const modalCloseDocumentCreateName = () => {
    events.$emit("modal:close", { name: "document-create-name" });
};



export const modalOpenDocumentCreateProgress = (type, name, template = null) => {
    events.$emit("modal:open", { name: "document-create-progress", data: { type: type, name: name, template: template } });
};

export const modalCloseDocumentCreateProgress = () => {
    events.$emit("modal:close", { name: "document-create-progress" });
};



export const modalOpenDocumentCreateComplete = (document) => {
    events.$emit("modal:open", { name: "document-create-complete", data: { document: document } });
};

export const modalCloseDocumentCreateComplete = () => {
    events.$emit("modal:close", { name: "document-create-complete" });
};



export const modalOpenDocumentSend = (document, signees) => {
    events.$emit("modal:open", { name: "document-send", data: { document: document, signees: signees } });
};

export const modalCloseDocumentSend = () => {
    events.$emit("modal:close", { name: "document-send" });
};



export const modalOpenDocumentSendComplete = () => {
    events.$emit("modal:open", { name: "document-send-complete" });
};

export const modalCloseDocumentSendComplete = () => {
    events.$emit("modal:close", { name: "document-send-complete" });
};



export const modalOpenDocumentSendLowBalance = () => {
    events.$emit("modal:open", { name: "document-send-low-balance" });
};

export const modalCloseDocumentSendLowBalance = () => {
    events.$emit("modal:close", { name: "document-send-low-balance" });
};



export const modalOpenDocumentCancel = (document) => {
    events.$emit("modal:open", { name: "document-cancel", data: { document: document } });
};

export const modalCloseDocumentCancel = () => {
    events.$emit("modal:close", { name: "document-cancel" });
};



export const modalOpenDocumentRoles = (organization, document) => {
    events.$emit("modal:open", { name: "document-roles", data: { organization: organization, document: document } });
};

export const modalCloseDocumentRoles = () => {
    events.$emit("modal:close", { name: "document-roles" });
};



export const modalOpenUserInvite = (organization) => {
    events.$emit("modal:open", { name: "user-invite", data: { organization: organization } });
};

export const modalCloseUserInvite = () => {
    events.$emit("modal:close", { name: "user-invite" });
};



export const modalOpenUserRemove = (organization, user) => {
    events.$emit("modal:open", { name: "user-remove", data: { organization: organization, user: user } });
};

export const modalCloseUserRemove = () => {
    events.$emit("modal:close", { name: "user-remove" });
};



export const modalOpenUserRoles = (organization, user) => {
    events.$emit("modal:open", { name: "user-roles", data: { organization: organization, user: user } });
};

export const modalCloseUserRoles = () => {
    events.$emit("modal:close", { name: "user-roles" });
};



export const modalOpenUserInviteCancel = (organization, user) => {
    events.$emit("modal:open", { name: "user-invite-cancel", data: { organization: organization, user: user } });
};

export const modalCloseUserInviteCancel = () => {
    events.$emit("modal:close", { name: "user-invite-cancel" });
};



export const modalOpenOrganizationInvites = (organizationInvites) => {
    events.$emit("modal:open", { name: "organization-invites", data: { organizationInvites: organizationInvites } });
};

export const modalCloseOrganizationInvites = () => {
    events.$emit("modal:close", { name: "organization-invites" });
};



export const modalOpenPaymentMethodAdd = () => {
    events.$emit("modal:open", { name: "payment-method-add" });
};

export const modalClosePaymentMethodAdd = () => {
    events.$emit("modal:close", { name: "payment-method-add" });
};



export const modalOpenBalanceAdd = () => {
    events.$emit("modal:open", { name: "balance-add" });
};

export const modalCloseBalanceAdd = () => {
    events.$emit("modal:close", { name: "balance-add" });
};



export const modalOpenOrganizationCreate = () => {
    events.$emit("modal:open", { name: "organization-create" });
};

export const modalCloseOrganizationCreate = () => {
    events.$emit("modal:close", { name: "organization-create" });
};



export const modalOpenOrganizationCreateComplete = (name, organizationId) => {
    events.$emit("modal:open", { name: "organization-create-complete", data: { name: name, organizationId: organizationId } });
};

export const modalCloseOrganizationCreateComplete = () => {
    events.$emit("modal:close", { name: "organization-create-complete" });
};



export const modalOpenDocumentSign = (signingUrl) => {
    events.$emit("modal:open", { name: "document-sign", data: { signingUrl: signingUrl } });
};

export const modalCloseDocumentSign = () => {
    events.$emit("modal:close", { name: "document-sign" });
};



export const modalOpenDocumentError = (error) => {
    events.$emit("modal:open", { name: "document-error", data: { error: error } });
};

export const modalCloseDocumentError = () => {
    events.$emit("modal:close", { name: "document-error" });
};



export const modalOpenDocumentMove = (document) => {
    events.$emit("modal:open", { name: "document-move", data: { document: document } });
};

export const modalCloseDocumentMove = () => {
    events.$emit("modal:close", { name: "document-move" });
};


export const modalOpenMockInviteLawyer = () => {
    events.$emit("modal:open", { name: "mock-invite-lawyer" });
};

export const modalCloseMockInviteLawyer = () => {
    events.$emit("modal:close", { name: "mock-invite-lawyer" });
};
