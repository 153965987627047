<template>
  <AppModal :active="active" :title="$t('modals.DocumentSend.title')" @close="modalCloseDocumentSend()">
    <div class="ModalDocumentSend_container">
      <div class="ModalDocumentSend_signees">
        <template v-for="signee in signees" :key="signee.id">
          <div class="ModalDocumentSend_signee-container">
            <div class="ModalDocumentSend_avatar">
              <AppAvatar :name="signee.fullName" />
            </div>
            <div class="ModalDocumentSend_info">
              <div class="ModalDocumentSend_name">{{ hasFullName(signee) ? signee.fullName : signee.email }}</div>
              <div class="ModalDocumentSend_email">{{ hasFullName(signee) ? signee.email : '' }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="ModalDocumentSend_buttons">
        <AppButton :loading="sendLoading" size="medium" :iconLeft="false" iconType="send" @click="send()">{{ $t("modals.DocumentSend.buttons.send") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import Document from "@/core/document";
import AppModal from "@/components/AppModal";
import { modalCloseDocumentSend, modalOpenDocumentSendComplete, modalOpenDocumentSendLowBalance } from "@/modals/index";
import AppButton from "@/components/AppButton";
import AppAvatar from "@/components/AppAvatar";
import { checkErrors } from "@/utilities/errors";
import SigningService from "@/services/signing";
import events from "@/events";

export default {
  name: "ModalDocumentSend",
  components: {
    AppModal,
    AppButton,
    AppAvatar
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Document
    },
    signees: {
      type: Array
    }
  },
  setup() {
    return {
      modalCloseDocumentSend,
      events
    };
  },
  data() {
    return {
      sendLoading: false
    };
  },
  methods: {
    hasFullName(signee) {
      return signee.fullName !== " " && signee.fullName !== "" && signee.fullName != null;
    },
    send() {
      const Signing = new SigningService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      if (this.document == null) {
        return;
      }

      this.sendLoading = true;

      Signing.send(this.document.id)
          .then((data) => {
            if (data.status === "OK") {
              modalCloseDocumentSend();

              this.sendLoading = false;

              this.events.$emit("document:send", { document: data?.document });

              modalOpenDocumentSendComplete();
            }
            if (data.status === "LIMIT_LOW_BALANCE") {
              modalCloseDocumentSend();

              this.sendLoading = false;

              modalOpenDocumentSendLowBalance();
            }
          })
          .catch((error) => {
            checkErrors(error);

            this.sendLoading = false;

            this.toast.error(this.$t("modals.DocumentSend.toasts.failed"));
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentSend_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentSend_signees {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: #2d2d2d;
    margin-bottom: 1.25rem;
    gap: 0.5rem;

    .ModalDocumentSend_signee-container {
      display: flex;
      flex-direction: row;
      height: 2.5rem;
      align-items: center;

      .ModalDocumentSend_avatar {
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 0.75rem;
      }

      .ModalDocumentSend_info {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        padding-top: 0.25rem;

        .ModalDocumentSend_name {
          font-weight: 600;
          color: #2d2d2d;
          font-size: 0.875rem;
          line-height: 1.0rem;
        }

        .ModalDocumentSend_email {
          font-weight: 400;
          color: #626d78;
          font-size: 0.75rem;
          line-height: 1.0rem;
        }
      }
    }
  }

  .ModalDocumentSend_buttons {
    margin-left: auto;
  }
}
</style>
