<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.96967 4.71967C8.67678 5.01256 8.67678 5.48744 8.96967 5.78033L15.1893 12L8.96967 18.2197C8.67678 18.5126 8.67678 18.9874 8.96967 19.2803C9.26256 19.5732 9.73744 19.5732 10.0303 19.2803L16.7803 12.5303C17.0732 12.2374 17.0732 11.7626 16.7803 11.4697L10.0303 4.71967C9.73744 4.42678 9.26256 4.42678 8.96967 4.71967Z" />
  </svg>
</template>

<script>
export default {
  name: "IconChevronRight",
  props: {
    color: {
      type: String,
      default: "#2D2D2D"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>