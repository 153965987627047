const initialState = {
    steps: {
        Dashboard: {
            complete: true,
            step: 0,
            "start": false,
            "select-organization": false,
            "create-button": false,
            "create-folder": false,
            "create-document": false,
            "create-template": false,
            "create-upload": false,
            "end": false
        }
    }
};

const stepOrders = {
    Dashboard: [
        "start",
        "select-organization",
        "create-button",
        "create-folder",
        "create-document",
        "create-template",
        "create-upload",
        "end"
    ]
};

const guides = {
    namespaced: true,
    state: initialState,
    getters: {
        steps(state) {
            return state.steps;
        },
        step: (state) => guide => {
            if (state.steps[guide].step >= stepOrders[guide].length) {
                return false;
            }

            return stepOrders[guide][state.steps[guide].step];
        },
        previousSteps: (state) => (guide) => {
            let result = [];

            for (let i = 0; i < state.steps[guide].step; i++) {
                result.push(stepOrders[guide][i]);
            }

            return result;
        }
    },
    mutations: {
        set(state, { guide, complete }) {
            state.steps[guide].complete = complete;
            if (!complete) {
                state.steps[guide].step = 0;
                for (const step of stepOrders[guide]) {
                    state.steps[guide][step] = false;
                }
            }
        },
        skip(state, guide) {
            for (const stepName of stepOrders[guide]) {
                state.steps[guide][stepName] = true;
            }
            state.steps[guide].complete = true;
        },
        next(state, guide) {
            state.steps[guide].step++;
            if (state.steps[guide].step >= stepOrders[guide].length) {
                state.steps[guide].complete = true;
            }
        }
    },
    actions: {
        set(context, { guide, complete }) {
            context.commit("set", { guide, complete });
        },
        skip(context, guide) {
            context.commit("skip", guide);
        },
        next(context, guide) {
            context.commit("next", guide);
        }
    }
};

export default guides;
