import { signOut } from "@/utilities/index";
import { modalOpenDocumentError } from "@/modals";

export function checkErrors(error) {
    console.error(error);

    checkUnauthorizedAccess(error);

    checkDocumentErrors(error);
}

export function checkUnauthorizedAccess(error) {
    if (error?.cause === "UNAUTHORIZED_ACCESS") {
        signOut("/session-expired");
    }

    if (error?.cause === "UNAUTHORIZED_GOOGLE") {
        signOut("/google-unauthorized");
    }
}

export function checkDocumentErrors(error) {
    if (error?.cause === "FILE_NOT_FOUND") {
        modalOpenDocumentError("fileNotFound");
    }
}
