<template>
  <div class="container">
    <div class="text" :class="active ? 'active' : ''">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTab",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.5rem;
  border-bottom: solid 0.0625rem #4198f0;
  cursor: pointer;

  .text {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.0rem;
    font-weight: 400;
    color: #626d78;
  }

  .active {
    font-weight: 500;
    color: #2d2d2d;
  }
}
</style>
