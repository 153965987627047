import ConnectorService from "@/services/connector";

export default class DocumentsService extends ConnectorService {
    constructor(url, id) {
        super(url + "documents/", id);
    }

    async list(parent = null) {
        return await this.getp("list" + (parent != null ? "/" + parent : ""));
    }

    async create(type, name, parent) {
        return await this.postp("create/" + type + (parent != null ? "/" + parent : ""), {
            name: name
        });
    }

    async upload(type, name, file, parent, callback) {
        let data = new FormData();
        data.append("name", name);
        data.append("file", file);

        return await this.postform("upload/" + type + (parent != null ? "/" + parent : ""), data, null, callback);
    }

    async delete(documentId, permanently = false) {
        return await this.postp("delete" + (permanently ? "/permanently" : ""), {
            documentId: documentId
        });
    }

    async rename(documentId, name = false) {
        return await this.postp("rename", {
            documentId: documentId,
            name: name
        });
    }

    async info(documentId) {
        return await this.getp("info/" + documentId);
    }

    async convert(documentId, name, includeChildren = true, parent = null) {
        return await this.postp("convert/" + documentId, {
            name: name,
            includeChildren: includeChildren,
            parent: parent
        });
    }

    async stats() {
        return await this.getp("stats");
    }

    async move(documentId, folderId) {
        return await this.getp("move/" + documentId + "/" + folderId);
    }

    async search(text) {
        return await this.postp("search", {
            "text": text
        });
    }

    async recent(limit = 10) {
        return await this.postp("search?orderBy=updated&orderValue=DESC&limit=" + limit, {
            "text": ""
        });
    }

    async searchFiltered(filters) {
        return await this.postp("search/" + filters, {
            "text": ""
        });
    }

    async getContent(documentId, hash = null, force = false) {
        return await this.getp("content/" + documentId + (hash != null ? "/" + hash : "") + (force ? "?force=true" : ""));
    }

    async useTemplate(templateId) {
        return await this.getp("use-template/" + templateId);
    }
}
