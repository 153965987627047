export default class UserInviteEvents {
    static add(instance, field) {
        instance.events.$on("user-invite:add", () => {
            if (field instanceof Function) {
                field(instance);

                return;
            }

            //TODO: UserInviteEvents -> Handle list
            console.error("TODO: UserInviteEvents::add -> Handle list")
        });
    }

    static cancel(instance, field) {
        instance.events.$on("user-invite:cancel", () => {
            if (field instanceof Function) {
                field(instance);

                return;
            }

            //TODO: UserInviteEvents -> Handle list
            console.error("TODO: UserInviteEvents::cancel -> Handle list")
        });
    }
}
