<template>
  <AppModal :active="active" :header="true" :title="$t('modals.DocumentDelete.title')" icon="warning" @close="modalCloseDocumentDelete()">
    <div class="ModalDocumentDelete_container">
      <div class="ModalDocumentDelete_text">{{ $t("modals.DocumentDelete.text") }}</div>
      <div class="ModalDocumentDelete_button">
        <AppButton :loading="loaders.delete" @click="this.delete()" type="outline" :icon="false" size="medium" primaryColor="#f14a4a">{{ $t("modals.DocumentDelete.buttons.delete") }}</AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppButton from "@/components/AppButton";
import { modalCloseDocumentDelete } from "@/modals/index";
import DocumentsService from "@/services/documents";
import { checkErrors } from "@/utilities/errors";
import { useToast } from "vue-toastification";
import events from "@/events";

export default {
  name: "ModalDocumentDelete",
  components: {
    AppModal,
    AppButton
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: null
    }
  },
  setup() {
    const toast = useToast();

    return {
      modalCloseDocumentDelete,
      toast,
      events
    };
  },
  data() {
    return {
      loaders: {
        delete: false
      }
    };
  },
  methods: {
    delete() {
      if (this.document === null) {
        return;
      }

      this.loaders.delete = true;

      const Documents = new DocumentsService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Documents.delete(this.document.id, true)
          .then(() => {
            this.toast.success(this.$t("modals.DocumentDelete.toasts.success"));

            this.events.$emit("document:delete", { document: this.document });

            this.loaders.delete = false;

            modalCloseDocumentDelete();

            if (this.$route.name === "Document" && this.$route.params.document === this.document.id) {
              this.$router.push({ name: "Documents" });
            }
          })
          .catch((error) => {
            this.toast.error(this.$t("modals.DocumentDelete.toasts.error"));

            this.loaders.delete = false;

            modalCloseDocumentDelete();

            checkErrors(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalDocumentDelete_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ModalDocumentDelete_text {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 20px;
  }

  .ModalDocumentDelete_button {
    margin-left: auto;
  }
}
</style>
