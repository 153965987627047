<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.71967 15.0303C5.01256 15.3232 5.48744 15.3232 5.78033 15.0303L12 8.81066L18.2197 15.0303C18.5126 15.3232 18.9874 15.3232 19.2803 15.0303C19.5732 14.7374 19.5732 14.2626 19.2803 13.9697L12.5303 7.21967C12.2374 6.92678 11.7626 6.92678 11.4697 7.21967L4.71967 13.9697C4.42678 14.2626 4.42678 14.7374 4.71967 15.0303Z" />
  </svg>
</template>

<script>
export default {
  name: "IconChevronUp",
  props: {
    color: {
      type: String,
      default: "#212121"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>