<template>
  <div class="NavigationItemGroup_item-group">
    <div v-if="title" class="NavigationItemGroup_header" @click="toggle">
      <div class="NavigationItemGroup_text">{{ title }}</div>
      <div class="NavigationItemGroup_dropdown"><AppIcon :type="collapsed ? 'chevron-right' : 'chevron-down'"/></div>
    </div>
    <div v-show="!collapsed" class="NavigationItemGroup_items">
      <slot />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
  name: "NavigationItemGroup",
  components: {
    AppIcon
  },
  data() {
    return {
      collapsed: false
    }
  },
  props: {
    title: {
      type: String
    }
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    }
  }
}
</script>

<style lang="scss" scoped>
.NavigationItemGroup_item-group {
  padding: 0.375rem 0;

  .NavigationItemGroup_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0.5rem;

    .NavigationItemGroup_text {
      flex: 1;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.0rem;
      color: #2c2c2c;
      padding-top: 0.1875rem;
    }

    .NavigationItemGroup_dropdown {
      height: 1.0rem;
      width: 1.0rem;
      align-items: center;
    }
  }

  .NavigationItemGroup_items {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
  }
}
</style>
