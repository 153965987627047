<template>
  <div class="DocumentNavigation_navigation" v-if="document != null">
    <!--<AppButton @click="inviteLawyer()" v-if="document.marketplace !== null" size="medium" iconType="help-chat" primaryColor="#2d2d2d" secondaryColor="white" stretch style="border: solid 0.03125rem #c3c8cc; padding-top: 1.25rem; padding-bottom: 1.25rem">
      Invite a lawyer
    </AppButton>-->
    <AppButton @click="useTemplate()" v-if="document.isTemplate" size="medium" iconType="draft-colored" primaryColor="#2d2d2d" secondaryColor="white" stretch style="border: solid 0.03125rem #c3c8cc; padding-top: 1.25rem; padding-bottom: 1.25rem; margin-bottom: 1.5rem">
      {{ $t("components.DocumentNavigation.buttons.useTemplate") }}
    </AppButton>
    <NavigationFieldItem v-for="field in fields" :key="field.name" :field="field" :document="document" />
    <DocumentNavigationSignees v-if="document.isTemplate || document.isDocument" :document="document" />
    <DocumentNavigationAttachments v-if="document.isTemplate || document.isDocument" :document="document" />
  </div>
</template>

<script>
import NavigationFieldItem from "@/components/NavigationFieldItem";
import DocumentNavigationSignees from "@/components/DocumentNavigationSignees";
import DocumentNavigationAttachments from "@/components/DocumentNavigationAttachments";
import AppButton from "@/components/AppButton";
import { modalOpenDocumentCreateName, modalOpenMockInviteLawyer } from "@/modals";

export default {
  name: "DocumentNavigation",
  components: {
    AppButton,
    DocumentNavigationSignees,
    DocumentNavigationAttachments,
    NavigationFieldItem
  },
  props: {
    document: {
      required: true,
      default: null
    }
  },
  data() {
    return {
      fields: [
        { name: "created", type: "date", weight: "1", header: true },
        { name: "updated", type: "date", weight: "1", header: true },
        { name: "state", type: "state", weight: "0.5", header: true }
      ]
    };
  },
  methods: {
    useTemplate() {
      modalOpenDocumentCreateName("contract", this.document);
    },
    inviteLawyer() {
      //const toast = useToast();

      modalOpenMockInviteLawyer();

      //toast.success("Lawyer invited successfully.");
    }
  }
}
</script>

<style lang="scss" scoped>
.DocumentNavigation_navigation {
  display: flex;
  flex-direction: column;
  width: calc(19.25rem - 2.0rem);
  padding: 1.5rem 1.0rem;
  background-color: #f7f7f7;
  flex: 1;
  gap: 0.75rem;
}
</style>
