<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :fill="backgroundColor" x="0.25" y="0.25" width="23.5" height="23.5" rx="7.75" fill-opacity="0.1" />
    <path :fill="color" d="M12 10.9172C12.5981 10.9172 13.0829 11.402 13.0829 12.0001C13.0829 12.5981 12.5981 13.0829 12 13.0829C11.402 13.0829 10.9172 12.5981 10.9172 12.0001C10.9172 11.402 11.402 10.9172 12 10.9172ZM16 10.9172C16.5981 10.9172 17.0829 11.402 17.0829 12.0001C17.0829 12.5981 16.5981 13.0829 16 13.0829C15.402 13.0829 14.9172 12.5981 14.9172 12.0001C14.9172 11.402 15.402 10.9172 16 10.9172ZM8.00002 10.9172C8.59805 10.9172 9.08286 11.402 9.08286 12.0001C9.08286 12.5981 8.59805 13.0829 8.00002 13.0829C7.40198 13.0829 6.91718 12.5981 6.91718 12.0001C6.91718 11.402 7.40198 10.9172 8.00002 10.9172Z" />
    <rect :stroke="borderColor" x="0.25" y="0.25" width="23.5" height="23.5" rx="7.75" stroke-width="0.5"/>
  </svg>
</template>

<script>
export default {
  name: "IconMoreSquareHorizontal",
  props: {
    color: {
      type: String,
      default: "#4198F0"
    },
    backgroundColor: {
      type: String,
      default: "#4198F0"
    },
    borderColor: {
      type: String,
      default: "#4198F0"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>