<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :fill="backgroundColor" x="0.25" y="0.25" width="23.5" height="23.5" rx="7.75" fill-opacity="0.1"/>
    <path :fill="color" d="M10.9172 12.0001C10.9172 11.402 11.402 10.9172 12 10.9172C12.5981 10.9172 13.0829 11.402 13.0829 12.0001C13.0829 12.5981 12.5981 13.0829 12 13.0829C11.402 13.0829 10.9172 12.5981 10.9172 12.0001ZM10.9172 8.00008C10.9172 7.40204 11.402 6.91724 12 6.91724C12.5981 6.91724 13.0829 7.40204 13.0829 8.00008C13.0829 8.59811 12.5981 9.08292 12 9.08292C11.402 9.08292 10.9172 8.59811 10.9172 8.00008ZM10.9172 16.0001C10.9172 15.402 11.402 14.9172 12 14.9172C12.5981 14.9172 13.0829 15.402 13.0829 16.0001C13.0829 16.5981 12.5981 17.0829 12 17.0829C11.402 17.0829 10.9172 16.5981 10.9172 16.0001Z" />
    <rect :stroke="borderColor" x="0.25" y="0.25" width="23.5" height="23.5" rx="7.75" stroke-width="0.5"/>
  </svg>
</template>

<script>
export default {
  name: "IconMoreSquareVertical",
  props: {
    color: {
      type: String,
      default: "#4198F0"
    },
    backgroundColor: {
      type: String,
      default: "#4198F0"
    },
    borderColor: {
      type: String,
      default: "#4198F0"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>