<template>
  <svg :width="width + 32" :height="height + 32" xmlns="http://www.w3.org/2000/svg">
    <path :d="d" stroke="none" fill="black" fill-rule="evenodd" fill-opacity="0.2" />
  </svg>
</template>

<script>
export default {
  name: "AppOverlayMask",
  props: {
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    }
  },
  computed: {
    d() {
      if (!this.width || !this.height || isNaN(this.width) || isNaN(this.height) || this.width < 0 || this.height < 0) {
        return "M0,0 z";
      }

      return `M16,8 h${this.width} a8,8 0 0 1 8,8 v${this.height} a8,8 0 0 1 -8,8 h-${this.width} a8,8 0 0 1 -8,-8 v-${this.height} a8,8 0 0 1 8,-8 M0,0 h${this.width + 32} v${this.height + 32} h-${this.width + 32} v-${this.height + 32} z`;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
