<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.25 3C20.2165 3 21 3.7835 21 4.75V6.75C21 7.44776 20.5916 8.05015 20.0008 8.33119L20 18.25C20 20.3211 18.3211 22 16.25 22H7.75C5.67893 22 4 20.3211 4 18.25L4.00015 8.33166C3.40883 8.05083 3 7.44816 3 6.75V4.75C3 3.7835 3.7835 3 4.75 3H19.25ZM18.5 8.5H5.5V18.25C5.5 19.4926 6.50736 20.5 7.75 20.5H16.25C17.4926 20.5 18.5 19.4926 18.5 18.25V8.5ZM10 11.5H14C14.4142 11.5 14.75 11.8358 14.75 12.25C14.75 12.6297 14.4678 12.9435 14.1018 12.9932L14 13H10C9.58579 13 9.25 12.6642 9.25 12.25C9.25 11.8703 9.53215 11.5565 9.89823 11.5068L10 11.5H14H10ZM19.25 4.5H4.75C4.61193 4.5 4.5 4.61193 4.5 4.75V6.75C4.5 6.88807 4.61193 7 4.75 7H19.25C19.3881 7 19.5 6.88807 19.5 6.75V4.75C19.5 4.61193 19.3881 4.5 19.25 4.5Z" />
  </svg>
</template>

<script>
export default {
  name: "IconArchive",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>