<template>
  <svg :fill="color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.75049 16.4997H21.2492C21.6635 16.4997 21.9992 16.8355 21.9992 17.2497C21.9992 17.6294 21.7171 17.9432 21.351 17.9929L21.2492 17.9997H2.75049C2.33627 17.9997 2.00049 17.664 2.00049 17.2497C2.00049 16.87 2.28264 16.5563 2.64872 16.5066L2.75049 16.4997H21.2492H2.75049ZM18.2499 5.00024C19.2164 5.00024 19.9999 5.78375 19.9999 6.75024V14.2505C19.9999 15.217 19.2164 16.0005 18.2499 16.0005H5.74986C4.78336 16.0005 3.99986 15.217 3.99986 14.2505V6.75024C3.99986 5.78375 4.78336 5.00024 5.74986 5.00024H18.2499ZM18.2499 6.50024H5.74986C5.61179 6.50024 5.49986 6.61217 5.49986 6.75024V14.2505C5.49986 14.3886 5.61179 14.5005 5.74986 14.5005H18.2499C18.3879 14.5005 18.4999 14.3886 18.4999 14.2505V6.75024C18.4999 6.61217 18.3879 6.50024 18.2499 6.50024Z" />
  </svg>
</template>

<script>
export default {
  name: "IconLaptop",
  props: {
    color: {
      type: String,
      default: "#163B61"
    }
  }
}
</script>

<style lang="scss" scoped>

</style>