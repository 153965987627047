<template>
  <AppHeader />
  <div class="AppSettings_container">
    <div class="AppSettings_left">
      <SettingsNavigation :loaded="loaded" :organization="organization" />
    </div>
    <div class="AppSettings_right">
      <div class="AppSettings_content" v-if="loaded">
        <SettingsInformation v-if="Account.permissions?.organization?.view?.details && (page === 'information' || page === '' || page === undefined)" :organization="organization" />
        <SettingsUsers v-if="Account.permissions?.organization?.view?.users && (page === 'users')" :organization="organization" />
        <SettingsPermissions v-if="Account.permissions?.organization?.view?.roles && (page === 'permissions')" :organization="organization" />
        <SettingsBilling v-if="Account.permissions?.isOwner && (page === 'billing')" />
        <SettingsPricing v-if="page === 'pricing'" :organization="organization" />
      </div>
      <div class="AppSettings_content" v-if="!loaded">
        <AppLoading />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import SettingsNavigation from "@/components/SettingsNavigation";
import SettingsInformation from "@/components/SettingsInformation";
import SettingsUsers from "@/components/SettingsUsers";
import SettingsPermissions from "@/components/SettingsPermissions";
import SettingsBilling from "@/components/SettingsBilling";
import SettingsPricing from "@/components/SettingsPricing";
import Account from "@/core/account";
import PlanService from "@/services/plan";
import { checkErrors } from "@/utilities/errors";
import AppLoading from "@/components/AppLoading";
import AuthenticationService from "@/services/authentication";
import Session from "@/core/session";
import UserEvents from "@/events/user";
import events from "@/events";
import UserInviteEvents from "@/events/userInvite";
import PrivilegesService from "@/services/privileges";
import RoleEvents from "@/events/role";

export default {
  name: "AppSettings",
  components: {
    AppLoading,
    SettingsPricing,
    SettingsBilling,
    SettingsPermissions,
    SettingsUsers,
    SettingsInformation,
    SettingsNavigation,
    AppHeader
  },
  mounted() {
    UserEvents.remove(this, (instance) => {
      instance.getUsers();
    });
    UserInviteEvents.add(this, (instance) => {
      instance.getUserInvites();
    });
    UserInviteEvents.cancel(this, (instance) => {
      instance.getUserInvites();
    });
    RoleEvents.edit(this, (instance) => {
      instance.getRoles();
    });
    RoleEvents.delete(this, (instance) => {
      instance.getRoles();
    });

    this.neededToLoad = 5;
    if (!Account.permissions?.organization?.view?.users) {
      this.neededToLoad -= 2;
    }
    if (!Account.permissions?.organization?.view?.roles && !Account.permissions?.users?.view?.roles) {
      this.neededToLoad--;
    }
    if (!Account.permissions?.isOwner) {
      this.neededToLoad -= 2;
    }

    this.getPersonalOrganization();
    this.getPlan();
    if (Account.permissions?.organization?.view?.users) {
      this.getUsers();
      this.getUserInvites();
    }
    if (Account.permissions?.organization?.view?.roles || Account.permissions?.users?.view?.roles) {
      this.getRoles();
    }
  },
  setup() {
    return {
      Account,
      events,
      neededToLoad: 5
    };
  },
  data() {
    return {
      loadCount: 0,
      personalOrganizationId: "",
      organization: Account.selectedOrganization,
      billing: {
        paymentMethod: false,
        transactions: []
      }
    }
  },
  methods: {
    getPersonalOrganization() {
      const organizations = Account.organizations;

      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].name === "Personal") {
          this.personalOrganizationId = organizations[i].id;

          break;
        }
      }

      this.loadCount++;
    },
    isPersonal() {
      return this.organization.id === this.personalOrganizationId;
    },
    getPlan() {
      const Plan = new PlanService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Plan.getPlan()
          .then((data) => {
            this.organization.setPlan(data);
            this.loadCount++;
          })
          .catch((error) => {
            checkErrors(error);

            this.loadCount++;
          });
    },
    getUsers() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.accounts()
          .then((data) => {
            this.organization.setUsers(data);

            this.loadCount++;
          })
          .catch((error) => {
            checkErrors(error);

            this.loadCount++;
          });
    },
    getUserInvites() {
      const Authentication = new AuthenticationService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Authentication.getInvites("company", Session.selectedOrganization?.id)
          .then((data) => {
            this.organization.setUserInvites(data);

            this.loadCount++;
          })
          .catch((error) => {
            checkErrors(error);

            this.loadCount++;
          });
    },
    getRoles() {
      const Privileges = new PrivilegesService(process.env.VUE_APP_API_URL, process.env.VUE_APP_API_ID);

      Privileges.roles()
          .then((data) => {
            this.organization.setRoles(data?.roles);

            this.loadCount++;
          })
          .catch((error) => {
            checkErrors(error);

            this.loadCount++;
          });
    }
  },
  computed: {
    loaded() {
      return this.loadCount >= this.neededToLoad;
    },
    page() {
      return this.$route.params.page;
    }
  }
}
</script>

<style lang="scss" scoped>
.AppSettings_container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .AppSettings_left {
    display: flex;
    flex-direction: column;
  }

  .AppSettings_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    .AppSettings_content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
